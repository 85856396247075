import React from "react";
import { Typography } from "@material-tailwind/react";
import CloseButton from "../CloseIcon";
import { t } from "i18next";

interface ModalHeaderProps {
    title: string;
    onClose: () => void;
}

export const ModalHeader = ({ title, onClose }: ModalHeaderProps) => {
    return (
        <div className="p-5 space-y-4">
            <div
                className="py-4 px-6 flex items-center border-b border-[#EEEEEEEE]"
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                        border: "0px 0px 1px 0px",
                    }}
                >
                    <Typography
                        style={{
                            display: "flex",
                            color: "#48464C",
                            fontWeight: "400",
                            fontSize: "24px",
                            lineHeight: "32px",
                        }}
                        placeholder={undefined}
                    >
                        {t(title)}
                    </Typography>
                </div>
                <CloseButton className="w-7 h-7 cursor-pointer" onClick={onClose} />
            </div>
        </div>
    );
};

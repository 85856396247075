/** @format */

import React, { useEffect, useState } from "react";
import { ReactComponent as TableIcon } from "../../assets/icons/table_icon.svg";
import { ReactComponent as ChartIcon } from "../../assets/icons/chart_icon.svg";

type ItemType = "table" | "chart";

interface ToggleButtonProps {
  itemType: ItemType;
  setItemType: (type: ItemType) => void;
  onChange?: (newType: ItemType) => void;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({
  itemType,
  onChange,
  setItemType,
}) => {
  const [isActive, setIsActive] = useState(itemType === "chart");

  useEffect(() => {
    setIsActive(itemType === "chart");
  }, [itemType]);

  const toggleActive = () => {
    const newState = !isActive;
    setIsActive(newState);
    const newType = newState ? "chart" : "table";
    setItemType(newType);
    if (onChange) {
      onChange(newType);
    }
  };

  return (
    <div className="inline-block cursor-pointer" onClick={toggleActive}>
      <div
        className={`flex items-center justify-between w-20 h-10 rounded-full p-1 transition-colors duration-300 ease-in-out ${
          isActive ? "bg-white" : "bg-gray-200"
        }`}
      >
        <div
          className={`flex items-center justify-center w-8 h-8 rounded-full transition-all duration-300 ease-in-out ${
            isActive ? "bg-transparent text-gray-400" : "bg-white text-blue-500"
          }`}
        >
          <TableIcon />
        </div>
        <div
          className={`flex items-center justify-center w-8 h-8 rounded-full transition-all duration-300 ease-in-out ${
            isActive ? "bg-blue-500 text-white" : "bg-transparent text-gray-400"
          }`}
        >
          <ChartIcon />
        </div>
      </div>
    </div>
  );
};

export default ToggleButton;

import { ArchiveBoxArrowDownIcon } from "@heroicons/react/24/solid";
import { Typography } from "@material-tailwind/react";
import { t } from "i18next";

export const DataSDK = () => {
    return (
        <div className="w-auto flex mt-6 flex-1 rounded-2xl border border-solid border-[#FFFBFE] bg-white mx-14 shadow-md px-6 py-4 space-x-2 items-center">
            <ArchiveBoxArrowDownIcon className="w-auto h-12 text-[#E6E0E9] bg-[#F5EFF7] p-2 rounded-lg" />
            <div className="flex flex-col justify-between">
                <Typography placeholder={undefined} className="text-[#E6E0E9] text-lg" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{
                    t("INSTALL_SDK")}
                </Typography>
                <Typography placeholder={undefined} className="text-[#E6E0E9]" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    {t("GATHER_DATA_SDK")}
                </Typography>
            </div>
        </div>
    )
}
import React from 'react';
import { checkForKeyPhrases, generateRandomId, isObjectEmpty } from '../../utils/chatAIUtils';

interface AdditionalObj {
    arguments: {
        explanation: {
            title: string;
            description: string;
        };
        sqlQuery: string;
    };
}


interface ChatHistoryProps {
    chatHistory: any;
    clearChat: () => void;
    setMessages: any;
    setTask: any;
    setPrevThreadId: any;
    setThreadId: any;
    setTypeCustoms: any;
    handlePlanTypeMessage: (data: any) => string | undefined;
    createTable: (cleanedSqlQuery: string, additionalObj: any, newMessageId: string) => Promise<void>;
    createChart: (additionalObj: any, newMessageId: string) => Promise<void>;
    createTableStructure: (additionalObj: any, query: any, lastId: any) => Promise<void>;
}

const ChatHistory: React.FC<ChatHistoryProps> = ({
    chatHistory,
    clearChat,
    setMessages,
    setTask,
    setPrevThreadId,
    setThreadId,
    setTypeCustoms,
    handlePlanTypeMessage,
    createTable,
    createChart,
    createTableStructure
}) => {

    const handleCheckpoint = (chat: any) => {
        clearChat();
        const parsedCheckpoint = JSON.parse(chat.checkpoint);
        if (parsedCheckpoint.channel_values.results !== null) {
            const messages = parsedCheckpoint.channel_values.messages;
            let additionalObj: any;
            let humanMessages: any = [];
            let aiMessages: any = [];
            let generatedIds: string[] = [];
            let errorResult = '';
            messages.forEach((message: any, index: number) => {
                const additionalData = message?.additionalData;
                const functions = Array.isArray(additionalData?.functions) ? additionalData.functions : null;
                const eResult =
                    additionalData && Array.isArray(additionalData['#E1'])
                        ? additionalData['#E1']
                        : additionalData
                            ? [additionalData['#E1']]
                            : null;
                if (Array.isArray(message.text)) {
                    message.text.forEach((textItem: any) => {
                        if (Array.isArray(textItem) && textItem.length === 2) {
                            if (textItem[0] === 'human') {
                                const originalHumanMessage = textItem[1].split(':')[1].trim();
                                if (originalHumanMessage.includes('#')) {
                                } else {
                                    humanMessages.push(textItem[1]);
                                    setMessages((prevMessages: any) => [
                                        ...prevMessages,
                                        {
                                            id: Date.now() + index,
                                            text: originalHumanMessage,
                                            sender: 'user',
                                            type: 'text',
                                        },
                                    ]);
                                }
                            } else if (textItem[0] === 'ai') {
                                const originalAiMessage = textItem[1];
                                if (originalAiMessage.includes('It was executed')) {
                                } else {
                                    aiMessages.push(textItem[1]);
                                    const newId = generateRandomId();
                                    generatedIds.push(newId);

                                    if (additionalData && (additionalData.functions || additionalData['#E1'])) {
                                        setMessages((prevMessages: any) => [
                                            ...prevMessages,
                                            {
                                                id: newId,
                                                text: 'Here is your final result',
                                                sender: 'server',
                                                type: 'text',
                                            },
                                        ]);
                                    } else {
                                        let parsed;
                                        if (originalAiMessage.includes('directResponse')) {
                                            const validString = originalAiMessage.slice(1, -1)
                                            parsed = JSON.parse(validString)
                                        } else if (originalAiMessage.includes('steps')) {
                                            const validJsonString = handlePlanTypeMessage(originalAiMessage)
                                            setMessages((prevMessages: any) => [
                                                ...prevMessages,
                                                {
                                                    id: newId,
                                                    text: "Sorry this Messages wasn't saved successfully...",
                                                    sender: 'ai',
                                                    type: 'text',
                                                },
                                            ]);
                                        }
                                        let newMsg = ''
                                        if (parsed && parsed.directResponse) {
                                            newMsg = parsed.directResponse
                                            setMessages((prevMessages: any) => [
                                                ...prevMessages,
                                                {
                                                    id: newId,
                                                    text: newMsg,
                                                    sender: 'ai',
                                                    type: 'text',
                                                },
                                            ]);
                                        } else {

                                        }
                                    }
                                }
                            }
                        }
                    });
                }

                if (functions && functions.length > 0) {
                    additionalObj = functions[0] as AdditionalObj;
                    if (additionalObj.function_name === 'getData') {
                        const sqlQuery = functions[0].arguments.sqlQuery;
                        const cleanedSqlQuery = sqlQuery.replace(/\n/g, ' ').replace(/\\/g, '');
                        if (generatedIds.length > 0) {
                            const lastId = generatedIds[generatedIds.length - 1];
                            createTable(cleanedSqlQuery, additionalObj, lastId);
                        } else {
                            console.error('No IDs were generated for AI messages');
                        }
                    } else if (additionalObj.function_name === 'createChart') {
                        if (generatedIds.length > 0) {
                            const lastId = generatedIds[generatedIds.length - 1];
                            createChart(additionalObj, lastId);
                        } else {
                            console.error('No IDs were generated for AI messages');
                        }
                    } else if (additionalObj.function_name === 'createTableStructure') {
                        if (generatedIds.length > 0) {
                            let result = parsedCheckpoint.channel_values.results
                            if (isObjectEmpty(result)) {
                            } else {
                                const lastId = generatedIds[generatedIds.length - 1];
                                createTableStructure(additionalObj, result, lastId);
                            }
                        } else {
                            console.error('No IDs were generated for AI messages');
                        }
                    } else {
                        errorResult = 'No Matching function found'
                        setMessages((prevMessages: any) => [
                            ...prevMessages.filter(
                                (msg: any) => msg.text !== "Here is your final result"
                            )
                        ]);
                    }
                } else {
                    const result = additionalData?.['#E1'];
                    if (result !== undefined) {
                        const parsedResult = JSON.parse(result);
                    }
                }
            });

            if (additionalObj) {


                setTask(parsedCheckpoint.channel_values.task);
                setPrevThreadId(chat.thread_id);
                setThreadId(chat.thread_id);
                let phraseContext: any;
                const addPhraseContextToTypeCustoms = (phraseContext: string) => {
                    const validContexts = ['chart', 'graph', 'table', 'segment', 'datapoint'];
                    if (validContexts.includes(phraseContext)) {
                        setTypeCustoms((prev: any) => {
                            const newArr = [...prev, phraseContext];
                            return newArr;
                        });
                    }
                };
                humanMessages.forEach((humanMessage: string) => {
                    const phraseContext = checkForKeyPhrases(humanMessage);
                    if (phraseContext) {
                        addPhraseContextToTypeCustoms(phraseContext);
                    } else {
                        addPhraseContextToTypeCustoms('table')
                    }
                });
            }
        }
    };
    return (
        chatHistory.map((chat: any, index: number) => {
            if (chat.checkpoint) {
                const parsedCheckpoint = JSON.parse(chat.checkpoint);
                const task = parsedCheckpoint.channel_values.task;
                if (task) {
                    return (
                        <>
                            <div
                                key={index}
                                onClick={() => {
                                    handleCheckpoint(chat);
                                }}
                                className="text-center pt-3 mt-4 cursor-pointer h-7 overflow-hidden relative"
                                style={{
                                    maskImage: 'linear-gradient(to left, transparent, black 20px)',
                                    WebkitMaskImage: 'linear-gradient(to left, transparent, black 20px)',
                                }}
                            >
                                <div className="absolute inset-0">
                                    {task.trim().length > 28 ? task.trim().slice(0, 27) + '' : task}
                                </div>
                                <div className="border border-[#1C1C1C0D] absolute bottom-0 left-0 right-0"></div>
                            </div>
                        </>
                    );
                }
            }
            return null;
        })
    );
};

export default ChatHistory;

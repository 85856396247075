/** @format */

import { useState, useEffect } from "react";
import { useSupabase } from "../context/supabaseContext";

export const useAuth = () => {
  const [userId, setUserId] = useState(null);
  const [userIdPrivate, setUserIdPrivate] = useState(null);
  const [loading, setLoading] = useState(true);
  const {publicSupabase } = useSupabase()
  useEffect(() => {
    // Attempt to recover session from localStorage first

    const localSession = localStorage.getItem("supabase.auth.token");
    const sessionData = localSession && JSON.parse(localSession);
    const userIdFromStorage = sessionData?.currentSession?.user?.id || null;
    if (userIdFromStorage) {
      setUserId(userIdFromStorage);
      setLoading(false);
    }

    const fetchSession = async () => {
      const { data: session, error } = await publicSupabase.auth.getSession();

      if (error) {
        console.error("Error fetching session:", error.message);
      }

      const currentUserId = session?.user?.id || null;
      if (currentUserId) {
        localStorage.setItem(
          "supabase.auth.token",
          JSON.stringify({ currentSession: session })
        );
      }
      setUserId(currentUserId);
      setLoading(false);
    };

    if (!userIdFromStorage) {
      fetchSession();
    }

    const { data: authListener } = publicSupabase.auth.onAuthStateChange(
      async (event, session) => {
        const currentUserId = session?.user?.id || null;
        if (currentUserId) {
          localStorage.setItem(
            "supabase.auth.token",
            JSON.stringify({ currentSession: session })
          );
        } else {
          localStorage.removeItem("supabase.auth.token");
        }
        setUserId(currentUserId);
        setLoading(false);
      }
    );

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  return { userId, loading, userIdPrivate, setUserIdPrivate };
};

/** @format */

import { useState, useEffect } from "react";
import { privateSupabase } from "../../api/SupabaseClient";
import { executeSQLQuery, getTotalCount } from "../../utils/supabaseSqlQuery";
import { getRGBColors } from "../../utils/chartColorUtils";
import useCubeData from "../../hooks/useCubeData";

const initialColors = [
  "bg-blue-500",
  "bg-pink-500",
  "bg-orange-500",
  "bg-yellow-500",
  "bg-cyan-500",
];

const transformData = (data: any): any => {
  const transformedData: { name: string; value: any }[] = [];

  if (!data || data.length === 0) {
    return transformedData;
  }

  const originalEntry = data[0];

  for (const [key, value] of Object.entries(originalEntry)) {
    transformedData.push({
      name: key,
      value: value,
    });
  }

  return transformedData;
};

const mergeFilters = (initialFilters: any[], newFilters: any[]) => {
  const mergedFilters = [...initialFilters];
  newFilters.forEach((newFilter) => {
    const existingIndex = mergedFilters.findIndex(
      (filter) => filter.member === newFilter.member
    );
    if (existingIndex !== -1) {
      mergedFilters[existingIndex] = newFilter;
    } else {
      mergedFilters.push(newFilter);
    }
  });
  return mergedFilters;
};

const mergeArrays = (initialArray: string[], newArray: string[]) => {
  const mergedArray = [...initialArray];
  newArray.forEach((newItem) => {
    if (!mergedArray.includes(newItem)) {
      mergedArray.push(newItem);
    }
  });
  return mergedArray;
};

export const processItems = async (
  items: any[],
  handleExecuteQueryCube: (queryString: any) => Promise<any>,
  selectedFilters: any[],
  selectedMeasures: string[],
  selectedTimeDimensions: any[],
  selectedDimensions: string[],
  selectedSegments: string[]
) => {
  const itemList = await Promise.all(
    items.map(async (item: any) => {
      if (item && item.query) {
        const query =
          typeof item.query === "string" ? JSON.parse(item.query) : item.query;

        const initialFilters = query.filters || [];
        const initialTimeDimensions = query.timeDimensions || [];
        const initialMeasures = query.measures || [];
        const initialDimensions = query.dimensions || [];
        const initialSegments = query.segments || [];

        const mergedFilters = mergeFilters(initialFilters, selectedFilters);
        const mergedTimeDimensions = mergeFilters(
          initialTimeDimensions,
          selectedTimeDimensions
        );

        const mergedMeasures = mergeArrays(initialMeasures, selectedMeasures);
        const mergedDimensions = mergeArrays(
          initialDimensions,
          selectedDimensions
        );
        const mergedSegments = mergeArrays(initialSegments, selectedSegments);

        const updatedQuery = {
          ...query,
          timeDimensions: mergedTimeDimensions,
          filters: mergedFilters,
          measures: mergedMeasures,
          dimensions: mergedDimensions,
          segments: mergedSegments,
        };

        let queryData: any, total: any;

        if (
          typeof item.query === "string" &&
          item.query.trim().toLowerCase().startsWith("select * from")
        ) {
          queryData = await executeSQLQuery(updatedQuery);
          total = await getTotalCount(updatedQuery);
        } else {
          const result = await handleExecuteQueryCube(updatedQuery);
          queryData = result.data || [];
          total = queryData.length;
        }

        switch (item.type) {
          case "table":
            if (!queryData || queryData.length === 0) {
              item.data = {
                tableData: {
                  data: [],
                  headers: [],
                  pagination: {
                    total: 0,
                    limit: 5,
                    offset: 0,
                  },
                },
              };
            } else {
              const headers = Object.keys(queryData[0]);
              item.data = {
                tableData: {
                  data: queryData,
                  headers,
                  pagination: {
                    total,
                    limit: 5,
                    offset: 0,
                  },
                },
              };
            }
            break;
          case "chart":
            if (!queryData || queryData.length === 0) {
              item.data = {
                labels: [],
                datasets: [],
              };
            } else {
              const labels = queryData.map(
                (entry: any) => entry[Object.keys(entry)[0]]
              );
              const datasetKeys = Object.keys(queryData[0]).slice(1); // All keys except the first one
              const datasets = datasetKeys.map((key, idx) => {
                const values = queryData.map((entry: any) => {
                  const value = entry[key];
                  return typeof value === "string" ? parseFloat(value) : value;
                });
                const colorSet = initialColors[idx % initialColors.length];
                const rgbColor = getRGBColors([colorSet])[0];
                return {
                  label: key,
                  data: values,
                  backgroundColor: rgbColor,
                  borderColor: rgbColor,
                  borderWidth: 1,
                };
              });

              item.data = {
                labels,
                datasets,
                type: item.visualization,
                legend:  Object.keys(queryData[0])

              };
            }
            break;
          default:
            if (queryData && queryData.length > 0) {
              const queryResultValue = Object.values(queryData[0])[0];
              item.data = queryResultValue;
            } else {
              item.data = null;
            }
        }

        item.initialFilters = {
          filters: initialFilters,
          timeDimensions: initialTimeDimensions,
        };
      }
      return item;
    })
  );

  return itemList;
};

const useDashboardData = (
  selectedReport?: any,
  selectedTimeFilters: any[] = [],
  selectedFilters: any[] = []
) => {
  const [draggableItems, setDraggableItems] = useState<any[]>([]);
  const [dashboardTitle, setDashboardTitle] = useState("New Dashboard Title");
  const [tempTitle, setTempTitle] = useState(dashboardTitle);
  const [isLoading, setIsLoading] = useState(true);
  const { handleExecuteQueryCube } = useCubeData();

  const fetchData = async (
    reportId?: number,
    filters: any[] = [],
    measures: string[] = [],
    timeDimensions: any[] = [],
    dimensions: string[] = [],
    segments: string[] = []
  ) => {
    setIsLoading(true);

    if (typeof reportId === "number") {
      const { data, error } = await privateSupabase
        .from("reports_dashboard")
        .select("title, items")
        .eq("id", reportId)
        .single();
      if (error) {
        console.error("Error fetching dashboard data: ", error);
        setIsLoading(false);
        return;
      }
      if (data.title) {
        setTempTitle(data.title);
        setDashboardTitle(data.title);
      }

      if (data && data.items.length > 0) {
        const itemIds = data.items[0].items || data.items;

        const { data: itemsData, error: itemsError } = await privateSupabase
          .from("library")
          .select("*")
          .in("id", itemIds);
        if (itemsError) {
          console.error("Error fetching items data: ", itemsError);
          setIsLoading(false);
          return;
        }

        const processedItems = await processItems(
          itemsData,
          handleExecuteQueryCube,
          filters,
          measures,
          timeDimensions,
          dimensions,
          segments
        );

        const updatedItems = processedItems.map((item: any) => {
          const position = item.position || {};
          return {
            ...item,
            gsX: position.gs_x,
            gsY: position.gs_y,
            gsW: position.gs_w,
            gsH: position.gs_h,
          };
        });

        setDraggableItems(updatedItems);
      } else {
        setDraggableItems([]);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData(selectedReport);
  }, [selectedReport]);

  return {
    draggableItems,
    setDraggableItems,
    tempTitle,
    dashboardTitle,
    setTempTitle,
    setDashboardTitle,
    isLoading,
    fetchData, // Expose fetchData to be called explicitly
  };
};

export default useDashboardData;

import React from 'react';
import {
    Typography,
} from "@material-tailwind/react";


interface StatusProps {
    status: 'running' | 'error' | 'completed';
}

const styles = {
    running: {
        backgroundColor: '#F2F5FF',
        typographyColor: '#0059AD',
    },
    completed: {
        backgroundColor: '#DEFFE5',
        typographyColor: '#51B767',
    },
    error: {
        backgroundColor: '#F9DEDC',
        typographyColor: '#B3261E',
    }
};

const getStatus = (connectionStatus: string) => {
    switch (connectionStatus) {
      case 'running':
        return 'Running';
      case 'completed':
        return 'Completed';
      case 'error':
        return 'Error';
      default:
        return 'Completed';
    }
  }

export const Status = ({ status }: StatusProps) => {
    const currentStyles = styles[status];

    return (
        <div
            style={{
                backgroundColor: currentStyles.backgroundColor,
                borderRadius: '8px',
                padding: '8px 16px',
                display: 'flex',
                justifyContent: 'center',
                minHeight: '40px',
                alignItems: 'center'
            }}
        >
            <Typography
                style={{
                    color: currentStyles.typographyColor,
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: '15.68px',
                }}
                variant="small"
                color="blue-gray"
                className="font-normal" placeholder={undefined}
            >
                {getStatus(status)}
            </Typography>
        </div>
    );
};

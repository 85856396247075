/** @format */

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Input, Button, Typography } from "@material-tailwind/react";
import { publicSupabase } from "../../../api/SupabaseClient";
import omniloy_logo from "../../../assets/images/omniloy_logo.svg";
import login_screen from "../../../assets/images/login_screen.svg";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { VerifyEmailOtpParams } from "@supabase/supabase-js";
import { t } from "i18next";

export function PasswordChange() {
  const { email } = useParams<{ email: string }>();
  const { token } = useParams<{ token: string }>();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [minPasswordLength] = useState(6);
  const [maxPasswordLength] = useState(254);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const handleUserSession = async () => {
    let response;
    response = await publicSupabase.auth.getSession();

    if (!!response?.data?.session?.user?.id) {
      setIsLoggedIn(true);
      return;
    }

    await publicSupabase.auth.verifyOtp({
      email,
      token,
      type: "recovery",
    } as VerifyEmailOtpParams);

    response = await publicSupabase.auth.getSession();

    if (!!response?.data?.session?.user?.id) {
      setIsLoggedIn(true);
      return;
    } else {
      toast.error(t("ERROR") + "EO-1050");
    }
  };

  useEffect(() => {
    handleUserSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("The password and password confirmation does not match");
      return;
    }

    if (password.length < minPasswordLength) {
      toast.error(
        `The password is too short (min ${minPasswordLength} characters)`
      );
      return;
    }

    if (password.length > maxPasswordLength) {
      toast.error("The password is too long");
      return;
    }

    if (isLoggedIn) {
      const updatedUserResponse = await publicSupabase.auth.updateUser({
        password,
      });
      if (!updatedUserResponse.error?.message) {
        toast.success("Password changed successfully!");
        setTimeout(() => {
          navigate("../../../sign-in");
        }, 1200);
      } else {
        toast.error(updatedUserResponse.error?.message);
      }
    } else {
      toast.error("Invalid token!");
    }
  };

  return (
    <div className="flex w-full h-screen bg-white overflow-hidden">
      <div className="flex-shrink-0 w-2/5 h-full">
        <img src={login_screen} alt="login" className="w-auto h-full" />
      </div>
      <div className="w-3/5 m-auto">
        <div className="max-w-md m-auto bg-white rounded-lg p-10">
          <div className="mb-8 items-start">
            <Typography
              className="text-3xl font-medium text-[#333D8E] text-left"
              placeholder={""}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              {t("CHANGE_PASSWORD")}
            </Typography>
            <hr className="my-8" />
          </div>

          <div>
            <div className="text-center mb-8 items-start">
              <img src={omniloy_logo} alt="Omniloy" className="mb-4" />
            </div>
            <form onSubmit={handleSubmit} className="flex flex-col gap-6">
              <Input
                type="password"
                size="lg"
                placeholder="Password"
                onChange={handlePasswordChange}
                crossOrigin={undefined}
                color="black"
                required
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              />

              <Input
                type="password"
                size="lg"
                placeholder="Password"
                onChange={handleConfirmPasswordChange}
                crossOrigin={undefined}
                color="black"
                required
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              />

              <Link
                to="/sign-in"
                className="text-xs text-[#3E54AC] hover:underline self-end"
              >
                {t("RETURN_LOGIN")}
              </Link>

              <Button
                className="w-full mt-4 bg-[#3E54AC]"
                placeholder={undefined}
                type="submit"
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                {t("CHANGE_PASSWORD")}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

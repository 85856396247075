import { t } from "i18next";
import React from "react";

const LoadingSpinner: React.FC<{ message: string }> = ({ message }) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "50vh",
            }}
        >
            <div className="loader"></div>
            <p
                style={{
                    marginTop: "16px",
                    fontSize: "16px",
                    color: "#000",
                }}
            >
                {t(message)}
            </p>
            <style>
                {`
          .loader {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            display: inline-block;
            position: relative;
            background: conic-gradient(from 180.13deg at 50.12% 50.12%, rgba(255, 255, 255, 0.31) 0deg, #0458DD 360deg);
            box-sizing: border-box;
            animation: rotation 1s linear infinite;
          }
          .loader::after {
            content: '';  
            box-sizing: border-box;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #F2F5FF; /* Fondo interior blanco para que el borde sea visible */
          }
          @keyframes rotation {
            0% { transform: rotate(0deg) }
            100% { transform: rotate(360deg) }
          }
        `}
            </style>
        </div>
    );
};

export default LoadingSpinner;

import React, { ChangeEvent, useEffect, useState } from "react";
import { Dialog, Input, Typography } from "@material-tailwind/react";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { t } from "i18next";
import { privateSupabase } from "../../../../api/SupabaseClient";
import toast from "react-hot-toast";
import useReloadState from "../../../../context/contextReload/contextHook";
import { ReactComponent as WebsiteIcon } from "../../../../assets/icons/company_website_icon.svg";
import { ReactComponent as StoresIcon } from "../../../../assets/icons/company_stores_icon.svg";
import { ReactComponent as DescriptionIcon } from "../../../../assets/icons/company_description_icon.svg";
import ProfileImage from "../../ProfileImage";
import DetailText from "../../DetailsText";
import DetailsTitle from "../../DetailsTitle";
import { ModalFooter, ModalHeader } from "../../../../components/Modal";
import { useSupabase } from "../../../../context/supabaseContext";
import defaultPlaceholder from "../../../../assets/images/blank-profile-picture.png";

interface CompanyDetailsProps {
  companyDetails: any;
  setCompanyDetails: any;
  role: number
}


const CompanyDetails = ({ companyDetails, setCompanyDetails, role }: CompanyDetailsProps) => {
  const [companyCircleIcon, setCompanyCircleIcon] = useState<any>();
  const reloadState = useReloadState();
  const [session, setSession] = useState<any>(null);
  const [editModelOpen, setEditModelOpen] = useState(false);
  const { userSession } = useSupabase();

  useEffect(() => {
    const handleAuthStateChange = (_event: any, newSession: any) => {
      setSession(newSession);
    };

    userSession().then((session: any) => {
      setSession(session.data.session);
    });

    privateSupabase.auth.onAuthStateChange(handleAuthStateChange);
  }, []);

  useEffect(() => {
  }, [session, reloadState.status]);

  const saveData = async () => {
    try {
      const updateData = { ...companyDetails };
      delete updateData.company_logo;
      const { data: savedData, error } = await privateSupabase
        .from("company_details")
        .update(updateData)
        .eq("id", 1);
      if (error) throw error;
      setEditModelOpen(false);
      toast.success("Company Details successfully updated");
    } catch (error) {
      console.error("ERROR: ", error);
      toast.error(t("ERROR") + "EO-1054");
    }
  };

  const getLogoSrc = () => {
    if (companyCircleIcon) return companyCircleIcon;
    let projectUrl = localStorage.getItem("supabaseProjectUrl");
    if (companyDetails.company_logo) {
      return `${projectUrl}/storage/v1/object/public/company_logos/company_logo/${companyDetails.company_logo}`;
    }
    return defaultPlaceholder;
  };

  const handleCompanyLogoChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCompanyCircleIcon(reader.result);
        uploadCompanyLogo(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadCompanyLogo = async (file: File) => {
    if (!file) {
      toast.error(t("ERROR") + "EO-1056");
      return;
    }

    const filePath = `company_logo/${file.name}`;
    const fileName = `${file.name}`;
    try {
      const { error } = await privateSupabase.storage
        .from("company_logos")
        .upload(filePath, file, {
          upsert: true,
        });

      if (error) throw error;
      await updateCompanyLogoPath(fileName);
    } catch (error) {
      console.error("Error uploading logo");
      toast.error(t("ERROR") + "EO-1057");
    }
  };

  const updateCompanyLogoPath = async (filePath: string) => {
    try {
      const { error } = await privateSupabase
        .from("company_details")
        .update({ company_logo: filePath })
        .eq("id", 1);

      if (error) throw error;

    } catch (error) {
      toast.error(t("ERROR") + "EO-1058");
    }
  };

  return (
    <div className="overflow-y-auto">
      <div className="flex flex-col rounded-xl w-full p-6 border ">
        <div className="rounded-xl bg-white shadow-md border mx-20 flex-col my-10">
          <div className="py-4 px-6 flex justify-between items-center border-b border-[#0059AD]">
            <Typography
              className="text-xl flex-1 font-normal"
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              {companyDetails.company || t("COMPANY_NAME")}
            </Typography>
            {role === 1 &&
              <PencilSquareIcon
                className="h-5 w-5 cursor-pointer text-gray-400"
                onClick={() => setEditModelOpen(true)}
              />
            }
          </div>
          <div className="flex justify-start gap-8 mt-6 pb-8">
            <ProfileImage
              isEditable={false}
              src={getLogoSrc()}
              onChange={() => { }}
            />
            <div className="flex flex-col justify-between gap-8">
              <div className="flex gap-4 justify-start items-center">
                <WebsiteIcon />
                <div>
                  <DetailsTitle titleText={t("COMPANY_WEBSITE")} marginBottom="0px" />
                  <DetailText inputText={companyDetails.company_website} isLink={true} color="#76787D" />
                </div>
              </div>
              <div className="flex gap-4 justify-start items-center">
                <StoresIcon />
                <div>
                  <DetailsTitle titleText={t("STORE_COUNT")} marginBottom="0px" />
                  <DetailText inputText={companyDetails.store_count} isLink={false} color="#76787D" />
                </div>
              </div>
              <div className="flex gap-4 justify-start items-center">
                <DescriptionIcon />
                <div>
                  <DetailsTitle titleText={t("COMPANY_DESCRIPTION")} marginBottom="0px" />
                  <DetailText inputText={companyDetails.company_description} isLink={false} color="#76787D" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        style={{ width: "970px", minWidth: "970px" }}
        open={editModelOpen}
        handler={setEditModelOpen}
        placeholder={undefined}
      >
        <ModalHeader title={"EDIT"} onClose={() => setEditModelOpen(false)} />
        <div className="flex justify-start gap-8 mt-6 pb-8">
          <ProfileImage
            isEditable={true}
            src={getLogoSrc()}
            onChange={handleCompanyLogoChange}
          />
          <div className="flex flex-col justify-between gap-8" style={{ minWidth: "500px" }}>
            <div className="flex gap-4 justify-start items-center">
              <WebsiteIcon />
              <Input
                label={t("COMPANY_WEBSITE")}
                type="text"
                value={companyDetails.company_website}
                onChange={(e) =>
                  setCompanyDetails({
                    ...companyDetails,
                    company_website: e.target.value,
                  })
                }
                crossOrigin={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              />
            </div>
            <div className="flex gap-4 justify-start items-center">
              <StoresIcon />
              <Input
                label={t("STORE_COUNT")}
                type="text"
                value={companyDetails.store_count}
                onChange={(e) =>
                  setCompanyDetails({
                    ...companyDetails,
                    store_count: e.target.value,
                  })
                }
                crossOrigin={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              />
            </div>
            <div className="flex gap-4 justify-start items-center">
              <DescriptionIcon />
              <Input
                label={t("COMPANY_DESCRIPTION")}
                type="text"
                value={companyDetails.company_description}
                onChange={(e) =>
                  setCompanyDetails({
                    ...companyDetails,
                    company_description: e.target.value,
                  })
                }
                crossOrigin={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              />
            </div>
          </div>
        </div>
        <ModalFooter buttonText="SAVE" onClick={saveData} />
      </Dialog>
    </div>
  );
};

export default CompanyDetails;

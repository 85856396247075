import { privateSupabase } from "../SupabaseClient";
import { AirtableSourceParams, AirtableCredentials, AirtableApiKeyParams, AirtableOAuthParams, AirtableConfiguration } from "./types/airtable";
import { GoogleAnalyticConfiguration, GoogleAnalyticCredentials, GoogleAnalyticsClientParams, GoogleAnalyticsServiceParams, GoogleAnalyticsSourceParams } from "./types/ga4";
import { GoogleSheetsSourceParams, GoogleSheetsCredentials, GoogleSheetsServiceParams, GoogleSheetsClientParams, GoogleSheetsConfiguration } from "./types/googleSheets";
import { MetabaseConfiguration, MetabaseSourceParams } from "./types/metabase";
import { PostgresSourceParams, PostgresConfiguration, PostgresDestinationParams, PostgresDestinationConfiguration } from "./types/postgres";
import { SnowflakeConfiguration, SnowflakeCredentials, SnowflakeOAuthParams, SnowflakeSourceParams, SnowflakeUserPassParams } from "./types/snowflake";
import { ConnectionOptions, ConnectionParams } from "./types/types";
import { WooComerceSourceParams, WooComerceConfiguration } from "./types/wooComerce";

function getHttpOptions(method: string, body?: any) {
    let options: {
        method: string;
        headers: {
            'Access-Control-Allow-Origin': '*';
            'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, HEAD';
            'Access-Control-Allow-Credentials': 'true';
            'content-type': 'application/json';
            authorization: string;
        };
        body?: string;
    } = {
        method: method,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'content-type': 'application/json',
            'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, HEAD',
            'Access-Control-Allow-Credentials': 'true',
            authorization: `Basic ${btoa(`${process.env.REACT_APP_AIRBYTE_USERNAME}:${process.env.REACT_APP_AIRBYTE_PASSWORD}`)}`
        }
    };

    if (body && (method === 'POST' || method === 'PUT' || method === 'PATCH')) {
        options.body = JSON.stringify(body);
    }

    return options;
}

async function handleHttpResponse(response: Response) {
    if (!response.ok) {
        const errorBody = await response.text();
        throw new Error(`HTTP Error Response: ${response.status} ${response.statusText} - ${errorBody}`);
    }
    return response.json();
}

/**
 * @deprecated The method will be reviewd
 */
export const createPostgresSource = async (params: PostgresSourceParams) => {
    try {
        const { host, port, databaseName, username, password, workspaceId, connectionName } = params;
        const configuration: PostgresConfiguration = {
            sourceType: 'postgres',
            port,
            ssl_mode: { mode: 'require' },
            replication_method: { method: 'Xmin' },
            tunnel_method: { tunnel_method: 'NO_TUNNEL' },
            host,
            database: databaseName,
            username,
            password,
        };

        const options = getHttpOptions('POST', { configuration, name: connectionName, workspaceId });
        const response = await fetch(`${process.env.REACT_APP_AIRBYTE_API_URL}/sources`, options);
        const responseJson = await handleHttpResponse(response);

        const { error } = await privateSupabase
            .from('connectors')
            .insert([
                { id: responseJson.sourceId, name: connectionName, type: 'source', connector: 'Postgres' },
            ]);
        if (error) {
            console.error(`Error storing the SOURCE connection on Supabase: ${error}`);
            throw new Error(`Error storing the SOURCE connection on Supabase: ${error}`)
        }

        return responseJson;
    } catch (e) {
        throw new Error(`Error creating the SOURCE connection ${e}`)
    }
}

/**
 * @deprecated The method will be reviewd
 */
export const createAirtableSource = async (params: AirtableSourceParams) => {
    try {
        const { auth_method, workspaceId, connectionName } = params;
        let credentials: AirtableCredentials;

        if (auth_method === 'api_key') {
            credentials = {
                auth_method: 'api_key',
                api_key: (params as AirtableApiKeyParams).api_key,
            };
        } else if (auth_method === 'oauth2.0') {
            credentials = {
                auth_method: 'oauth2.0',
                client_id: (params as AirtableOAuthParams).client_id,
                client_secret: (params as AirtableOAuthParams).client_secret,
                access_token: (params as AirtableOAuthParams).access_token,
                token_expiry_date: (params as AirtableOAuthParams).token_expiry_date,
                refresh_token: (params as AirtableOAuthParams).refresh_token,
            };
        } else {
            throw new Error(`Unsupported auth method: ${auth_method}`);
        }
        const configuration: AirtableConfiguration = {
            sourceType: 'airtable',
            credentials
        };

        const options = getHttpOptions('POST', { configuration, name: connectionName, workspaceId });
        const response = await fetch(`${process.env.REACT_APP_AIRBYTE_API_URL}/sources`, options);
        const responseJson = await handleHttpResponse(response);

        const { error } = await privateSupabase
            .from('connectors')
            .insert([
                { id: responseJson.sourceId, name: connectionName, type: 'source', connector: 'Airtable' },
            ]);
        if (error) {
            console.error(`Error storing the SOURCE connection on Supabase: ${error}`);
            throw new Error(`Error storing the SOURCE connection on Supabase: ${error}`)
        }

        return responseJson;
    } catch (e) {
        throw new Error(`Error creating the SOURCE connection ${e}`)
    }
}

/**
 * @deprecated The method will be reviewd
 */
export const createGA4Source = async (params: GoogleAnalyticsSourceParams) => {
    try {
        const { auth_type, workspaceId, connectionName, property_ids} = params;
        let credentials: GoogleAnalyticCredentials;

        if (auth_type === 'Service') {
            credentials = {
                auth_type: 'Service',
                credentials_json: (params as GoogleAnalyticsServiceParams).credentials_json,
            };
        } else if (auth_type === 'Client') {
            credentials = {
                auth_type: 'Client',
                client_id: (params as GoogleAnalyticsClientParams).client_id,
                client_secret: (params as GoogleAnalyticsClientParams).client_secret,
                access_token: (params as GoogleAnalyticsClientParams).access_token,
                refresh_token: (params as GoogleAnalyticsClientParams).refresh_token,
            };
        } else {
            throw new Error(`Unsupported auth method: ${auth_type}`);
        }
        const configuration: GoogleAnalyticConfiguration = {
            sourceType: 'google-analytics-data-api',
            credentials,
            property_ids
        };

        const options = getHttpOptions('POST', { configuration, name: connectionName, workspaceId });
        const response = await fetch(`${process.env.REACT_APP_AIRBYTE_API_URL}/sources`, options);
        const responseJson = await handleHttpResponse(response);

        const { error } = await privateSupabase
            .from('connectors')
            .insert([
                { id: responseJson.sourceId, name: connectionName, type: 'source', connector: 'Google Analytics 4' },
            ]);
        if (error) {
            console.error(`Error storing the SOURCE connection on Supabase: ${error}`);
            throw new Error(`Error storing the SOURCE connection on Supabase: ${error}`)
        }

        return responseJson;
    } catch (e) {
        throw new Error(`Error creating the SOURCE connection ${e}`)
    }
}

/**
 * @deprecated The method will be reviewd
 */
export const createGoogleSheetSource = async (params: GoogleSheetsSourceParams) => {
    try {
        const { auth_type, workspaceId, connectionName, spreadsheet_id, names_conversion} = params;
        let credentials: GoogleSheetsCredentials;

        if (auth_type === 'Service') {
            credentials = {
                auth_type: 'Service',
                service_account_info: (params as GoogleSheetsServiceParams).service_account_info,
            };
        } else if (auth_type === 'Client') {
            credentials = {
                auth_type: 'Client',
                client_id: (params as GoogleSheetsClientParams).client_id,
                client_secret: (params as GoogleSheetsClientParams).client_secret,
                refresh_token: (params as GoogleSheetsClientParams).refresh_token,
            };
        } else {
            throw new Error(`Unsupported auth method: ${auth_type}`);
        }
        const configuration: GoogleSheetsConfiguration = {
            sourceType: 'google-sheets',
            credentials,
            spreadsheet_id,
            names_conversion
        };

        const options = getHttpOptions('POST', { configuration, name: connectionName, workspaceId });
        const response = await fetch(`${process.env.REACT_APP_AIRBYTE_API_URL}/sources`, options);
        const responseJson = await handleHttpResponse(response);

        const { error } = await privateSupabase
            .from('connectors')
            .insert([
                { id: responseJson.sourceId, name: connectionName, type: 'source', connector: 'Google Sheets' },
            ]);
        if (error) {
            console.error(`Error storing the SOURCE connection on Supabase: ${error}`);
            throw new Error(`Error storing the SOURCE connection on Supabase: ${error}`)
        }

        return responseJson;
    } catch (e) {
        throw new Error(`Error creating the SOURCE connection ${e}`)
    }
}

/**
 * @deprecated The method will be reviewd
 */
export const createSnoflwakeSource = async (params: SnowflakeSourceParams) => {
    try {
        const { auth_type, workspaceId, connectionName, host, role, warehouse, database } = params;
        let credentials: SnowflakeCredentials;

        if (auth_type === 'username/password') {
            credentials = {
                auth_type: 'username/password',
                username: (params as SnowflakeUserPassParams).username,
                password: (params as SnowflakeUserPassParams).password,
            };
        } else if (auth_type === 'OAuth') {
            credentials = {
                auth_type: 'OAuth',
                client_id: (params as SnowflakeOAuthParams).client_id,
                client_secret: (params as SnowflakeOAuthParams).client_secret,
                access_token: (params as SnowflakeOAuthParams).access_token,
                refresh_token: (params as SnowflakeOAuthParams).refresh_token,
            };
        } else {
            throw new Error(`Unsupported auth method: ${auth_type}`);
        }
        const configuration: SnowflakeConfiguration = {
            sourceType: 'snowflake',
            credentials,
            host,
            role,
            warehouse,
            database
        };

        const options = getHttpOptions('POST', { configuration, name: connectionName, workspaceId });
        const response = await fetch(`${process.env.REACT_APP_AIRBYTE_API_URL}/sources`, options);
        const responseJson = await handleHttpResponse(response);

        const { error } = await privateSupabase
            .from('connectors')
            .insert([
                { id: responseJson.sourceId, name: connectionName, type: 'source', connector: 'Snowflake' },
            ]);
        if (error) {
            console.error(`Error storing the SOURCE connection on Supabase: ${error}`);
            throw new Error(`Error storing the SOURCE connection on Supabase: ${error}`)
        }

        return responseJson;
    } catch (e) {
        throw new Error(`Error creating the SOURCE connection ${e}`)
    }
}

/**
 * @deprecated The method will be reviewd
 */
export const createWooComerceSource = async (params: WooComerceSourceParams) => {
    try {
        const { api_key, api_secret, shop, start_date, workspaceId, connectionName } = params;
        const configuration: WooComerceConfiguration = {
            api_key,
            api_secret,
            shop,
            start_date,
            sourceType: "woocommerce"
        };

        const options = getHttpOptions('POST', { configuration, name: connectionName, workspaceId });
        const response = await fetch(`${process.env.REACT_APP_AIRBYTE_API_URL}/sources`, options);
        const responseJson = await handleHttpResponse(response);

        const { error } = await privateSupabase
            .from('connectors')
            .insert([
                { id: responseJson.sourceId, name: connectionName, type: 'source', connector: 'WooCommerce' },
            ]);
        if (error) {
            console.error(`Error storing the SOURCE connection on Supabase: ${error}`);
            throw new Error(`Error storing the SOURCE connection on Supabase: ${error}`)
        }

        return responseJson;
    } catch (e) {
        throw new Error(`Error creating the SOURCE connection ${e}`)
    }
}

/**
 * @deprecated The method will be reviewd
 */
export const createMetabaseSource = async (params: MetabaseSourceParams) => {
    try {
        const { instance_api_url, username, password, session_token, connectionName, workspaceId } = params;
        const configuration: MetabaseConfiguration = {
            instance_api_url,
            username,
            password,
            session_token,
            sourceType: "metabase"
        };

        const options = getHttpOptions('POST', { configuration, name: connectionName, workspaceId });
        const response = await fetch(`${process.env.REACT_APP_AIRBYTE_API_URL}/sources`, options);
        const responseJson = await handleHttpResponse(response);

        const { error } = await privateSupabase
            .from('connectors')
            .insert([
                { id: responseJson.sourceId, name: connectionName, type: 'source', connector: 'Metabase' },
            ]);
        if (error) {
            console.error(`Error storing the SOURCE connection on Supabase: ${error}`);
            throw new Error(`Error storing the SOURCE connection on Supabase: ${error}`)
        }

        return responseJson;
    } catch (e) {
        throw new Error(`Error creating the SOURCE connection ${e}`)
    }
}

/**
 * @deprecated The method will be reviewd
 */
export const createPostgresDestination = async (params: PostgresDestinationParams) => {
    try {
        const { schema, host, port, databaseName, username, password, workspaceId, connectionName } = params;
        const configuration: PostgresDestinationConfiguration = {
            sourceType: 'postgres',
            port,
            schema,
            ssl_mode: { mode: 'require' },
            tunnel_method: { tunnel_method: 'NO_TUNNEL' },
            host: host,
            database: databaseName,
            username: username,
            password: password
        };

        const options = getHttpOptions('POST', { configuration, name: connectionName, workspaceId });
        const response = await fetch(`${process.env.REACT_APP_AIRBYTE_API_URL}/destinations`, options);
        const responseJson = await handleHttpResponse(response);

        const { error } = await privateSupabase
            .from('connectors')
            .insert([
                { id: responseJson.sourceId, name: connectionName, type: 'destination', connector: 'Postgres' },
            ]);
        if (error) {
            console.error(`Error storing the DESTINATION connection on Supabase: ${error}`);
            throw new Error(`Error storing the DESTINATION connection on Supabase: ${error}`)
        }

        return responseJson;
    } catch (e) {
        throw new Error(`Error creating the DESTINATION connection ${e}`)
    }

}

/**
 * @deprecated The method will be reviewd
 * Actually, we are not using workaspaces at the moment.
 */
const createWorkspace = async (name: string) => {
    try {
        const options = getHttpOptions('POST', { name });
        const response = await fetch(`${process.env.REACT_APP_AIRBYTE_API_URL}/workspaces`, options)
        return response.json();
    } catch (e) {
        throw new Error(`Error creating the workspace: ${e}`)
    }
}

/**
 * @deprecated The method will be reviewd
 */
export const createConnection = async (params: ConnectionParams) => {
    try {
        const { prefix, connectionName, sourceId, destinationId, configurations } = params;
        const connectionOptions: ConnectionOptions = {
            schedule: { scheduleType: 'manual' },
            configurations,
            dataResidency: 'auto',
            nonBreakingSchemaUpdatesBehavior: 'ignore',
            name: connectionName,
            sourceId,
            destinationId,
            status: 'active',
            prefix,
            namespaceDefinition: "destination",
        };

        const options = getHttpOptions('POST', connectionOptions);
        const response = await fetch(`${process.env.REACT_APP_AIRBYTE_API_URL}/connections`, options)
        const responseJson = await handleHttpResponse(response);

        const { error } = await privateSupabase
            .from('connections')
            .insert([
                {
                    id: responseJson.connectionId, sourceId: responseJson.sourceId, destinationId: responseJson.destinationId, name: connectionName, prefix, streams: configurations?.streams
                },
            ]);
        if (error) {
            console.error(`Error storing the connection on Supabase: ${error}`);
            throw new Error(`Error storing the connection on Supabase: ${error}`)
        }

        return responseJson;

    } catch (e) {
        throw new Error(`Error creating the connection between ${params.sourceId} and ${params.destinationId}: ${e}`)
    }
}

/**
 * @deprecated The method will be reviewd
 */
export const getSourceStreams = async (sourceId: string, destinationId: string =  `19421644-ee0c-4b6c-b77b-b8bc8bc1cdf4`) => {
    try {
        const url = `${process.env.REACT_APP_AIRBYTE_API_URL}/streams?sourceId=${sourceId}&destinationId=${destinationId}&ignoreCache=true`;
        const options = getHttpOptions('GET');
        const response = await fetch(url, options)
        return handleHttpResponse(response);
    } catch (e) {
        throw new Error(`Error getting the streams for source ${sourceId}: ${e}`)
    }
}


export const launchSync = async (connectionId: string) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_OMNILOY_API_SERVER}/launchSync`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ connectionId }),
        })
        const responseJson = await handleHttpResponse(response);
        console.log({responseJson})

        const { error } = await privateSupabase.from('connections')
        .update([
            {
                status: responseJson.job.status, jobId: responseJson.job.id,
            },
        ])
        .eq('id', connectionId);

        return responseJson;
    } catch (e) {
        throw new Error(`Error launching job for connection ${connectionId}: ${e}`)
    }
}

export const getJobStatus = async (jobId: string) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_OMNILOY_API_SERVER}/getJobStatus`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ jobId }),
        })
        const responseJson = await handleHttpResponse(response);
        console.log({responseJson})

        const status = responseJson.status;
        console.log({status})
        const { error } = await privateSupabase.from('connections').update([ { status }, ]).eq('id', responseJson.configId); //connectionId
        if (status === "succeeded") {
            const lastSyncDate = new Date(responseJson.updatedAt * 1000);
            const { error } = await privateSupabase.from('connections').update([ { last_sync: lastSyncDate.toISOString() }, ]).eq('id', responseJson.configId); //connectionId
        }

        return;
    } catch (e) {
        throw new Error(`Error getting job status ${jobId}: ${e}`)
    }
}




/** @format */

import React from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
} from "@material-tailwind/react";
import { t } from "i18next";

interface DeleteModalProps {
  open: boolean;
  title: string;
  text: string;
  onClose: () => void;
  onDelete: () => void;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  open,
  title,
  text,
  onClose,
  onDelete,
}) => {
  return (
    <Dialog className={"p-6"} open={open} handler={onClose} placeholder={undefined}>
      <DialogHeader placeholder={undefined}>{t(title)}</DialogHeader>
      <DialogBody placeholder={undefined}>
        {t(text)}
      </DialogBody>
      <DialogFooter className="gap-10" placeholder={undefined}>
        <Button
          variant="text"
          color="gray"
          onClick={onClose}
          placeholder={undefined}
        >
          {t("CANCEL")}
        </Button>
        <Button
          variant="gradient"
          color="red"
          onClick={onDelete}
          placeholder={undefined}
        >
          {t("DELETE")}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default DeleteModal;

/** @format */

import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import { privateSupabase } from "../../api/SupabaseClient";
import { useSelection } from "../../context/selectionContext";
import LoadingSpinner from "../../components/LoadingSpinner";
import {
  fetchData,
  fetchFilterOptions,
  applyFilter,
  removeFilter,
  transformTimestampToDate,
  getDateRange,
} from "../../utils/ColumnFilterHandler";

interface SegmentProps {
  searchInput: string;
}

export function Segment({ searchInput }: SegmentProps) {
  const { selectedOption } = useSelection();
  const [data, setData] = useState<any[]>([]);
  const [columns, setColumns] = useState<string[]>([
    "Name",
    "Created by",
    "Last Edited",
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState<Record<string, string[]>>({});
  const [filterOptions, setFilterOptions] = useState<Record<string, string[]>>(
    {}
  );

  const tableName = "segments";

  const columnTypes: Record<string, string> = {
    last_edited: "timestamp",
  };

  useEffect(() => {
    const fetchDataAsync = async () => {
      setIsLoading(true);
      const fromIndex = (currentPage - 1) * itemsPerPage;
      const toIndex = fromIndex + itemsPerPage;
      const { data, totalItems } = await fetchData(
        privateSupabase,
        tableName,
        filters,
        searchInput,
        columnTypes,
        fromIndex,
        toIndex
      );
      setData(data);
      setTotalItems(totalItems);
      setIsLoading(false);
    };

    fetchDataAsync();
  }, [itemsPerPage, currentPage, filters, searchInput]);

  useEffect(() => {
    const fetchFilterOptionsAsync = async () => {
      const options = await fetchFilterOptions(
        privateSupabase,
        tableName,
        columnTypes
      );
      setFilterOptions(options);
    };

    fetchFilterOptionsAsync();
  }, []);

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    const firstItemIndex = (currentPage - 1) * itemsPerPage;
    const newCurrentPage = Math.floor(firstItemIndex / newItemsPerPage) + 1;
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newCurrentPage);
  };

  const mapColumnNameToDisplayName = (columnName: string) => {
    const mapping: Record<string, string> = {
      name: "Name",
      created_by: "Created by",
      last_edited: "Last Edited",
    };
    return mapping[columnName] || columnName;
  };

  const mapDisplayNameToColumnName = (displayName: string) => {
    const mapping: Record<string, string> = {
      Name: "name",
      "Created by": "created_by",
      "Last Edited": "last_edited",
    };
    return mapping[displayName] || displayName;
  };

  const mappedHeaders = columns.map((col) => mapDisplayNameToColumnName(col));

  const mappedData = data.map((row) => {
    const mappedRow: Record<string, any> = {};
    Object.keys(row).forEach((key) => {
      mappedRow[mapColumnNameToDisplayName(key)] = row[key];
    });
    return mappedRow;
  });

  console.log("Mapped Data: ", mappedData); // Log mapped data

  return (
    <div className="h-full w-full">
      {isLoading && <LoadingSpinner message="LOADING" />}
      {!isLoading &&
        totalItems === 0 &&
        "No segments found. Please, create new segments"}
      {!isLoading && totalItems > 0 && (
        <div className="px-8 pb-8 h-full">
          <Table
            headers={columns} // Display names for headers
            rows={mappedData} // Data mapped with display names
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            onItemsPerPageChange={handleItemsPerPageChange}
            totalItems={totalItems}
            onPageChange={setCurrentPage}
            onRowClick={() => {}} // Implement row click handler if needed
            filters={filters}
            applyFilter={async (column, value) => {
              const newFilterOptions = await applyFilter(
                privateSupabase,
                tableName,
                filters,
                mapDisplayNameToColumnName(column), // Map display name to column name for filtering
                value,
                columnTypes
              );
              console.log("New Filter Options: ", newFilterOptions); // Log new filter options
              setFilterOptions((prevOptions) => ({
                ...prevOptions,
                ...newFilterOptions,
                [column]: prevOptions[column], // Preserve current column options
              }));
              setFilters((prevFilters) => ({
                ...prevFilters,
                [mapDisplayNameToColumnName(column)]: value, // Use original column name in state
              }));
              setCurrentPage(1);
            }}
            removeFilter={async (column) => {
              const newFilterOptions = await removeFilter(
                privateSupabase,
                tableName,
                filters,
                mapDisplayNameToColumnName(column), // Map display name to column name for removing filter
                columnTypes
              );
              console.log("Removed Filter Options: ", newFilterOptions); // Log removed filter options
              setFilterOptions((prevOptions) => ({
                ...prevOptions,
                ...newFilterOptions,
                [column]: prevOptions[column], // Preserve current column options
              }));
              setFilters((prevFilters) => {
                const {
                  [mapDisplayNameToColumnName(column)]: removed,
                  ...rest
                } = prevFilters; // Use original column name in state
                return rest;
              });
              setCurrentPage(1);
            }}
            filterOptions={filterOptions}
            tableName={tableName}
          />
        </div>
      )}
    </div>
  );
}

import React from 'react';
import { BookmarkIcon } from '@heroicons/react/24/outline';
import { t } from 'i18next';

interface SaveButtonProps {
  onClick: any;
  isCreateAi: boolean;
}

const SaveButton: React.FC<SaveButtonProps> = ({ onClick, isCreateAi }) => {
  return (
    <div className="space-x-2 flex justify-end ml-4">
      <button
        className="flex flex-row justify-center items-center gap-2 px-6 py-2.5 bg-[#F1EBFF] text-[#5B26D3] text-sm font-medium rounded-[45px] normal-case text-center"
        onClick={onClick}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <BookmarkIcon className="w-4 h-4" />
        {isCreateAi ? t("SAVE_DASHBOARD") : t("SAVE_LIBRARY")}
      </button>
    </div>
  );
};

export default SaveButton;

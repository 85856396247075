/** @format */

import React, { useEffect, useRef } from "react";
import DeleteIconButton from "../Buttons/DeleteIconButton";

export const TitleCard = ({
  item,
  isEditMode,
  handleDelete,
  handleChange,
  titlesWithError,
}: any) => {
  const titleInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (titleInputRef.current) {
      titleInputRef.current.focus();
    }
  }, [isEditMode]);

  const titleInput = (
    <input
      ref={titleInputRef}
      type="text"
      className={`p-2 bg-[#F2F5FF] focus:outline-none text-xl resize-none ${
        !isEditMode && "pointer-events-none"
      } ${titlesWithError && titlesWithError.includes(item.sectionIndex) ? "border border-red-500" : ""}`}
      placeholder="Enter title..."
      value={item.title || ""}
      readOnly={!isEditMode}
      onChange={handleChange}
    />
  );

  return (
    <div
      className={`flex w-full items-center`}
    >
      <div className="inline-flex items-center bg-[#F2F5FF]">
        {titleInput}
        {isEditMode && (
          <DeleteIconButton showText={false} onDelete={handleDelete} />
        )}
      </div>
    </div>
  );
};

/** @format */

import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { privateSupabase } from "../../api/SupabaseClient";
import { useFilter } from "../../context/filterContext";

const useColumnData = (initialTableNames) => {
  const [allTableData, setAllTableData] = useState([]);
  const [tableNames, setTableNames] = useState(initialTableNames || []);
  const { setAllTableNames } = useFilter();

  useEffect(() => {
    const fetchColumnsAndData = async (tableName) => {
      // Fetch column definitions
      const { data: columnsData, error: columnsError } = await privateSupabase
        .from(tableName)
        .select("*")
        .limit(1);

      if (columnsError) throw columnsError;

      const columns = Object.keys(columnsData[0] || {});
      const filteredColumns = columns.filter(
        (column) =>
          !column.startsWith("_airbyte") && !column.startsWith("_airtable")
      );

      if (tableName.startsWith("sf_")) {
        return {
          columnNames: filteredColumns,
          columnData: [],
          tableDescription: "",
        };
      }

      // Fetch the first 2 rows of data for "at_" tables
      const { data: rowData, error: rowError } = await privateSupabase
        .from(tableName)
        .select(filteredColumns.join(","))
        .limit(2);

      if (rowError) throw rowError;

      // Fetch table description
      const { data: descriptionData, error: descriptionError } =
        await privateSupabase.rpc("get_table_description", {
          table_name: tableName,
        });

      if (descriptionError) throw descriptionError;

      const tableDescription =
        descriptionData && descriptionData.length > 0
          ? descriptionData[0].description
          : "";

      return {
        columnNames: filteredColumns,
        columnData: rowData,
        tableDescription: tableDescription,
      };
    };

    const fetchAllColumnDefinitions = async () => {
      if (!tableNames || tableNames.length === 0) return;

      try {
        const tablesData = await Promise.all(
          tableNames.map(async (tableName) => {
            const { columnNames, columnData, tableDescription } =
              await fetchColumnsAndData(tableName);
            return { tableName, columnNames, columnData, tableDescription };
          })
        );
        setAllTableData(tablesData);
      } catch (error) {
        toast.error("Error fetching table data");
      }
    };

    fetchAllColumnDefinitions();
  }, [tableNames]);

  useEffect(() => {
    const getTables = async () => {
      try {
        const { data, error } = await privateSupabase.rpc("get_table_names");
        if (error) throw error;
        const tableNamesArray = data
          .map((item) => item.table_name)
          .filter(
            (name) =>
              name.startsWith("view_employee_open_leads") ||
              name.startsWith("view_orders") ||
              name.startsWith("view_leads_utm") ||
              name.startsWith("view_sales_overview") ||
              name.startsWith("sf_") ||
              name.startsWith("csv_")
          ); // Include both "at_" and "sf_" tables
        setAllTableNames(tableNamesArray);
        setTableNames(tableNamesArray); // Update local state for table names
      } catch (error) {
        console.error("Cannot fetch tables from database");
      }
    };
    getTables();
  }, [setAllTableNames]);

  return allTableData;
};

export default useColumnData;

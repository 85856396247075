/** @format */

import React, { useEffect, useRef, useState } from "react";
import { Dialog, Select, Option } from "@material-tailwind/react";
import { InfoCard, TableCard, ChartCard } from "../Cards";
import {
  CheckIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";

import CloseButton from "../../components/CloseIcon";
import MainButton from "../../components/Buttons/MainButton";
import { privateSupabase } from "../../api/SupabaseClient";
import ToggleButton from "../Buttons/ToggleButton";
import { BORDER_COLORS, COLORS } from "../../utils/chartColorUtils";
import { t } from "i18next";
import useCubeData from "../../hooks/useCubeData";
import toast from "react-hot-toast";
import DownloadIconButton from "../Buttons/DownloadIconButton";
import EditIconButton from "../Buttons/EditIconButton";
import ShareIconButton from "../Buttons/ShareIconButton";

interface EditElementProps {
  isOpen: boolean;
  onClose: () => void;
  source: any;
  originalType: string;
}

export const EditElementCard = ({
  isOpen,
  onClose,
  source,
  originalType,
}: EditElementProps) => {
  let itemData: any;
  let itemName;
  let itemId: any;
  if (source.type === "card") {
    itemData = source.data.data;
    itemName = source.data.title;
    itemId = source.data.id;
  } else {
    itemData = source.data;
    itemName = source.title;
    itemId = source.id;
  }
  const initialColors = [
    "bg-blue-500",
    "bg-pink-500",
    "bg-orange-500",
    "bg-yellow-500",
    "bg-cyan-500",
    "bg-red-500",
    "bg-green-500",
    "bg-purple-500",
    "bg-indigo-500",
    "bg-gray-500",
  ];

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [colors, setColors] = useState<string[]>(() => {
    if (source && source.colors && Array.isArray(source.colors)) {
      return source.colors;
    } else {
      return initialColors;
    }
  });
  const [colorIndex, setColorIndex] = useState(0);
  //Edit labels
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [originalTitle, setOriginalTitle] = useState("");
  const [editableTitle, setEditableTitle] = useState("");
  const [editIndex, setEditIndex] = useState<any>();
  const [labels, setLabels] = useState(source.labels);

  const [isEditingYAxisTitle, setIsEditingYAxisTitle] = useState(false);
  const [originalYAxisTitle, setOriginalYAxisTitle] = useState("");
  const [editableYAxisTitle, setEditableYAxisTitle] = useState("");
  const [editYAxisindex, setEditYAxisIndex] = useState<any>();
  const [labelsYAxis, setLabelsYaxis] = useState([]);
  const { handleExecuteQueryCube } = useCubeData();
  const dropdownRef = useRef<HTMLDivElement>(null);

  //Data
  const [tableData, setTableData] = useState<any>();
  const [chartData, setChartData] = useState<any>();
  const [dataPointData, setDataPointData] = useState<any>();

  //Types
  const [currentType, setCurrentType] = useState<any>(source.type);
  const [chartType, setChartType] = useState<string>(source.visualization);

  useEffect(() => {
    const getData = async () => {
      switch (originalType) {
        case "table": {
          setTableData(itemData);
          const result = transformTableDataToChart(itemData);
          setChartData(result);
          setLabels(source.labels || result.labels);
          return;
        }
        case "chart": {
          setChartData(itemData);
          setLabels(source.labels || itemData.labels);
          const result = await transformChartDatatoTable();
          setTableData(result);
          return;
        }
        case "datapoint": {
          setDataPointData(dataPointData);
          return;
        }
      }
    };
    getData();
  }, [originalType]);

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  const transformChartDatatoTable = async () => {
    const sql = source.query;
    if (sql !== "") {
      const queryData = await handleExecuteQueryCube(sql);

      if (queryData.data && queryData.data.length > 0) {
        const headers =
          queryData.data.length > 0 ? Object.keys(queryData.data[0]) : [];
        const formattedData = queryData.data.map(
          (item: any, index: number) => ({
            id: index,
            ...item,
          })
        );

        const tableData: any = {
          tableData: {
            id: itemId,
            data: formattedData,
            headers: headers,
          },
        };
        return tableData;
      }
    }
  };

  useEffect(() => {
    if (chartData && chartData?.datasets.length === 1) {
      if (chartData?.labels) {
        const labelCount = chartData.labels.length;
        const label = chartData.labels;
        setLabels(label);
        if (labelCount > initialColors.length) {
          const repeatedColors = Array(
            Math.ceil(labelCount / initialColors.length)
          )
            .fill(initialColors)
            .flat()
            .slice(0, labelCount);
          setColors(repeatedColors);
        }
      }
    } else if (chartData && chartData?.datasets.length !== 1) {
      if (chartData?.type === "bar" || chartData?.type === "line") {
      } else {
        const labelCount = chartData.labels.length;
        const label = chartData.labels;
        setLabels(label);
        if (labelCount > initialColors.length) {
          const repeatedColors = Array(
            Math.ceil(labelCount / initialColors.length)
          )
            .fill(initialColors)
            .flat()
            .slice(0, labelCount);
          setColors(repeatedColors);
        }
      }
    }
  }, [chartData]);

  const handleEditTitle = (index: number, item: string) => {
    setEditableTitle(item);
    setEditIndex(index);
    setIsEditingTitle(true);
    setOriginalTitle(item);
  };

  const handleEditYAxisTitle = (index: number, item: string) => {
    setEditableYAxisTitle(item);
    setEditYAxisIndex(index);
    setIsEditingYAxisTitle(true);
    setOriginalYAxisTitle(item);
  };

  const handleTitleChange = (e: any, index: number) => {
    setEditableTitle(e.target.value);
  };

  const handleYAxisTitleChange = (e: any, index: number) => {
    setEditableYAxisTitle(e.target.value);
  };

  const handleSaveTitle = () => {
    if (editIndex !== null) {
      const newLabels: any = [...labels];
      newLabels[editIndex] = editableTitle;
      setLabels(newLabels);
      setIsEditingTitle(false);
      setEditIndex(null);
      //chartItemData.data.labels = newLabels;
    }
  };

  const handleCancelEdit = () => {
    setIsEditingTitle(false);
    setEditableTitle(originalTitle);
  };

  const handleCancelEditYAxis = () => {
    setIsEditingYAxisTitle(false);
    setEditableYAxisTitle(originalYAxisTitle);
  };

  const toggleDropdown = (index: number) => {
    setColorIndex(index);
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSave = async () => {
    try {
      const { data, error } = await privateSupabase
        .from("library")
        .update({
          labels: labels,
          visualization: chartType,
          colors: colors,
          type: currentType,
        })
        .eq("id", itemId)
        .select();
      if (error) throw new Error();
    } catch (error) {
      toast.error("Error while updating element");
    }
    toast.success("Element updated successfully");
    handleClose();
  };

  /*const updateLabels = async (
    chartId: string,
    labels: any,
    visualization: string,
    colors: any
  ) => {
    try {
      const { data, error } = await privateSupabase
        .from("library")
        .update({
          labels: labels,
          visualization: visualization,
          colors: colors,
          type: itemType === "table" ? "table" : "chart",
        })
        .eq("id", chartId)
        .select();

      if (error) throw new Error();
      onClose();
    } catch (error) {
      console.log("error while updating", error);
    }
  };*/

  const colorMap: { [key: string]: string } = {
    "bg-red-500": "Red",
    "bg-green-500": "Green",
    "bg-blue-500": "Blue",
    "bg-yellow-500": "Yellow",
    "bg-purple-500": "Purple",
    "bg-pink-500": "Pink",
    "bg-indigo-500": "Indigo",
    "bg-orange-500": "Orange",
    "bg-gray-500": "Gray",
    "bg-cyan-500": "Cyan",
  };

  const colorButtons: string[] = [
    "bg-red-500",
    "bg-green-500",
    "bg-blue-500",
    "bg-yellow-500",
    "bg-purple-500",
    "bg-pink-500",
    "bg-indigo-500",
    "bg-orange-500",
    "bg-cyan-500",
    "bg-gray-500",
  ];

  const capitalizeFirstLetter = (string: string) => {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return "";
    }
  };

  const handleToggle = async (type: string) => {
    if (source && source.colors && Array.isArray(source.colors)) {
      setColors(source.colors);
    } else {
      setColors(initialColors);
    }
    setCurrentType(type);
  };

  const handleSelect = async (value: any) => {
    let chartDataAux = chartData;
    chartDataAux.type = value;
    setChartData(chartDataAux);
    setChartType(value);
    setLabels(chartDataAux.labels);
  };

  const handleClose = () => {
    onClose();
  };

  const changeColors = (labelIndex: number, newColor: string) => {
    const colorName = `bg-${newColor.toLowerCase()}-500`;
    const newColors = [...colors];
    newColors[labelIndex] = colorName;
    const newData = {
      ...chartData,
      colors: newColors,
    };
    setChartData(newData);
    setColors(newColors);
  };

  return (
    <Dialog
      size="lg"
      open={isOpen}
      handler={handleClose}
      className="bg-gray-100 text-black h-4/5 overflow-y-auto p-12"
      placeholder={undefined}
    >
      <div className="flex flex-row justify-between w-full">
        <h2 className="text-xl">{itemName}</h2>
        <div className="flex flex-row justify-center items-center color-black pb-5">
          <DownloadIconButton isDisabled={true} showText={false} onClick={() => { }}></DownloadIconButton>
          <ShareIconButton isDisabled={true} onClick={() => { }}/>
          <CloseButton className="w-6 h-6 cursor-pointer" onClick={onClose} />
        </div>
      </div>
      <div className="flex flex-row justify-between items-center mt-6 pb-3">
        <div className="w-1/3  cursor-pointer">
          <p className="text-center pb-3">{t("VISUALIZATION")}</p>
          <div className=" h-0.5 w-full bg-[#0458DD]"></div>
        </div>
        <div className="w-1/3">
          <p className="text-center text-gray-400 pb-3">
            {t("GENERAL_INFORMATION")}
          </p>
          <div className="border w-full bg-[#8A8D94]"></div>
        </div>
        <div className="w-1/3">
          <p className="text-center text-gray-400 pb-3">
            {t("VERIFY_CONTENT")}
          </p>
          <div className="border w-full bg-[#8A8D94]"></div>
        </div>
      </div>

      <div className="flex flex-row justify-between w-full items-center h-4/5">
        <div className="w-1/3  h-full border-r border-gray-500 border-opacity-10">
          {currentType !== "table" && (
            <div className="flex flex-col w-full">
              <div className="mt-4">
                <h1>{t("VISUALIZATION")}</h1>
                <div className="flex flex-col mt-1 w-11/12">
                  <Select
                    label={capitalizeFirstLetter(chartData?.type || "Bar")}
                    name="chartType"
                    value={chartType || "Bar"}
                    onChange={(e) => handleSelect(e)}
                    placeholder={undefined}
                  >
                    <Option value="line">Line</Option>
                    <Option value="bar">Bar</Option>
                    <Option value="pie">Pie</Option>
                    <Option value="doughnut">Doughnut</Option>
                    <Option value="polarArea">Polar Area</Option>
                  </Select>
                </div>
              </div>

              <div className="mt-6">
                <div className="flex flex-row justify-between items-center w-11/12">
                  <p>{t("X-AXIS")}</p>
                </div>
                <div>
                  <div className="flex flex-col mt-2 border border-gray-500 border-opacity-10 rounded-lg h-44 w-11/12 overflow-auto">
                    <div className="mt-2 flex flex-row justify-between w-11/12 items-center h-full">
                      <div className="flex flex-col justify-between w-full h-full">
                        {labels &&
                          labels.map((item: any, index: any) => (
                            <div className="flex w-full" key={index}>
                              {isEditingTitle && index === editIndex ? (
                                <div className="flex items-center">
                                  <input
                                    type="text"
                                    value={editableTitle}
                                    onChange={(e) => {
                                      handleTitleChange(e, editIndex);
                                    }}
                                    className="ml-2 bg-[#F2F5FF] border-b border-[#48464C] outline-none "
                                    autoFocus
                                  />
                                  <CheckIcon
                                    className="w-5 h-5 ml-2 text-[#48464C] cursor-pointer"
                                    onClick={handleSaveTitle}
                                  />
                                  <XMarkIcon
                                    className="w-5 h-5 ml-2 text-[#48464C] cursor-pointer"
                                    onClick={handleCancelEdit}
                                  />
                                </div>
                              ) : (
                                <div className="ml-2 w-full flex flex-row justify-between items-center">
                                  <span>{item}</span>
                                  <div className="flex items-start">
                                    {(colors &&
                                      chartData &&
                                      chartData?.type !== "bar" &&
                                      chartData?.type !== "line") ||
                                      chartData?.datasets.length === 1 ? (
                                      <div className="ml-2">
                                        <button
                                          className={`p-3 rounded-full ${colors[index]}`}
                                          onClick={() => toggleDropdown(index)}
                                        ></button>
                                        {colorIndex === index && (
                                          <div
                                            className="absolute left-40 m-2 z-10 bg-white w-44 rounded-lg"
                                            style={{
                                              top: `${290 + (index % 6) * 30}px`,
                                            }}
                                            ref={dropdownRef}
                                          >
                                            {isDropdownOpen && (
                                              <div className="flex flex-wrap p-2">
                                                {colorButtons.map(
                                                  (bgColor, colorIdx) => (
                                                    <button
                                                      key={colorIdx}
                                                      className={`px-3 m-1 rounded-full ${bgColor}`}
                                                      onClick={() =>
                                                        changeColors(
                                                          index,
                                                          colorMap[bgColor]
                                                        )
                                                      }
                                                    ></button>
                                                  )
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                    <EditIconButton showText={false} onClick={() => handleEditTitle(index, item)} />

                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-6">
                <div className="flex flex-row justify-between items-center w-11/12">
                  <p>{t("Y-AXIS")}</p>
                </div>
                <div>
                  <div className="flex flex-col mt-2 border border-gray-500 border-opacity-10 rounded-lg h-44 w-11/12 overflow-auto">
                    <div className="mt-2 flex flex-row justify-between w-11/12 items-center h-full">
                      <div className="flex flex-col justify-between w-full h-full">
                        {chartData?.datasets.map((item: any, index: any) => (
                          <div className="flex w-full" key={index}>
                            {isEditingYAxisTitle && index === editYAxisindex ? (
                              <div className="flex items-center">
                                <input
                                  type="text"
                                  value={editableYAxisTitle}
                                  onChange={(e) => {
                                    handleYAxisTitleChange(e, editYAxisindex);
                                  }}
                                  className="ml-2 bg-[#F2F5FF] border-b border-[#48464C] outline-none "
                                  autoFocus
                                />
                                <CheckIcon
                                  className="w-5 h-5 ml-2 text-[#48464C] cursor-pointer"
                                  onClick={handleSaveTitle}
                                />
                                <XMarkIcon
                                  className="w-5 h-5 ml-2 text-[#48464C] cursor-pointer"
                                  onClick={handleCancelEditYAxis}
                                />
                              </div>
                            ) : (
                              <div className="ml-2 w-full flex flex-row justify-between items-center">
                                <span>{item.label}</span>
                                <div className="flex items-center">
                                  {colors &&
                                    chartData &&
                                    (chartData?.type === "bar" ||
                                      chartData?.type === "line") &&
                                    chartData?.datasets.length !== 1 ? (
                                    <div className="ml-2">
                                      <button
                                        className={`p-3 rounded-full ${colors[index]}`}
                                        onClick={() => toggleDropdown(index)}
                                      ></button>
                                      {colorIndex === index && (
                                        <div
                                          className="absolute left-40 m-2 z-10 bg-white w-44 rounded-lg"
                                          style={{
                                            top: `${490 + (index % 6) * 30}px`,
                                          }}
                                          ref={dropdownRef}
                                        >
                                          {isDropdownOpen && (
                                            <div className="flex flex-wrap p-2">
                                              {colorButtons.map(
                                                (bgColor, colorIdx) => (
                                                  <button
                                                    key={colorIdx}
                                                    className={`p-3 m-1 rounded-full ${bgColor}`}
                                                    onClick={() =>
                                                      changeColors(
                                                        index,
                                                        colorMap[bgColor]
                                                      )
                                                    }
                                                  ></button>
                                                )
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="p-5 w-2/3 h-4/5 flex flex-col justify-between">
          <div
            className={`flex flex-col justify-between mx-3 ${currentType === "table" ? "items-start" : "items-center"}`}
            style={{ overflow: "auto" }}
          >
            <div className="pb-4 w-full h-full">
              {currentType === "table" && tableData && tableData.tableData && (
                <TableCard
                  tableData={{
                    ...tableData.tableData,
                    id: tableData.tableData.id,
                    data: tableData.tableData.data,
                  }}
                  isExpanded={true}
                />
              )}
              {currentType === "chart" && (
                <ChartCard
                  data={chartData}
                  options={chartData?.options || {}}
                  type={chartData?.type}
                  labels={labels}
                  colors={colors}
                />
              )}
              {currentType === "datapoint" && (
                <InfoCard
                  key={dataPointData}
                  title={dataPointData}
                  data={dataPointData}
                  percentage={0}
                  isPercentagePositive={false}
                />
              )}
            </div>
          </div>
          <div className="flex flex-row justify-center items-center">
            {originalType !== "datapoint" && (
              <ToggleButton
                itemType={currentType}
                setItemType={setCurrentType}
                onChange={handleToggle}
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-center items-center">
        <div className="ml-auto flex flex-row gap-5">
          <button
            onClick={handleClose}
            className="mx-2 p-2 text-gray-500 hover:text-gray-700"
          >
            {t("CANCEL")}
          </button>
          <MainButton buttonText="SAVE" onClick={handleSave} plusIcon={false} />
        </div>
      </div>
    </Dialog>
  );
};

// Utility function to transform table data to chart data
const transformTableDataToChart = (data: any) => {
  const tableData = data.tableData?.data || [];
  const headers = data.tableData?.headers || [];
  if (headers.length < 2) {
    console.error("Not enough data to transform into chart format");
    return {};
  }
  return {
    labels: tableData.map((item: any) => item[headers[0]]),
    datasets: headers.slice(1).map((header: any, index: any) => ({
      label: header,
      data: tableData.map((item: any) => item[header]),
      backgroundColor: COLORS[index % COLORS.length].value,
      borderColor: BORDER_COLORS[index % BORDER_COLORS.length].value,
    })),
    type: "bar",
  };
};

/** @format */

import React from "react";
import { Button } from "@material-tailwind/react";
import { PlusIcon } from "@heroicons/react/24/solid";
import { PencilIcon } from "@heroicons/react/24/outline";
import { t } from "i18next";

export interface ButtonProperties {
  buttonText?: string;
  onClick: () => void;
  plusIcon?: boolean;
  editIcon?: boolean;
  disabled?: boolean;
}

const MainButton = ({
  buttonText,
  onClick,
  plusIcon = true,
  editIcon = false,
  disabled = false

}: ButtonProperties) => {
  return (
    <Button
      style={{
        minWidth: "150px",
        textTransform: "none",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "100px",
      }}
      className="h-10 bg-[#0458DD]"
      color="white"
      variant="outlined"
      disabled={disabled}
      onClick={onClick}
      placeholder={undefined}
    >
      <div className="flex justify-center items-center gap-2">
        {plusIcon && <PlusIcon className="h-4 w-4 cursor-pointer" />}
        {editIcon && <PencilIcon className="h-4 w-4 cursor-pointer" />}
        {t(buttonText!)}
      </div>
    </Button>
  );
};

export default MainButton;

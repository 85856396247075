import { useState } from "react";
import { formatAndCleanCubeContent, extractCubeName } from "../../utils/semanticLayerUtils";
import toast from "react-hot-toast";
import MainButton from "../../components/Buttons/MainButton";
import { ReactComponent as SaveIcon } from "../../assets/icons/save.svg";
import { t } from "i18next";
import { Button } from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface SingleCubeProps {
  cube: any;
  isExpanded: boolean;
  onExpand: () => void;
  onUpdate: (updatedCube: any, originalCube: any) => void;
}

export const SingleCube: React.FC<SingleCubeProps> = ({ cube, isExpanded, onExpand, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [originalCubeContent, setOriginalCubeContent] = useState(cube.content)
  const [editedContent, setEditedContent] = useState(formatAndCleanCubeContent(cube.content));
  const cubeName = extractCubeName(cube.content)

  const handleEdit = () => {
    setIsEditing(true)
  }

  const handleSave = () => {
    try {
      const updatedCube = editedContent;
      onUpdate(updatedCube, originalCubeContent)
      setIsEditing(false)
    } catch (error) {
      toast.error('Invalid type. Please check your input')
    }
  }

  const handleCancel = () => {
    setEditedContent(formatAndCleanCubeContent(cube.content));
    setIsEditing(false);
  };

  return (
    <div className="mb-2">
      <div className="flex items-center justify-between cursor-pointer hover:bg-gray-100 p-2 rounded">
        <div
          className="flex items-center"
          onClick={onExpand}
        >
          <span className="mr-2">{isExpanded ? '▼' : '▶'}</span>
          <span className="font-semibold">{cubeName || 'Unnamed Cube'}</span>
        </div>
      </div>
      {isExpanded && (
        <div className="flex flex-row justify-between ml-6 p-2 bg-gray-50 rounded max-h-96 overflow-y-auto">
          <div className="w-full">
            {isEditing ? (
              <>
                <textarea
                  className="w-full h-64 p-2 border rounded"
                  value={editedContent}
                  onChange={(e) => setEditedContent(e.target.value)}
                />
                <div className="flex flex-row mt-2">
                  <Button onClick={handleCancel} placeholder={undefined}
                    style={{
                      minWidth: "150px",
                      textTransform: "none",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "100px",
                      border: "1px solid white",
                      color: "#0458DD"
                    }}
                    className="h-10 bg-white"
                    variant="outlined"
                  >
                    <div className="flex justify-center items-center gap-2">
                      <XMarkIcon className="h-4 w-4 cursor-pointer" />
                      {t("DISCARD")}
                    </div>
                  </Button>
                  <Button onClick={handleSave} placeholder={undefined}
                    style={{
                      minWidth: "150px",
                      textTransform: "none",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "100px",
                      border: "1px solid #0458DD",
                      color: "#0458DD"
                    }}
                    className="h-10 bg-white"
                    variant="outlined"
                  >
                    <div className="flex justify-center items-center gap-2">
                      <SaveIcon className="h-4 w-4 cursor-pointer" />
                      {t("SAVE")}
                    </div>

                  </Button>
                </div>
              </>
            ) : (
              <pre>{formatAndCleanCubeContent(cube.content)}</pre>
            )}
          </div>
          <div>
            {!isEditing && (
              <MainButton
                buttonText="EDIT"
                onClick={handleEdit}
                plusIcon={false}
                editIcon={true}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export const formatAndCleanCubeContent = (content: string) => {
    const removeCubeWrapper = (str: string) => {
      let result = str.replace(/^cube\(`[^`]+`,/, '').replace(/\);$/, '');
      return result.trim();
    };
  
    const formatContent = (str: string) => {
      let indentLevel = 0;
      const indent = '  '; 
  
      if (str === undefined) {
        return '';
      }
  
      return str.replace(/[{},]/g, (match: string) => {
        if (match === '{') {
          indentLevel++;
          return `{\n${indent.repeat(indentLevel)}`;
        }
        if (match === '}') {
          indentLevel--;
          return `\n${indent.repeat(indentLevel)}}`;
        }
        if (match === ',') {
          return `,\n${indent.repeat(indentLevel)}`;
        }
        return match; 
      });
    };
  
    const cleanedContent = removeCubeWrapper(content);
    const formattedContent = formatContent(cleanedContent);
  
    return formattedContent;
  };
  
export function extractCubeName(cubeString: string): string {
    const cubeRegex = /cube\(`([^`]+)`/;
    const match = cubeString.match(cubeRegex);
    if (match && match[1]) {
      return match[1].trim();
    }
    throw new Error('Cube name not found');
  }

  export function extractCubeNames(content:any) {
    const cubeRegex = /cube\s*\(`([^`]+)`/g;
    const cubeNames = [];
    let match;
  
    while ((match = cubeRegex.exec(content)) !== null) {
        cubeNames.push(match[1]);
    }
  
    return cubeNames;
  }
  
  export function separateCubes(input:string) {
    const cubeDefinitions = input.split(/cube\(/).slice(1);
    const cubes = cubeDefinitions.map(cubeDef => 'cube(' + cubeDef.trim());
    return cubes;
  }

  export function removeLineBreaks(str:string) {
    return str.replace(/\n\s*/g, '');
  }

  export const addCubeWrapper = (content: string, cubeName: string) => {
    const trimmedContent = content.trim();
    return `cube(\`${cubeName}\`, ${trimmedContent});`;
  };
import React, { useState } from 'react';
import { Dialog } from "@material-tailwind/react";
import { DataLoader } from '../../components/dataConnection/DataLoader';
import { DataSDK } from '../../components/DataSDK';
import { t } from 'i18next';
import { ModalHeader } from '../../components/Modal';

interface ConnectorsManagerProps {
    open: boolean;
    handleClose: () => void;
}

export const ConnectorsManager = ({ open, handleClose }: ConnectorsManagerProps) => {
    const [isNext, setIsNext] = useState<boolean>(false);

    return (
        <Dialog
            size="lg"
            open={open}
            handler={handleClose}
            className="p-4 bg-gray-100 max-h-[1000px] overflow-y-auto" placeholder={undefined}>
            <ModalHeader title={t('ADD_DATA_SOURCE')} onClose={handleClose} />
            <div className="tab-mainMetrics">
                <DataLoader setIsNext={setIsNext} handleClose={handleClose} />
                {!isNext && <DataSDK />}
            </div>
        </Dialog>
    );
};

export default ConnectorsManager;
/** @format */
import React, { useEffect, useState } from "react";
import { Input, Button, Typography } from "@material-tailwind/react";
import { useNavigate, useParams } from "react-router-dom";
import { t } from "i18next";
import login_screen from "../../../assets/images/login_screen.svg";
import { privateSupabase } from "../../../api/SupabaseClient";
import { useSupabase } from "../../../context/supabaseContext";
import toast from "react-hot-toast";
import eyeIcon from "../../../assets/icons/eye_icon.svg";
import substractIcon from "../../../assets/icons/subtract_icon.svg";

export const PasswordRecovery = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const { publicSupabase, initializePrivateSupabase } = useSupabase();
    const [passwordModal, setPasswordModal] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const handlePasswordChange = (e: any) => setPassword(e.target.value);
    const handleConfirmPasswordChange = (e: any) =>
        setConfirmPassword(e.target.value);
    useEffect(() => {
        const fetchUserAndSetup = async () => {
            const { data, error } = await publicSupabase
                .from('invited_members')
                .select('email')
                .eq('id', id)
                .single()
            setEmail(data?.email);
        };
        fetchUserAndSetup();
    }, []);

    const resetPassword = async () => {
        if (password === confirmPassword) {
            const { data: projectData } = await publicSupabase
                .from("private_projects")
                .select("project_url, anon_key")
                .contains("project_emails", [email])
                .single();
            if (projectData) {
                initializePrivateSupabase(
                    projectData.project_url,
                    projectData.anon_key
                );

                localStorage.setItem(
                    "supabaseProjectUrl",
                    projectData.project_url
                );
                localStorage.setItem("supabaseAnonKey", projectData.anon_key);


                const { data: signupData, error } = await privateSupabase.auth.signUp({email, password});
                if (error) {
                    console.error(error.message);
                    return;
                }
                const { data: dataUpdate, error: errorUpdate } =
                    await privateSupabase.rpc("update_last_login", {
                        user_email: signupData!.user!.email,
                    });
                if (signupData !== null) {
                    const { data } = await publicSupabase.from("invited_members").select("email, role").eq("id", id).single();
                    if (data) {
                        const result = await privateSupabase
                            .from("profiles")
                            .upsert([{ id: signupData!.user!.id, username: email, email: email, role: data.role, last_update: new Date().toISOString(), company: 1 }])

             
                    }
                    const result = await publicSupabase.from("invited_members").delete().eq("email", email)

                    toast.success(t("SET_PASSWORD_SUCCESS"));
                    navigate("/");
                } else {
                    toast.error(t("SET_PASSWORD_SUCCESS"));
                }
            }
        }
    };

    return (
        <div className="flex w-full h-screen bg-white overflow-hidden">
            <div className="flex-shrink-0 w-2/5 h-full">
                <img src={login_screen} alt="login" className="w-auto h-full" />
            </div>
            <div className="w-3/5 m-auto">
                <div style={{ display: "flex", flexDirection: "column", minHeight: "590px", justifyContent: "space-between" }} className="max-w-md m-auto bg-white rounded-lg p-10">
                    <div>
                       

                        

                        {!passwordModal &&
                            <div className="mb-8 items-start">
                                <Typography
                                    style={{ fontWeight: 500, fontSize: "28px"}}
                                    className="text-3xl font-medium text-[#0B1962] text-left"
                                    placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            >
                                    {t("CONGRATULATIONS")}
                                </Typography>
                                <hr className="my-8" style={{ color: "#C5CCE6", height: "1px" }} />
                                <Typography
                                    style={{ fontWeight: 400, fontSize: "18px", paddingTop: "10px" }}
                                    className="text-3xl font-medium text-[#0B1962]  text-left"
                                    placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            >
                                    {t("YOU_HAVE_BEEN_INVITED")}
                                </Typography>

                            </div>
                        }
                        {passwordModal &&
                            <div className="mb-8 items-start">
                                <Typography
                                    style={{ fontWeight: 400, fontSize: "24px", lineHeight: "30px" }}
                                    className="text-3xl font-medium text-[#0B1962]  text-left"
                                    placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            >
                                    {t("SET_YOUR_PASSWORD")}
                                </Typography>
                                <hr className="my-8" style={{ color: "#C5CCE6", height: "1px" }} />
                            </div>
                        }
                        <div>
                        </div>


                        {passwordModal &&
                            <form onSubmit={() => { }} className="flex flex-col gap-2 mt-8">
                                <Input
                                    type={passwordVisible ? "text" : "password"}
                                    size="lg"
                                    label={t("PASSWORD")}
                                    placeholder=""
                                    onChange={handlePasswordChange}
                                    icon={<img
                                        src={eyeIcon}
                                        alt="Toggle visibility"
                                        onClick={() => setPasswordVisible(!passwordVisible)}
                                        className="cursor-pointer"
                                    />}
                                    crossOrigin={undefined}
                                    onPointerEnterCapture={undefined}
                                    onPointerLeaveCapture={undefined}
                                />
                                <Input
                                    type={passwordVisible ? "text" : "password"}
                                    size="lg"
                                    label={t("REPEAT_PASSWORD")}
                                    placeholder=""
                                    onChange={handleConfirmPasswordChange}
                                    icon={<img
                                        src={eyeIcon}
                                        alt="Toggle visibility"
                                        onClick={() => setPasswordVisible(!passwordVisible)}
                                        className="cursor-pointer"
                                    />}
                                    crossOrigin={undefined}
                                    onPointerEnterCapture={undefined}
                                    onPointerLeaveCapture={undefined}
                                />
                                <Typography
                                    style={{ fontWeight: 700, fontSize: "16px", lineHeight: "18px", paddingTop: "20px", paddingBottom: "10px" }}
                                    className="text-3xl font-medium text-[#0B1962]  text-left"
                                    placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            >
                                    {t("PASSWORD_MUST")}
                                </Typography>
                                <div style={{ display: "flex", gap: "5px", paddingLeft: "20px" }}>
                                    <img src={substractIcon} alt="Omniloy" />
                                    <Typography
                                        style={{ fontWeight: 400, fontSize: "12px", lineHeight: "14px" }}
                                        className="text-3xl font-medium text-[#0B1962]  text-left"
                                        placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            >
                                        {t("MUST_1")}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", gap: "5px", paddingLeft: "20px" }}>
                                    <img src={substractIcon} alt="Omniloy" />
                                    <Typography
                                        style={{ fontWeight: 400, fontSize: "12px", lineHeight: "14px" }}
                                        className="text-3xl font-medium text-[#0B1962]  text-left"
                                        placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            >
                                        {t("MUST_3")}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", gap: "5px", paddingLeft: "20px" }}>
                                    <img src={substractIcon} alt="Omniloy" />
                                    <Typography
                                        style={{ fontWeight: 400, fontSize: "12px", lineHeight: "14px" }}
                                        className="text-3xl font-medium text-[#0B1962]  text-left"
                                        placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            >
                                        {t("MUST_3")}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", gap: "5px", paddingLeft: "20px" }}>
                                    <img src={substractIcon} alt="Omniloy" />
                                    <Typography
                                        style={{ fontWeight: 400, fontSize: "12px", lineHeight: "14px" }}
                                        className="text-3xl font-medium text-[#0B1962]  text-left"
                                        placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            >
                                        {t("MUST_4")}
                                    </Typography>
                                </div>
                            </form>

                        }

                    </div>
                    {!passwordModal &&
                        <div>
                            <Button
                                style={{
                                    minWidth: "150px",
                                    textTransform: "none",
                                    fontWeight: "600",
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                                onClick={() => { setPasswordModal(true) }}
                                className="w-full mt-4 bg-[#0458DD]"
                                placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}              >
                                {t("GET_STARTED")}
                            </Button>
                        </div>

                    }
                    {passwordModal &&
                        <div>
                            <Button
                                onClick={() => { resetPassword() }}
                                style={{
                                    minWidth: "150px",
                                    textTransform: "none",
                                    fontWeight: "600",
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                                className="w-full mt-4 bg-[#0458DD]"
                                placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}              >
                                {t("SET_PASSWORD")}
                            </Button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

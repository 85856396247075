import { privateSupabase } from "../SupabaseClient";

export const getConnectorPrefix = (connector: string) => { 
    switch (connector) { //We will add more options when we add more connectors.
        case 'airtable':
            return 'at';
        default:
            return 'at'; 
    }
};

export const migrateData = async (connector: string, company_id: string, prefix: string, sources: string[] | string = "*") => {
    try {
        const response = await fetch(`${process.env.REACT_APP_OMNILOY_API_SERVER}/migrate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ connector, company_id, prefix, sources }),
        })

        const result = await response.json();
        console.log('Data migrated successfully:', result);
    } catch (error) {
        console.error('Error migrating data:', error);
    }
};

export const checkMigrationStatus = async (connector: string, company_id: string) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_OMNILOY_API_SERVER}/migrationStatus`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ connector, company_id }),
        })

        const result = await response.json();
        const status =  result.status;

        const { error } = await privateSupabase.from('migrations').update([ { status }, ]).eq('migration_id', `${company_id}_${connector}`); 
        if (status === "completed") {
            const { error } = await privateSupabase.from('migrations').update([ { migration_date: result.migration_date }, ]).eq('migration_id', `${company_id}_${connector}`); 
        }
    } catch (error) {
        console.error('Error checking migration status:', error);
    }
};
/** @format */
import { Typography } from "@material-tailwind/react";
import { t } from "i18next";
import React from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { SidebarDropdownOption, SidebarOptionProps } from "./SidebarOptions";


interface SidebarItemProps extends SidebarOptionProps {
    onSelect: (option: SidebarDropdownOption, event: React.MouseEvent<HTMLElement>) => void;
    isOpen?: boolean;
    toggleDropdown?: (event: React.MouseEvent<HTMLElement>) => void;
    children?: React.ReactNode;
    customIcon?: string;
}

export const SidebarItem: React.FC<SidebarItemProps> = React.memo(
    ({ children, label, isOpen, toggleDropdown, dropdownOptions }) => {
        return (
            <>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignContent: "center",
                        alignItems: "center",
                        gap: "1.5rem",
                        paddingLeft: "2rem",
                        borderRadius: "10px",
                        marginBottom: "0.5rem",
                        padding: "0.5rem",
                    }}
                    onClick={toggleDropdown}
                >

                    <Typography style={{ fontWeight: 400, fontSize: "12px", lineHeight: "15px", color: "#76787D" }} placeholder={undefined}>
                        {t(label!)}
                    </Typography>
                    {dropdownOptions?.length !== 0 && (
                        <button onClick={toggleDropdown} style={{ marginLeft: 'auto' }}>
                            {isOpen ? <ChevronUpIcon width="1rem" color="#76787D" /> : <ChevronDownIcon width="1rem" color="#76787D" />}
                        </button>
                    )}
                </div>

                {children}
            </>
        );
    }
);

SidebarItem.displayName = "SidebarItem";
import React from "react";
import { Typography } from "@material-tailwind/react";
import { t } from "i18next";

interface DetailTextProps {
    labelText?: string;
    inputText?: string;
    color: string;
    isLink?: boolean
}

const DetailText = ({ labelText, inputText, color, isLink }: DetailTextProps) => {
    const displayValue =
    isLink && inputText ? (
      <a
        href={inputText}
        target="_blank"
        rel="noopener noreferrer"
        className="text-[#0059AD] hover:underline"
      >
        {inputText}
      </a>
    ) : (
        inputText
    );
    return (
        <div>
            <label
                style={{
                    color: "#76787D",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "16px",
                    textWrap: "nowrap",
                }}
            >
                {t(labelText!)}
            </label>
            <Typography
                style={{
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    color: color,
                    textWrap: "nowrap",
                }}
                className="text-sm font-normal text-[#79767D]"
                placeholder={undefined}
            >
                {inputText === null || inputText === "" ? "-" : displayValue}
            </Typography>
        </div>
    );
};

export default DetailText;

/** @format */
import React, { useCallback, useEffect, useState } from "react";
import FilterComponent from "../components/FilterComponent";
import { useFilter } from "../context/filterContext";
import { publicSupabase, userSession } from "../api/SupabaseClient";
import { Reports } from "./reports";
import MainButton from "../components/Buttons/MainButton";
import { useSupabase } from "../context/supabaseContext";

export function Analytics() {
  const { sqlQuery, setOpenEditDashboard } = useFilter();
  const [session, setSession] = useState<any>(null);
  const [searchInput, setSearchInput] = useState("");
  const [filterValues, setFilterValues] = useState({});
  const [showDashboardCreator, setShowDashboardCreator] = useState(false);
  const [newDashboard, setNewDashboard] = useState(false);
  const { getUserRole } = useSupabase();
  const [role, setRole] = useState<number>(3);

  useEffect(() => {
    const fetchUserRole = async () => {
      const userRole = await getUserRole();
      setRole(userRole);
    };
    fetchUserRole();
  }, []);

  useEffect(() => {
    userSession().then((session: any) => {
      setSession(session.data.session);
    });
    publicSupabase.auth.onAuthStateChange((_event: any, session: any) => {
      setSession(session);
    });
  }, []);

  const handleFilterChange = useCallback((filterName: any, value: any) => {
    setFilterValues((prev) => ({ ...prev, [filterName]: value }));
  }, []);

  const getAvailableFiltersForCurrentTable = () => {
    return ["name", "created_by"];
  };

  const onSearchChange = useCallback((e: any) => {
    setSearchInput(e.target.value);
  }, []);

  const handleCreateNewReport = () => {
    setShowDashboardCreator(true);
    setNewDashboard(true);
    setOpenEditDashboard(true);
  };

  return (
    <div className="tab-products flex flex-col overflow-hidden h-full">
      <div className="flex flex-col justify-between bg-white rounded-2xl mx-6 flex-grow overflow-hidden">
        {!showDashboardCreator && !newDashboard && (
          <div className="flex flex-row justify-between items-center w-full px-8 mt-8 mb-4">
            <div className="flex flex-row justify-start items-center space-x-4">
              <FilterComponent
                availableFilters={getAvailableFiltersForCurrentTable()}
                placeHolders={["Name", "Created By"]}
                filterValues={filterValues}
                onFilterChange={handleFilterChange}
                searchInput={searchInput}
                onSearchChange={onSearchChange}
                showFilters={false}
                showSearch={true}
              />
            </div>
            {role !== 3 && (
              <div>
                <MainButton
                  buttonText="CREATE_REPORT"
                  onClick={handleCreateNewReport}
                />
              </div>
            )}
          </div>
        )}
        <Reports
          filters={filterValues}
          sqlQuery={sqlQuery}
          searchInput={searchInput}
          onShowDashboardCreator={setShowDashboardCreator}
          newDashboard={newDashboard}
          setNewDashboard={setNewDashboard} // Pass setNewDashboard as a prop
        />
      </div>
      <div className="h-10"></div>
    </div>
  );
}

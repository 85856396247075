import React from 'react';


interface ProfileImageProps {
  src: string;
  isEditable?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
}

const ProfileImage: React.FC<ProfileImageProps> = ({ src, isEditable, onChange, children }) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const handleClick = () => {
    if (isEditable && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="flex flex-col px-20 py-10 relative border-r border-gray-300" style={{ gap: "50px" }}>
      <div className="flex flex-col items-center justify-center m-auto">
        {!isEditable ?
          <img
            src={src}
            alt="Company Logo"
            className={`w-48 h-48 rounded-full object-contain ${isEditable ? 'cursor-pointer' : ''}`}
          />
          :
          <img
            onClick={handleClick}
            src={src}
            alt="Company Logo"
            className={`w-48 h-48 rounded-full object-contain ${isEditable ? 'cursor-pointer' : ''}`}
          />
        }
        {isEditable &&
          <input
            ref={fileInputRef}
            type="file"
            onChange={onChange}
            className="hidden"
          />
        }

      </div>
      {children}
    </div>
  );
};

export default ProfileImage;

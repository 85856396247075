/** @format */
import React from "react";
import { Dialog, Button, Input } from "@material-tailwind/react";
import { t } from "i18next";
import ProfileImage from "../../ProfileImage";
import { Option, Select } from "@material-tailwind/react/components/Select";
import DetailsTitle from "../../DetailsTitle";
import DetailsText from "../../DetailsText";
import capitalizeFirstLetter from "../../../../utils/strUtils";
import { ModalHeader } from "../../../../components/Modal";

interface EditProfileModalProps {
    editModelOpen: boolean;
    setEditModelOpen: (open: boolean) => void;
    profileData: any;
    setProfileData: (data: any) => void;
    getImageSrc: () => string;
    handleUserIconChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    saveData: () => void;
    setLanguage: (language: string) => void;
    countries: any[];
}
const EditProfileModal = ({
    editModelOpen,
    setEditModelOpen,
    profileData,
    setProfileData,
    getImageSrc,
    handleUserIconChange,
    saveData,
    setLanguage,
    countries,
}: EditProfileModalProps) => {
    return (
        <Dialog
            style={{ width: "1450px", minWidth: "1450px" }}
            open={editModelOpen}
            handler={setEditModelOpen}
            placeholder={undefined}
        >
            <ModalHeader title={"EDIT"} onClose={() => setEditModelOpen(false)} />

            <div className="py-4 px-6 flex items-center" style={{ padding: "20px 80px" }}>
                <Input
                    label={t("NAME")}
                    type="text"
                    value={profileData?.full_name}
                    onChange={(e) =>
                        setProfileData({ ...profileData, full_name: e.target.value })
                    }
                    crossOrigin={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                />
            </div>
            <div className="flex justify-start gap-8 mt-6 pb-8">
                <ProfileImage
                    isEditable={true}
                    src={getImageSrc()}
                    onChange={(e) => handleUserIconChange(e)}
                >
                    <div>
                        <div className="flex flex-col">
                            <DetailsTitle titleText="PERSONAL_INFORMATION" marginBottom="10px" />
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "80px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "25px",
                                        flex: "1",
                                    }}
                                >
                                    <Input
                                        label={t("DATE_OF_BIRTH")}
                                        type="date"
                                        value={profileData?.birthdate}
                                        onChange={(e) =>
                                            setProfileData({
                                                ...profileData,
                                                birthdate: e.target.value,
                                            })
                                        }
                                        crossOrigin={undefined}
                                        onPointerEnterCapture={undefined}
                                        onPointerLeaveCapture={undefined}
                                    />
                                    <Select
                                        label={t("COUNTRY")}
                                        value={profileData.country}
                                        onChange={(e) =>
                                            setProfileData({ ...profileData, country: e })
                                        }
                                        placeholder={undefined}
                                        onPointerEnterCapture={undefined}
                                        onPointerLeaveCapture={undefined}
                                    >
                                        {countries.map((country, index) => (
                                            <Option key={index} value={country.name}>
                                                {country.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "25px",
                                        flex: "1",
                                    }}
                                >
                                    <Input
                                        label={t("CITY")}
                                        type="text"
                                        value={profileData?.city}
                                        onChange={(e) =>
                                            setProfileData({ ...profileData, city: e.target.value })
                                        }
                                        crossOrigin={undefined}
                                        onPointerEnterCapture={undefined}
                                        onPointerLeaveCapture={undefined}
                                    />

                                    <Input
                                        label={t("POSTAL_CODE")}
                                        type="text"
                                        value={profileData?.postal_code}
                                        onChange={(e) => {
                                            setProfileData({
                                                ...profileData,
                                                postal_code: e.target.value.toString(),
                                            })
                                        }
                                        }
                                        crossOrigin={undefined}
                                        onPointerEnterCapture={undefined}
                                        onPointerLeaveCapture={undefined}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </ProfileImage>
                <div className="flex flex-col" style={{ gap: "50px", padding: "20px" }}>
                    {/** PROFESSIONAL INFORMATION  */}
                    <div className="flex flex-col">
                        <DetailsTitle titleText="PROFESSIONAL_INFORMATION" marginBottom="10px"  />
                        <Input
                            label={t("JOB_TITLE")}
                            type="text"
                            value={profileData?.job_title}
                            onChange={(e) =>
                                setProfileData({
                                    ...profileData,
                                    job_title: e.target.value,
                                })
                            }
                            crossOrigin={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}
                        />
                    </div>

                    {/** CONTACT INFORMATION  */}
                    <div className="flex flex-col">
                        <DetailsTitle titleText="CONTACT_INFORMATION" marginBottom="10px" />
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "100px",
                            }}
                        >
                            <Input
                                label={t("ADDRESS")}
                                type="text"
                                value={profileData?.address}
                                onChange={(e) =>
                                    setProfileData({ ...profileData, address: e.target.value })
                                }
                                crossOrigin={undefined}
                                onPointerEnterCapture={undefined}
                                onPointerLeaveCapture={undefined}
                            />
                            <Input
                                label={t("MOBILE")}
                                type="text"
                                value={profileData?.mobile}
                                onChange={(e) =>
                                    setProfileData({ ...profileData, mobile: e.target.value })
                                }
                                crossOrigin={undefined}
                                onPointerEnterCapture={undefined}
                                onPointerLeaveCapture={undefined}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "100px",
                                marginTop: "10px",
                            }}
                        >
                            <DetailsText
                                labelText="Email"
                                inputText={profileData.email}
                                color= "#0C1122"
                            />
                        </div>
                    </div>

                    {/** ACCOUNT INFORMATION  */}
                    <div className="flex flex-col">
                        <DetailsTitle titleText="ACCOUNT_INFORMATION" marginBottom="10px" />
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "100px",
                                alignItems: "flex-end",
                                flex: 1
                            }}
                        >
                            <div style={{ flex: 1 }}>
                                <DetailsText
                                    labelText="PERMISSIONS"
                                    inputText={capitalizeFirstLetter(
                                        profileData.role.role_description
                                    )}
                                    color= "#0C1122"
                                />
                            </div>
                            <div style={{ flex: 1 }}>
                                <Select
                                    style={{ flex: 1 }}
                                    label={t("LANGUAGE")}
                                    value={profileData?.language}
                                    onChange={(e: any) => setLanguage(e)}
                                    placeholder={undefined}
                                    onPointerEnterCapture={undefined}
                                    onPointerLeaveCapture={undefined}
                                >
                                    <Option value="en">{t("ENGLISH")}</Option>
                                    <Option value="es">{t("SPANISH")}</Option>
                                </Select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="py-4 px-6 flex flex-row-reverse"
                style={{ gap: "10px", paddingLeft: "80px", paddingRight: "80px", paddingBottom: "30px" }}
            >
                <Button
                    style={{ minWidth: "150px", textTransform: "none", fontWeight: "500", fontSize: "14px", lineHeight: "20px", display: "flex", alignItems: "center", justifyContent: "center" }}
                    className="h-10 bg-[#0059AD]"
                    color="white"
                    variant="outlined"
                    onClick={saveData}
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                >
                    {t("SAVE")}
                </Button>
            </div>
        </Dialog>
    );
};

export default EditProfileModal;
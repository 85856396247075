/** @format */

import React, { useEffect, useState, useRef } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface ColumnFilterProps {
  column: string;
  filters: Record<string, string[]>;
  applyFilter?: (column: string, value: string[]) => void;
  applyFilterFinal?: (column: string, value: string[]) => void;
  removeFilter: (column: string) => void;
  removeFilterWithData?: (column: string) => void;
  table?: string;
  onSort: (column: string, direction: "asc" | "desc") => void;
  filterOptions: string[];
  columnType?: string;
  columnIndex: number;
  fetchedData?: any;
}

const ColumnFilter: React.FC<ColumnFilterProps> = ({
  column,
  filters,
  applyFilter,
  removeFilter,
  removeFilterWithData,
  applyFilterFinal,
  table,
  fetchedData,
  onSort,
  filterOptions,
  columnType,
  columnIndex,
}) => {
  const [selectedFilters, setSelectedFilters] = useState<string[]>(
    filters[column] || []
  );
  const [showDropdown, setShowDropdown] = useState(false);
  const [showCustom, setShowCustom] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSelectedFilters(filters[column] || []);
  }, [filters, column]);

  const handleFilterChange = (value: string) => {
    if (
      columnType === "date" ||
      columnType === "timestamp" ||
      columnType === "timestampz"
    ) {
      if (value === "all_time") {
        handleRemoveAll();
        return;
      }
      let newSelectedFilters;
      if (selectedFilters.includes(value)) {
        newSelectedFilters = selectedFilters.filter(
          (filter) => filter !== value
        );
      } else {
        newSelectedFilters = [value];
      }
      setSelectedFilters(newSelectedFilters);
      if (applyFilter) {
        applyFilter(column, newSelectedFilters);
      }
      if (applyFilterFinal) {
        applyFilterFinal(column, newSelectedFilters);
      }
    } else {
      let newSelectedFilters;
      if (selectedFilters.includes(value)) {
        newSelectedFilters = selectedFilters.filter(
          (filter) => filter !== value
        );
      } else {
        newSelectedFilters = [...selectedFilters, value];
      }
      setSelectedFilters(newSelectedFilters);
      if (applyFilter) {
        applyFilter(column, newSelectedFilters);
      }
      if (applyFilterFinal) {
        applyFilterFinal(column, newSelectedFilters);
      }
    }
  };

  const handleRemoveAll = () => {
    setSelectedFilters([]);
    if (removeFilterWithData) {
      removeFilterWithData(column);
    } else {
      removeFilter(column);
    }
  };

  const handleSelectAll = () => {
    if (selectedFilters.length === filterOptions.length) {
      handleRemoveAll();
    } else {
      setSelectedFilters(filterOptions);
      if (applyFilter) {
        applyFilter(column, filterOptions);
      }
      if (applyFilterFinal) {
        applyFilterFinal(column, filterOptions);
      }
    }
  };

  const handleSort = (direction: "asc" | "desc") => {
    onSort(column, direction);
    setShowDropdown(false);
  };

  const filteredOptions = filterOptions.filter((option) => {
    const optionStr = typeof option === "string" ? option : String(option);
    return optionStr.toLowerCase().includes(searchInput.toLowerCase());
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
        setShowCustom(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const dateOptions = [
    { label: "All Time", value: "all_time" },
    { label: "Last Year", value: "last_year" },
    { label: "Last Month", value: "last_month" },
    { label: "Last Week", value: "last_week" },
    { label: "Today", value: "today" },
    { label: "Custom", value: "custom" },
  ];

  const renderDateOptions = () => (
    <>
      {dateOptions.map((option, index) => (
        <div
          key={index}
          className={`w-[7rem] flex items-center p-2 justify-between  cursor-pointer ${
            selectedFilters.includes(option.value) ? "text-blue-600" : ""
          }`}
          onClick={() => {
            if (option.value === "custom") {
              setShowCustom(!showCustom);
            } else {
              handleFilterChange(option.value);
              setShowCustom(false);
            }
          }}
        >
          <span className="ml-2">{option.label}</span>
        </div>
      ))}
    </>
  );

  const renderCustomDatePicker = () => (
    <div className="flex flex-col p-4 border border-gray-300 rounded mt-2 w-full max-h-[350px] overflow-y-auto z-100">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center text-lg font-medium justify-between w-full">
          <span>
            {startDate ? startDate.toLocaleDateString() : "Start date"}
          </span>
          <span className="px-2">-</span>
          <span>{endDate ? endDate.toLocaleDateString() : "End date"}</span>
        </div>
      </div>
      <DatePicker
        selected={startDate}
        onChange={(dates: [Date | null, Date | null]) => {
          const [start, end] = dates;
          setStartDate(start);
          setEndDate(end);
        }}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        inline
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="flex justify-between items-center px-2 py-2">
            <button
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              type="button"
              className={`text-gray-500 hover:text-gray-700 ${
                prevMonthButtonDisabled ? "cursor-not-allowed opacity-50" : ""
              }`}
            >
              {"<"}
            </button>
            <div className="flex items-center space-x-2">
              <select
                value={date.getFullYear()}
                onChange={({ target: { value } }) =>
                  changeYear(parseInt(value))
                }
                className="text-lg font-medium bg-white border border-gray-300 rounded px-2 py-1"
              >
                {Array.from(new Array(5), (_, i) => (
                  <option key={i} value={date.getFullYear() - 2 + i}>
                    {date.getFullYear() - 2 + i}
                  </option>
                ))}
              </select>
              <select
                value={date.getMonth()}
                onChange={({ target: { value } }) =>
                  changeMonth(parseInt(value))
                }
                className="text-lg font-medium bg-white border border-gray-300 rounded px-2 py-1"
              >
                {[
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ].map((month, index) => (
                  <option key={index} value={index}>
                    {month}
                  </option>
                ))}
              </select>
            </div>
            <button
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              type="button"
              className={`text-gray-500 hover:text-gray-700 ${
                nextMonthButtonDisabled ? "cursor-not-allowed opacity-50" : ""
              }`}
            >
              {">"}
            </button>
          </div>
        )}
      />
      <div className="flex justify-end mt-4 space-x-2">
        <button
          className="bg-white text-[#0458DD] px-4 py-2 rounded-full text-sm font-normal"
          onClick={() => {
            setStartDate(null);
            setEndDate(null);
            setShowCustom(false);
          }}
        >
          Cancel
        </button>
        <button
          className="bg-[#0458DD] text-white px-4 py-2 rounded-full text-sm font-normal"
          onClick={() => {
            if (startDate && endDate) {
              const dateRangeValue = `${startDate.toISOString()},${endDate.toISOString()}`;
              handleFilterChange(dateRangeValue);
              setShowCustom(false);
              setShowDropdown(false);
            }
          }}
        >
          Apply
        </button>
      </div>
    </div>
  );

  return (
    <>
      <div className="relative" ref={dropdownRef}>
        <button
          className="flex items-center justify-center w-8 h-8"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <ChevronDownIcon className="w-4 h-4" />
        </button>
        {showDropdown && (
          <div
            className={`absolute z-10 bg-white border border-gray-300 mt-1 shadow-lg text-black py-4 px-2 rounded-xl ${fetchedData ? "h-[250px]" : ""} max-h-[24rem]`}
            style={{
              display: "flex",
              flexDirection: showCustom ? "row" : "column",
              maxWidth: showCustom ? "40rem" : "auto",
              minWidth: showCustom ? "auto" : "10rem",
              overflowX: "auto",
              left: columnIndex === 0 ? "0" : "auto",
              right: columnIndex !== 0 ? "0" : "auto",
            }}
          >
            <div
              style={{
                borderRight: showCustom ? "1px solid gray" : "none",
                paddingRight: showCustom ? "1rem" : "0",
              }}
            >
              {columnType === "date" ||
              columnType === "timestamp" ||
              columnType === "timestampz" ? (
                renderDateOptions()
              ) : (
                <>
                  <div className="flex flex-row p-1 justify-between border-b border-gray-300">
                    <button onClick={() => handleSort("asc")} className="">
                      A to Z
                    </button>
                    <button onClick={() => handleSort("desc")} className="">
                      Z to A
                    </button>
                  </div>
                  <div className="p-2">
                    <input
                      type="text"
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                      placeholder={`Search ${column}`}
                      className="w-full px-2 py-1 border border-gray-300 rounded"
                    />
                  </div>
                  <label className="flex items-center p-2 justify-between w-full">
                    <span className="ml-2">Select All</span>
                    <input
                      type="checkbox"
                      checked={
                        selectedFilters.length > 0 &&
                        selectedFilters.length === filterOptions.length
                      }
                      onChange={handleSelectAll}
                      className="form-checkbox h-4 w-4 text-blue-600"
                    />
                  </label>
                  {filteredOptions.map((option, index) => (
                    <label
                      key={index}
                      className="flex items-center p-2 justify-between w-full"
                    >
                      <span className="ml-2 text-black">{option}</span>
                      <input
                        type="checkbox"
                        checked={selectedFilters.includes(option)}
                        onChange={() => handleFilterChange(option)}
                        className="form-checkbox h-4 w-4 text-blue-600"
                      />
                    </label>
                  ))}
                </>
              )}
            </div>
            {showCustom && (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                {renderCustomDatePicker()}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ColumnFilter;

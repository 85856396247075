/** @format */

import React, { useState } from "react";
import PropTypes from "prop-types";
import ReloadContext from "./context";

const initialValue = {
  status: false,
};

export default function ReloadProvider({ children }) {
  const [reloadState, setState] = useState(initialValue);
  const setReloadState = (obj) => {
    setState((prevState) => ({ ...prevState, ...obj }));
  };

  return (
    <ReloadContext.Provider value={{ reloadState, setReloadState }}>
      {children}
    </ReloadContext.Provider>
  );
}

ReloadProvider.propTypes = {
  children: PropTypes.node,
};

export const normalizeItem = (item: any) => {
    if (item.type === "card") {
      const newItem = {
        ...item,
        title: item.data.title,
        id: item.data.id,
        type: item.data.type,
        visualization: item.data.visualization,
        description: item.data.description,
        collection: item.data.collection,
        labels: item.data.labels,
        thread_id: item.data.thread_id,
        ai_process: item.data.ai_process,
        query: item.data.query,
        size: item.data.size,
        created_at: item.data.created_at,
        created_by: item.data.created_by,
        last_update: item.data.last_update,
        modification_date: item.data.modification_date,
      };
      return newItem;
    }
  
    return item;
  };
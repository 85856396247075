/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useFilter } from "../../context/filterContext";
import { useChatVisibility } from "../../context/chatVisibilityContext";
import { Button } from "@material-tailwind/react";
import { ChevronDownIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";
import {
  PlusIcon,
  InformationCircleIcon,
  QueueListIcon,
} from "@heroicons/react/24/solid";
import { t } from "i18next";
import { privateSupabase } from "../../api/SupabaseClient";
import { createIncrementalCounter } from "../../utils/chatAIUtils";
import ChatHistory from "./ChatHistory";

const SidebarAI = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    setTablesExplanation,
    setOpenCreateCustom,
    setTypeCustom,
    setTableHeaders,
    setTableData,
    setTableTitle,
    setTableDescription,
    setChartChatData,
    setCleanedQuery,
    setMessages,
    setQuerySteps,
    setQueryError,
    setQueryErrorCode,
    setQueryErrorHint,
    setQueryErrorExplanation,
    setNewThreadId,
    setThreadId,
    setRenderType,
    chatHistory,
    setPrevThreadId,
    setTables,
    messages,
    setMatchingIndex,
    task,
    setTask,
    setTypeCustoms,
    setRenderTypes,
    setChartType,
    setInitialMessage,
    setMessage,
  } = useFilter();
  const { setGlobalRender } = useChatVisibility();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const generateRandomId = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomPart = "";
    for (let i = 0; i < 4; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomPart += characters[randomIndex];
    }
    const timestamp = Date.now().toString();
    return `t_id_${randomPart}${timestamp}`;
  };

  const clearOldChat = () => {
    localStorage.removeItem("actualMessageUser");
    setInitialMessage("");
    setRenderTypes([]);
    setTask(null);
    setNewThreadId(true); // This line is correct for generating a new thread ID.
    setChartChatData(null);
    setTablesExplanation([]);
    setTableData([]);
    setTables([]);
    setTableDescription("");
    setTableHeaders([]);
    setTableTitle("");
    setCleanedQuery("");
    setTypeCustom("");
    setRenderType("");
    setQuerySteps(null);
    setQueryError(null);
    setQueryErrorCode(null);
    setQueryErrorHint(null);
    setQueryErrorExplanation(null);
    setMessages([
      { id: Date.now(), text: t("ASSISTANT"), sender: "server", type: "text" },
    ]); // Ensure initial message is set correctly
    setMessage("");
    setThreadId(generateRandomId()); // Generate a new threadId
  };

  const redirectToHome = () => {
    setInitialMessage("");
    setRenderTypes([]);
    setTask(null);
    setNewThreadId(true);
    setChartChatData(null);
    setTablesExplanation([]);
    setTableData([]);
    setTableDescription("");
    setTableHeaders([]);
    setTableTitle("");
    setCleanedQuery("");
    setTypeCustom("");
    setRenderType("");
    setQuerySteps(null);
    setQueryError(null);
    setQueryErrorCode(null);
    setQueryErrorHint(null);
    setQueryErrorExplanation(null);
    setMessages([]);
    setThreadId(null);
    localStorage.removeItem("currentTask");
    localStorage.removeItem("actualMessageUser");
    setOpenCreateCustom(false);
    setGlobalRender(true);
    if (messages.length === 0) {
      navigate("/");
    }
  };

  useEffect(() => {
    if (task) {
      findMatchingMessageIndex(task);
    }
  }, [messages, task]);

  const findMatchingMessageIndex = (task: string) => {
    for (let i = messages.length - 1; i >= 0; i--) {
      if (messages[i].sender === "user" && messages[i].text.includes(task)) {
        setMatchingIndex(i);
      }
    }
    return -1; // Return -1 if no match is found
  };

  const generateIndex = createIncrementalCounter();

  const executeSQLQuery = async (sql: string) => {
    try {
      const { data, error } = await privateSupabase.rpc(
        "execute_dynamic_query_json_new",
        {
          query_text: sql,
        }
      );
      if (error) throw error;
      return data;
    } catch (error) {
      console.error("Error executing SQL query:", error);
      throw error;
    }
  };

  const handlePlanTypeMessage = (data: any) => {
    setMessages([]);
    const steps = data
      .split(/#E\d+\s*/)
      .filter((step: any) => step.trim() !== "");
    if (steps.length > 0) {
      setMessages((prevMessages: any) =>
        prevMessages.filter(
          (msg: any) =>
            !msg.text.startsWith(
              "Processing your request, please be patient while we work on these steps:"
            )
        )
      );

      const customMessage = `Processing your request, please be patient while we work on these steps:\n`;

      const combinedSteps = steps
        .map((step: any, index: any) => `\nStep ${index + 1}: ${step}`)
        .join("");
      return `${customMessage}${combinedSteps}`;
    }
  };

  const createChart = async (additionalObj: any, newMessageId: string) => {
    let newIndex = generateIndex();
    const tableTitle = additionalObj.arguments.explanation.title;
    const tableDescription = additionalObj.arguments.explanation.description;
    const newTable = {
      id: newMessageId,
      index: newIndex,
      tableData: "",
      tableHeaders: [],
      tableTitle: tableTitle,
      tableDescription: tableDescription,
      currentPage: 1,
      itemsPerPage: 10,
      lastSqlQuery: "",
      explanation: [
        {
          title: tableTitle,
          description: tableDescription,
        },
      ],
      querySteps: [],
      editableTitle: tableTitle,
      isEditingTitle: false,
      task: "Here is your result",
      type: "",
      cleanedQuery: "",
    };

    // Add the new table to the existing tables
    setTables((prevTables: any) => [...prevTables, newTable]);
    let { chartType, data, labels } = additionalObj.arguments;

    if (typeof data[0] === "string" && data[0].startsWith("[")) {
      const parsedData = JSON.parse(data[0]);
      labels = parsedData.map(
        (item: any) => item.productName || "Unknown Product"
      );
      data = parsedData.map((item: any) => item.price);
    } else if (typeof data[0] === "string") {
      data = data[0].split(",").map((value) => parseFloat(value.trim()));
      labels = labels[0].split(",").map((label: any) => label.trim());
    }

    const chartData = {
      data: {
        data: data,
        labels: labels,
      },
      type: chartType,
    };
    setTablesExplanation((prevTables: any) => [
      ...prevTables,
      {
        title: additionalObj.arguments.explanation.title,
        description: additionalObj.arguments.explanation.description,
        chartTitle: additionalObj.arguments.explanation.chartTitle,
        chartLabel: additionalObj.arguments.explanation.chartLabel,
      },
    ]);
    setChartChatData(chartData.data);
    setChartType(chartData.type);
  };

  const createTableStructure = async (
    additionalObj: any,
    query: any,
    lastId: any
  ) => {
    const queryData = await executeSQLQuery(query["#E1"]);
    if (queryData && queryData.length > 0) {
      let newIndex = generateIndex();
      const tableTitle = additionalObj.arguments.tableName;
      const tableHeaders = additionalObj.arguments.column;
      const newTable = {
        id: lastId,
        index: newIndex,
        tableData: queryData,
        tableHeaders: tableHeaders,
        tableTitle: tableTitle,
        tableDescription: "",
        currentPage: 1,
        itemsPerPage: 10,
        lastSqlQuery: "",
        explanation: [],
        querySteps: [],
        editableTitle: tableTitle,
        isEditingTitle: false,
        task: "Here is your result",
        type: "",
        cleanedQuery: "",
      };

      setTables((prevTables: any) => [...prevTables, newTable]);
    }
  };

  const createTable = async (
    cleanedSqlQuery: string,
    additionalObj: any,
    newMessageId: string
  ) => {
    try {
      const queryData = await executeSQLQuery(cleanedSqlQuery);
      if (queryData && queryData.length > 0) {
        let newIndex = generateIndex();
        const tableTitle = additionalObj.arguments.sqlTitle;
        const tableDescription = additionalObj.arguments.sqlDescription;
        const explanation = additionalObj.arguments.explanation;
        const tableHeaders = additionalObj.arguments.headers;
        const newTable = {
          id: newMessageId,
          index: newIndex,
          tableData: queryData,
          tableHeaders: tableHeaders,
          tableTitle: tableTitle,
          tableDescription: tableDescription,
          currentPage: 1,
          itemsPerPage: 10,
          lastSqlQuery: cleanedSqlQuery,
          explanation: [
            {
              title: explanation.title,
              description: explanation.description,
            },
          ],
          querySteps: explanation.steps || [],
          editableTitle: tableTitle,
          isEditingTitle: false,
          task: "Here is your result",
          type: "",
          cleanedQuery: cleanedSqlQuery,
        };

        setTables((prevTables: any) => [...prevTables, newTable]);
      }
    } catch (error) {
      console.error("Error retrieving segment details:", error);
    }
  };

  const clearChat = () => {
    localStorage.removeItem("actualMessageUser");
    setNewThreadId(true);
    setChartChatData(null);
    setTablesExplanation([]);
    setTableData([]);
    setTableDescription("");
    setTableHeaders([]);
    setTableTitle("");
    setCleanedQuery("");
    setTypeCustom("");
    setRenderType("");
    setQuerySteps(null);
    setQueryError(null);
    setQueryErrorCode(null);
    setQueryErrorHint(null);
    setQueryErrorExplanation(null);
    setThreadId(null);
    setNewThreadId(true);
  };

  return (
    <div className="flex flex-col w-[270px] max-h-screen bg-white rounded-[20px] p-6 m-4 flex-shrink-0 justify-between shadow-lg">
      <div>
        <div
          className="flex items-center pr-4 py-4 cursor-pointer mb-4"
          onClick={redirectToHome}
        >
          <ChevronLeftIcon className="h-5 w-5 text-gray-700" />
          <h1 className="ml-2 text-lg font-semibold text-gray-700 ">Omni</h1>
        </div>

        <div className="flex flex-col gap-6">
          <div>
            <Button
              className="w-full"
              style={{
                display: "flex",
                alignItems: "center",
                background: "#F1EBFF",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "17px",
                textTransform: "none",
                color: "#5B26D3",
                gap: "16px",
                borderRadius: "25px",
                border: "solid 1px #D3BFFF",
                padding: "8px 16px 8px 16px",
                transition: "background 0.3s, border 0.3s",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.background = "white";
                e.currentTarget.style.border = "1px solid #D3BFFF";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.background = "#F1EBFF";
                e.currentTarget.style.border = "solid 1px #D3BFFF";
              }}
              onClick={clearOldChat}
              placeholder={undefined}
            >
              <PlusIcon className="w-6  ml-1" />
              <p className="ml-4">{t("NEW_CHAT")}</p>
            </Button>
          </div>
          <div>
            <Button
              className="w-full flex flex-row justify-around"
              style={{
                display: "flex",
                alignItems: "center",
                background: "white",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "17px",
                textTransform: "none",
                color: "black",
                gap: "16px",
                borderRadius: "25px",
                border: "solid 1px #1C1C1C0D",
                padding: "8px 16px 8px 16px",
                transition: "background 0.3s, border 0.3s",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.background = "white";
                e.currentTarget.style.border = "1px solid #EEEEEE";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.background = "white";
                e.currentTarget.style.border = "solid 1px #1C1C1C0D";
              }}
              onClick={() => {}}
              disabled={true}
              placeholder={undefined}
            >
              <InformationCircleIcon className="w-6 h-6" />
              {t("AI_DEFINITIONS")}
              <ChevronDownIcon className="w-4 h-4" />
            </Button>
          </div>
          <div>
            <Button
              className="w-full flex flex-row justify-around"
              disabled={true}
              style={{
                display: "flex",
                justifyItems: "evenly",
                background: "white",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "17px",
                textTransform: "none",
                color: "black",
                gap: "16px",
                borderRadius: "25px",
                border: "solid 1px #1C1C1C0D",
                padding: "12px 16px 12px 16px",
                transition: "background 0.3s, border 0.3s",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.background = "white";
                e.currentTarget.style.border = "solid 1px #EEEEEE";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.background = "white";
                e.currentTarget.style.border = "solid 1px #1C1C1C0D";
              }}
              onClick={toggleDropdown}
              placeholder={undefined}
            >
              <QueueListIcon className="w-4 h-4" />
              <p className="ml-1">{t("CHAT_HISTORY")}</p>
              <ChevronDownIcon className="w-4 h-4 ml-1" />
            </Button>
          </div>
          <div className="h-[645px] overflow-auto">
            {isDropdownOpen && chatHistory && (
              <ChatHistory
                chatHistory={chatHistory}
                clearChat={clearChat}
                setMessages={setMessages}
                setTask={setTask}
                setPrevThreadId={setPrevThreadId}
                setThreadId={setThreadId}
                setTypeCustoms={setTypeCustoms}
                handlePlanTypeMessage={handlePlanTypeMessage}
                createTable={createTable}
                createChart={createChart}
                createTableStructure={createTableStructure}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarAI;

/** @format */

import React from "react";
import {
  CheckIcon,
  XMarkIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import DownloadOptions from "../Cards/DownloadOptions";
import { InfoCard } from "../Cards";
import { Typography } from "@material-tailwind/react";
import DownloadIconButton from "../Buttons/DownloadIconButton";
import EditIconButton from "../Buttons/EditIconButton";

interface DataPointProps {
  table: any;
  typeToRender: string;
  noDataQuery: boolean;
  datapointInfo: {
    title: string;
    data: any;
    percentage: number;
    isPercentagePositive: boolean;
  } | null;
  handleTitleChange: (id: string, value: string) => void;
  handleSaveTitle: (id: string) => void;
  handleCancelEdit: (id: string) => void;
  handleEditTitle: (id: string) => void;
  setCurrentTable: (table: any) => void;
  setIsDownloadOptionsOpen: (isOpen: boolean) => void;
  isDownloadOptionsOpen: boolean;
  handleDownloadOptionClick: (option: string, table: any) => void;
}

const DataPointRender: React.FC<DataPointProps> = ({
  table,
  typeToRender,
  noDataQuery,
  datapointInfo,
  handleTitleChange,
  handleSaveTitle,
  handleCancelEdit,
  handleEditTitle,
  setCurrentTable,
  setIsDownloadOptionsOpen,
  isDownloadOptionsOpen,
  handleDownloadOptionClick,
}) => {
  const firstObject = table.tableData[0];
  const title = Object.values(firstObject)[0];
  const data = Object.values(firstObject)[1];
  return (
    <div className="w-full rounded-lg shadow-lg bg-white p-4 mt-20 relative">
      <div className="flex justify-between items-center text-base text-[#48464C] font-medium mb-4 w-full">
        <div className="flex items-center w-full">
          {table.tableTitle && (
            <div className="flex items-center w-full">
              {table.isEditingTitle ? (
                <div className="flex items-center w-full">
                  <input
                    type="text"
                    value={table.editableTitle}
                    onChange={(e) =>
                      handleTitleChange(table.id, e.target.value)
                    }
                    className="border-b border-[#48464C] outline-none flex-grow"
                    autoFocus
                  />
                  <CheckIcon
                    className="w-5 h-5 ml-2 text-[#48464C] cursor-pointer"
                    onClick={() => handleSaveTitle(table.id)}
                  />
                  <XMarkIcon
                    className="w-5 h-5 ml-2 text-[#48464C] cursor-pointer"
                    onClick={() => handleCancelEdit(table.id)}
                  />
                </div>
              ) : (
                <div className="flex items-center w-full">
                  <span className="flex-grow">{table.editableTitle}</span>
                  <EditIconButton showText={false} onClick={() => handleEditTitle(table.id)} />
                </div>
              )}
            </div>
          )}
        </div>
        <div className="relative">
          <DownloadIconButton showText={false} onClick={() => {
            setCurrentTable(table);
            setIsDownloadOptionsOpen(!isDownloadOptionsOpen);
          }} />
          {isDownloadOptionsOpen && (
            <DownloadOptions
              onOptionClick={(option: any) =>
                handleDownloadOptionClick(option, table)
              }
              type={typeToRender}
            />
          )}
        </div>
      </div>
      {noDataQuery ? (
        <Typography
          className="flex w-full items-center justify-center text-lg font-semibold"
          placeholder={undefined}
        >
          No data fetched, please try again
        </Typography>
      ) : (
        <div id={`datapoint-${table.id}`} className="w-full h-[600px] px-12">
          <InfoCard
            data={data}
            percentage={datapointInfo?.percentage}
            isPercentagePositive={datapointInfo?.isPercentagePositive}
            isChatAI={true}
          />
        </div>
      )}
    </div>
  );
};

export default DataPointRender;

import { useEffect, useState } from "react";
import { SingleCube } from "./singleCube";
import { addCubeWrapper, extractCubeNames, extractCubeName, removeLineBreaks, separateCubes } from "../../utils/semanticLayerUtils";
import toast from "react-hot-toast";
import { getConfig } from '../../config';
import { getCubeCompanyName } from "../../api/SupabaseClient";

const config = getConfig();
export const Cubes = ({ cube }: any) => {
  const [cubes, setCubes] = useState<any[]>(cube);
  const [expandedCubeIndex, setExpandedCubeIndex] = useState<number | null>(null);
  useEffect(() => {
    setCubes(cube);
  }, [cube]);

  const updateCubeData = async (updatedCubes: any) => {
    try {
      const company_name = await getCubeCompanyName();
      const extractedCubeNames = extractCubeNames(updatedCubes);
      const extractedCubeContent = separateCubes(updatedCubes);

      const cubeFiles: Record<string, string> = {};

      for (let i = 0; i < extractedCubeNames.length; i++) {
        const cubeName = extractedCubeNames[i];
        const cubeContent = extractedCubeContent[i];

        const fileName = `${cubeName.toLowerCase()}.js`;
        cubeFiles[fileName] = cubeContent;
      }

      const payload = {
        cubeFiles: {
          model: {
            cubes: cubeFiles
          }
        }
      };

      const response = await fetch(config.semanticLayer + `/company/edit-cube-files/${company_name}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Failed to update semantic layer: ${response.statusText}`);
      }

      toast.success("Cube File Updated Successfully.")
    } catch (error) {
      toast.error('Error updating Cube File');
      throw error;
    }
  }

  const handleExpand = (index: number) => {
    setExpandedCubeIndex(expandedCubeIndex === index ? null : index);
  };

  const handleCubeUpdate = (updatedCube: any, originalCube: any, index: number) => {
    const newCubes = [...cubes];
    const newCubeData = removeLineBreaks(updatedCube)
    const cubeName = extractCubeName(originalCube)
    const wrapperData = addCubeWrapper(newCubeData, cubeName)
    newCubes[index].content = wrapperData;
    updateCubeData(newCubeData)
    setCubes(newCubes);
  };

  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Cube Explorer</h2>
      {cubes.map((singleCube, index) => (
        <SingleCube
          key={index}
          cube={singleCube}
          isExpanded={expandedCubeIndex === index}
          onExpand={() => handleExpand(index)}
          onUpdate={(updatedCube: any, originalCube: any) => handleCubeUpdate(updatedCube, originalCube, index)}
        />
      ))}
    </div>
  );
};
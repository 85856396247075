import React, { useCallback } from 'react';
import ReactFlow, { Node, Edge, Controls, useNodesState, useEdgesState } from 'reactflow';
import 'reactflow/dist/style.css';
import { extractCubeName } from '../utils/semanticLayerUtils';

interface CubeFlowProps {
  cubes: any[];
}

const CubeFlow: React.FC<CubeFlowProps> = ({ cubes }) => {
  const createLayout = useCallback(() => {
    const nodes: Node[] = [];
    const edges: Edge[] = [];
    const levelWidth = 300;
    const levelHeight = 100;

    cubes.forEach((cube, index) => {
      const cubeName = extractCubeName(cube.content)
      const level = Math.floor(Math.sqrt(index));
      const positionInLevel = index - level * level;
      
      nodes.push({
        id: cubeName,
        data: { label: cubeName },
        position: { 
          x: level * levelWidth, 
          y: positionInLevel * levelHeight
        },
        style: {
          width: 150,
          padding: 10,
          border: '1px solid #ccc',
          borderRadius: '5px'
        }
      });

      if (index > 0) {
        edges.push({
          id: `e${index-1}-${index}`,
          source: extractCubeName(cubes[index - 1].content),
          target: cubeName,
          type: 'smoothstep'
        });
      }
    });

    return { nodes, edges };
  }, [cubes]);

  const { nodes: initialNodes, edges: initialEdges } = createLayout();
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  return (
    <div style={{ width: '100%', height: '600px' }}>
      <ReactFlow 
        nodes={nodes} 
        edges={edges} 
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        fitView
      >
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default CubeFlow;
/** @format */

import React, { useState, useEffect } from "react";
import Table from "../components/Table";
import { privateSupabase } from "../api/SupabaseClient";
import { transformSqlToSupabaseQuery } from "../utils/supabaseSqlQuery";
import toast from "react-hot-toast";
import { t } from "i18next";
import { DashboardCreator } from "./DashboardCreator";
import { Input } from "@material-tailwind/react";
import {
  CheckIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import LoadingSpinner from "../components/LoadingSpinner";
import { useFilter } from "../context";
import ReportsOptions from "../components/ReportOptions";
import DuplicateReportModal from "../components/DuplicateModal";
import DeleteModal from "../components/DeleteModal";

interface Report {
  id: number;
  name?: string;
  title?: string;
  chart: any[];
  created_by: string;
  last_update: Date | null;
  headers: any[];
  profiles: any;
}

interface ProductsProps {
  filters: Record<string, any>;
  searchInput: string;
  sqlQuery: any;
  onShowDashboardCreator: (show: boolean) => void;
  newDashboard: boolean;
  setNewDashboard: (value: boolean) => void;
}

export function Reports({
  filters,
  searchInput,
  sqlQuery,
  onShowDashboardCreator,
  newDashboard,
  setNewDashboard,
}: ProductsProps) {
  const [reportsData, setReportsData] = useState<Report[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [showDashboardCreator, setShowDashboardCreator] = useState(false);
  const [selectedId, setSelectedId] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [reportToDelete, setReportToDelete] = useState<Report | null>(null);
  const { openEditDashboard, setOpenEditDashboard } = useFilter();
  const [isEdit, setIsEdit] = useState(openEditDashboard);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
  const [reportToDuplicate, setReportToDuplicate] = useState<Report | null>(
    null
  );
  const [renamingReportId, setRenamingReportId] = useState<number | null>(null);
  const [newTitle, setNewTitle] = useState<string>("");

  useEffect(() => {
    if (newDashboard) {
      setIsEdit(true);
      setShowDashboardCreator(true);
      setSelectedId(null);
      setRenamingReportId(null)
    }

  }, [newDashboard]);

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setRenamingReportId(null)
    setItemsPerPage(newItemsPerPage);
  };

  const handlePageChange = (page: number) => {
    setRenamingReportId(null)
    setCurrentPage(page);
  };

  const getReports = async () => {
    setIsLoading(true);
    const from = (currentPage - 1) * itemsPerPage;
    const to = from + itemsPerPage - 1;

    let query: any;
    if (sqlQuery && sqlQuery.filter.length > 0) {
      query = transformSqlToSupabaseQuery(sqlQuery, "reports_dashboard");
    } else {
      query = privateSupabase
        .from("reports_dashboard")
        .select(`
          *,
          profiles:created_by (
            email,
            full_name
          )
        `, { count: "exact" })
        .range(from, to);

      Object.entries(filters).forEach(([key, value]) => {
        if (!value) return;

        query = query.ilike(key, `%${value}%`);
      });

      if (searchInput) {
        query = query.ilike("title", `%${searchInput}%`);
      }
    }

    const { data, error, count } = await query;
    if (error) {
      setReportsData([]);
      setIsLoading(false);
      return;
    }
    setReportsData(data || []);
    setTotalItems(count || 0);
    setIsLoading(false);
  };

  const selectRow = async (row: any) => {
    if (renamingReportId === row.id) return;
    loadReport(row.id);
  };

  const loadReport = async (reportId: number) => {
    setSelectedId(reportId);
    const { data, error } = await privateSupabase
      .from("reports_dashboard")
      .select("*")
      .eq("id", reportId)
      .single();

    if (error) {
      toast.error(t("ERROR") + "EO-1041");
      return;
    }
    setShowDashboardCreator(true);
    onShowDashboardCreator(true);
  };

  useEffect(() => {
    getReports();
    setRenamingReportId(null)
  }, [filters, sqlQuery, itemsPerPage, currentPage, searchInput]);

  const formatDate = (dateString: Date | null) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleDateString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const generateRowsMain = () =>
    reportsData.map((report) => ({
      id: report.id,
      Name:
        renamingReportId === report.id ? (
          <div
            className="flex items-center"
            onClick={(e) => e.stopPropagation()}
          >
            <Input
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
              className="mr-2"
              placeholder={report.title}
              crossOrigin={undefined}
            />
            <CheckIcon
              className="w-5 h-5 text-green-500 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                saveNewTitle(report.id);
              }}
            />
            <XMarkIcon
              className="w-5 h-5 text-red-500 cursor-pointer ml-2"
              onClick={(e) => {
                e.stopPropagation();
                cancelRename();
              }}
            />
          </div>
        ) : (
          report.title
        ),
      "Created by": report.profiles.full_name !== "" && report.profiles.full_name !== null ? report.profiles.full_name : report.profiles.email,
      "Last update": formatDate(report.last_update),
      Actions: renderActions(report),
    }));

  const headers = ["Name", "Created by", "Last update"];

  const handleBackToReports = () => {
    setShowDashboardCreator(false);
    onShowDashboardCreator(false);
    setNewDashboard(false);
    setIsEdit(false);
    setOpenEditDashboard(false);
    getReports();
  };

  const handleReportChange = (reportId: number) => {
    loadReport(reportId);
  };


  const handleDelete = (report: Report) => {
    setReportToDelete(report);
    setDeleteModalOpen(true);
  };

  const handleDuplicate = async (reportId: number) => {
    try {
      const { data, error } = await privateSupabase
        .from("reports_dashboard")
        .select("*")
        .eq("id", reportId)
        .single();

      if (error) {
        toast.error(t("ERROR") + "EO-1041");
        return;
      }

      const report = data;

      // Remove the id and adjust other properties if necessary
      const { id, ...newReportData } = report;
      newReportData.title = `${newReportData.title} - Copy`;

      const { error: insertError } = await privateSupabase
        .from("reports_dashboard")
        .insert([newReportData]);

      if (insertError) {
        toast.error(t("ERROR") + "EO-1043");
      } else {
        toast.success("Report duplicated successfully");
        getReports(); // Refresh the reports list
      }
    } catch (error) {
      toast.error(t("ERROR") + "EO-1044");
    }
  };

  const handleDuplicateClick = (report: Report) => {
    setRenamingReportId(null);
    setReportToDuplicate(report);
    setDuplicateModalOpen(true);
  };

  const confirmDuplicate = async () => {
    if (!reportToDuplicate) return;
    await handleDuplicate(reportToDuplicate.id);
    setDuplicateModalOpen(false);
    setReportToDuplicate(null);
  };

  const confirmDelete = async () => {
    if (!reportToDelete) return;
    const { error } = await privateSupabase
      .from("reports_dashboard")
      .delete()
      .eq("id", reportToDelete.id);

    if (error) {
      toast.error(t("ERROR") + "EO-1042");
    } else {
      toast.success("Report deleted successfully");
      setDeleteModalOpen(false);
      setReportToDelete(null);
      getReports();
    }
  };

  const handleRenameClick = (report: Report) => {
    setRenamingReportId(report.id);
    setNewTitle(report.title || "");
  };

  const saveNewTitle = async (reportId: number) => {
    const { error } = await privateSupabase
      .from("reports_dashboard")
      .update({ title: newTitle })
      .eq("id", reportId);

    if (error) {
      toast.error(t("ERROR") + "EO-1045");
    } else {
      toast.success("Report renamed successfully");
      setRenamingReportId(null);
      getReports();
    }
  };

  const cancelRename = () => {
    setRenamingReportId(null);
    setNewTitle("");
  };

  const renderActions = (report: Report) => (
    <div className="flex justify-end mr-4">
      <ReportsOptions
        onDelete={() => handleDelete(report)}
        onDuplicate={() => handleDuplicateClick(report)}
        onRename={() => handleRenameClick(report)}
      />
    </div>
  );

  return (
    <div className={`flex flex-col h-full w-full ${showDashboardCreator ? "bg-[#F2F5FF]" : "bg-white"} px-8 pb-8  pt-6`}>
      {isLoading && <LoadingSpinner message="LOADING" />}
      {showDashboardCreator && (
        <div className="h-full w-full bg-[#F2F5FF]">
          <DashboardCreator
            selectedReport={selectedId}
            isEdit={isEdit}
            isNewDashboard={newDashboard}
            onClose={handleBackToReports}
          />
        </div>
      )}
      {!isLoading && !showDashboardCreator && (
        <>
          <Table
            headers={headers}
            rows={generateRowsMain()}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            onItemsPerPageChange={handleItemsPerPageChange}
            totalItems={totalItems}
            onPageChange={handlePageChange}
            onRowClick={selectRow}
            renderCustomColumn={(row) => (
              <div className="flex justify-end mr-4">
                <ReportsOptions
                  onDelete={() => handleDelete(row)}
                  onDuplicate={() => handleDuplicate(row.id)}
                  onRename={() => handleRenameClick(row)}
                />
              </div>
            )}
          />
          <DeleteModal
            open={deleteModalOpen}
            title={"DELETE_REPORT"}
            text={"DELETE_REPORT_TEXT"}
            onClose={() => setDeleteModalOpen(false)}
            onDelete={confirmDelete}
          />
          <DuplicateReportModal
            open={duplicateModalOpen}
            onClose={() => setDuplicateModalOpen(false)}
            onDuplicate={confirmDuplicate}
          />
        </>
      )}
    </div>
  );
}

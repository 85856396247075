/** @format */

import React from "react";

interface InfoCardProps {
  title?: any;
  data: any;
  percentage?: number;
  isPercentagePositive?: boolean;
  isChatAI?: boolean;
}

export const InfoCard: React.FC<InfoCardProps> = ({
  title,
  data,
  percentage,
  isPercentagePositive = true,
  isChatAI = false,
}) => {
  const formattedData = (() => {
    if (typeof data === "number") {
      return data.toLocaleString("de-DE");
    } else if (typeof data === "string") {
      return data;
    } else {
      // Convertir el objeto en un string JSON para mostrar
      return JSON.stringify(data);
    }
  })();

  return (
    <div>
      {/* {isChatAI && (
        <div className="flex justify-start text-xl font-bold p-2 -mt-12">
          {title}
        </div>
      )} */}
      <div className="flex justify-center text-3xl font-extrabold text-[#0059AD] p-2">
        {formattedData}
      </div>
      <div
        className={`flex flex-row items-center justify-end h-auto mt-auto self-end ${
          isPercentagePositive
            ? "text-[#51B767] font-bold   rounded-full"
            : "text-[#B3261E] font-bold   rounded-full"
        }`}
      >
        {percentage ? (
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`h-5 w-5 ${isPercentagePositive ? "" : "rotate-180"}`}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 15l7-7 7 7"
              />
            </svg>
            <span className="text-sm ml-1">{percentage}%</span>
          </div>
        ) : (
          <div className="h-5"></div>
        )}
      </div>
    </div>
  );
};

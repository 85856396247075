/** @format */

import React, { createContext, useContext, useState } from "react";

interface ChatVisibilityContextProps {
  globalRender: boolean;
  customRender: boolean;
  setGlobalRender: (render: boolean) => void;
  setCustomRender: (render: boolean) => void;
}

const ChatVisibilityContext = createContext<ChatVisibilityContextProps>({
  globalRender: true,
  customRender: false,
  setGlobalRender: () => {},
  setCustomRender: () => {},
});

export const useChatVisibility = () => useContext(ChatVisibilityContext);

export const ChatVisibilityProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [globalRender, setGlobalRender] = useState(true);
  const [customRender, setCustomRender] = useState(false);

  return (
    <ChatVisibilityContext.Provider
      value={{ globalRender, customRender, setGlobalRender, setCustomRender }}
    >
      {children}
    </ChatVisibilityContext.Provider>
  );
};

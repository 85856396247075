
import React from "react";
import {
    TrashIcon,
} from "@heroicons/react/24/outline";
import { t } from "i18next";

const DeleteIconButton = ({
    showText,
    onDelete,
}: {
    showText: boolean;
    onDelete: () => void;
}) => {
    return (
        <button
            className={`flex px-4 py-2 text-gray-800 ${showText ? "hover:bg-gray-100" : "hover:text-gray-500"}  w-full text-left items-center gap-2`}
            onClick={onDelete}
        >
            <TrashIcon className="w-5 h-5" />
            {showText && (
                t("DELETE")
            )}
        </button>
    )
}

export default DeleteIconButton;
/** @format */

import { useState } from "react";
import MainButton from "../../components/Buttons/MainButton";
import useCubeData from "../../hooks/useCubeData";
import CubeFlow from "../../components/CubeFlow";
import { Cubes } from "./cubes";

export const SemanticLayer = () => {
  const [isCubeFlowOpen, setIsCubeFlowOpen] = useState<boolean>(false);
  const { cubeData } = useCubeData()

  const handleCubeFlow = () => {
    setIsCubeFlowOpen(!isCubeFlowOpen);
  };

  return (
    <>
      <div className="flex flex-col justify-between bg-white rounded-2xl mx-6 h-[calc(100%-50px)] pb-6">
        <div className="flex flex-row bg-white justify-between mx-6 pb-12 pt-4 mt-6 overflow-y-auto">
          <div className="flex justify-start items-center my-auto gap-4">
            <MainButton
              buttonText={ isCubeFlowOpen ? "Show Explorer" : "Display Graph" }
              onClick={handleCubeFlow}
            />
          </div>
        </div>
        <div className="flex flex-col flex-1 justify-between bg-white rounded-2xl mx-6 overflow-y-hidden p-4">
          {isCubeFlowOpen ? (
            <CubeFlow cubes={cubeData} />
          ) : (
            <Cubes cube={cubeData} />
          )}
        </div>
      </div>
    </>
  );
};

export default SemanticLayer;

/** @format */

import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { GridStack } from "gridstack";
import "gridstack/dist/gridstack.min.css";
import { CardComponent } from "./CardComponent";

const GridStackContainer = ({
  draggableItems,
  isEditMode,
  nodesRef,
  deleteItem,
  duplicateItem,
  handleTitleChange,
  handleIconChange,
  handleSizeChange,
  isMainDashboard,
  selectedReport,
  floatMode,
  forceUpdate,
}) => {
  const gridRef = useRef(null);
  const gridInstance = useRef(null);
  const [, setRenderTrigger] = useState(0);

  useEffect(() => {
    const initGrid = () => {
      if (gridRef.current) {
        if (gridInstance.current) {
          gridInstance.current.destroy(false);
        }

        const grid = GridStack.init(
          {
            float: floatMode,
            cellHeight: 40,
            margin: 10,
            column: 12,
            disableOneColumnMode: true,
          },
          gridRef.current
        );

        gridInstance.current = grid;

        grid.on("change", function (event, items) {
          if (!items || !Array.isArray(items)) {
            console.error("Invalid items array in grid change event");
            return;
          }
          items.forEach(function (item) {
            if (item.el) {
              const itemType = item.el.getAttribute("data-gs-type");
              if (itemType === "title") {
                grid.update(item.el, { h: 2, minH: 2, maxH: 2 });
              } else if (itemType === "datapoint") {
                grid.update(item.el, { h: 4, minH: 4, maxH: 4 });
              }
            }
          });
        });

        grid.on("resizestop", function (event, element) {
          const itemId = element.getAttribute("gs-id");
          const item = draggableItems.find((item) => item.id === itemId);
          if (item) {
            const gsX = parseInt(element.getAttribute("gs-x") || "0");
            const gsY = parseInt(element.getAttribute("gs-y") || "0");
            const gsW = parseInt(element.getAttribute("gs-w") || "6");
            let gsH = parseInt(element.getAttribute("gs-h") || "6");

            // Enforce minimum height for tables
            if (item.type === "table" && gsH < 12) {
              gsH = 12;
              gridInstance.current?.update(element, { h: gsH });
            }

            handleSizeChange(item.id, gsX, gsY, gsW, gsH);
          }
        });
      }
    };

    initGrid();

    // Force update to ensure re-render after initial mount
    forceUpdate();

    return () => {
      if (gridInstance.current) {
        gridInstance.current.destroy(false);
        gridInstance.current = null;
      }
    };
  }, [floatMode, isEditMode]);

  useEffect(() => {
    if (gridInstance.current) {
      gridInstance.current.removeAll();

      draggableItems.forEach((item, index) => {
        const node = document.createElement("div");
        node.className = "grid-stack-item overflow-y-hidden";
        const position =
          item.position?.find((pos) => pos.report === selectedReport) || {};
        const defaultHeights = {
          title: 2,
          datapoint: 4,
          table: 12,
          chart: 10,
        };
        const initialHeight =
          item.type === "table"
            ? Math.max(
                position.size?.gs_h || defaultHeights[item.type] || 6,
                12
              )
            : position.size?.gs_h || defaultHeights[item.type] || 6;
        const attributes = {
          "gs-x": position.size?.gs_x || 0,
          "gs-y": position.size?.gs_y || 0,
          "gs-w": position.size?.gs_w || 6,
          "gs-h": initialHeight,
          "gs-id": item.id || `item-${index}`,
          "data-gs-type": item.type,
        };

        Object.keys(attributes).forEach((key) => {
          node.setAttribute(key, attributes[key]?.toString());
        });

        const contentDiv = document.createElement("div");
        contentDiv.className = "grid-stack-item-content";
        contentDiv.style.overflow = "unset";
        node.appendChild(contentDiv);

        gridInstance.current?.el.appendChild(node);
        gridInstance.current?.makeWidget(node);

        nodesRef.current.set(item, contentDiv);
      });

      // Adjust heights after initial render
      setTimeout(() => {
        draggableItems.forEach((item) => {
          const contentDiv = nodesRef.current.get(item);
          if (contentDiv) {
            const height = contentDiv.scrollHeight;
            const newHeight = Math.ceil(height / 40);
            const node = contentDiv.closest(".grid-stack-item");
            if (node) {
              const gsX = parseInt(node.getAttribute("gs-x") || "0");
              const gsY = parseInt(node.getAttribute("gs-y") || "0");
              const gsWidth = parseInt(node.getAttribute("gs-w") || "6");

              const options = {
                x: gsX,
                y: gsY,
                w: gsWidth,
                h:
                  item.type === "title"
                    ? 2
                    : item.type === "datapoint"
                      ? 4
                      : item.type === "table"
                        ? Math.max(12, newHeight)
                        : newHeight,
                minH:
                  item.type === "title"
                    ? 2
                    : item.type === "datapoint"
                      ? 4
                      : item.type === "table"
                        ? 12
                        : undefined,
                maxH:
                  item.type === "title"
                    ? 2
                    : item.type === "datapoint"
                      ? 4
                      : undefined,
              };

              gridInstance.current?.update(node, options);
            }
          }
        });
      }, 100);
    }
  }, [draggableItems, nodesRef, gridInstance, selectedReport]);

  useEffect(() => {
    if (gridInstance.current) {
      gridInstance.current.setStatic(!isEditMode);
    }
  }, [isEditMode]);

  useEffect(() => {
    forceUpdate(); // Force update whenever draggableItems change
  }, [draggableItems]);

  return (
    <div className="grid-stack mr-6" ref={gridRef}>
      {draggableItems.map((item, index) => {
        const contentDiv = nodesRef.current.get(item);
        return (
          contentDiv &&
          ReactDOM.createPortal(
            <CardComponent
              key={`${item.type}-${index}-${item.id}`}
              item={item}
              itemIndex={index}
              deleteItem={deleteItem}
              duplicateItem={duplicateItem}
              handleTitleChange={handleTitleChange}
              handleIconChange={handleIconChange}
              handleSizeChange={handleSizeChange}
              isEditMode={isEditMode}
              titlesWithError={[]}
              isMainDashboard={isMainDashboard}
            />,
            contentDiv
          )
        );
      })}
    </div>
  );
};

export default GridStackContainer;

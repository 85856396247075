/** @format */

import { t } from "i18next";
import React, { useEffect, useState } from "react";
import CompanyDetails from "./Company/CompanyDetails";
import { privateSupabase, userSession } from "../../../api/SupabaseClient";
import toast from "react-hot-toast";
import { useSupabase } from "../../../context/supabaseContext";

export const defaultPlaceholder = "https://via.placeholder.com/150";
export function Company() {
  const { getUserRole } = useSupabase();
  const [role, setRole] = useState<number>(3);
  const [session, setSession] = useState<any>(null);
  const [companyDetails, setCompanyDetails] = useState<any>({
    company: "",
    company_logo: "",
    company_website: "",
    company_description: "",
    store_count: "",
  });

  useEffect(() => {
    const handleAuthStateChange = (_event: any, newSession: any) => {
      setSession(newSession);
    };

    userSession().then((session: any) => {
      setSession(session.data.session);
    });

    privateSupabase.auth.onAuthStateChange(handleAuthStateChange);
  }, [])

  const getCompanyDetails = async () => {
    try {
      const { data, error } = await privateSupabase
        .from("company_details")
        .select("*")
        .single();
      if (error) throw error;
      setCompanyDetails(data);
    } catch (error) {
      toast.error(t("ERROR") + "EO-1052");
    }
  };

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      await getCompanyDetails();
    }
    fetchCompanyDetails();
  }, [session]);

  useEffect(() => {
    const fetchUserRole = async () => {
      const userRole = await getUserRole();
      setRole(userRole);
    }
    fetchUserRole();
  }, [session]);

  return (
    <div
      className="w-full pb-12 px-12 pt-4 mt-6"
      style={{ overflowY: "auto", maxHeight: "calc(100vh - 120px)" }}
    >
      <div className="tab-website">
        <div className="!p-0">
          <CompanyDetails companyDetails={companyDetails} setCompanyDetails={setCompanyDetails} role={role} />
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useCallback } from 'react';
import Table from "../../components/Table";
import { privateSupabase } from "../../api/SupabaseClient";
import { Tab, Tabs, TabsHeader, Typography, Input, Button } from '@material-tailwind/react';
import { t } from 'i18next';
import { ReactComponent as PostgresIcon } from "../../assets/icons/postgresql_icon.svg";
import { ReactComponent as WooComerceIcon } from "../../assets/icons/woocomerce_icon.svg";
import { ReactComponent as MetabaseIcon } from "../../assets/icons/metabase_icon.svg";
import { ReactComponent as SheetsIcon } from "../../assets/icons/sheets_icon.svg";
import { ReactComponent as SnowflakeIcon } from "../../assets/icons/snowflake_icon.svg";
import { ReactComponent as ActiveCampaignIcon } from "../../assets/icons/activecampaign_icon.svg";
import { ReactComponent as AirtableIcon } from "../../assets/icons/airtable_icon.svg";
import { ChevronLeftIcon, XMarkIcon } from '@heroicons/react/24/outline';
import LoadingSpinner from '../../components/LoadingSpinner';
import MainButton from '../../components/Buttons/MainButton';
import FilterComponent from '../../components/FilterComponent';
import FilterButton from '../../components/Buttons/FilterButton';
import { ReactComponent as SaveIcon } from "../../assets/icons/save.svg";

interface SourceDetailsProps {
    handleBack: () => void;
    source: {
        connector: string;
        table: string;
        rows: number;
        prefix: string;
    }
}

interface TabData {
    label: string;
    value: string;
    desc: string;
}

const tabData: TabData[] = [
    {
        label: "TABLE_CONTENT",
        value: "Table Content",
        desc: ``,
    },
    {
        label: "COLUMN_DETAIL",
        value: "Column Detail",
        desc: ``,
    },
];


const getServiceIcon = (connector: string) => {
    switch (connector) {
        case 'Postgres':
            return <PostgresIcon className="w-4 h-4" />;
        case 'WooCommerce':
            return <WooComerceIcon className="w-4 h-4" />;
        case 'Metabase':
            return <MetabaseIcon className="w-4 h-4" />;
        case 'Google Sheets':
            return <SheetsIcon className="w-4 h-4" />;
        case 'Snowflake':
            return <SnowflakeIcon className="w-4 h-4" />;
        case 'ActiveCampaign':
            return <ActiveCampaignIcon className="w-4 h-4" />;
        case 'Airtable':
            return <AirtableIcon className="w-4 h-4" />;
        default:
            return null;
    }
};

const SourceDetails = ({ handleBack, source }: SourceDetailsProps) => {
    const [data, setData] = useState<any>([]);
    const [filteredData, setFilteredData] = useState<any>([]);
    const [description, setDescription] = useState<string>("")
    const [newDescription, setNewDescription] = useState<string>("")
    const [initialDescription, setInitialDescription] = useState<string>("")
    const [exampleData, setExampleData] = useState<any>([]);
    const [columnDetails, setColumnDetails] = useState<string[]>([]);
    const [filteredColumnDetails, setFilteredColumnDetails] = useState<string[]>([]);
    const [columnsForDetails, setColumnsForDetails] = useState<string[]>([]);
    const [columns, setColumns] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState("Table Content");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPageForDetails, setCurrentPageForDetails] = useState(1);
    const [itemsPerPageForDetails, setItemsPerPageForDetails] = useState(10);
    const [totalItemsForDetails, setTotalItemsForDetails] = useState(0);
    const [filterValues, setFilterValues] = useState({});
    const [searchInput, setSearchInput] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const icon = getServiceIcon(source.connector);

    const handleItemsPerPageChange = (newItemsPerPage: number) => {
        const firstItemIndex = (currentPage - 1) * itemsPerPage;
        const newCurrentPage = Math.floor(firstItemIndex / newItemsPerPage) + 1;
        setItemsPerPage(newItemsPerPage);
        setCurrentPage(newCurrentPage);
    };

    const handleItemsPerPageChangeForDetails = (newItemsPerPage: number) => {
        const firstItemIndex = (currentPage - 1) * itemsPerPage;
        const newCurrentPage = Math.floor(firstItemIndex / newItemsPerPage) + 1;
        setItemsPerPageForDetails(newItemsPerPage);
        setCurrentPageForDetails(newCurrentPage);
    };

    useEffect(() => {
        fetchData();
        fetchColumnDetails();
    }, [source, activeTab, itemsPerPage, currentPage]);

    useEffect(() => {
        applySearchFilter();
    }, [searchInput, data, columnDetails]);

    const fetchData = async () => {
        const fromIndex = (currentPage - 1) * itemsPerPage;
        const toIndex = fromIndex + itemsPerPage;
        setIsLoading(true);

        try {
            const tableName = `${source.prefix}${source.table}`;
            const { data, error, count } = await privateSupabase.from(tableName).select('*', { count: 'exact' })
                .range(fromIndex, toIndex - 1);
            const { data: table_description } = await privateSupabase.from("table_descriptions").select('description').eq("table_name", tableName).single()
            setDescription(table_description?.description)
            setNewDescription(table_description?.description)
            setInitialDescription(table_description?.description)
            if (error) {
                console.error(`Error getting info from table ${source.table}`)
            }

            if (!data || !count) {
                console.log(`No data in table ${source.table}`)
                setData([]);
                setColumns([]);
                setTotalItems(0);
                setIsLoading(false);
                return;
            }

            setColumns(data.length > 0 ? Object.keys(data[0]).filter(key => !key.startsWith('_airbyte_')) : []);
            setData(data);
            setFilteredData(data);
            setTotalItems(count);
            setExampleData(data.slice(0, 3));  // Save the first three rows as example data
        } catch (error) {
            console.error(`Error fetching data from ${source.table}: ${error}`)
            setData([]);
            setFilteredData([]);
            setColumns([]);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchColumnDetails = async () => {
        setIsLoading(true);

        try {
            const { data, error } = await privateSupabase
                .rpc('fetch_column_details', { table_name: `${source.prefix}${source.table}` });

            if (error) {
                console.error(`Error getting column details for table ${source.table}`, error);
            }

            if (!data) {
                console.log(`No column details for table ${source.table}`);
                setColumnDetails([]);
                setFilteredColumnDetails([]);
                setColumnsForDetails([]);
                setTotalItemsForDetails(0);
                setIsLoading(false);
                return;
            }

            const examplesMap: Record<string, any[]> = {};
            exampleData.forEach((row: any) => {
                Object.keys(row).forEach(column => {
                    if (!examplesMap[column]) {
                        examplesMap[column] = [];
                    }
                    if (examplesMap[column].length < 3) {
                        examplesMap[column].push(row[column]);
                    }
                });
            });

            const columnDetailsWithExamples = data.map((row: any) => {
                const columnName = row.column_name;
                const examples = examplesMap[columnName] ? examplesMap[columnName].filter(Boolean).join(', ') : '';
                return {
                    ...row,
                    Examples: examples
                };
            });

            setColumnDetails(columnDetailsWithExamples);
            setFilteredColumnDetails(columnDetailsWithExamples);
            setColumnsForDetails(columnDetailsWithExamples.length > 0 ? Object.keys(columnDetailsWithExamples[0]) : []);
            setTotalItemsForDetails(columnDetailsWithExamples.length);
        } catch (error) {
            console.error(`Error fetching column details from ${source.table}: ${error}`);
            setColumnDetails([]);
            setFilteredColumnDetails([]);
        } finally {
            setIsLoading(false);
        }
    };

    const applySearchFilter = () => {
        if (searchInput.trim() === "") {
            setFilteredData(data);
            setFilteredColumnDetails(columnDetails);
            return;
        }

        const filteredData = data.filter((row: any) => {
            const firstColumn = columns[0];
            return row[firstColumn] && row[firstColumn].toString().toLowerCase().includes(searchInput.toLowerCase());
        });

        const filteredColumnDetails = columnDetails.filter((row: any) => {
            const firstColumn = columnsForDetails[0];
            return row[firstColumn] && row[firstColumn].toString().toLowerCase().includes(searchInput.toLowerCase());
        });

        setFilteredData(filteredData);
        setFilteredColumnDetails(filteredColumnDetails);
    };

    const getPaginatedColumnDetails = () => {
        const fromIndex = (currentPageForDetails - 1) * itemsPerPageForDetails;
        const toIndex = fromIndex + itemsPerPageForDetails;
        return filteredColumnDetails.slice(fromIndex, toIndex);
    };

    const handleFilterChange = useCallback((filterName: any, value: any) => {
        setFilterValues((prev) => ({ ...prev, [filterName]: value }));
    }, []);

    const onSearchChange = useCallback((e: any) => {
        setSearchInput(e.target.value);
    }, []);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = async () => {
        const tableName = `${source.prefix}${source.table}`;
        const { error } = await privateSupabase
            .from("table_descriptions")
            .update({ description: newDescription })
            .eq("table_name", tableName);

        if (error) {
            console.error(`Error updating description for table ${tableName}`, error);
            return;
        }

        setDescription(newDescription);
        setIsEditing(false);
    };

    const handleDiscardClick = () => {
        setNewDescription(initialDescription);
        setIsEditing(false);
    };

    return (
        <div
            className={`flex flex-col justify-between rounded-2xl}`}
            style={{ overflowY: "auto", maxHeight: "calc(100vh - 120px)" }}
        >
            {isLoading ? (
                <LoadingSpinner message="LOADING" />
            ) : (
                <>
                    <div className="flex flex-col justify-between bg-white rounded-2xl my-6 mx-6 pt-12 pb-12">
                        <div className="flex items-center justify-between mx-6">
                            <div
                                className="flex items-center cursor-pointer"
                                onClick={handleBack}
                            >
                                <ChevronLeftIcon className="w-5 h-5 text-[#46474C]" />
                                <Typography
                                    style={{
                                        fontWeight: 500,
                                        fontSize: "20px",
                                        lineHeight: "28px",
                                        color: "##2F3235",
                                        marginLeft: "0.5rem",
                                    }}
                                    placeholder={undefined}
                                >
                                    {source.table}
                                </Typography>
                            </div>
                            {isEditing ? (
                                <div className="flex space-x-4">
                                    <Button onClick={handleDiscardClick} placeholder={undefined}
                                        style={{
                                            minWidth: "150px",
                                            textTransform: "none",
                                            fontWeight: "500",
                                            fontSize: "14px",
                                            lineHeight: "20px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: "100px",
                                            border: "1px solid white",
                                            color: "#0458DD"
                                        }}
                                        className="h-10 bg-white"
                                        variant="outlined"
                                    >
                                        <div className="flex justify-center items-center gap-2">
                                            <XMarkIcon className="h-4 w-4 cursor-pointer" />
                                            {t("DISCARD")}
                                        </div>
                                    </Button>
                                    <Button onClick={handleSaveClick} placeholder={undefined}
                                        style={{
                                            minWidth: "150px",
                                            textTransform: "none",
                                            fontWeight: "500",
                                            fontSize: "14px",
                                            lineHeight: "20px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: "100px",
                                            border: "1px solid #0458DD",
                                            color: "#0458DD"
                                        }}
                                        className="h-10 bg-white"
                                        variant="outlined"
                                    >
                                        <div className="flex justify-center items-center gap-2">
                                            <SaveIcon className="h-4 w-4 cursor-pointer" />
                                            {t("SAVE")}
                                        </div>

                                    </Button>
                                </div>
                            ) : (
                                <MainButton
                                    buttonText="EDIT"
                                    onClick={handleEditClick}
                                    plusIcon={false}
                                    editIcon={true}
                                />
                            )}
                        </div>
                        <div className="flex flex-row justify-start items-center mx-6 my-6 space-x-12">
                            <div className="flex flex-col items-start">
                                <span style={{ color: "#76787D" }}>{t("DESCRIPTION")}</span>
                                {isEditing ? (
                                    <Input
                                        value={newDescription}
                                        onChange={(e) => setNewDescription(e.target.value)} crossOrigin={undefined} />
                                ) : (
                                    <span className="text-black">{description?.toLocaleString()}</span>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-row justify-start items-center mx-6 space-x-12">
                            <div className="flex flex-col items-start">
                                <span style={{ color: "#76787D" }}>{t("DATA_SOURCE")}</span>
                                <div className="flex items-center space-x-2">
                                    {icon}
                                    <span className="text-#76787D">{source.connector}</span>
                                </div>
                            </div>
                            <div className="flex flex-col items-start">
                                <span style={{ color: "#76787D" }}>{t("QUANTITY_ITEMS")}</span>
                                <span className="text-black">{totalItems.toLocaleString()}</span>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col justify-between rounded-t-lg mx-6 pt-12 ">
                        <div className="flex justify-start items-center my-auto">
                            <Tabs
                                value={activeTab}
                                className="flex justify-start p-0 items-center rounded-t-lg my-auto"
                            >
                                <TabsHeader
                                    className="bg-transparent p-0 rounded-t-lg gap-4 rounded-lg"
                                    indicatorProps={{ className: "shadow-none" }}
                                    placeholder={undefined}
                                >
                                    {tabData.map(({ label, value }) => (
                                        <Tab
                                            key={value}
                                            value={value}
                                            onClick={() => setActiveTab(value)}
                                            className={`p-6 rounded-t-lg cursor-pointer text-sm ${activeTab === value
                                                ? "text-[#0458DD] font-bold bg-white"
                                                : "text-[#0458DD] bg-[#E0E6FA]"
                                                }`}
                                            style={{ height: "2rem", }}
                                            placeholder={undefined}
                                        >
                                            {t(label)}
                                        </Tab>
                                    ))}
                                </TabsHeader>
                            </Tabs>
                        </div>
                        <div className="flex flex-col rounded-tr-lg rounded-b-lg bg-white pt-12 px-6 pb-12">
                            {activeTab === "Table Content" ? (
                                <>
                                    <div className="flex flex-row bg-white justify-between pb-12 ">
                                        <div className="flex justify-start space-x-4 items-center">
                                            <div className="flex flex-row justify-start items-center space-x-4">
                                                <FilterComponent
                                                    availableFilters={["connector", "stream_name"]}
                                                    placeHolders={["Connector", "Table"]}
                                                    filterValues={filterValues}
                                                    onFilterChange={handleFilterChange}
                                                    searchInput={searchInput}
                                                    onSearchChange={onSearchChange}
                                                    showFilters={false}
                                                    showSearch={true}
                                                />
                                                <div className="flex flex-row space-x-4 ">
                                                    <FilterButton
                                                        onClick={applySearchFilter}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Table
                                        headers={columns}
                                        rows={filteredData.map((row: any) => {
                                            return columns.reduce((acc: Record<string, any>, column) => {
                                                acc[column] = row[column];
                                                return acc;
                                            }, {});
                                        })}
                                        currentPage={currentPage}
                                        itemsPerPage={itemsPerPage}
                                        onItemsPerPageChange={handleItemsPerPageChange}
                                        totalItems={totalItems}
                                        onPageChange={setCurrentPage}
                                    />
                                </>
                            ) : (
                                <>
                                    <div className="flex flex-row bg-white justify-between pb-12 ">
                                        <div className="flex justify-start space-x-4 items-center">
                                            <div className="flex flex-row justify-start items-center space-x-4">
                                                <FilterComponent
                                                    availableFilters={["connector", "stream_name"]}
                                                    placeHolders={["Connector", "Table"]}
                                                    filterValues={filterValues}
                                                    onFilterChange={handleFilterChange}
                                                    searchInput={searchInput}
                                                    onSearchChange={onSearchChange}
                                                    showFilters={false}
                                                    showSearch={true}
                                                />
                                                <div className="flex flex-row space-x-4 ">
                                                    <FilterButton
                                                        onClick={applySearchFilter}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Table
                                        headers={columnsForDetails}
                                        rows={getPaginatedColumnDetails().map((row: any) => {
                                            return columnsForDetails.reduce((acc: Record<string, any>, column) => {
                                                acc[column] = row[column];
                                                return acc;
                                            }, {});
                                        })}
                                        currentPage={currentPageForDetails}
                                        itemsPerPage={itemsPerPageForDetails}
                                        onItemsPerPageChange={handleItemsPerPageChangeForDetails}
                                        totalItems={totalItemsForDetails}
                                        onPageChange={setCurrentPageForDetails}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default SourceDetails;
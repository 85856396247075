/** @format */

import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Typography,
} from "@material-tailwind/react";
import { t } from "i18next";
import i18n from "../../../../locales/i18n";
import {
  publicSupabase,
  privateSupabase,
  userSession,
} from "../../../../api/SupabaseClient";
import toast from "react-hot-toast";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import ProfileImage from "../../ProfileImage";
import capitalizeFirstLetter from "../../../../utils/strUtils";
import DetailsTitle from "../../DetailsTitle";
import DetailsText from "../../DetailsText";
import EditProfileModal from "./EditProfileModal";
import formatDate from "../../../../utils/formatDate";
import defaultPlaceholder from "../../../../assets/images/blank-profile-picture.png";

interface ProfileProps {
  profileDetails: any;
  setProfileDetails: any;
  pictureName: any;
  setPictureName: any;
}

const Profile = ({ profileDetails, setProfileDetails, pictureName, setPictureName }: ProfileProps) => {

  const [userCircleIcon, setUserCircleIcon] = useState<any>();
  const [fileToUpload, setFileToUpload] = useState<any>();
  const [countries, setCountries] = useState<any[]>([]);

  const [language, setLanguage] = useState<any>(
    window.localStorage.getItem("i18nextLng")
  );
  const [session, setSession] = useState<any>(null);
  const projectUrl = localStorage.getItem("supabaseProjectUrl");
  const [editModelOpen, setEditModelOpen] = useState(false);

  const setLocalStorage = (value: any) => {
    try {
      localStorage.setItem("i18nextLng", value);
      i18n.changeLanguage(value);
      setLanguage(value);
    } catch (error) {
      console.error(error);
    }
  };

  const getImageSrc = () => {
    if (userCircleIcon) return userCircleIcon;
    if (profileDetails?.profile_picture && session?.user?.id) {
      return `${projectUrl}/storage/v1/object/public/profile_pictures/${session?.user?.id}/${profileDetails?.profile_picture}`;
    }
    return defaultPlaceholder;
  };



  useEffect(() => {
    setLocalStorage(language);
  }, [language]);

  useEffect(() => {
    const handleAuthStateChange = (_event: any, newSession: any) => {
      setSession(newSession);
    };

    userSession().then((session: any) => {
      setSession(session.data.session);
    });

    privateSupabase.auth.onAuthStateChange(handleAuthStateChange);
  }, [])

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const { data, error } = await publicSupabase
          .from("countries")
          .select("*");
        if (error) throw error;

        setCountries(data);
      } catch (error) {
        console.error("Error obtaining countries from Supabase:", error);
      }
    };

    fetchCountries();
  }, []);

  const handleUserIconChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setFileToUpload(file);
      setPictureName(file.name);
      const reader = new FileReader();
      reader.onloadend = () => setUserCircleIcon(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const saveData = async () => {
    const dataToUpdate = { ...profileDetails };
    dataToUpdate.profile_picture = pictureName;
    dataToUpdate.language = language;
    delete dataToUpdate.company;
    delete dataToUpdate.role;
    profileDetails.profile_picture = pictureName;
    profileDetails.language = language;
    try {
      const { data: savedData } = await privateSupabase
        .from("profiles")
        .update(dataToUpdate)
        .eq("id", session.user.id)
        .select();
      if (savedData) await uploadProfilePicture();
    } catch (error) {
      console.error("ERROR: ", error);
    }
    setEditModelOpen(false);
    toast.success(t("PROFILE_EDIT_SUCCESS"));
    setLocalStorage(profileDetails.language);
  };

  const uploadProfilePicture = async () => {
    if (!fileToUpload || !pictureName) {
      console.error("No file selected for upload");
      return;
    }

    const filePath = `${session.user.id}/${pictureName}`;
    try {
      const { error } = await privateSupabase.storage
        .from("profile_pictures")
        .upload(filePath, fileToUpload);

      if (error) throw error;
    } catch (error) {
      console.error("Error uploading profile image");
    }
  };

  return (
    <div className="overflow-y-auto">
      <div className="flex flex-col rounded-xl w-full p-6 border">
        <div className="rounded-xl bg-white  shadow-md border mx-20 flex-col my-10">
          <div className="py-4 px-6 flex items-center border-b border-[#0059AD]">
            <Typography
              className="text-xl flex-1 font-normal"
              style={{ color: "#0C1122" }}
              placeholder={undefined}
            >
              {profileDetails.full_name !== null ? `${profileDetails.full_name}` : "-"}
            </Typography>
            <PencilSquareIcon
              className="h-5 w-5 cursor-pointer text-gray-400"
              onClick={() => setEditModelOpen(true)}
            />
          </div>
          <div className="flex justify-start gap-8 mt-6 pb-8">
            <ProfileImage
              isEditable={false}
              src={getImageSrc()}
              onChange={() => { }}
            >
              <div>
                <div className="flex flex-col">
                  <DetailsTitle titleText="PERSONAL_INFORMATION" marginBottom="10px" />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "80px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "25px",
                        flex: "1",
                      }}
                    >
                      <DetailsText
                        labelText="DATE_OF_BIRTH"
                        inputText={profileDetails.birthdate !== null ? formatDate(profileDetails.birthdate) : ''}
                        color="#0C1122"
                      />
                      <DetailsText
                        labelText="COUNTRY"
                        inputText={profileDetails?.country}
                        color="#0C1122"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "25px",
                        flex: "1",
                      }}
                    >
                      <DetailsText
                        labelText="CITY"
                        inputText={profileDetails?.city}
                        color="#0C1122"
                      />

                      <DetailsText
                        labelText="POSTAL_CODE"
                        inputText={profileDetails?.postal_code}
                        color="#0C1122"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </ProfileImage>
            <div className="flex flex-col" style={{ gap: "50px", padding: "20px" }}>
              {/** PROFESSIONAL INFORMATION  */}
              <div className="flex flex-col">
                <DetailsTitle titleText="PROFESSIONAL_INFORMATION" marginBottom="10px" />
                <DetailsText
                  labelText="JOB_TITLE"
                  inputText={profileDetails?.job_title}
                  color="#0C1122"
                />
              </div>

              {/** CONTACT INFORMATION  */}
              <div className="flex flex-col">
                <DetailsTitle titleText="CONTACT_INFORMATION" marginBottom="10px" />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "100px",
                  }}
                >
                  <DetailsText
                    labelText="ADDRESS"
                    inputText={profileDetails?.address}
                    color="#0C1122"
                  />
                  <DetailsText
                    labelText="MOBILE"
                    inputText={profileDetails?.mobile}
                    color="#0C1122"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "100px",
                    marginTop: "10px",
                  }}
                >
                  <DetailsText
                    labelText="EMAIL"
                    inputText={profileDetails.email}
                    color="#0C1122"
                  />
                </div>
              </div>

              {/** ACCOUNT INFORMATION  */}
              <div className="flex flex-col">
                <DetailsTitle titleText="ACCOUNT_INFORMATION" marginBottom="10px" />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "100px",
                  }}
                >
                  <DetailsText
                    labelText="PERMISSIONS"
                    inputText={capitalizeFirstLetter(
                      profileDetails.role.role_description
                    )}
                    color="#0C1122"
                  />
                  <DetailsText
                    labelText="LANGUAGE"
                    inputText={t(profileDetails.language)}
                    color="#0C1122"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditProfileModal editModelOpen={editModelOpen} setEditModelOpen={setEditModelOpen} profileData={profileDetails} setProfileData={setProfileDetails} getImageSrc={getImageSrc} handleUserIconChange={handleUserIconChange} saveData={saveData} setLanguage={setLanguage} countries={countries} />
    </div>
  );
};

export default Profile;

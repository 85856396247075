/** @format */
import ChatAssistant from "../../components/OmniChat/ChatAssistant";
import { useChatVisibility } from "../../context/chatVisibilityContext";
import { useEffect } from "react";
import { useFilter } from "../../context/filterContext";
import useColumnData from "../../components/OmniChat/useColumnData";
import SidebarAI from "../../components/OmniChat/Sidebar";
import { t } from "i18next";

const OmniChat = () => {
  const {
    tableStep,
    setTableStep,
    setSegmentStep,
    setActiveRoute,
    segmentStep,
    chartStep,
    tablesExplanation,
    setTablesExplanation,
    tableData,
    setTableData,
    setTableDescription,
    setTableHeaders,
    setTableTitle,
    setChartTableData,
    setChartChatData,
    setAllSelectedTablesInfo,
    setCleanedQuery,
    currentPage,
    itemsPerPage,
    setDisplayedSourcesData,
    setEditedTitle,
    typeCustoms,
    setRenderTypes,
    setRenderType,
    typeCustom,
    setTypeCustoms,
  } = useFilter();
  const allTableData = useColumnData(useFilter().allTableNames);
  useEffect(() => {
    if (typeCustom !== "") {
      setRenderType(typeCustom);
    } else {
      setRenderType("");
    }
  }, [typeCustom]);

  useEffect(() => {
    if (typeCustoms.length > 0) {
      const newRenderTypes = [];
      if (typeCustoms.includes("barChart")) {
        newRenderTypes.push("barChart");
      }
      if (typeCustoms.includes("lineChart")) {
        newRenderTypes.push("lineChart");
      }
      if (typeCustoms.includes("doghnutChart")) {
        newRenderTypes.push("doghnutChart");
      }
      if (typeCustoms.includes("table")) {
        newRenderTypes.push("table");
      }
      if (typeCustoms.includes("segment")) {
        newRenderTypes.push("segment");
      }
      if (typeCustoms.includes("dataPoint")) {
        newRenderTypes.push("dataPoint");
      }

      setRenderTypes(newRenderTypes);
    } else {
      setRenderTypes([]);
    }
  }, [typeCustoms]);

  useEffect(() => {
    if (allTableData.length > 0) {
      const simplifiedTableData = allTableData.map(
        ({ tableName, columnNames, tableDescription }) => ({
          tableName,
          columnNames,
          tableDescription,
        })
      );
      if (simplifiedTableData.length > 0) {
        setAllSelectedTablesInfo(simplifiedTableData);
        setChartTableData(allTableData);
      }
    }
  }, [allTableData]);

  useEffect(() => {
    localStorage.removeItem("actualMessageUser");
    setTypeCustoms([]);
    setRenderTypes([]);
    setTablesExplanation([]);
    setTableData([]);
    setTableDescription("");
    setTableHeaders([]);
    setTableTitle("");
    setSegmentStep(0);
    setTableStep(0);
    setChartChatData({});
    setCleanedQuery("");
  }, []);

  useEffect(() => {
    if (tablesExplanation.length > 0) {
      setEditedTitle(tablesExplanation[0].title);
    }
  }, [tablesExplanation]);

  const { customRender, setGlobalRender, setCustomRender } =
    useChatVisibility();

  useEffect(() => {
    setActiveRoute("");
    setGlobalRender(false);
    setCustomRender(true);
  }, [segmentStep, tableStep, chartStep]);

  useEffect(() => {
    const fromIndex = (currentPage - 1) * itemsPerPage;
    const toIndex = fromIndex + itemsPerPage;
    setDisplayedSourcesData(tableData.slice(fromIndex, toIndex));
  }, [tableData, currentPage, itemsPerPage]);

  return (
    <>
      <div className="flex w-full overflow-hidden bg-[#F7F7F7]">
        <SidebarAI />
        {customRender && (
          <div className=" w-full overflow-x-auto">
            <ChatAssistant custom={true} messageServer={""} />
          </div>
        )}
      </div>
    </>
  );
};

export default OmniChat;

/** @format */

import React from "react";

interface DownloadOptionsProps {
  onOptionClick: (option: string) => void;
  type: string;
}

const DownloadOptions: React.FC<DownloadOptionsProps> = ({
  onOptionClick,
  type,
}) => {
  const options = type === "table" ? ["CSV"] : ["PDF", "PNG"];

  return (
    <div className="absolute mt-2 right-2 w-24 bg-white border border-gray-200 rounded-lg shadow-lg z-10">
      <div className="py-1">
        {options.map((option) => (
          <button
            key={option}
            className="flex px-4 py-2 text-gray-800 hover:bg-gray-100 w-full text-left items-center gap-2"
            onClick={() => onOptionClick(option)}
          >
            {option}
          </button>
        ))}
      </div>
    </div>
  );
};

export default DownloadOptions;

/** @format */

import React from "react";
import { Dialog, Typography } from "@material-tailwind/react";
import { ModalHeader } from "../Modal";
import { format } from "sql-formatter";

interface VerifyDialogProps {
  isOpen: boolean;
  onClose: () => void;
  query: string;
  description: string;
}

const formatSQL = (sql: string) => {
  return format(sql, {
    language: "postgresql",
    tabWidth: 2,
    keywordCase: "upper",
    linesBetweenQueries: 2,
  });
};

const VerifyDialog = ({
  isOpen,
  onClose,
  query,
  description,
}: VerifyDialogProps) => {
  let formattedQuery;
  if (query) {
    formattedQuery = formatSQL(query);
  }

  return (
    <Dialog
      size="lg"
      open={isOpen}
      handler={onClose}
      className="p-4 bg-gray-100 max-h-[1000px] overflow-y-auto"
      placeholder={undefined}
    >
      <ModalHeader title="Verify Query" onClose={onClose} />
      {description && (
        <div className="w-full space-y-4 px-2">
          <div className="flex flex-col gap-4 px-2">
            <div className="!text-[#46474C]">
              {description.split("\n").map((line, lineIndex) => (
                <Typography placeholder={undefined}>{line}</Typography>
              ))}
            </div>
          </div>
        </div>
      )}
      <Typography className="px-6 py-1 font-medium" placeholder={undefined}>
        Code to Verify
      </Typography>
      {query && (
        <div className="p-5 space-y-4">
          <pre>{formattedQuery}</pre>
        </div>
      )}
    </Dialog>
  );
};

export default VerifyDialog;

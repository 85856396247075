/** @format */

import React, { useCallback, useEffect, useMemo, useState } from "react";
import FilterComponent from "../../components/FilterComponent";
import Table from "../../components/Table";
import { privateSupabase } from "../../api/SupabaseClient";
import MainButton from "../../components/Buttons/MainButton";
import { useSupabase } from "../../context/supabaseContext";
import { useFilter, useSelection } from "../../context";
import LoadingSpinner from "../../components/LoadingSpinner";
import {
  fetchData,
  fetchFilterOptions,
  applyFilter,
  removeFilter,
  transformTimestampToDate,
  getDateRange,
} from "../../utils/ColumnFilterHandler";

export const Segments: React.FC = () => {
  const { getUserRole } = useSupabase();
  const { selectedOption } = useSelection();
  const { setOpenCreateCustom, setTypeCustom } = useFilter();

  const [searchInput, setSearchInput] = useState("");
  const [filterValues, setFilterValues] = useState<Record<string, any>>({});
  const [role, setRole] = useState<number>(3);
  const [data, setData] = useState<any[]>([]);
  const [columns, setColumns] = useState<string[]>([
    "Name",
    "Created by",
    "Last Edited",
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState<Record<string, string[]>>({});
  const [filterOptions, setFilterOptions] = useState<Record<string, string[]>>(
    {}
  );

  const tableName = "segments";

  const columnTypes: Record<string, string> = {
    last_edited: "timestamp",
  };

  useMemo(() => {
    const fetchUserRole = async () => {
      const userRole = await getUserRole();
      setRole(userRole);
    };
    fetchUserRole();
  }, []);

  useEffect(() => {
    const fetchDataAsync = async () => {
      setIsLoading(true);
      const fromIndex = (currentPage - 1) * itemsPerPage;
      const toIndex = fromIndex + itemsPerPage;
      const { data, totalItems } = await fetchData(
        privateSupabase,
        tableName,
        filters,
        searchInput,
        columnTypes,
        fromIndex,
        toIndex
      );
      setData(data);
      setTotalItems(totalItems);
      setIsLoading(false);
    };

    fetchDataAsync();
  }, [itemsPerPage, currentPage, filters, searchInput]);

  useEffect(() => {
    const fetchFilterOptionsAsync = async () => {
      const options = await fetchFilterOptions(
        privateSupabase,
        tableName,
        columnTypes
      );
      setFilterOptions(options);
    };

    fetchFilterOptionsAsync();
  }, []);

  const getAvailableFiltersForCurrentTable = () => {
    return ["name", "created_by"];
  };

  const handleFilterChange = useCallback((filterName: string, value: any) => {
    setFilterValues((prev) => ({ ...prev, [filterName]: value }));
  }, []);

  const openCustomCreation = () => {
    setTypeCustom("segment");
    setOpenCreateCustom(true);
  };

  const onSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchInput(e.target.value);
    },
    []
  );

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    const firstItemIndex = (currentPage - 1) * itemsPerPage;
    const newCurrentPage = Math.floor(firstItemIndex / newItemsPerPage) + 1;
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newCurrentPage);
  };

  const mapColumnNameToDisplayName = (columnName: string) => {
    const mapping: Record<string, string> = {
      name: "Name",
      created_by: "Created by",
      last_edited: "Last Edited",
    };
    return mapping[columnName] || columnName;
  };

  const mapDisplayNameToColumnName = (displayName: string) => {
    const mapping: Record<string, string> = {
      Name: "name",
      "Created by": "created_by",
      "Last Edited": "last_edited",
    };
    return mapping[displayName] || displayName;
  };

  const mappedHeaders = columns.map((col) => mapDisplayNameToColumnName(col));

  const mappedData = data.map((row) => {
    const mappedRow: Record<string, any> = {};
    Object.keys(row).forEach((key) => {
      mappedRow[mapColumnNameToDisplayName(key)] = row[key];
    });
    return mappedRow;
  });

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-col h-full overflow-y-auto">
        <div className="tab-products flex flex-col h-full overflow-hidden">
          <div className="flex flex-col justify-between bg-white rounded-2xl mx-6 flex-grow overflow-hidden">
            <div className="flex flex-row justify-between items-center w-full px-8 mt-8 mb-4">
              <div className="flex flex-row justify-start items-center space-x-4">
                <FilterComponent
                  availableFilters={getAvailableFiltersForCurrentTable()}
                  placeHolders={["Name", "Created By"]}
                  filterValues={filterValues}
                  onFilterChange={handleFilterChange}
                  searchInput={searchInput}
                  onSearchChange={onSearchChange}
                  showFilters={false}
                  showSearch={true}
                />
              </div>
              {role !== 3 && (
                <div>
                  <MainButton
                    buttonText="CREATE_SEGMENT"
                    onClick={openCustomCreation}
                  />
                </div>
              )}
            </div>
            <div className="px-8 pb-8 flex-grow overflow-hidden">
              {isLoading && <LoadingSpinner message="LOADING" />}
              {!isLoading &&
                totalItems === 0 &&
                "No segments found. Please, create new segments"}
              {!isLoading && totalItems > 0 && (
                <Table
                  headers={columns}
                  rows={mappedData}
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  onItemsPerPageChange={handleItemsPerPageChange}
                  totalItems={totalItems}
                  onPageChange={setCurrentPage}
                  filters={filters}
                  applyFilter={async (column, value) => {
                    const newFilterOptions = await applyFilter(
                      privateSupabase,
                      tableName,
                      filters,
                      mapDisplayNameToColumnName(column),
                      value,
                      columnTypes
                    );
                    setFilterOptions((prevOptions) => ({
                      ...prevOptions,
                      ...newFilterOptions,
                      [column]: prevOptions[column],
                    }));
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      [mapDisplayNameToColumnName(column)]: value,
                    }));
                    setCurrentPage(1);
                  }}
                  removeFilter={async (column) => {
                    const newFilterOptions = await removeFilter(
                      privateSupabase,
                      tableName,
                      filters,
                      mapDisplayNameToColumnName(column),
                      columnTypes
                    );
                    setFilterOptions((prevOptions) => ({
                      ...prevOptions,
                      ...newFilterOptions,
                      [column]: prevOptions[column],
                    }));
                    setFilters((prevFilters) => {
                      const {
                        [mapDisplayNameToColumnName(column)]: removed,
                        ...rest
                      } = prevFilters;
                      return rest;
                    });
                    setCurrentPage(1);
                  }}
                  filterOptions={filterOptions}
                  tableName={tableName}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="h-10"></div>
    </div>
  );
};

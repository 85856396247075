/** @format */

import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { GridStack } from "gridstack";
import "gridstack/dist/gridstack.min.css";
import { CardComponent } from "../DashboardCreator/CardComponent";

const GridStackLibraryContainer = ({
  draggableItems,
  onItemClick,
  isSelect,
  setEditElement,
}) => {
  const gridRef = useRef(null);
  const gridInstance = useRef(null);

  useEffect(() => {
    if (gridRef.current) {
      if (gridInstance.current) {
        gridInstance.current.destroy(false);
      }

      const grid = GridStack.init(
        {
          float: false,
          cellHeight: 40,
          margin: 10,
          column: 12,
          disableOneColumnMode: true,
          staticGrid: true,
        },
        gridRef.current
      );

      gridInstance.current = grid;
    }

    return () => {
      if (gridInstance.current) {
        gridInstance.current.destroy(false);
        gridInstance.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (gridInstance.current) {
      gridInstance.current.removeAll();

      let currentX = 0;
      let currentY = 0;
      let maxHeightInRow = 0;

      draggableItems.forEach((item, index) => {
        const node = document.createElement("div");
        node.className = "grid-stack-item";

        let itemWidth = 5;
        let itemHeight = 12; // Set a fixed height for all non-datapoint items

        if (item.type === "datapoint") {
          itemHeight = 4;
        } else if (item.type === "table") {
          const headersLength = item.data?.tableData?.headers?.length || 0;
          itemWidth = headersLength * 2; // Set width based on the number of headers
        } else if (item.type === "chart") {
          itemWidth = 3; // Set width for chart items
        }

        // Check if the item fits in the current row
        if (currentX + itemWidth > 12) {
          // Move to the next row
          currentX = 0;
          currentY += maxHeightInRow;
          maxHeightInRow = 0;
        }

        const attributes = {
          "gs-x": currentX,
          "gs-y": currentY,
          "gs-w": itemWidth,
          "gs-h": itemHeight,
          "gs-id": item.id || `item-${index}`,
          "data-gs-type": item.type,
        };

        // Update currentX and maxHeightInRow
        currentX += itemWidth;
        if (itemHeight > maxHeightInRow) {
          maxHeightInRow = itemHeight;
        }

        Object.keys(attributes).forEach((key) => {
          node.setAttribute(key, attributes[key].toString());
        });

        const contentDiv = document.createElement("div");
        contentDiv.className = "grid-stack-item-content";
        contentDiv.style.overflow = "unset";
        node.appendChild(contentDiv);

        node.onclick = () => {
          if (isSelect) {
            onItemClick(item);
          }
        };

        gridInstance.current?.el.appendChild(node);
        gridInstance.current?.makeWidget(node);

        ReactDOM.render(
          <CardComponent
            key={`${item.type}-${index}-${item.id}`}
            item={item}
            itemIndex={index}
            deleteItem={() => {}}
            duplicateItem={() => {}}
            handleTitleChange={() => {}}
            handleIconChange={() => {}}
            handleSizeChange={() => {}}
            isEditMode={false}
            isLibrary={true}
            isSelect={isSelect}
            setEditElement={setEditElement}
          />,
          contentDiv
        );

        // Add empty space below datapoint cards if necessary
        if (item.type === "datapoint" && itemHeight < maxHeightInRow) {
          const emptyHeight = maxHeightInRow - itemHeight;
          const emptyNode = document.createElement("div");
          emptyNode.className = "grid-stack-item";
          emptyNode.setAttribute("gs-x", currentX - itemWidth);
          emptyNode.setAttribute("gs-y", currentY + itemHeight);
          emptyNode.setAttribute("gs-w", itemWidth);
          emptyNode.setAttribute("gs-h", emptyHeight);
          emptyNode.setAttribute("data-gs-type", "empty");

          const emptyContentDiv = document.createElement("div");
          emptyContentDiv.className = "grid-stack-item-content";
          contentDiv.style.overflow = "unset";
          emptyContentDiv.style.background = "white";
          emptyNode.appendChild(emptyContentDiv);

          gridInstance.current?.el.appendChild(emptyNode);
          gridInstance.current?.makeWidget(emptyNode);
        }
      });
    }
  }, [draggableItems, isSelect, onItemClick]);

  return <div className="grid-stack" ref={gridRef}></div>;
};

export default GridStackLibraryContainer;

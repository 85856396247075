import React, { useEffect, useMemo, useState } from "react";
import { t } from "i18next";
import ConnectorsManager from "./ConnectorsManager";
import { privateSupabase } from "../../api/SupabaseClient";
import { ReactComponent as PostgresIcon } from "../../assets/icons/postgresql_icon.svg";
import { ReactComponent as WooComerceIcon } from "../../assets/icons/woocomerce_icon.svg";
import { ReactComponent as MetabaseIcon } from "../../assets/icons/metabase_icon.svg";
import { ReactComponent as SheetsIcon } from "../../assets/icons/sheets_icon.svg";
import { ReactComponent as SnowflakeIcon } from "../../assets/icons/snowflake_icon.svg";
import { ReactComponent as ActiveCampaignIcon } from "../../assets/icons/activecampaign_icon.svg";
import { ReactComponent as AirtableIcon } from "../../assets/icons/airtable_icon.svg";
import { ReactComponent as CsvIcon } from "../../assets/icons/csv_file_icon.svg";
import { Status } from "../../components/Status";
import downloadIcon from '../../assets/icons/download_icon.svg';
import { useSupabase } from "../../context/supabaseContext";
import SourceDetails from "./SourceDetails";

import RefreshButton from "../../components/Buttons/RefreshButton";
import { migrateData, checkMigrationStatus, getConnectorPrefix } from "../../api/migrations/migrate";


interface Connection {
  id: string;
  name: string;
  company_id: string | null;
  status: string | null;
  description: string | null;
  last_sync: string;
  source: any;
  file: string | null;
  isConnector: boolean;
}

export function Settings() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setRefresh(!refresh);
  };
  const [connections, setConnections] = useState<Connection[]>([]);
  const [refresh, setRefresh] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const { getUserRole } = useSupabase();
  const [role, setRole] = useState<number>(3);
  const [loading, setLoading] = useState(true); // Estado de carga

  useEffect(() => {
    const fetchUserRole = async () => {
      const userRole = await getUserRole();
      setRole(userRole);
    };
    fetchUserRole();
  }, [getUserRole]);

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const fetchAndUpdateJobIds = async () => {
    try {
      const { data: jobData, error: jobError } = await privateSupabase
        .from('migrations')
        .select('connector, company_id');

      if (jobError) throw jobError;

      const jobPromises = jobData.map(async ({ connector, company_id }) => {
        try {
          await checkMigrationStatus(connector, company_id);
        } catch (error) {
          console.error(`Error fetching status for connection ${connector}_${company_id}:`, error);
        }
      });
      await Promise.all(jobPromises);
    } catch (error) {
      console.error('Error fetching job IDs:', error);
    }
  };

  const fetchConnections = async (page: number, itemsPerPage: number) => {
    setLoading(true); // Iniciar carga
    try {

      let formattedConnections: Connection[] = [];
        const { data: connectionsData, error: connectionsError } = await privateSupabase
          .from('migrations')
          .select(`migration_id,
            connector,
            status,
            company_id,
            migration_date
          `)
          .range((page - 1) * itemsPerPage, page * itemsPerPage - 1);

        if (connectionsError) throw connectionsError;

        formattedConnections = connectionsData!.map(item => ({
          id: item.migration_id,
          name: item.connector,
          company_id: item.company_id,
          status: item.status,
          description: null,
          last_sync: item.migration_date,
          source: item.connector,
          file: null,
          isConnector: true,
        }));


      const { data: dataFiles, error: dataFilesError } = await privateSupabase
        .from('data_files')
        .select('id, name, description, created_at, file', { count: 'exact' })
        .range((page - 1) * itemsPerPage, page * itemsPerPage - 1);

      if (dataFilesError) throw dataFilesError;

      const formattedDataFiles: Connection[] = dataFiles!.map(item => ({
        id: item.id,
        name: item.name,
        company_id: null,
        status: null,
        description: item.description,
        last_sync: formatDate(item.created_at),
        source: null,
        file: item.file,
        isConnector: false,
      }));

      setConnections([...formattedConnections, ...formattedDataFiles]);
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    } finally {
      setLoading(false); // Finalizar carga
    }
  };

  useEffect(() => {
    const initialize = async () => {
      await fetchAndUpdateJobIds();
      await fetchConnections(currentPage, itemsPerPage);
    };

    initialize();
  }, [refresh, currentPage, itemsPerPage]);

  const getServiceIcon = (connector: any) => {
    switch (connector) {
      case 'Postgres':
        return <PostgresIcon className="mr-2 h-6 w-6" />;
      case 'WooCommerce':
        return <WooComerceIcon className="mr-2 h-6 w-6" />;
      case 'Metabase':
        return <MetabaseIcon className="mr-2 h-6 w-6" />;
      case 'Google Sheets':
        return <SheetsIcon className="mr-2 h-6 w-6" />;
      case 'Snowflake':
        return <SnowflakeIcon className="mr-2 h-6 w-6" />;
      case 'ActiveCampaign':
        return <ActiveCampaignIcon className="mr-2 h-6 w-6" />;
      case 'Airtable':
        return <AirtableIcon className="mr-2 h-6 w-6" />;
      case 'csv':
        return <CsvIcon className="mr-2 h-6 w-6" />;
      default:
        return null; // O algún icono por defecto
    }
  };

  const relaunchJob = async (connectionId: string, company_id: string, connector: string) => {
    await migrateData(connector, company_id, getConnectorPrefix(connector));
    setRefresh(!refresh);
    console.log(`Launched Job for connection ${connectionId} - connector ${connector} - company_id ${company_id}`)
  }

  const downloadCsv = async (fileName: string, csvName: string) => {
    const path = `${fileName!.toLowerCase().replace(/\s+/g, '_')}/${csvName}`
    try {
      console.log(`Downloading CSV ${csvName}`);
      const { data, error } = await privateSupabase.storage.from('data_files').download(path);

      if (error) {
        console.log(`Error downloading your csv ${csvName}: ${error.message}`);
        return;
      }

      const url = URL.createObjectURL(data);

      const a = document.createElement('a');
      a.href = url;
      a.download = csvName;
      document.body.appendChild(a);
      a.click();

      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  };

  const getStatus = (connectionStatus: string) => {
    switch (connectionStatus) {
      case 'running':
        return 'running';
      case 'completed':
        return 'completed';
      case 'error':
        return 'error';
      default:
        return 'completed';
    }
  }

  const columnHeaders = [
    "Tool Name",
    "Status",
    "Sync Mode",
    "Sync Frequency",
    "Last Run",
    "Data Description",
    ""
  ];

  const rows = useMemo(() => connections.map((connection: Connection) => ({
    "Tool Name": (
      <div className="flex items-center">
        {getServiceIcon(connection.source ?? "csv")}
        {connection.name}
      </div>
    ),
    "Status": connection.isConnector ? <Status status={getStatus(connection.status!)} /> : "-",
    "Sync Mode": connection.isConnector ? <Status status={"completed"} /> : "-",
    "Sync Frequency": connection.isConnector ? "Manual " : "-",
    "Last Run": formatDate(connection.last_sync),
    "Data Description": connection.description ?? "-",
    "": (
      <div className="flex items-center" style={{ justifyContent: "center" }}  >
        {connection.isConnector ?
          <RefreshButton onClick={() => { relaunchJob(connection.id, connection.company_id!, connection.name) }} />
          :
          <button className="flex items-center" onClick={() => { downloadCsv(connection.name, connection.file!) }}>
            <img src={downloadIcon} alt={t("DOWNLOAD_CSV")} className="h-8 w-8" />
          </button>
        }
      </div>
    )
  })), [connections]);

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div
      key={"activeTab"}
      className="bg-white rounded-2xl mx-6 "
      style={{ overflowY: "auto", maxHeight: "calc(100vh - 120px)" }}
    >
      <SourceDetails
        columnHeaders={columnHeaders}
        rows={rows}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        handleItemsPerPageChange={handleItemsPerPageChange}
        totalItems={connections.length}
        handlePageChange={handlePageChange}
        handleOpen={handleOpen}
        role={role}
        loading={loading}
      />
      <ConnectorsManager open={open} handleClose={handleClose} />
    </div>
  );
}

/** @format */

import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  XMarkIcon,
  PlusIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import {
  Collapse,
  Card,
  CardBody,
  List,
  ListItem,
} from "@material-tailwind/react";

interface FilterBarProps {
  onFilterClick: (
    selectedMeasures: string[],
    selectedDimensions: string[],
    selectedFilters: any[],
    selectedSegments: any[],
    selectedTimeDimensions: any[]
  ) => void;
  filterData: any;
  cubeMetadata: any;
  clearFilters?: () => void;
  initialQuery?: any;
  clearedFilters?: boolean;
  setClearedFilters?: any;
  isChatAI?: boolean;
}

const GlobalFilterBar: React.FC<FilterBarProps> = ({
  onFilterClick,
  filterData,
  cubeMetadata,
  clearFilters,
  setClearedFilters,
  initialQuery = {
    measures: [],
    dimensions: [],
    filters: [],
    segments: [],
    timeDimensions: [],
  },
  clearedFilters,
  isChatAI = false,
}) => {
  const [filtersActive, setFiltersActive] = useState<boolean>(false);
  const [expandedCategory, setExpandedCategory] = useState<string | null>(null);
  const [collapseOpen, setCollapseOpen] = useState<boolean>(false);
  const [measures, setMeasures] = useState<any[]>([]);
  const [dimensions, setDimensions] = useState<any[]>([]);
  const [segments, setSegments] = useState<any[]>([]);
  const [timeDimensions, setTimeDimensions] = useState<any[]>([]);
  const [filters, setFilters] = useState<any[]>([]);
  const [selectedMeasures, setSelectedMeasures] = useState<string[]>(
    initialQuery.measures || []
  );
  const [selectedDimensions, setSelectedDimensions] = useState<string[]>(
    initialQuery.dimensions || []
  );
  const [selectedFilters, setSelectedFilters] = useState<any[]>(
    initialQuery.filters || []
  );
  const [selectedSegments, setSelectedSegments] = useState<string[]>(
    initialQuery.segments || []
  );
  const [selectedTimeDimensions, setSelectedTimeDimensions] = useState<any[]>(
    initialQuery.timeDimensions || []
  );
  const [selectedDateRange, setSelectedDateRange] = useState<any>(["All time"]);
  const [customStartDate, setCustomStartDate] = useState<Date | null>(null);
  const [customEndDate, setCustomEndDate] = useState<Date | null>(null);
  const [expandedItems, setExpandedItems] = useState<{
    [key: string]: boolean;
  }>({});
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setCollapseOpen(false);
        setExpandedCategory(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  useEffect(() => {
    if (cubeMetadata && Array.isArray(cubeMetadata.cubes)) {
      const newMeasures = cubeMetadata.cubes.map((cube: any) => ({
        cube: cube.name,
        title: cube.title,
        measures: cube.measures.map((measure: any) => measure.name),
      }));

      const newDimensions = cubeMetadata.cubes.map((cube: any) => ({
        cube: cube.name,
        title: cube.title,
        dimensions: cube.dimensions.map((dimension: any) => dimension.name),
      }));

      const newSegments = cubeMetadata.cubes.map((cube: any) => ({
        cube: cube.name,
        title: cube.title,
        segments: cube.segments.map((segment: any) => segment.name),
      }));

      const newTimeDimensions = cubeMetadata.cubes.map((cube: any) => ({
        cube: cube.name,
        title: cube.title,
        timeDimensions: cube.dimensions
          .filter((dimension: any) => dimension.type === "time")
          .map((dimension: any) => dimension.name),
      }));

      const newFilters = cubeMetadata.cubes.map((cube: any) => ({
        cube: cube.name,
        title: cube.title,
        filters: cube.dimensions.map((filter: any) => filter.name),
      }));

      setMeasures(newMeasures);
      setDimensions(newDimensions);
      setSegments(newSegments);
      setTimeDimensions(newTimeDimensions);
      setFilters(newFilters);
    }
  }, [cubeMetadata]);

  useEffect(() => {
    if (clearedFilters) {
      setSelectedMeasures(initialQuery.measures || []);
      setSelectedDimensions(initialQuery.dimensions || []);
      setSelectedFilters(initialQuery.filters || []);
      setSelectedSegments(initialQuery.segments || []);
      setSelectedTimeDimensions(initialQuery.timeDimensions || []);
      setSelectedDateRange("All time");
      setCustomStartDate(null);
      setCustomEndDate(null);
      refetchDataInternal(
        initialQuery.measures || [],
        initialQuery.dimensions || [],
        initialQuery.filters || [],
        initialQuery.segments || [],
        initialQuery.timeDimensions || []
      );
      setClearedFilters(false);
    }
  }, [clearedFilters, initialQuery]);

  useEffect(() => {
    const hasFilters =
      selectedMeasures.length > 0 ||
      selectedDimensions.length > 0 ||
      selectedFilters.length > 0 ||
      selectedTimeDimensions.length > 0 ||
      selectedSegments.length > 0;

    setFiltersActive(hasFilters);
    refetchDataInternal(
      selectedMeasures,
      selectedDimensions,
      selectedFilters,
      selectedSegments,
      selectedTimeDimensions
    );
  }, [
    selectedMeasures,
    selectedDimensions,
    selectedFilters,
    selectedTimeDimensions,
    selectedSegments,
  ]);

  const handleMeasureClick = (measure: string) => {
    setSelectedMeasures((prev) => {
      const updatedMeasures = prev.includes(measure)
        ? prev.filter((m) => m !== measure)
        : [...prev, measure];
      refetchDataInternal(
        updatedMeasures,
        selectedDimensions,
        selectedFilters,
        selectedSegments,
        selectedTimeDimensions
      );
      return updatedMeasures;
    });
  };

  const handleDimensionClick = (dimension: string) => {
    setSelectedDimensions((prev) => {
      const updatedDimensions = prev.includes(dimension)
        ? prev.filter((d) => d !== dimension)
        : [...prev, dimension];
      refetchDataInternal(
        selectedMeasures,
        updatedDimensions,
        selectedFilters,
        selectedSegments,
        selectedTimeDimensions
      );
      return updatedDimensions;
    });
  };

  const handleSegmentClick = (segment: string) => {
    setSelectedSegments((prev) => {
      const updatedSegments = prev.includes(segment)
        ? prev.filter((s) => s !== segment)
        : [...prev, segment];
      refetchDataInternal(
        selectedMeasures,
        selectedDimensions,
        selectedFilters,
        updatedSegments,
        selectedTimeDimensions
      );
      return updatedSegments;
    });
  };

  const handleTimeDimensionClick = (timeDimension: string) => {
    const updatedTimeDimensions = [
      {
        dimension: timeDimension,
        dateRange: selectedDateRange,
        granularity: "day",
      },
    ];
    setSelectedTimeDimensions(updatedTimeDimensions);
    setSelectedDateRange("All time");
    setExpandedCategory(null);
    refetchDataInternal(
      selectedMeasures,
      selectedDimensions,
      selectedFilters,
      selectedSegments,
      updatedTimeDimensions
    );
  };

  const handleFilterClick = (filter: string) => {
    const filterType = getFilterType(filter);
    const operators = filterOperators[filterType] || filterOperators.default;
    setSelectedFilters((prevFilters) => {
      const updatedFilters = [
        ...prevFilters,
        { member: filter, operator: operators[0], values: [""] },
      ];
      refetchDataInternal(
        selectedMeasures,
        selectedDimensions,
        updatedFilters,
        selectedSegments,
        selectedTimeDimensions
      );
      return updatedFilters;
    });
    setExpandedCategory(null);
  };

  const handleFilterOperatorChange = (index: number, operator: string) => {
    setSelectedFilters((prev) => {
      const updatedFilters = [...prev];
      updatedFilters[index].operator = operator;
      refetchDataInternal(
        selectedMeasures,
        selectedDimensions,
        updatedFilters,
        selectedSegments,
        selectedTimeDimensions
      );
      return updatedFilters;
    });
  };

  const handleFilterValueChange = (index: number, value: string) => {
    setSelectedFilters((prev) => {
      const updatedFilters = [...prev];
      updatedFilters[index].values = [value];
      refetchDataInternal(
        selectedMeasures,
        selectedDimensions,
        updatedFilters,
        selectedSegments,
        selectedTimeDimensions
      );
      return updatedFilters;
    });
  };

  const handleDateRangeSelect = (dateRange: string) => {
    setSelectedDateRange(dateRange);
    if (selectedTimeDimensions.length > 0) {
      const updatedTimeDimensions = selectedTimeDimensions.map((td) => ({
        ...td,
        dateRange: dateRange === "All time" ? ["All time"] : [dateRange],
      }));
      setSelectedTimeDimensions(updatedTimeDimensions);
      refetchDataInternal(
        selectedMeasures,
        selectedDimensions,
        selectedFilters,
        selectedSegments,
        updatedTimeDimensions
      );
    }
  };

  const handleCustomDateRangeSelect = () => {
    if (selectedTimeDimensions.length > 0 && customStartDate && customEndDate) {
      const dateRange = [
        customStartDate.toISOString(),
        customEndDate.toISOString(),
      ];
      setSelectedDateRange(dateRange.join(" to "));
      const updatedTimeDimensions = selectedTimeDimensions.map((td) => ({
        ...td,
        dateRange,
      }));
      setSelectedTimeDimensions(updatedTimeDimensions);
      refetchDataInternal(
        selectedMeasures,
        selectedDimensions,
        selectedFilters,
        selectedSegments,
        updatedTimeDimensions
      );
    }
  };

  const handleRemoveTimeDimension = () => {
    setSelectedTimeDimensions([]);
    setSelectedDateRange("All time");
    setExpandedCategory(null);
    refetchDataInternal(
      selectedMeasures,
      selectedDimensions,
      selectedFilters,
      selectedSegments,
      []
    );
  };

  const handleRemoveFilter = (index: number) => {
    setSelectedFilters((prev) => {
      const updatedFilters = [...prev];
      updatedFilters.splice(index, 1);
      refetchDataInternal(
        selectedMeasures,
        selectedDimensions,
        updatedFilters,
        selectedSegments,
        selectedTimeDimensions
      );
      return updatedFilters;
    });
    setExpandedCategory(null);
  };

  const handleRemoveMeasure = (measure: string) => {
    if (
      selectedMeasures.length === 1 &&
      selectedDimensions.length === 0 &&
      selectedSegments.length === 0
    ) {
      return;
    }

    setSelectedMeasures((prev) => {
      const updatedMeasures = prev.filter((m) => m !== measure);
      refetchDataInternal(
        updatedMeasures,
        selectedDimensions,
        selectedFilters,
        selectedSegments,
        selectedTimeDimensions
      );
      return updatedMeasures;
    });
  };

  const handleRemoveDimension = (dimension: string) => {
    if (
      selectedDimensions.length === 1 &&
      selectedMeasures.length === 0 &&
      selectedSegments.length === 0
    ) {
      return;
    }

    setSelectedDimensions((prev) => {
      const updatedDimensions = prev.filter((d) => d !== dimension);
      refetchDataInternal(
        selectedMeasures,
        updatedDimensions,
        selectedFilters,
        selectedSegments,
        selectedTimeDimensions
      );
      return updatedDimensions;
    });
  };

  const handleRemoveSegment = (segment: string) => {
    if (
      selectedSegments.length === 1 &&
      selectedMeasures.length === 0 &&
      selectedDimensions.length === 0
    ) {
      return;
    }

    setSelectedSegments((prev) => {
      const updatedSegments = prev.filter((s) => s !== segment);
      refetchDataInternal(
        selectedMeasures,
        selectedDimensions,
        selectedFilters,
        updatedSegments,
        selectedTimeDimensions
      );
      return updatedSegments;
    });
  };

  const toggleCategory = (category: string | null) => {
    setExpandedCategory((prev) => (prev === category ? null : category));
  };

  const handlePlusClick = () => {
    setExpandedCategory(null);
    setCollapseOpen((prev) => !prev);
  };

  const toggleItem = (item: string) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [item]: !prevState[item],
    }));
  };

  const refetchDataInternal = (
    measures: string[],
    dimensions: string[],
    filters: any[],
    segments: string[],
    timeDimensions: any[]
  ) => {
    onFilterClick(measures, dimensions, filters, segments, timeDimensions);
  };

  const handleClearFilters = () => {
    setSelectedMeasures(initialQuery.measures || []);
    setSelectedDimensions(initialQuery.dimensions || []);
    setSelectedFilters(initialQuery.filters || []);
    setSelectedSegments(initialQuery.segments || []);
    setSelectedTimeDimensions(initialQuery.timeDimensions || []);
    setSelectedDateRange("All time");
    setCustomStartDate(null);
    setCustomEndDate(null);
    setExpandedCategory(null);
    setFiltersActive(false);
    onFilterClick([], [], [], [], []);
  };

  const renderOptions = (
    items: any[],
    handleClick: (item: string) => void,
    selectedItems: string[]
  ) => {
    return items.map((item) => (
      <div key={item.cube}>
        <div
          className="font-bold flex justify-between items-center cursor-pointer"
          onClick={() => toggleItem(item.cube)}
        >
          {item.title}
          {expandedItems[item.cube] ? (
            <ChevronUpIcon className="h-5 w-5" />
          ) : (
            <ChevronDownIcon className="h-5 w-5" />
          )}
        </div>
        <Collapse open={expandedItems[item.cube]}>
          <ul className="ml-4">
            {item.measures &&
              item.measures.length > 0 &&
              item.measures.map((measure: any, index: number) => (
                <li key={index}>
                  <button
                    className={`text-left w-full px-2 py-1 ${
                      selectedItems.includes(measure)
                        ? "bg-blue-100 rounded-full"
                        : ""
                    }`}
                    onClick={() => handleClick(measure)}
                  >
                    {measure}
                  </button>
                </li>
              ))}
          </ul>
        </Collapse>
      </div>
    ));
  };

  const renderDimensions = (
    items: any[],
    handleClick: (item: string) => void,
    selectedItems: string[]
  ) => {
    return items.map((item) => (
      <div key={item.cube}>
        <div
          className="font-bold flex justify-between items-center cursor-pointer"
          onClick={() => toggleItem(item.cube)}
        >
          {item.title}
          {expandedItems[item.cube] ? (
            <ChevronUpIcon className="h-5 w-5" />
          ) : (
            <ChevronDownIcon className="h-5 w-5" />
          )}
        </div>
        <Collapse open={expandedItems[item.cube]}>
          <ul className="ml-4">
            {item.dimensions &&
              item.dimensions.length > 0 &&
              item.dimensions.map((dimension: any, index: number) => (
                <li key={index}>
                  <button
                    className={`text-left w-full px-2 py-1 ${
                      selectedItems.includes(dimension)
                        ? "bg-blue-100 rounded-full"
                        : ""
                    }`}
                    onClick={() => handleClick(dimension)}
                  >
                    {dimension}
                  </button>
                </li>
              ))}
          </ul>
        </Collapse>
      </div>
    ));
  };

  const renderSegments = (
    items: any[],
    handleClick: (item: string) => void,
    selectedItems: string[]
  ) => {
    return items.map((item) => (
      <div key={item.cube}>
        <div
          className="font-bold flex justify-between items-center cursor-pointer"
          onClick={() => toggleItem(item.cube)}
        >
          {item.title}
          {expandedItems[item.cube] ? (
            <ChevronUpIcon className="h-5 w-5" />
          ) : (
            <ChevronDownIcon className="h-5 w-5" />
          )}
        </div>
        <Collapse open={expandedItems[item.cube]}>
          <ul className="ml-4">
            {item.segments &&
              item.segments.length > 0 &&
              item.segments.map((segment: any, index: number) => (
                <li key={index}>
                  <button
                    className={`text-left w-full px-2 py-1 ${
                      selectedItems.includes(segment)
                        ? "bg-blue-100 rounded-full"
                        : ""
                    }`}
                    onClick={() => handleClick(segment)}
                  >
                    {segment}
                  </button>
                </li>
              ))}
          </ul>
        </Collapse>
      </div>
    ));
  };

  const renderTimeDimensions = (
    items: any[],
    handleClick: (item: string) => void
  ) => {
    return items.map((item) => (
      <div key={item.cube}>
        <div
          className="font-bold flex justify-between items-center cursor-pointer"
          onClick={() => toggleItem(item.cube)}
        >
          {item.title}
          {expandedItems[item.cube] ? (
            <ChevronUpIcon className="h-5 w-5" />
          ) : (
            <ChevronDownIcon className="h-5 w-5" />
          )}
        </div>
        <Collapse open={expandedItems[item.cube]}>
          <ul className="ml-4">
            {item.timeDimensions &&
              item.timeDimensions.length > 0 &&
              item.timeDimensions.map((timeDimension: any, index: number) => (
                <li key={index}>
                  <button
                    className="text-left w-full px-2 py-1"
                    onClick={() => handleClick(timeDimension)}
                  >
                    {timeDimension}
                  </button>
                </li>
              ))}
          </ul>
        </Collapse>
      </div>
    ));
  };

  const renderFilters = (items: any[], handleClick: (item: string) => void) => {
    return items.map((item) => (
      <div key={item.cube}>
        <div
          className="font-bold flex justify-between items-center cursor-pointer"
          onClick={() => toggleItem(item.cube)}
        >
          {item.title}
          {expandedItems[item.cube] ? (
            <ChevronUpIcon className="h-5 w-5" />
          ) : (
            <ChevronDownIcon className="h-5 w-5" />
          )}
        </div>
        <Collapse open={expandedItems[item.cube]}>
          <ul className="ml-4">
            {item.filters &&
              item.filters.length > 0 &&
              item.filters.map((filter: any, index: number) => (
                <li key={index}>
                  <button
                    className="text-left w-full px-2 py-1"
                    onClick={() => handleClick(filter)}
                  >
                    {filter}
                  </button>
                </li>
              ))}
          </ul>
        </Collapse>
      </div>
    ));
  };

  const getFilterType = (filterName: string): string => {
    for (const cube of cubeMetadata.cubes) {
      for (const dimension of cube.dimensions) {
        if (dimension.name === filterName) {
          return dimension.type;
        }
      }
      for (const measure of cube.measures) {
        if (measure.name === filterName) {
          return measure.type;
        }
      }
    }
    return "default";
  };

  const dateRanges = [
    "Custom",
    "All time",
    "Today",
    "Yesterday",
    "This week",
    "This month",
    "This quarter",
    "This year",
    "Last 7 days",
    "Last 30 days",
    "Last week",
    "Last month",
    "Last quarter",
    "Last year",
  ];

  const filterOperators: { [key: string]: string[] } = {
    default: [
      "equals",
      "does not equal",
      "is set",
      "is not set",
      "greater than",
      "greater than or equal to",
      "less than",
      "less than or equal to",
    ],
    date: [
      "equals",
      "does not equal",
      "in date range",
      "not in date range",
      "after date",
      "after or on date",
      "before date",
      "before or on date",
    ],
    string: [
      "contains",
      "does not contain",
      "equals",
      "does not equal",
      "is set",
      "is not set",
      "starts with",
      "does not start with",
      "ends with",
      "does not end with",
    ],
  };

  return (
    <div className="flex justify-between mb-4 w-full relative" ref={ref}>
      <div className="flex space-x-4 w-full justify-end">
        <div className="flex flex-wrap space-x-2">
          {selectedMeasures.map((measure, index) => (
            <div
              key={index}
              className="flex items-center space-x-2  flex-nowrap"
            >
              <div className="relative border rounded-full px-2 py-1 cursor-pointer flex items-center bg-blue-100 text-blue-500 border-blue-500">
                <div className="absolute -top-1/2 left-2 text-[10px] font-semibold text-blue-500">
                  Measure
                </div>
                {measure}
                {!(
                  selectedMeasures.length === 1 &&
                  selectedDimensions.length === 0 &&
                  selectedSegments.length === 0
                ) && (
                  <XMarkIcon
                    className="w-4 h-4 ml-2 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveMeasure(measure);
                    }}
                  />
                )}
              </div>
            </div>
          ))}
          {selectedDimensions.map((dimension, index) => (
            <div
              key={index}
              className="flex items-center space-x-2  flex-nowrap"
            >
              <div className="relative border rounded-full px-2 py-1 cursor-pointer flex items-center bg-blue-100 text-blue-500 border-blue-500">
                <div className="absolute -top-1/2 left-2 text-[10px] font-semibold text-blue-500">
                  Dimension
                </div>
                {dimension}
                {!(
                  selectedDimensions.length === 1 &&
                  selectedMeasures.length === 0 &&
                  selectedSegments.length === 0
                ) && (
                  <XMarkIcon
                    className="w-4 h-4 ml-2 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveDimension(dimension);
                    }}
                  />
                )}
              </div>
            </div>
          ))}
          {selectedSegments.map((segment, index) => (
            <div
              key={index}
              className="flex items-center space-x-2 flex-nowrap"
            >
              <div className="relative border rounded-full px-2 py-1 cursor-pointer flex items-center bg-blue-100 text-blue-500 border-blue-500">
                <div className="absolute -top-1/2 left-2 text-[10px] font-semibold text-blue-500">
                  Segment
                </div>
                {segment}
                {!(
                  selectedSegments.length === 1 &&
                  selectedMeasures.length === 0 &&
                  selectedDimensions.length === 0
                ) && (
                  <XMarkIcon
                    className="w-4 h-4 ml-2 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveSegment(segment);
                    }}
                  />
                )}
              </div>
            </div>
          ))}
          {selectedTimeDimensions.map((timeDimension, index) => (
            <div
              key={index}
              className="flex items-center space-x-2 flex-nowrap"
            >
              <div className="relative border rounded-full px-2 py-1 cursor-pointer flex items-center bg-blue-100 text-blue-500 border-blue-500">
                <div className="absolute -top-1/2 left-2 text-[10px] font-semibold text-blue-500">
                  Time Dimension
                </div>
                {timeDimension.dimension}

                <XMarkIcon
                  className="w-4 h-4 ml-2 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveTimeDimension();
                  }}
                />
              </div>
              <select
                className="border rounded-full px-2 py-1 bg-blue-100 text-blue-500 border-blue-500"
                value={selectedDateRange}
                onChange={(e) => handleDateRangeSelect(e.target.value)}
              >
                {dateRanges.map((range, index) => (
                  <option key={index} value={range}>
                    {range}
                  </option>
                ))}
              </select>
              {selectedDateRange === "Custom" && (
                <div className="flex space-x-2">
                  <DatePicker
                    selected={customStartDate}
                    onChange={(date: Date) => setCustomStartDate(date)}
                    selectsStart
                    startDate={customStartDate}
                    endDate={customEndDate}
                    placeholderText="Start Date"
                    className="px-2 py-1 border rounded-full bg-blue-100 text-blue-500 border-blue-500"
                  />
                  <DatePicker
                    selected={customEndDate}
                    onChange={(date: Date) => setCustomEndDate(date)}
                    selectsEnd
                    startDate={customStartDate}
                    endDate={customEndDate}
                    minDate={customStartDate}
                    placeholderText="End Date"
                    className="px-2 py-1 border rounded-full bg-blue-100 text-blue-500 border-blue-500"
                  />
                  <button
                    className="px-4 py-2 bg-blue-500 text-white rounded-full"
                    onClick={handleCustomDateRangeSelect}
                  >
                    Apply
                  </button>
                </div>
              )}
            </div>
          ))}
          {selectedFilters.map((filter, index) => {
            const filterType = getFilterType(filter.member);
            const operators =
              filterOperators[filterType] || filterOperators.default;
            return (
              <div
                key={index}
                className="flex items-center space-x-2 flex-nowrap"
              >
                <div className="relative border rounded-full px-2 py-1 cursor-pointer flex items-center bg-blue-100 text-blue-500 border-blue-500">
                  <div className="absolute -top-1/2 left-2 text-[10px] font-semibold text-blue-500">
                    Filter
                  </div>
                  {filter.member}
                  <XMarkIcon
                    className="w-4 h-4 ml-2 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveFilter(index);
                    }}
                  />
                </div>
                <select
                  className="border rounded-full px-2 py-1 bg-blue-100 text-blue-500 border-blue-500"
                  value={filter.operator}
                  onChange={(e) =>
                    handleFilterOperatorChange(index, e.target.value)
                  }
                >
                  {operators.map((operator, opIndex) => (
                    <option key={opIndex} value={operator}>
                      {operator}
                    </option>
                  ))}
                </select>
                <input
                  type="text"
                  className="border rounded-full px-2 py-1 bg-blue-100 text-blue-500 border-blue-500"
                  value={filter.values[0]}
                  onChange={(e) =>
                    handleFilterValueChange(index, e.target.value)
                  }
                />
              </div>
            );
          })}
        </div>
        <div className="relative flex items-center space-x-2">
          <PlusIcon
            className="h-9 w-9 bg-[#E0E6FA] p-2 cursor-pointer rounded-full text-[#0458DD]"
            onClick={handlePlusClick}
          />

          {filtersActive && (
            <XMarkIcon
              className="h-9 w-9 bg-red-500 p-2 cursor-pointer rounded-full text-white"
              onClick={clearFilters ? clearFilters : handleClearFilters}
            />
          )}

          <Collapse
            open={collapseOpen}
            className={`absolute right-20 -top-0 z-50 w-84 rounded-xl ${
              !collapseOpen ? "hidden" : "border border-gray-500"
            }`}
          >
            <Card className="w-full p-0" placeholder={undefined}>
              <CardBody placeholder={undefined} className="!p-0">
                <List placeholder={undefined}>
                  {isChatAI &&
                    measures.some((measure) => measure.measures.length > 0) && (
                      <ListItem
                        onClick={() => toggleCategory("measures")}
                        placeholder={undefined}
                        className={`cursor-pointer ${
                          expandedCategory === "measures" ? "bg-blue-100" : ""
                        }`}
                      >
                        Measures
                      </ListItem>
                    )}
                  {isChatAI &&
                    dimensions.some(
                      (dimension) => dimension.dimensions.length > 0
                    ) && (
                      <ListItem
                        onClick={() => toggleCategory("dimensions")}
                        placeholder={undefined}
                        className={`cursor-pointer ${
                          expandedCategory === "dimensions" ? "bg-blue-100" : ""
                        }`}
                      >
                        Dimensions
                      </ListItem>
                    )}
                  {isChatAI &&
                    segments.some((segment) => segment.segments.length > 0) && (
                      <ListItem
                        onClick={() => toggleCategory("segments")}
                        placeholder={undefined}
                        className={`cursor-pointer ${
                          expandedCategory === "segments" ? "bg-blue-100" : ""
                        }`}
                      >
                        Segments
                      </ListItem>
                    )}
                  {timeDimensions.some(
                    (timeDimension) => timeDimension.timeDimensions.length > 0
                  ) && (
                    <ListItem
                      onClick={() => toggleCategory("timeDimensions")}
                      placeholder={undefined}
                      className={`cursor-pointer ${
                        expandedCategory === "timeDimensions"
                          ? "bg-blue-100"
                          : ""
                      }`}
                    >
                      Time Dimensions
                    </ListItem>
                  )}
                  {filters.some((filter) => filter.filters.length > 0) && (
                    <ListItem
                      onClick={() => toggleCategory("filters")}
                      placeholder={undefined}
                      className={`cursor-pointer ${
                        expandedCategory === "filters" ? "bg-blue-100" : ""
                      }`}
                    >
                      Filters
                    </ListItem>
                  )}
                </List>
              </CardBody>
            </Card>
          </Collapse>
        </div>
      </div>

      <div className="absolute right-5">
        {expandedCategory === "measures" && (
          <Collapse
            open={expandedCategory === "measures"}
            className="relative right-80 -top-0 z-50 border border-gray-500 rounded-xl"
          >
            <Card className="w-auto" placeholder={undefined}>
              <CardBody className="px-4 py-2" placeholder={undefined}>
                {renderOptions(measures, handleMeasureClick, selectedMeasures)}
              </CardBody>
            </Card>
          </Collapse>
        )}
        {expandedCategory === "dimensions" && (
          <Collapse
            open={expandedCategory === "dimensions"}
            className="relative right-80 -top-0 z-50 border border-gray-500 rounded-xl"
          >
            <Card className="w-auto" placeholder={undefined}>
              <CardBody className="px-4 py-2" placeholder={undefined}>
                {renderDimensions(
                  dimensions,
                  handleDimensionClick,
                  selectedDimensions
                )}
              </CardBody>
            </Card>
          </Collapse>
        )}
        {expandedCategory === "segments" && (
          <Collapse
            open={expandedCategory === "segments"}
            className="relative right-80 -top-0 z-50 border border-gray-500 rounded-xl"
          >
            <Card className="w-auto" placeholder={undefined}>
              <CardBody className="px-4 py-2" placeholder={undefined}>
                {renderSegments(segments, handleSegmentClick, selectedSegments)}
              </CardBody>
            </Card>
          </Collapse>
        )}
        {expandedCategory === "timeDimensions" && (
          <Collapse
            open={expandedCategory === "timeDimensions"}
            className="relative right-80 -top-0 z-50 border border-gray-500 rounded-xl"
          >
            <Card className="w-auto" placeholder={undefined}>
              <CardBody className="px-4 py-2" placeholder={undefined}>
                {renderTimeDimensions(timeDimensions, handleTimeDimensionClick)}
              </CardBody>
            </Card>
          </Collapse>
        )}
        {expandedCategory === "filters" && (
          <Collapse
            open={expandedCategory === "filters"}
            className="relative right-80 -top-0 z-50 border border-gray-500 rounded-xl"
          >
            <Card className="w-auto" placeholder={undefined}>
              <CardBody className="px-4 py-2" placeholder={undefined}>
                {renderFilters(filters, handleFilterClick)}
              </CardBody>
            </Card>
          </Collapse>
        )}
      </div>
    </div>
  );
};

export default GlobalFilterBar;

/** @format */

import React from "react";
import { CodeBracketIcon } from "@heroicons/react/24/outline";
import { t } from "i18next";

const VerifyButton = ({ onClick }: any) => {
  return (
    <div className="flex items-center">
      <button
        onClick={onClick}
        className="bg-[#F1EBFF] text-[#5B26D3] flex flex-row items-center text-sm gap-2 ml-2 px-5 py-2.5 font-medium rounded-[45px] text-center transition-colors duration-300"
      >
        <CodeBracketIcon className="w-6 h-6" />
        <p>{t("VERIFY_RESULT")} </p>
      </button>
    </div>
  );
};

export default VerifyButton;

/** @format */

import React, { useEffect, useState } from "react";
import { Card } from "@material-tailwind/react";
import Table from "../Table";
import { areArraysEqual, createFilterOptions, filterData, removeFilterWithData } from "../../utils/ColumnFilterHandler";


interface TableCardProps {
  tableData: any;
  isExpanded?: boolean;
  tableSql?:any
}

export const TableCard = ({
  tableData,
  isExpanded,
}: TableCardProps) => {
  const [columns, setColumns] = useState<string[]>(tableData?.headers || []);
  const [filters, setFilters] = useState<Record<string, string[]>>({});
  const [filterOptions, setFilterOptions] = useState<Record<string, string[]>>(
    {}
  );
  const [filteredData, setFilteredData] = useState<any>([]);

  const columnTypes: Record<string, string> = {
    "Last modification": "timestamp",
  };

  const headers = tableData?.headers;
  const paginatedData = tableData.data.slice(0, 0 + 5);
  const [data, setData] = useState<any>(paginatedData);
  const [itemsPerPage, setItemsPerPage] = useState(
    tableData?.pagination?.limit || 5
  );
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const fetchFilterOptionsAsync = () => {
      const options = createFilterOptions(
        data,
        columnTypes
      );
      setFilterOptions(options);
    };

    fetchFilterOptionsAsync();
  }, []);

  useEffect(() => {
    const newFilteredData = filterData(tableData.data, filters);
    setFilteredData(newFilteredData);
  }, [data, filters]);

  useEffect(() => {
    const newOffset = (currentPage - 1) * itemsPerPage;
    const paginatedData = filteredData.slice(newOffset, newOffset + itemsPerPage);
    if (!areArraysEqual(paginatedData, data)) {
      setData(paginatedData);
    }

  }, [itemsPerPage, currentPage, filteredData]);

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  return (
    <div className="w-full h-full">
      {headers && data ? (
        <>
          <Card
            className={`w-full overflow-y-auto ${isExpanded ? "!max-h-[70vh]" : "h-full"}`}
            placeholder={undefined}
          >
            <Table
              headers={columns.map((col) =>
                col
                  .split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")
              )}
              rows={data.map((row: any) => {
                return columns.reduce((acc: Record<string, any>, column) => {
                  acc[
                    column
                      .split("_")
                      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(" ")
                  ] = row[column];
                  return acc;
                }, {});
              })}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={tableData?.pagination?.total || tableData.data.length}
              onItemsPerPageChange={handleItemsPerPageChange}
              onPageChange={handlePageChange}
              filters={filters}
              fetchedData={data}
              applyFilterFinal={(column: string, value: string[]) => {
                setFilters(prevFilters => ({
                  ...prevFilters,
                  [column]: value
                }));
              }}
              removeFilterWithData={(column:any) => {
                const newFilterOptions = removeFilterWithData(
                  data,
                  filters,
                  column,
                  columnTypes
                );
                setFilterOptions((prevOptions) => ({
                  ...prevOptions,
                  ...newFilterOptions,
                  [column]: prevOptions[column], 
                }));
                setFilters((prevFilters) => {
                  const { [column]: removed, ...rest } = prevFilters;
                  return rest;
                });
                setCurrentPage(1);
              }}
            filterOptions={filterOptions}
            columnTypes={columnTypes} 
            />
          </Card>
        </>
      ) : (
        <div>No data available</div>
      )}
    </div>
  );
};

/** @format */

import React from "react";
import { ReactComponent as TitleIcon } from "../../assets/icons/title_icon.svg";
import {
  SquaresPlusIcon,
  DocumentIcon,
  SparklesIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Dialog, DialogHeader, Typography } from "@material-tailwind/react";
import { t } from "i18next";

interface SideMenuProps {
  iconStyle: string;
  hoverStyle: string;
  handleAddCardFromLibrary: () => void;
  handleAddCardFromAI: () => void;
  addSectionOrCard: (
    type: "Title" | "Normal Card" | "Small Card" | "Table"
  ) => void;
}

const SideMenu: React.FC<SideMenuProps> = ({
  iconStyle,
  hoverStyle,
  handleAddCardFromLibrary,
  handleAddCardFromAI,
  addSectionOrCard,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(!open);

  const addFromLibrary = () => {
    handleOpen();
    handleAddCardFromLibrary();
  };

  const addFromAI = () => {
    handleOpen();
    handleAddCardFromAI();
  };
  return (
    <>
      <div
        className="mr-6 transform -translate-y-1/2 w-16 flex flex-col justify-center items-center py-2 "
        style={{ position: "fixed", top: "50%" }}
      >
        <div className="flex flex-col bg-white p-4 rounded-2xl border-y border-r gap-8">
          <button className="my-2" onClick={handleOpen}>
            <SquaresPlusIcon className={`${iconStyle} ${hoverStyle}`} />
          </button>
          <button className="my-2" onClick={() => addSectionOrCard("Title")}>
            <TitleIcon className="w-5 h-5 hover:text-gray-700" />
          </button>
        </div>
      </div>
      <Dialog
        open={open}
        handler={handleOpen}
        placeholder={undefined}
        className="p-4 rounded-3xl"
      >
        <DialogHeader
          className="flex justify-between items-center"
          placeholder={undefined}
        >
          <span>{t("CREATE_ELEMENTS")}</span>
          <XMarkIcon
            className="w-8 h-8 text-[#76787D] cursor-pointer"
            onClick={handleOpen}
          />
        </DialogHeader>
        <div className="flex flex-col p-4 gap-8">
          <Typography placeholder={undefined}>{t("HOW_CREATE_ELEMENTS")}</Typography>
          <div className="flex justify-between gap-8 w-full">
            <div
              className="group flex flex-col rounded-2xl w-full gap-3 p-6 border hover:bg-[#F1EBFF] hover:border-[#D3BFFF]"
              onClick={addFromAI}
            >
              <SparklesIcon className="w-12 h-12 bg-[#F2F5FF] text-[#0458DD] group-hover:bg-[#F1EBFF] group-hover:text-[#5B26D3] p-2 rounded-lg" />
              <Typography
                className="font-medium text-lg group-hover:text-[#5B26D3]"
                placeholder={undefined}
              >
                {t("CREATE_WITH_AI")}
              </Typography>
              <Typography
                className="font-base text-sm group-hover:text-[#5B26D3]"
                placeholder={undefined}
              >
                {t("CREATE_MULTIPLE_WITH_AI")}
              </Typography>
            </div>
            <div
              className="group flex flex-col rounded-2xl w-full gap-3 p-6 border hover:bg-[#F1EBFF] hover:border-[#D3BFFF]"
              onClick={addFromLibrary}
            >
              <DocumentIcon className="w-12 h-12 bg-[#F2F5FF] text-[#0458DD] group-hover:bg-[#F1EBFF] group-hover:text-[#5B26D3] p-2 rounded-lg" />
              <Typography
                className="font-medium text-lg group-hover:text-[#5B26D3]"
                placeholder={undefined}
              >
               {t("CHOOSE_FROM_LIBRARY")}
              </Typography>
              <Typography
                className="font-base text-sm group-hover:text-[#5B26D3]"
                placeholder={undefined}
              >
                {t("CHOOSE_ELEMENTS_FROM_LIBRARY")}
              </Typography>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default SideMenu;

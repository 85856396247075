/** @format */

import React, { useState, useEffect, useRef } from "react";
import {
  EllipsisVerticalIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid";
import {
  InfoCard,
  ChartCard,
  TableCard,
  ExpandedCard,
  TitleCard,
} from "../../components/Cards";
import { privateSupabase } from "../../api/SupabaseClient";
import toast from "react-hot-toast";
import { useDownload } from "../../hooks/useDownload";
import CardOptionsMenu from "../../components/Cards/CardOptionsMenu";
import { EditElementCard } from "../../components/Cards/EditChartCard";
import { t } from "i18next";

export const CardComponent = ({
  item,
  itemIndex,
  deleteItem,
  duplicateItem,
  handleTitleChange,
  handleIconChange,
  handleSizeChange,
  isEditMode,
  titlesWithError,
  isMainDashboard,
  isLibrary,
  isSelect,
  setEditElement
}: any) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [chartItemData, setChartItemData] = useState<any>();
  const [showTooltip, setShowTooltip] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const cardRef = useRef<HTMLDivElement>(null);
  const downloadButtonRef = useRef<HTMLDivElement>(null);
  const editButtonRef = useRef<HTMLDivElement>(null);
  const { downloadCSV, downloadPDF, downloadPNG } = useDownload(cardRef, item);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleDuplicate = () => {
    duplicateItem(itemIndex);
    setIsDropdownOpen(false);
  };

  const handleDelete = () => {
    deleteItem(itemIndex);
    setIsDropdownOpen(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedTitle = e.target.value;
    handleTitleChange(updatedTitle, item.id);
  };

  const handleDownloadOptionClick = async (option: string) => {
    if (downloadButtonRef.current) {
      downloadButtonRef.current.style.visibility = "hidden";
    }

    switch (option) {
      case "CSV":
        downloadCSV();
        break;
      case "PDF":
        await downloadPDF();
        break;
      case "PNG":
        await downloadPNG();
        break;
      default:
        console.log(`Unhandled option: ${option}`);
    }

    if (downloadButtonRef.current) {
      downloadButtonRef.current.style.visibility = "visible";
    }

    setIsDropdownOpen(false);
  };

  const handleEditOptionClick = async (option: string) => {
    if (editButtonRef.current) {
      editButtonRef.current.style.visibility = "hidden";
    }

    switch (option) {
      case "Small":
      case "Medium":
      case "Large":
        await updateSize(option);
        const itemId = item.data.id;
        handleSizeChange(option, itemId);
        break;
      default:
        console.error(`Unhandled option: ${option}`);
    }

    if (editButtonRef.current) {
      editButtonRef.current.style.visibility = "visible";
    }
    setIsDropdownOpen(false);
  };

  const deleteElement = async () => {
    try {
      const { data: libraryData, error: libraryError } = await privateSupabase
        .from("library")
        .delete()
        .eq("id", item.id);
      if (libraryError) {
        toast.error("Error while deleting element");
        throw libraryError;
      }
      const { data: reportsData, error: reportsFetchError } =
        await privateSupabase.from("reports_dashboard").select("id, items");

      if (reportsFetchError) {
        toast.error("Error while fetching reports_dashboard");
        throw reportsFetchError;
      }

      for (const report of reportsData) {
        let itemsUpdated = false;
        const updatedItems = report.items.map((subItem: any) => {
          if (subItem.items && subItem.items.length > 0) {
            const filteredItems = subItem.items.filter(
              (itemId: number) => itemId !== item.id
            );
            if (filteredItems.length !== subItem.items.length) {
              itemsUpdated = true;
            }
            return { ...subItem, items: filteredItems };
          }
          return subItem;
        });

        if (itemsUpdated) {
          const { data, error: reportsUpdateError } = await privateSupabase
            .from("reports_dashboard")
            .update({ items: updatedItems })
            .eq("id", report.id)
            .select();

          if (reportsUpdateError) {
            toast.error("Error while updating reports_dashboard");
            throw reportsUpdateError;
          }
        }
      }
      toast.success("Card deleted successfully");
      deleteItem(item.id);
    } catch (error) {
      console.log(error);
    }
  };

  const updateSize = async (option: string) => {
    let id = item.id;
    if (item.id === undefined) {
      id = item.data.id;
    }
    try {
      const { data, error } = await privateSupabase
        .from("library")
        .update({ size: option })
        .eq("id", id)
        .select();

      if (error) {
        throw error;
      }
      item.size = option;
      toast.success("Size changed successfully");
    } catch (error) {
      toast.error(t("ERROR") + "EO-1100");
    }
  };

  const openEdit = (item: any) => {
    setChartItemData(item);
    setIsEditOpen(!isEditOpen);
    setEditElement(true);
    setIsDropdownOpen(false);
  };

  const handleIconSelect = (iconKey: string) => {
    handleIconChange(iconKey, item.sectionIndex);
  };

  const renderContent = () => {
    if (!item.data) return null;
    const itemData = item.data;
    const itemName = item.title;
    const itemId = item.id;
    const itemType = item.type;

    if (itemType === "table") {
      const tableData = itemData.tableData;
      const tableDataWithId = {
        ...tableData,
        id: itemId,
      };
      const headers = tableDataWithId?.headers;
      return (
        <div
          className="flex flex-col p-4 border rounded-2xl shadow-md w-full h-full"
          style={{ background: "white" }}
        >
          <div className="text-left font-semibold mb-4 h-auto">{itemName}</div>
          {headers ? (
            <TableCard
              tableData={{ ...tableDataWithId, data: tableData.data }}
              tableSql={item.query}
            />
          ) : (
            <div>No data available</div>
          )}
        </div>
      );
    }

    if (itemType === "datapoint") {
      return (
        <div
          className="flex flex-col p-4 border rounded-2xl shadow-md w-full h-full"
          style={{ background: "white" }}
        >
          <div className="text-left font-semibold mb-4">{itemName}</div>
          <InfoCard
            key={itemData.title}
            title={itemData.title}
            data={itemData}
            percentage={0}
            isPercentagePositive={true}
          />
        </div>
      );
    }

    if (itemType === "chart") {
      if (!itemData || !itemData.datasets) {
        console.error("Invalid chart data", itemData);
        return <div>Invalid chart data</div>;
      }

      let dataToUse;
      if (chartItemData !== undefined) {
        dataToUse = chartItemData.data;
      } else {
        dataToUse = itemData;
      }

      return (
        <div
          className="flex flex-col p-4 border rounded-2xl shadow-md w-full h-full"
          style={{ background: "white" }}
        >
          <div className="text-left font-semibold mb-4 h-auto">{itemName}</div>
          <ChartCard
            data={dataToUse}
            options={dataToUse?.options}
            type={dataToUse?.type}
            colors={item?.colors}
            labels={item?.labels}
            isEditMode={isEditMode}

          />
        </div>
      );
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  const hasOverwrittenFilters =
    item.initialFilters &&
    (item.initialFilters.filters.length > 0 ||
      item.initialFilters.timeDimensions.length > 0);

  const renderTooltipContent = () => {
    return (
      <div className="bg-gray-700 text-white text-sm rounded p-2 shadow-lg">
        <div>
          <strong>Overwritten Filters:</strong>
        </div>
        {item.initialFilters.filters.length > 0 && (
          <div>
            <strong>Filters:</strong>
            <ul className="list-disc list-inside">
              {item.initialFilters.filters.map((filter: any, index: number) => (
                <li key={index}>
                  {filter.dimension}: {filter.operator} {filter.value}
                </li>
              ))}
            </ul>
          </div>
        )}
        {item.initialFilters.timeDimensions.length > 0 && (
          <div>
            <strong>Time Dimensions:</strong>
            <ul className="list-disc list-inside">
              {item.initialFilters.timeDimensions.map(
                (timeDimension: any, index: number) => (
                  <li key={index}>{timeDimension.dimension}</li>
                )
              )}
            </ul>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      key={`${item.type}-${itemIndex}`}
      className="flex flex-col items-center h-full"
      style={{ height: item.type === "title" ? "auto" : "h-full" }}
      ref={cardRef}
    >
      {item.type === "title" ? (
        <TitleCard
          item={item}
          isEditMode={isEditMode}
          handleIconSelect={handleIconSelect}
          handleDelete={handleDelete}
          handleChange={handleChange}
          titlesWithError={titlesWithError}
          isMainDashboard={isMainDashboard}
        />
      ) : (
        <div className="flex items-center justify-center w-full h-full relative">
          {!isEditOpen && (renderContent())}
          <div
            className="absolute top-2 right-0 m-2 flex items-center"
            ref={dropdownRef}
          >
            {/* {hasOverwrittenFilters && (
              <div
                className="relative flex items-center mr-2"
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <ExclamationTriangleIcon className="h-6 w-6 text-yellow-500 cursor-pointer" />
                {showTooltip && (
                  <div className="absolute top-8 right-0 z-20">
                    {renderTooltipContent()}
                  </div>
                )}
              </div>
            )} */}

            {!isSelect && (
              <div className="relative" ref={downloadButtonRef}>

                <EllipsisVerticalIcon
                  className="h-6 w-6 cursor-pointer"
                  onClick={toggleDropdown}
                />
                {isDropdownOpen && item.type !== "title" && (
                  <CardOptionsMenu
                    isEditMode={isEditMode}
                    handleDuplicate={handleDuplicate}
                    handleDelete={handleDelete}
                    setIsDialogOpen={setIsDialogOpen}
                    deleteElement={deleteElement}
                    openEdit={() => {
                      openEdit(item);
                    }}
                    handleDownloadOptionClick={handleDownloadOptionClick}
                    type={item.data.type}
                    handleEditOptionClick={handleEditOptionClick}
                    isLibrary={isLibrary}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}
      <ExpandedCard
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        source={item}
      />
      {chartItemData && (
        <EditElementCard
          isOpen={isEditOpen}
          onClose={() => { setEditElement(false); setIsEditOpen(false); }}
          originalType={item.type}
          source={item}
        />
      )}
    </div>
  );
};

export default CardComponent;

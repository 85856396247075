import React, { useCallback, useEffect, useState } from "react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import { t } from "i18next";
import { privateSupabase, publicSupabase } from "../../../../api/SupabaseClient";
import toast from "react-hot-toast";
import useReloadState from "../../../../context/contextReload/contextHook";
import Table from "../../../../components/Table";
import { formatDistanceToNow } from "date-fns";
import FilterComponent from "../../../../components/FilterComponent";
import InviteMembers from "./InviteMembers";
import MainButton from "../../../../components/Buttons/MainButton";
import FilterButton from "../../../../components/Buttons/FilterButton";
import { useSupabase } from "../../../../context/supabaseContext";
import DeleteUserModal from "./DeleteUserModal";
import DeleteIconButton from "../../../../components/Buttons/DeleteIconButton";
import LoadingSpinner from "../../../../components/LoadingSpinner";
interface FilterOptions {
  [key: string]: string;
}

interface MemberDetailsProps {
  role: number
}

const MemberDetails = ({ role }: MemberDetailsProps) => {
  const reloadState = useReloadState();
  const [session, setSession] = useState<any>(null);
  const [users, setUsers] = useState<any>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [openDropdownIndex, setOpenDropdownIndex] = useState<null | number>(null);
  const [searchInput, setSearchInput] = useState("");
  const [filterValues, setFilterValues] = useState({});
  const [roles, setRoles] = useState<any>([]);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rowData, setRowData] = useState<any>();
  const { userSession } = useSupabase();

  const handleDelete = (rowData: any) => {
    setOpenDropdownIndex(null);
    setRowData(rowData)
    setDeleteModalOpen(true);
  };


  useEffect(() => {
    const handleAuthStateChange = (_event: any, newSession: any) => {
      setSession(newSession);
    };

    userSession().then((session: any) => {
      setSession(session.data.session);
    });

    privateSupabase.auth.onAuthStateChange(handleAuthStateChange);
  }, []);

  useEffect(() => {
    if (session) {
      fetchUsers(session.user.email);
      fetchRoles();
    }
  }, [session, reloadState.status]);

  useEffect(() => {
    if (session) {
      fetchUsers(session.user.email, { ...filterValues, search: searchInput });
    }
  }, [filterValues, searchInput, session]);


  const fetchRoles = async () => {
    try {
      const { data: roles, error } = await privateSupabase.from("roles").select("*");
      if (error) throw error;
      setRoles(roles);
    } catch (error) {
      toast.error(t("ERROR") + "EO-1053");
    }
  };


  const fetchUsers = async (
    excludeEmail: string,
    filters: FilterOptions = {}
  ) => {
    try {
      setLoading(true);
      // Fetch users from the view in the public Supabase
      const { data: projectUsersData, error: projectUsersError } = await publicSupabase
        .from("v_project_users")
        .select("*")

      if (projectUsersError) throw projectUsersError;

      const projectEmails = projectUsersData.map(user => user.email);

      if (!projectEmails.length) {
        setUsers([]);
        setTotalItems(0);
        return;
      }

      // Fetch profiles from the private Supabase
      let query = privateSupabase
        .from("profiles")
        .select("*", { count: "exact" })
        .in("email", projectEmails)

      Object.entries(filters).forEach(([key, value]) => {
        if (!value) return;
        if (key === "search") {
          query = query.ilike("username", `%${value}%`);
        } else {
          query = query.ilike(key, `%${value}%`);
        }
      });

      const { data: profiles, error: errorProfiles, count } = await query;

      if (errorProfiles) throw errorProfiles;

      if (filters.search) {
        const profilesWithPending = profiles.map(profile => {
          const user = projectUsersData.find(user => user.email === profile.email);
          return {
            ...profile,
            pending: user ? user.pending : false
          };
        });
        setUsers(profilesWithPending || []);
        setTotalItems(profilesWithPending.length);
      } else {
        const from = (currentPage - 1) * itemsPerPage;
        const to = from + itemsPerPage - 1;

        const paginatedQuery = query.range(from, to);
        const { data: paginatedProfiles, error: paginatedError, count: paginatedCount } = await paginatedQuery;

        if (paginatedError) throw paginatedError;

        const profilesWithPending = paginatedProfiles.map(profile => {
          const user = projectUsersData.find(user => user.email === profile.email);
          return {
            ...profile,
            pending: user ? user.pending : false
          };
        });

        setUsers(profilesWithPending || []);
        setTotalItems(paginatedCount || 0);
      }

    } catch (error: any) {
      toast.error(t("ERROR") + "EO-1059");
    } finally {
      setLoading(false);
    }
  };


  const renderCustomColumn = (rowData: any, index: number) => {
    return (
      <div>
        {role === 1 ? (
          <select
            value={rowData.role}
            onChange={(e) => {
              handleRoleChange(rowData.id, e.target.value);
            }}
          >
            {roles.map((role: any) => (
              <option key={role.id} value={role.id}>
                {t(role.role_description)}
              </option>
            ))}
          </select>
        ) : (
          t(rowData?.role_description)
        )
        }
      </div>
    );
  };

  const handleRoleChange = async (userId: string, newRoleId: any) => {
    const { data, error } = await privateSupabase
      .from("profiles")
      .update({ role: Number(newRoleId) })
      .eq("id", userId);

    if (error) {
      toast.error(t("ERROR") + "EO-1060");
      console.error("Failed to update role:", error);
    } else {
      toast.success("Role updated successfully");
    }
  };

  const getRelativeTime = (timestamp: any) => {
    return formatDistanceToNow(new Date(timestamp), { addSuffix: true });
  };
  //const headers = [t("NAME"), t("EMAIL_ADDRESS"), t("LAST_ACTIVE"), t("TEAM_PERMISSIONS"), ""];

  const generateRows = () =>
    users.map((user: any, index: number) => ({
      id: user.id,
      Name: user.username,
      "Email Address": user.email,
      "Last Active": user.pending ? (
        <span className="text">Pending</span>
      ) : (
        <span className="text-[#0059AD]">
          {getRelativeTime(user.last_update)}
        </span>
      ),
      "Team Permissions": renderCustomColumn(user, user.id),
      "": renderActions(user, index)
    }));

  const headers = ["Name", "Email Address", "Last Active", "Team Permissions", ""];

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleFilterButtonClick = () => {
    setShowFilterOptions(!showFilterOptions);
  };

  const getAvailableFiltersForCurrentTable = () => {
    return ["username", "email"];
  };

  const getPlaceHoldersForCurrentTable = () => {
    return ["Name", "Email"];
  };

  const handleFilterChange = useCallback(
    (filterName: string, value: string) => {
      setFilterValues((prev) => ({ ...prev, [filterName]: value }));
    },
    []
  );

  const onSearchChange = useCallback((e: any) => {
    setSearchInput(e.target.value);
  }, []);

  const renderActions = (rowData: any, rowIndex: number) => (
    rowData.email !== session?.user.email && (
      <div className="relative inline-block text-left">
        <button
          className="inline-flex w-full justify-center"
          onClick={(e) => {
            e.stopPropagation();
            setOpenDropdownIndex(openDropdownIndex === rowIndex ? null : rowIndex);
          }}
        >
          <EllipsisVerticalIcon className="h-6 w-6 cursor-pointer" />
        </button>
        {openDropdownIndex === rowIndex && (
          <div className="absolute left-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-10">
            <div className="py-1">
              <DeleteIconButton showText={true} onDelete={() => handleDelete(rowData)} />
            </div>
          </div>
        )}
      </div>
    )
  );

  return (
    <div
      className={`flex flex-col justify-between bg-white rounded-2xl mx-6 pb-12}`}
      style={{ overflowY: "auto", maxHeight: "calc(100vh - 120px)" }}
    >
      <div className="flex flex-col justify-between bg-white rounded-2xl mx-6">
        <div className="flex flex-row bg-white justify-between pb-12 pt-4 mt-6">
          <div className="flex justify-start space-x-4 items-center">
            <FilterComponent
              availableFilters={getAvailableFiltersForCurrentTable()}
              placeHolders={getPlaceHoldersForCurrentTable()}
              filterValues={filterValues}
              onFilterChange={handleFilterChange}
              searchInput={searchInput}
              onSearchChange={onSearchChange}
              showFilters={showFilterOptions}
              showSearch={true}
            />
            <div className="flex flex-row space-x-4 ">
              <FilterButton onClick={handleFilterButtonClick} />
            </div>
          </div>
          <div className="flex justify-start items-center my-auto gap-4">
            {role === 1 &&
              <MainButton buttonText={"INVITE_MEMBERS"} onClick={() => setInviteDialogOpen(true)} />
            }
          </div>

        </div>
        {loading ? (
          <LoadingSpinner message="LOADING" />
        ) : (
          <Table
            headers={headers}
            rows={generateRows()}
            currentPage={currentPage}
            totalItems={totalItems}
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        )}
      </div>
      <InviteMembers
        inviteDialogOpen={inviteDialogOpen}
        setInviteDialogOpen={setInviteDialogOpen}
        roles={roles}
      />
      <DeleteUserModal open={deleteModalOpen} handler={setDeleteModalOpen} email={rowData?.email} fetchUsers={() => fetchUsers(session.user.email)} ></DeleteUserModal>
    </div>
  );
};

export default MemberDetails;

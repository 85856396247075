import { useContext } from "react";
import ReloadContext from "./context";

export default function useReloadState() {
    const context = useContext(ReloadContext);

    if (context === undefined) {
        throw new Error("useReloadState must be used within a ReloadProvider");
    }

    const { reloadState = {}, setReloadState } = context;
    const { status } = reloadState;

    const setReloadStatus = (c) => {
        setReloadState({ status: c});
    };

    return { status, setReloadStatus };
}

/** @format */

import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import { useAuth } from "./hooks/useAuth";
import routes from "./routes";
import { useFilter } from "./context";
import OmniChat from "./pages/OmniChat";
import CustomToast from "./components/CustomToast";
import Navbar from "./components/Navbar/Navbar";

function App() {
  const { userId, loading } = useAuth();
  const location = useLocation();
  const {
    openCreateCustom,
    openEditDashboard,
  } = useFilter();
  const excludedPaths = [
    "/sign-in",
    "/sign-up",
    "/logout",
    "/invited-member",
    "/reset-password",
    "/change-password",
  ];
  const isPathExcluded = excludedPaths.some((path) =>
    location.pathname.startsWith(path)
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!userId && !isPathExcluded) {
    return <Navigate to="/sign-in" replace />;
  }

  return (
    <div className="bg-[#F2F5FF] flex h-screen overflow-hidden">
      {!isPathExcluded && !openCreateCustom && !openEditDashboard && <Sidebar />}
      <div
        className="flex-1 flex flex-col overflow-x-auto"
        style={{ width: "-webkit-fill-available" }}
      >
        {!isPathExcluded && !openCreateCustom && !openEditDashboard && <Navbar />}
        {openCreateCustom && (
          <OmniChat />
        )}
        {!openCreateCustom && (
          <>
            <div className="flex-1 overflow-auto h-[calc(100vh-96px)]">
              <CustomToast />
              <Routes>
                {routes.map(({ path, element }, key) => (
                  <Route key={key} path={path} element={element} />
                ))}
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default App;

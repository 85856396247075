/** @format */

import { CodeBracketIcon } from "@heroicons/react/24/outline";
import { Spinner } from "@material-tailwind/react";
import { t } from "i18next";

const VerifyButton = ({
  currentView,
  setCurrentView,
  typeCustom,
  table,
  setTable,
  isLoading,
}) => {
  const handleClick = () => {
    if (currentView === "data") {
      setCurrentView("table");
      setTable(table);
    } else {
      setTable(table);
      setCurrentView("data");
    }
  };
  return (
    <div className="flex items-center">
      <button
        onClick={handleClick}
        className={`${
          currentView === "data"
            ? "bg-[#F1EBFF] text-[#5B26D3] text-l ml-14 px-6 py-3"
            : "bg-[#F1EBFF] text-[#5B26D3] flex flex-row items-center text-sm gap-2 ml-2 px-5 py-2.5"
        } font-medium rounded-[45px] text-center transition-colors duration-300`}
        disabled={isLoading}
      >
        {currentView === "data" ? (
          "Go back"
        ) : (
          <>
            {isLoading && <Spinner className="h-4 w-4 mr-2" />}
            <CodeBracketIcon className="w-6 h-6" />
            <p>{t("VERIFY_RESULT")}</p>
          </>
        )}
      </button>
    </div>
  );
};

export default VerifyButton;

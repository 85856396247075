/** @format */

import React, { useState } from "react";
import {
  Button,
  Input,
  Typography,
  Textarea,
} from "@material-tailwind/react";
import toast from "react-hot-toast";
import { createGoogleSheetSource } from "../../../api/airbyte/apiFunctions";
import { UUID } from "crypto";
import ApiConnectionStreamsDialog from "./ApiConnectionStreamsDialog";
import { GoogleSheetsServiceParams, GoogleSheetsClientParams } from "../../../api/airbyte/types/googleSheets";
import { t } from "i18next";


export const ApiConnectionGoogleSheetsDialog = ({ handleBack }: any) => {

  //const [authMethod, setAuthMethod] = useState<"Service" | "Client">("Client");
  const [formState, setFormState] = useState<any>({
    service_account_info: "",
    connectionName: "",
    /*client_id: "",
    client_secret: "",
    refresh_token: "",*/
    spreadsheet_id: "",
  });
  const [validationErrors, setValidationErrors] = useState<any>({});

  const [sourceId, setSourceId] = useState<string>("");
  const [connectionName, setConnectionName] = useState<string>("");
  const [isSelectStreams, setSelectStreams] = useState<boolean>(false);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target ? e.target : e;
    setFormState((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    // Remove any existing validation error for this field
    if (validationErrors[name]) {
      setValidationErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: false,
      }));
    }
  };


  /*const handleSelect = (e: any) => {
    setAuthMethod(e);
  };*/
  

  const validateForm = () => {
    const errors: any = {};

    let requiredFields = ["connectionName", "spreadsheet_id"]; // Campo común

    /*if (authMethod === "Client") {
      requiredFields = [
        ...requiredFields,
        "client_id",
        "client_secret",
        "refresh_token",
      ];*/
    //} else { //Service
      requiredFields = [
        ...requiredFields,
        "service_account_info",
      ];
    //}

    requiredFields.forEach(field => {
      if (!formState[field] || formState[field].trim() === "") {
        errors[field] = "This field is required"; 
      }
    });

    return errors;
  };

  const handleSubmit = async () => {

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      toast.error("Please fill in all required fields.");
      return;
    }

    let sourceParams: GoogleSheetsClientParams | GoogleSheetsServiceParams;
    /*if (authMethod === "Client") {
      sourceParams = {
        auth_type: 'Client',
        client_id: formState.client_id,
        client_secret: formState.client_secret,
        refresh_token: formState.refresh_token,
        workspaceId: `1a858109-a63d-4a61-8faf-bdfa3aed5e0b` as UUID,
        connectionName: formState.connectionName,
        spreadsheet_id: formState.spreadsheet_id,
        names_conversion: true
      }*/
    //} else { //Service
      sourceParams = {
        auth_type: 'Service',
        service_account_info: formState.service_account_info,
        workspaceId: `1a858109-a63d-4a61-8faf-bdfa3aed5e0b` as UUID,
        connectionName: formState.connectionName,
        spreadsheet_id: formState.spreadsheet_id,
        names_conversion: true
      }
    //}
    const GoogleSheetsSource = await createGoogleSheetSource(sourceParams);
    setSourceId(GoogleSheetsSource.sourceId)

    setConnectionName(formState.connectionName)
    setSelectStreams(true);
  };

  if (isSelectStreams) {
    return (
      <ApiConnectionStreamsDialog sourceId={sourceId} connectionName={connectionName} handleClose={handleBack} />
    );
  }

  return (
      
    <div className="p-4 bg-gray-100 max-h-[1000px] overflow-y-auto">
          <div className="flex flex-col border p-4 rounded-xl bg-white shadow mb-4">
            <Typography className="text-xs my-1" placeholder={undefined}  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              Source name
            </Typography>
            <Input
              label="Source Name"
              name="sourceName"
              value="Google Sheets"
              crossOrigin={undefined}
              disabled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            />
          </div>
          <div className="flex flex-col border p-4 rounded-xl bg-white shadow mb-4">
            <Typography className="text-xs my-1" placeholder={undefined}  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              Connection name
            </Typography>
            <Input
              label=""
              name="connectionName"
              onChange={handleInputChange}
              crossOrigin={undefined}
              error={!!validationErrors.connectionName}
              success={!validationErrors.connectionName && formState.connectionName !== ""}
              required onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            />
          </div>
          
          {/*<div className="flex flex-col border p-4 rounded-xl bg-white shadow mb-4">
            <Select
              label="Authentication Method"
              name="authMethod"
              value={authMethod}
              onChange={handleSelect}
              placeholder={undefined}
            >
              <Option value="apiKey">API Key</Option>
              <Option value="oauth">OAuth 2.0</Option>
            </Select>
      </div>*/}
          <div className="flex flex-col border p-4 rounded-xl bg-white shadow mb-4">
            {/*authMethod === "Client" && (
              <div>
                <Typography className="text-xs my-1" placeholder={undefined}>
                  Client ID
                </Typography>
                <Input required name="client_id" error={!!validationErrors.client_id} success={!validationErrors.client_id && formState.client_id !== ""} onChange={handleInputChange} crossOrigin={undefined} />
                <Typography className="text-xs my-1" placeholder={undefined}>
                  Client Secret
                </Typography>
                <Input required name="client_secret" type="password" error={!!validationErrors.client_secret} success={!validationErrors.client_secret && formState.client_secret !== ""} onChange={handleInputChange} crossOrigin={undefined} />
                <Typography className="text-xs my-1" placeholder={undefined}>
                  Refresh Token
                </Typography>
                <Input required name="refresh_token" error={!!validationErrors.refresh_token} success={!validationErrors.refresh_token && formState.refresh_token !== ""} onChange={handleInputChange} crossOrigin={undefined} />
              </div>
            )
            */
            //authMethod === "Service" && (
              <div>
                <Typography className="text-xs my-1" placeholder={undefined}  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  Service Acount JSON
                </Typography>
                <Textarea name="service_account_info" error={!!validationErrors.service_account_info} success={!validationErrors.service_account_info && formState.service_account_info !== ""} onChange={handleInputChange} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
              </div>
            //)}
          }
          </div>
          <div className="flex flex-col border p-4 rounded-xl bg-white shadow mb-4">
            <Typography className="text-xs my-1" placeholder={undefined}  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              Spread Sheet ID
            </Typography>
            <Input
              label=""
              name="spreadsheet_id"
              onChange={handleInputChange}
              crossOrigin={undefined}
              error={!!validationErrors.spreadsheet_id}
              success={!validationErrors.spreadsheet_id && formState.spreadsheet_id !== ""}
              required onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            />
          </div>
          <div className="flex justify-end space-x-4">
        <Button className="text-blue-500 border border-blue-500 bg-white hover:bg-blue-50 rounded-lg" onClick={handleBack} variant="outlined" placeholder={undefined}>
          {t("BACK")}
        </Button>
        <Button className="border border-blue-500 text-white bg-blue-500 hover:bg-blue-700 rounded-lg" color="blue" onClick={handleSubmit} placeholder={undefined}>
          {t("SAVE")}
        </Button>
      </div>
        </div>
  );
};
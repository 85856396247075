/** @format */

import { t } from "i18next";
import React, { useEffect, useState } from "react";
import Profile from "./Profile/Profile";
import { privateSupabase, userSession } from "../../../api/SupabaseClient";
import { useSupabase } from "../../../context/supabaseContext";

let initialProfileData = {
  birthdate: "",
  city: "",
  postal_code: "",
  address: "",
  email: "",
  job_title: "",
  company: "",
  mobile: "",
  country: "",
  profile_picture: "",
  language: "",
  role: "",
  full_name: "",
};

export const defaultPlaceholder = "https://via.placeholder.com/250";
export function ProfileDetails() {
  const { getUserRole } = useSupabase();
  const [role, setRole] = useState<number>(3);
  const [pictureName, setPictureName] = useState("");
  const [profileDetails, setProfileDetails] = useState<any>(initialProfileData);
  const [session, setSession] = useState<any>(null);

  useEffect(() => {
    const handleAuthStateChange = (_event: any, newSession: any) => {
      setSession(newSession);
    };

    userSession().then((session: any) => {
      setSession(session.data.session);
    });

    privateSupabase.auth.onAuthStateChange(handleAuthStateChange);
  }, []);

  const getProfileDetails = async () => {
    try {
      const { data, error } = await privateSupabase
        .from("profiles")
        .select(
          `
            *,
            role:roles(role_description),
            company:company_details(company)
          `
        )
        .eq("id", session.user.id)
        .single();
      if (error) throw error;

      setProfileDetails(data);
      setPictureName(data.profile_picture);
      window.localStorage.setItem("i18nextLng", data.language);
    } catch (error) {
      console.error("ERROR: ", error);
    }
  };

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      await getProfileDetails();
    };
    fetchCompanyDetails();
  }, [session]);

  useEffect(() => {
    const fetchUserRole = async () => {
      const userRole = await getUserRole();
      setRole(userRole);
    };
    fetchUserRole();
  }, [session]);

  return (
    <div
      className="w-full pb-12 px-12 pt-4 mt-6"
      style={{ overflowY: "auto", maxHeight: "calc(100vh - 120px)" }}
    >
      <div className="tab-mainMetrics">
        <Profile
          profileDetails={profileDetails}
          setProfileDetails={setProfileDetails}
          pictureName={pictureName}
          setPictureName={setPictureName}
        />
      </div>
    </div>
  );
}

import React, { useState } from "react";
import {
  Button,
  Input,
  Typography,
} from "@material-tailwind/react";
import toast from "react-hot-toast";
import { createPostgresSource } from "../../../api/airbyte/apiFunctions";
import { UUID } from "crypto";
import ApiConnectionStreamsDialog from "./ApiConnectionStreamsDialog";
import { PostgresSourceParams } from "../../../api/airbyte/types/postgres";
import { t } from "i18next";

export const ApiConnectionPostgresDialog = ({ handleBack }: any) => {
  const [formState, setFormState] = useState<any>({
    connectionName: "",
    schema: "",
    host: "",
    port: 5432,
    databaseName: "",
    username: "",
    password: "",
    sslMode: "require",
    sshTunnelMethod: "No Tunnel",
    replicationSlot: "",
    publication: "",
  });
  const [validationErrors, setValidationErrors] = useState<any>({});
  const [sourceId, setSourceId] = useState<string>("");
  const [connectionName, setConnectionName] = useState<string>("");
  const [isSelectStreams, setSelectStreams] = useState<boolean>(false);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target ? e.target : e;
    setFormState((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    if (validationErrors[name]) {
      setValidationErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: false,
      }));
    }
  };

  const validateForm = () => {
    const errors: any = {};
    const requiredFields = [
      "connectionName",
      "schema",
      "host",
      "port",
      "databaseName",
      "username",
      "password"
    ];
    requiredFields.forEach((field) => {
      if (!formState[field]) {
        errors[field] = true;
      }
    });
    return errors;
  };

  const handleSubmit = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      toast.error("Please fill in all required fields.");
      return;
    }

    const sourceParams: PostgresSourceParams = {
      host: formState.host,
      port: formState.port,
      databaseName: formState.databaseName,
      username: formState.username,
      password: formState.password,
      workspaceId: `1a858109-a63d-4a61-8faf-bdfa3aed5e0b` as UUID,
      connectionName: formState.connectionName,
    }
    const postgresSource = await createPostgresSource(sourceParams);
    setSourceId(postgresSource.sourceId)
    setConnectionName(formState.connectionName)
    setSelectStreams(true);
  };

  if (isSelectStreams) {
    return (
      <ApiConnectionStreamsDialog sourceId={sourceId} connectionName={connectionName} handleClose={handleBack} />
    );
  }

  return (
    <div className="p-4 bg-gray-100 max-h-[1000px] overflow-y-auto">
      <div className="flex flex-col border p-4 rounded-xl bg-white shadow mb-4">
        <Typography className="text-xs my-1"  placeholder={undefined}>
          Source name
        </Typography>
        <Input
          label="Source Name"
          name="sourceName"
          value="PostgreSQL"
          disabled crossOrigin={undefined}        />
      </div>
      <div className="flex flex-col border p-4 rounded-xl bg-white shadow mb-4">
        <Typography className="text-xs my-1"  placeholder={undefined}>
          Connection name
        </Typography>
        <Input
          label=""
          name="connectionName"
          onChange={handleInputChange}
          error={!!validationErrors.connectionName}
          success={!validationErrors.connectionName && formState.connectionName !== ""}
          required crossOrigin={undefined}        />
        <Typography className="text-xs my-1"  placeholder={undefined}>
          Schema
        </Typography>
        <Input
          label=""
          name="schema"
          onChange={handleInputChange}
          error={!!validationErrors.schema}
          success={!validationErrors.schema && formState.schema !== ""}
          required crossOrigin={undefined}        />
        <Typography className="text-xs my-1"  placeholder={undefined}>
          Host
        </Typography>
        <Input
          label=""
          name="host"
          onChange={handleInputChange}
          error={!!validationErrors.host}
          success={!validationErrors.host && formState.host !== ""}
          required crossOrigin={undefined}        />
        <Typography className="text-xs my-1"  placeholder={undefined}>
          Port
        </Typography>
        <Input
          label=""
          name="port"
          type="number"
          onChange={handleInputChange}
          defaultValue={5432}
          error={!!validationErrors.port}
          success={!validationErrors.port && formState.port !== ""}
          required crossOrigin={undefined}        />
        <Typography className="text-xs my-1"  placeholder={undefined}>
          Database Name
        </Typography>
        <Input
          label=""
          name="databaseName"
          onChange={handleInputChange}
          error={!!validationErrors.databaseName}
          success={!validationErrors.databaseName && formState.databaseName !== ""}
          required crossOrigin={undefined}        />
      </div>
      <div className="flex flex-col border p-4 rounded-xl bg-white shadow mb-4">
        <Typography className="text-xs my-1"  placeholder={undefined}>
          Username
        </Typography>
        <Input
          label=""
          name="username"
          onChange={handleInputChange}
          error={!!validationErrors.username}
          success={!validationErrors.username && formState.username !== ""}
          required crossOrigin={undefined}        />
        <Typography className="text-xs my-1"  placeholder={undefined}>
          Password
        </Typography>
        <Input
          label=""
          name="password"
          type="password"
          onChange={handleInputChange}
          error={!!validationErrors.password}
          success={!validationErrors.password && formState.password !== ""}
          required crossOrigin={undefined}        />
      </div>
      <div className="flex justify-end space-x-4">
        <Button className="text-blue-500 border border-blue-500 bg-white hover:bg-blue-50 rounded-lg" onClick={handleBack} variant="outlined" placeholder={undefined}>
          {t("BACK")}
        </Button>
        <Button className="border border-blue-500 text-white bg-blue-500 hover:bg-blue-700 rounded-lg" color="blue" onClick={handleSubmit} placeholder={undefined}>
          {t("SAVE")}
        </Button>
      </div>
    </div>
  );
};

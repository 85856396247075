import React from "react";
import { Button } from "@material-tailwind/react";
import { t } from "i18next";
import MainButton from "../Buttons/MainButton";

interface ModalFooterProps {
    buttonText: string;
    disabled?: boolean;
    isRed?: boolean;
    onClick: () => void;
}

export const ModalFooter = ({ buttonText, disabled, isRed, onClick }: ModalFooterProps) => {
    return (
        <div
            className="py-4 px-6 flex flex-row-reverse"
            style={{
                gap: "10px",
                paddingLeft: "80px",
                paddingRight: "80px",
                paddingBottom: "30px",
            }}
        >
            {isRed ? (
                <Button
                    style={{
                        minWidth: "150px",
                        textTransform: "none",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "16px"
                    }}
                    className="h-10 bg-[#B3261E]"
                    color="white"
                    variant="outlined"
                    onClick={onClick}
                    disabled={disabled}
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                >
                    {t(buttonText)}
                </Button>
            ) : (
                <MainButton 
                    buttonText={t(buttonText)} 
                    onClick={onClick} 
                    plusIcon={false}
                    disabled={disabled}
                />
            )
            }
        </div>
    );
};

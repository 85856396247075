/** @format */

import React from "react";
import MainButton from "../../components/Buttons/MainButton";
import { BarsArrowUpIcon } from "@heroicons/react/24/solid";
import { t } from "i18next";

interface DashboardControlsProps {
  isEditMode: boolean;
  handleCancel: () => void;
  handleSave: () => void;
  setIsEditMode: (isEditMode: boolean) => void;
  handleEditMode: () => void;
  toggleFloatMode: () => void;
}

const DashboardControls: React.FC<DashboardControlsProps> = ({
  isEditMode,
  handleCancel,
  handleSave,
  setIsEditMode,
  handleEditMode,
  toggleFloatMode,
}) => {
  return (
    <div className="flex justify-end gap-4">
      {isEditMode ? (
        <>
          <button
            onClick={toggleFloatMode}
            className="mx-2 p-2 text-gray-500 hover:text-gray-700"
          >
            <BarsArrowUpIcon className="h-6 w-6" />
          </button>
          <button
            onClick={handleCancel}
            className="mx-2 p-2 text-gray-500 hover:text-gray-700"
          >
            {t("CANCEL")}
          </button>
          <MainButton buttonText="SAVE" onClick={handleSave} plusIcon={false} />
        </>
      ) : (
        <MainButton
          buttonText="EDIT"
          onClick={() => {
            setIsEditMode(true);
            handleEditMode();
          }}
          plusIcon={false}
          editIcon={true}
        />
      )}
    </div>
  );
};

export default DashboardControls;

/** @format */

import React from "react";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";

interface DashboardTitleProps {
  isEditMode: boolean;
  isMainDashboard: boolean;
  tempTitle: string;
  dashboardTitle: string;
  setTempTitle: (title: string) => void;
  handleBackToReports: () => void
}

const DashboardTitle: React.FC<DashboardTitleProps> = ({
  isEditMode,
  isMainDashboard,
  tempTitle,
  dashboardTitle,
  setTempTitle,
  handleBackToReports,
}) => {
  return (
    <div className="flex justify-start space-x-4 items-center">
      {isEditMode ? (
        <div className="inline-flex items-center">
          <input
            type="text"
            className={`p-2 focus:outline-none text-xl resize-none ${!isEditMode && "pointer-events-none"
              }`}
            value={tempTitle}
            onChange={(e) => setTempTitle(e.target.value)}
            placeholder="Enter title..."
            style={{ background: "#F2F5FF" }}
          />
        </div>
      ) : (
        <>
        {!isMainDashboard && 
          <ChevronLeftIcon
            className="w-6 h-6 cursor-pointer mr-2"
            onClick={handleBackToReports}
          />
        }
          <h1 className="text-xl font-semibold">{dashboardTitle}</h1>
        </>
      )}
    </div>
  );
};

export default DashboardTitle;

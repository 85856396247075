// src/config.ts

type Config = {
  websocketServer: string;
  supabaseAnonKey: string;
  supabaseUrl: string;
  supabasePublicUser: string;
  supabasePublicUserPass: string;
  semanticLayer: string;
};

export const getConfig = (): Config => {
  const {
    REACT_APP_WEBSOCKET_SERVER,
    REACT_APP_SUPABASE_ANON_KEY,
    REACT_APP_SUPABASE_URL,
    REACT_APP_SUPABASE_PUBLIC_USER,
    REACT_APP_SUPABASE_PUBLIC_USER_PASS,
    REACT_APP_SEMANTIC
  } = process.env;

  if (!REACT_APP_WEBSOCKET_SERVER || !REACT_APP_SUPABASE_ANON_KEY || !REACT_APP_SUPABASE_URL
    || !REACT_APP_SUPABASE_PUBLIC_USER || !REACT_APP_SUPABASE_PUBLIC_USER_PASS || !REACT_APP_SEMANTIC) {
    throw new Error("One or more required environment variables are not defined.");
  }

  return {
    websocketServer: REACT_APP_WEBSOCKET_SERVER,
    supabaseAnonKey: REACT_APP_SUPABASE_ANON_KEY,
    supabaseUrl: REACT_APP_SUPABASE_URL,
    supabasePublicUser: REACT_APP_SUPABASE_PUBLIC_USER,
    supabasePublicUserPass: REACT_APP_SUPABASE_PUBLIC_USER_PASS,
    semanticLayer: REACT_APP_SEMANTIC
  };
};

import { useRef } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const useDownload = (cardRef: any, item: any) => {
  const downloadButtonRef = useRef<HTMLDivElement>(null);

  const downloadCSV = () => {
    if (item.data.type === "table") {
      const { headers, data } = item.data.tableData;
      const csvContent = [
        headers.join(","),
        ...data.map((row: any) =>
          headers.map((header: any) => row[header]).join(",")
        ),
      ].join("\n");

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", `${item.data.label || "data"}.csv`);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const downloadPDF = async () => {
    const canvas = await html2canvas(cardRef.current as HTMLDivElement, {
      scale: 2,
    });
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF();
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save(`${item.data.label || "data"}.pdf`);
  };

  const downloadPNG = async () => {
    const canvas = await html2canvas(cardRef.current as HTMLDivElement, {
      scale: 2,
    });
    const imgData = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.setAttribute("href", imgData);
    link.setAttribute("download", `${item.data.label || "data"}.png`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return { downloadCSV, downloadPDF, downloadPNG, downloadButtonRef };
};

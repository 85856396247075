import React, { useState } from "react";
import {
  Button,
  Input,
  Typography,
} from "@material-tailwind/react";
import toast from "react-hot-toast";
import { UUID } from "crypto";
import ApiConnectionStreamsDialog from "./ApiConnectionStreamsDialog";
import { SnowflakeOAuthParams, SnowflakeUserPassParams } from "../../../api/airbyte/types/snowflake";
import { createSnoflwakeSource } from "../../../api/airbyte/apiFunctions";
import { t } from "i18next";

export const ApiConnectionSnowflakeDialog = ({ handleBack }: any) => {
  //const [authMethod, setAuthMethod] = useState<"user/password" | "OAuth">("OAuth");
  const [formState, setFormState] = useState<any>({
    connectionName: "",
    username: "",
    password: "",
    host: "",
    database: "",
    warehouse: "",
    role: ""
  });
  const [validationErrors, setValidationErrors] = useState<any>({});

  const [sourceId, setSourceId] = useState<string>("");
  const [connectionName, setConnectionName] = useState<string>("");
  const [isSelectStreams, setSelectStreams] = useState<boolean>(false);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target ? e.target : e;
    setFormState((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    // Remove any existing validation error for this field
    if (validationErrors[name]) {
      setValidationErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: false,
      }));
    }
  };

  /*const handleSelect = (e: any) => {
    setAuthMethod(e);
  };*/


  const validateForm = () => {
    const errors: any = {};

    let requiredFields = ["connectionName"]; // Campo común

    //if (authMethod === "username/password") {
    requiredFields = [
      ...requiredFields,
      "username",
      "password",
      "host",
      "database",
      "warehouse",
      "role"
    ];
    /*} else { //OAuth
      requiredFields = [
        ...requiredFields,
      ];
    }*/

    requiredFields.forEach(field => {
      if (!formState[field] || formState[field].trim() === "") {
        errors[field] = "This field is required"; // Coloca un mensaje más específico si es necesario
      }
    });

    return errors;
  };

  const handleSubmit = async () => {

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      toast.error("Please fill in all required fields.");
      return;
    }

    let sourceParams: SnowflakeOAuthParams | SnowflakeUserPassParams;
    //if (authMethod === "OAuth") {
    /*sourceParams = {
      auth_type: 'OAuth',
      client_id: formState.client_id,
      client_secret: formState.client_secret,
      //access_token: formState.access_token,
      //token_expiry_date: formState.token_expiry_date,
      host: formState.host, 
      role: formState.role, 
      warehouse: formState.warehouse, 
      database: formState.database,
      refresh_token: formState.refresh_token,
      workspaceId: `1a858109-a63d-4a61-8faf-bdfa3aed5e0b` as UUID,
      connectionName: formState.connectionName,

    }*/
    //} else { //user/password
      sourceParams = {
        auth_type: 'username/password',
        username: formState.username,
        password: formState.password,
        host: formState.host,
        database: formState.database,
        warehouse: formState.warehouse,
        role: formState.role,
        workspaceId: `1a858109-a63d-4a61-8faf-bdfa3aed5e0b` as UUID,
        connectionName: formState.connectionName,

      }
    //}
    const snowflakeSource = await createSnoflwakeSource(sourceParams);
    setSourceId(snowflakeSource.sourceId)

    setConnectionName(formState.connectionName)
    setSelectStreams(true);
  };

  if (isSelectStreams) {
    return (
      <ApiConnectionStreamsDialog sourceId={sourceId} connectionName={connectionName} handleClose={handleBack} />
    );
  }

  return (
    <div className="p-4 bg-gray-100 max-h-[1000px] overflow-y-auto">
          <div className="flex flex-col border p-4 rounded-xl bg-white shadow mb-4">
            <Typography className="text-xs my-1" placeholder={undefined}  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              Source name
            </Typography>
            <Input
              label="Source Name"
              name="sourceName"
              value="Snowflake"
              crossOrigin={undefined}
              disabled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            />
          </div>
          <div className="flex flex-col border p-4 rounded-xl bg-white shadow mb-4">
            <Typography className="text-xs my-1" placeholder={undefined}  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              Connection name
            </Typography>
            <Input
              label=""
              name="connectionName"
              onChange={handleInputChange}
              crossOrigin={undefined}
              error={!!validationErrors.connectionName}
              success={!validationErrors.connectionName && formState.connectionName !== ""}
              required onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            />
          </div>
          {/*<div className="flex flex-col border p-4 rounded-xl bg-white shadow mb-4">
            <Select
              label="Authentication Method"
              name="authMethod"
              value={authMethod}
              onChange={handleSelect}
              placeholder={undefined}
            >
              <Option value="apiKey">API Key</Option>
              <Option value="oauth">OAuth 2.0</Option>
            </Select>
      </div>*/}
          <div className="flex flex-col border p-4 rounded-xl bg-white shadow mb-4">
            {//authMethod === "oauth" && (
              <div>
                <Typography className="text-xs my-1" placeholder={undefined}  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  Username
                </Typography>
                <Input required name="username" error={!!validationErrors.username} success={!validationErrors.username && formState.username !== ""} onChange={handleInputChange} crossOrigin={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                <Typography className="text-xs my-1" placeholder={undefined}  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  Password
                </Typography>
                <Input required type="password" name="password" error={!!validationErrors.password} success={!validationErrors.password && formState.password !== ""} onChange={handleInputChange} crossOrigin={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                <Typography className="text-xs my-1" placeholder={undefined}  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  Host
                </Typography>
                <Input required name="host" error={!!validationErrors.host} success={!validationErrors.host && formState.host !== ""} onChange={handleInputChange} crossOrigin={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                <Typography className="text-xs my-1" placeholder={undefined}  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  Database
                </Typography>
                <Input required name="database" error={!!validationErrors.database} success={!validationErrors.database && formState.database !== ""} onChange={handleInputChange} crossOrigin={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                <Typography className="text-xs my-1" placeholder={undefined}  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  Warehouse
                </Typography>
                <Input required name="warehouse" error={!!validationErrors.warehouse} success={!validationErrors.warehouse && formState.warehouse !== ""} onChange={handleInputChange} crossOrigin={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                <Typography className="text-xs my-1" placeholder={undefined}  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  Role
                </Typography>
                <Input required name="role" error={!!validationErrors.role} success={!validationErrors.role && formState.role !== ""} onChange={handleInputChange} crossOrigin={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                
              </div>
              //)
            }
            {/*authMethod === "apiKey" && (
              <div>
                <Typography className="text-xs my-1" placeholder={undefined}>
                  API Key
                </Typography>
                <Input name="api_key" error={!!validationErrors.api_key} success={!validationErrors.api_key && formState.api_key !== ""} onChange={handleInputChange} crossOrigin={undefined} />
              </div>
            )*/}
          </div>
          <div className="flex justify-end space-x-4">
        <Button className="text-blue-500 border border-blue-500 bg-white hover:bg-blue-50 rounded-lg" onClick={handleBack} variant="outlined" placeholder={undefined}>
          {t("BACK")}
        </Button>
        <Button className="border border-blue-500 text-white bg-blue-500 hover:bg-blue-700 rounded-lg" color="blue" onClick={handleSubmit} placeholder={undefined}>
          {t("SAVE")}
        </Button>
      </div>
        </div>
  );
};
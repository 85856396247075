/** @format */

import React, { useState } from "react";
import {
  Button,
  Input,
  Typography,
} from "@material-tailwind/react";
import toast from "react-hot-toast";
import { createMetabaseSource } from "../../../api/airbyte/apiFunctions";
import { UUID } from "crypto";
import ApiConnectionStreamsDialog from "./ApiConnectionStreamsDialog";
import { MetabaseSourceParams } from "../../../api/airbyte/types/metabase";
import { t } from "i18next";



export const ApiConnectionMetabaseDialog = ({ handleBack }: any) => {
  const [formState, setFormState] = useState<any>({
    instance_api_url: "",
    username: "",
    password: "",
    session_token: "",
    connectionName: "",
  });
  const [validationErrors, setValidationErrors] = useState<any>({});

  const [sourceId, setSourceId] = useState<string>("");
  const [connectionName, setConnectionName] = useState<string>("");
  const [isSelectStreams, setSelectStreams] = useState<boolean>(false);


  const handleInputChange = (e: any) => {
    const { name, value } = e.target ? e.target : e;
    setFormState((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    // Remove any existing validation error for this field
    if (validationErrors[name]) {
      setValidationErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: false,
      }));
    }
  };

  const validateForm = () => {
    const errors: any = {};
    const requiredFields = [
      "instance_api_url",
      "connectionName"
    ];
    requiredFields.forEach((field) => {
      if (!formState[field]) {
        errors[field] = true; // true indicates an error exists
      }
    });
    return errors;
  };

  const handleContinue = async () => {

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      toast.error("Please fill in all required fields.");
      return; // Stop submission as there are errors
    }

    const sourceParams: MetabaseSourceParams = {
      instance_api_url: formState.instance_api_url,
      username: formState.username,
      password: formState.password,
      session_token: formState.session_token,
      workspaceId: `1a858109-a63d-4a61-8faf-bdfa3aed5e0b` as UUID,
      connectionName: formState.connectionName,

    }
    const metabaseSource = await createMetabaseSource(sourceParams);
    setSourceId(metabaseSource.sourceId)
    setConnectionName(formState.connectionName)
    setSelectStreams(true);
  };

  if (isSelectStreams) {
    return (
      <ApiConnectionStreamsDialog sourceId={sourceId} connectionName={connectionName} handleClose={handleBack} />
    );
  }

  return (

    <div className="p-4 bg-gray-100 max-h-[1000px] overflow-y-auto">
      <div className="flex flex-col border p-4 rounded-xl bg-white shadow mb-4">
        <Typography className="text-xs my-1" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          Source name
        </Typography>
        <Input
          label="Source Name"
          name="sourceName"
          value="Metabase"
          crossOrigin={undefined}
          disabled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
      </div>
      <div className="flex flex-col border p-4 rounded-xl bg-white shadow mb-4">
        <Typography className="text-xs my-1" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          Connection name
        </Typography>
        <Input
          label=""
          name="connectionName"
          onChange={handleInputChange}
          crossOrigin={undefined}
          error={!!validationErrors.connectionName}
          success={!validationErrors.connectionName && formState.connectionName !== ""}
          required onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
        <Typography className="text-xs my-1" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          Metabase Instance API UR
        </Typography>
        <Input
          label=""
          name="instance_api_url"
          onChange={handleInputChange}
          crossOrigin={undefined}
          error={!!validationErrors.instance_api_url}
          success={!validationErrors.instance_api_url && formState.instance_api_url !== ""}
          required onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
        <Typography className="text-xs my-1" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          Username
        </Typography>
        <Input
          label=""
          name="username"
          onChange={handleInputChange}
          crossOrigin={undefined}
          error={!!validationErrors.username}
          success={!validationErrors.username && formState.username !== ""} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
        <Typography className="text-xs my-1" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          Password
        </Typography>
        <Input
          label=""
          name="password"
          type="password"
          onChange={handleInputChange}
          crossOrigin={undefined}
          error={!!validationErrors.password}
          success={!validationErrors.password && formState.password !== ""} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
        <Typography className="text-xs my-1" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          Session Token
        </Typography>
        <Input
          label=""
          name="session_token"
          type="password"
          onChange={handleInputChange}
          crossOrigin={undefined}
          error={!!validationErrors.session_token}
          success={!validationErrors.session_token && formState.session_token !== ""} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
      </div>
      <div className="flex justify-end space-x-4">
        <Button className="text-blue-500 border border-blue-500 bg-white hover:bg-blue-50 rounded-lg" onClick={handleBack} variant="outlined" placeholder={undefined}>
          {t("BACK")}
        </Button>
        <Button className="border border-blue-500 text-white bg-blue-500 hover:bg-blue-700 rounded-lg" color="blue" onClick={handleContinue} placeholder={undefined}>
          {t("SAVE")}
        </Button>
      </div>
    </div>
  );
};
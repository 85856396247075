import { t } from "i18next";
import { Analytics, Dashboard, SignIn, Settings, Leads, Customers, Segments, RawData, Library, SemanticLayer } from "./pages";
import { PasswordChange, PasswordRecovery, PasswordReset } from "./pages/Password";
import { ProfileDetails } from "./pages/Account/ProfileDetails";
import { Company } from "./pages/Account/CompanyDetails";
import { Members } from "./pages/Account/MemberDetails";

export const routes = [
    {
        name: t("DATABASE"),
        path: "/",
        element: <Dashboard />
    },
    {
        name: t("Raw data"),
        path: "/settings/rawdata",
        element: <RawData />
    },
    {
        name: t("Members"),
        path: "/settings/members",
        element: <Members />
    },
    {
        name: t("Library"),
        path: "/settings/library",
        element: <Library />
    },
    {
        name: "Semantic Layer",
        path: "/settings/semantic-layer",
        element: <SemanticLayer />
    },
    {
        name: "Analytics",
        path: "/analytics",
        element: <Analytics />,
    },
    {
        name: "Settings",
        path: "/settings/sources",
        element: <Settings />,
    },
    {
        name: "Profile Details",
        path: "/settings/profile",
        element: <ProfileDetails />,
    },
    {
        name: "Company Details",
        path: "/settings/company",
        element: <Company />,
    },
    {
        name: "Logout",
        path: "/logout",
        element: <SignIn />,
    },
    {
        name: "Sign In",
        path: "/sign-in",
        element: <SignIn />,
    },
    {
        name: "Invited Member",
        path: "/invited-member/:id",
        element: <PasswordRecovery />,
    },
    {
        name: "Logout",
        path: "/logout",
        element: <></>,
    },
    {
        icon: null,
        name: "Reset Password",
        path: "/reset-password",
        element: <PasswordReset />,
    },
    {
        icon: null,
        name: "Change Password",
        path: "/change-password/:email/:token",
        element: <PasswordChange />,
    },
    {
        icon: null,
        name: "Leads",
        path: "/leads",
        element: <Leads />,
    },
    {
        icon: null,
        name: "Customers",
        path: "/customers",
        element: <Customers />,
    },
    {
        icon: null,
        name: "Segments",
        path: "/segments",
        element: <Segments />,
    }
];

export default routes;

/** @format */

import React, { useEffect, useState } from "react";
import { privateSupabase } from "../api/SupabaseClient";
import LoadingSpinner from "../components/LoadingSpinner";
import { DashboardCreator } from "../pages";
import { useFilter } from "../context";
import GlobalFilterBar from "./GlobalFilterBar";
import useCubeData from "../hooks/useCubeData";

const MainMetrics: React.FC = () => {
  const [mainDashboardId, setMainDashboardId] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showDashboardCreator, setShowDashboardCreator] =
    useState<boolean>(false);
  const [selectedFilters, setSelectedFilters] = useState<any[]>([]);

  const [selectedMeasures, setSelectedMeasures] = useState<any[]>([]);
  const [selectedDimensions, setSelectedDimensions] = useState<any[]>([]);
  const [selectedSegments, setSelectedSegments] = useState<any[]>([]);
  const [selectedTimeDimensions, setSelectedTimeDimensions] = useState<any>([]);
  const { setSelectedRep } = useFilter();
  const { cubeMetadata, filterData } = useCubeData();
  const [filtersUpdated, setFiltersUpdated] = useState<boolean>(false);
  const [session, setSession] = useState<any>(null);

  useEffect(() => {
    privateSupabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  useEffect(() => {
    const init = async () => {
      if (session && session.user) {
        await initializeMainDashboard();
      }
    };
    init();
  }, [session]);

  const initializeMainDashboard = async () => {
    try {
      setIsLoading(true);
      const { data } = await fetchMainDashboard();

      if (!data) {
        await insertInitialValue();
        const { data } = await fetchMainDashboard();
        setMainDashboardId(data?.id);
        setSelectedRep(data?.id);
      } else {
        setMainDashboardId(data?.id);
        setSelectedRep(data?.id);
      }

      if (data) {
        setMainDashboardId(data?.id);
      }

      setShowDashboardCreator(true);
      setIsLoading(false);
    } catch (error) {
      console.error("ERROR: ", error);
      setIsLoading(false);
    }
  };

  const insertInitialValue = async () => {
    const { data, error } = await privateSupabase
      .from("reports_dashboard")
      .insert([
        {
          title: "Main Dashboard",
          created_by: session.user.email,
          last_update: new Date().toISOString(),
          is_main_dashboard: true,
          items: [],
        },
      ]);

    if (error) {
      console.error("Error inserting initial value: ", error);
    }

    return { data, error };
  };

  const fetchMainDashboard = async () => {
    const { data, error } = await privateSupabase
      .from("reports_dashboard")
      .select("id")
      .eq("is_main_dashboard", true)
      .single();

    if (error) {
      console.error("Error fetching main dashboard: ", error);
    }

    return { data, error };
  };

  const handleFilterClick = (
    selectedMeasures: string[],
    selectedDimensions: string[],
    selectedFilters: any[],
    selectedSegments: any[],
    selectedTimeDimensions: any[]
  ) => {
    const regularFilters = selectedFilters.filter(
      (filter) => !filter.member.includes("date")
    );

    setSelectedFilters(regularFilters);
    setSelectedMeasures(selectedMeasures);
    setSelectedDimensions(selectedDimensions);
    setSelectedTimeDimensions(selectedTimeDimensions);
    setSelectedSegments(selectedSegments);
    setFiltersUpdated(true); // Trigger data fetch in DashboardCreator
  };

  return (
    <div className="flex flex-col bg-[#F2F5FF] w-full p-6">
      {isLoading && <LoadingSpinner message="LOADING" />}
      {!isLoading && showDashboardCreator && mainDashboardId !== 0 && (
        <div className="h-full w-full">
          <GlobalFilterBar
            onFilterClick={handleFilterClick}
            filterData={filterData}
            cubeMetadata={cubeMetadata}
          />
          <DashboardCreator
            selectedReport={mainDashboardId}
            onReportSaved={initializeMainDashboard}
            isMainDashboard={true}
            selectedFilters={selectedFilters}
            selectedMeasures={selectedMeasures}
            selectedSegments={selectedSegments}
            selectedTimeDimensions={selectedTimeDimensions}
            selectedDimensions={selectedDimensions}
            filtersUpdated={filtersUpdated}
            setFiltersUpdated={setFiltersUpdated}
          />
        </div>
      )}
    </div>
  );
};

export default MainMetrics;

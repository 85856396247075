/** @format */
import React from "react";
import { ReactComponent as SearchIcon } from "./../assets/icons/search_icon.svg";
import { t } from "i18next";

interface SearchButtonProps {
  searchInput: string;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchButton: React.FC<SearchButtonProps> = ({
  searchInput,
  onSearchChange,
}) => {
  return (
    <div className="flex items-center text-[#3E54AC] px-4 py-2 rounded-full border bg-[white] border-[#79767D]" style={{ height: "40px" }}>
      <SearchIcon className="h-5 w-5 mr-2" />
      <input
        type="text"
        value={searchInput}
        placeholder={t("SEARCH")}
        className="bg-transparent outline-none w-full"
        onChange={onSearchChange}
      />
    </div>
  );
};

export default SearchButton;

/** @format */

import React, { useState } from "react";
import { Dialog } from "@material-tailwind/react";
import { ModalHeader } from "../Modal";
import { InfoCard, TableCard, ChartCard } from "../Cards";
import VerifyButton from "./VerifyButton";
import VerifyDialog from "./VerifyDialog";

interface ExpandDetailsProps {
  isOpen: boolean;
  onClose: () => void;
  source: any;
}

export const ExpandedCard = ({
  isOpen,
  onClose,
  source,
}: ExpandDetailsProps) => {
  const [isVerifyDialogOpen, setIsVerifyDialogOpen] = useState(false);

  let itemData;
  let itemName;
  let itemId: any;
  let itemType: any;

  if (source.type === "card") {
    itemData = source.data.data;
    itemName = source.data.title;
    itemId = source.data.id;
    itemType = source.data.type;
  } else {
    itemData = source.data;
    itemName = source.title;
    itemId = source.id;
    itemType = source.type;
  }

  const handleExpandedCardClose = () => {
    onClose();
  };

  const handleVerifyClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsVerifyDialogOpen(true);
  };

  const handleVerifyDialogClose = () => {
    setIsVerifyDialogOpen(false);
  };

  return (
    <>
      <Dialog
        size="lg"
        open={isOpen}
        handler={handleExpandedCardClose}
        className="p-4 bg-gray-100 h-4/5 overflow-y-auto"
        placeholder={undefined}
      >
        <ModalHeader title={itemName} onClose={handleExpandedCardClose} />
        <div className="p-5 space-y-4 h-4/5">
          <>
            {itemType === "table" && (
              <TableCard
                tableData={{
                  ...itemData.tableData,
                  id: itemId,
                  data: itemData.tableData.data,
                }}
                isExpanded={true}
              />
            )}
            {itemType === "chart" && (
              <ChartCard
                data={itemData}
                options={itemData.options}
                type={itemData.type}
                colors={source.colors}
                labels={source.labels}
                isEditMode={false}
              />
            )}
            {itemType === "datapoint" && (
              <InfoCard
                key={itemName}
                title={itemName}
                data={itemData}
                percentage={0}
                isPercentagePositive={false}
              />
            )}
          </>
          <div className="flex justify-end">
            <VerifyButton onClick={handleVerifyClick} />
          </div>
        </div>
      </Dialog>
      <VerifyDialog
        isOpen={isVerifyDialogOpen}
        onClose={handleVerifyDialogClose}
        query={source.sqlQuery}
        description={source.sqlDescription}
      />
    </>
  );
};

export default ExpandedCard;

/** @format */

import React, { useState } from "react";
import {
  Button,
  Input,
  Typography,
} from "@material-tailwind/react";
import toast from "react-hot-toast";
import { createWooComerceSource } from "../../../api/airbyte/apiFunctions";
import { UUID } from "crypto";
import ApiConnectionStreamsDialog from "./ApiConnectionStreamsDialog";
import { WooComerceSourceParams } from "../../../api/airbyte/types/wooComerce";
import { t } from "i18next";



export const ApiConnectionWooComerceDialog = ({ handleBack }: any) => {
  const [formState, setFormState] = useState<any>({
    api_key: "",
    api_secret: "",
    shop: "",
    start_date: "",
    connectionName: "",
  });
  const [validationErrors, setValidationErrors] = useState<any>({});

  const [sourceId, setSourceId] = useState<string>("");
  const [connectionName, setConnectionName] = useState<string>("");
  const [isSelectStreams, setSelectStreams] = useState<boolean>(false);


  const handleInputChange = (e: any) => {
    const { name, value } = e.target ? e.target : e;
    setFormState((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    // Remove any existing validation error for this field
    if (validationErrors[name]) {
      setValidationErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: false,
      }));
    }
  };

  const validateForm = () => {
    const errors: any = {};
    const requiredFields = [
      "api_key",
      "api_secret",
      "shop",
      "start_date",
      "connectionName",

    ];
    requiredFields.forEach((field) => {
      if (!formState[field]) {
        errors[field] = true; // true indicates an error exists
      }
    });
    return errors;
  };

  const handleContinue = async () => {

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      toast.error("Please fill in all required fields.");
      return; // Stop submission as there are errors
    }

    const sourceParams: WooComerceSourceParams = {
      api_key: formState.api_key,
      api_secret: formState.api_secret,
      shop: formState.shop,
      start_date: formState.start_date,
      workspaceId: `1a858109-a63d-4a61-8faf-bdfa3aed5e0b` as UUID,
      connectionName: formState.connectionName,

    }
    const wooComerceSource = await createWooComerceSource(sourceParams);
    setSourceId(wooComerceSource.sourceId)
    setConnectionName(formState.connectionName)
    setSelectStreams(true);
  };

  if (isSelectStreams) {
    return (
      <ApiConnectionStreamsDialog sourceId={sourceId} connectionName={connectionName} handleClose={handleBack} />
    );
  }

  return (

    <div className="p-4 bg-gray-100 max-h-[1000px] overflow-y-auto">
      <div className="flex flex-col border p-4 rounded-xl bg-white shadow mb-4">
        <Typography className="text-xs my-1" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          Source name
        </Typography>
        <Input
          label="Source Name"
          name="sourceName"
          value="WooCommerce"
          crossOrigin={undefined}
          disabled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
      </div>
      <div className="flex flex-col border p-4 rounded-xl bg-white shadow mb-4">
        <Typography className="text-xs my-1" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          Connection name
        </Typography>
        <Input
          label=""
          name="connectionName"
          onChange={handleInputChange}
          crossOrigin={undefined}
          error={!!validationErrors.connectionName}
          success={!validationErrors.connectionName && formState.connectionName !== ""}
          required onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
        <Typography className="text-xs my-1" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          API Key
        </Typography>
        <Input
          label=""
          name="api_key"
          onChange={handleInputChange}
          crossOrigin={undefined}
          error={!!validationErrors.api_key}
          success={!validationErrors.api_key && formState.api_key !== ""}
          required onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
        <Typography className="text-xs my-1" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          API Secret
        </Typography>
        <Input
          label=""
          name="api_secret"
          type="password"
          onChange={handleInputChange}
          crossOrigin={undefined}
          error={!!validationErrors.api_secret}
          success={!validationErrors.api_secret && formState.api_secret !== ""}
          required onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
        <Typography className="text-xs my-1" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          Shop
        </Typography>
        <Input
          label=""
          name="shop"
          onChange={handleInputChange}
          crossOrigin={undefined}
          error={!!validationErrors.shop}
          success={!validationErrors.shop && formState.shop !== ""}
          required onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
        <Typography className="text-xs my-1" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          Start Date
        </Typography>
        <Input
          label=""
          name="start_date"
          onChange={handleInputChange}
          crossOrigin={undefined}
          error={!!validationErrors.start_date}
          success={!validationErrors.start_date && formState.start_date !== ""}
          required onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
      </div>
      <div className="flex justify-end space-x-4">
        <Button className="text-blue-500 border border-blue-500 bg-white hover:bg-blue-50 rounded-lg" onClick={handleBack} variant="outlined" placeholder={undefined}>
          {t("BACK")}
        </Button>
        <Button className="border border-blue-500 text-white bg-blue-500 hover:bg-blue-700 rounded-lg" color="blue" onClick={handleContinue} placeholder={undefined}>
          {t("SAVE")}
        </Button>
      </div>
    </div>
  );
};
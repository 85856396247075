/** @format */

import React from "react";
import "@material/web/button/filled-button.js";
import "@material/web/button/outlined-button.js";
import "@material/web/checkbox/checkbox.js";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import i18n from "./locales/i18n";
import { I18nextProvider } from "react-i18next";
import {
  ReloadProvider,
  FilterProvider,
  TableDataProvider,
  SupabaseProvider,
  ChatVisibilityProvider,
  CurrentPathProvider,
  SelectionProvider,
} from "./context";
import CustomToast from "./components/CustomToast";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <I18nextProvider i18n={i18n}>
      <SupabaseProvider>
        <ReloadProvider>
          <TableDataProvider>
            <SelectionProvider>
              <BrowserRouter>
                <CurrentPathProvider>
                  <FilterProvider>
                    <ChatVisibilityProvider>
                      <CustomToast />
                      <App />
                    </ChatVisibilityProvider>
                  </FilterProvider>
                </CurrentPathProvider>
              </BrowserRouter>
            </SelectionProvider>
          </TableDataProvider>
        </ReloadProvider>
      </SupabaseProvider>
  </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react';
import { CodeBracketIcon } from '@heroicons/react/24/outline';
import { Typography } from '@mui/material';

interface QueryStep {
    title: string;
    description: string;
}

interface QueryStepsProps {
    querySteps: QueryStep[];
}

const QuerySteps: React.FC<QueryStepsProps> = ({ querySteps }) => {
    if (!querySteps.length) {
        return null;
    }

    return (
        <div className="query-steps flex flex-col space-y-2">
            {querySteps.map((step, index) => (
                <div
                    key={index}
                    className="flex justify-start gap-2 items-center w-full text-[#46474C]"
                >
                    <CodeBracketIcon className="w-6 h-6 bg-[#74737514] rounded-full p-1" />
                    <Typography>{index + 1}.</Typography>
                    <Typography>{step.title}:</Typography>
                    <Typography>{step.description}</Typography>
                </div>
            ))}
        </div>
    );
};

export default QuerySteps;

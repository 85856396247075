/** @format */

import React, { useEffect, useState } from "react";
import ColumnFilter from "./ColumnFilter";
import Pagination from "./Pagination";
import { getCaseInsensitiveValue } from "../../utils/ColumnFilterHandler";

interface TableProps {
  headers: string[];
  rows: any;
  onRowClick?: (rowData: { [key: string]: any }) => void;
  renderCustomColumn?: (rowData: any, rowIndex: number) => React.ReactNode;
  // Filters
  filters?: Record<string, string[]>;
  applyFilter?: (column: string, value: string[]) => void;
  applyFilterFinal?: (column: string, value: string[]) => void;
  removeFilter?: (column: string) => void;
  removeFilterWithData?: (column: string) => void;
  tableName?: string;
  fetchedData?: any;
  filterOptions?: Record<string, string[]>;
  columnTypes?: Record<string, string>; // Add columnTypes as optional
  // Pagination
  currentPage: number;
  totalItems: number;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
  onItemsPerPageChange: (newItemsPerPage: number) => void;
}

const Table: React.FC<TableProps> = ({
  headers,
  rows,
  onRowClick,
  renderCustomColumn,
  // Filters
  filters = {},
  applyFilter = () => {},
  applyFilterFinal = () => {},
  removeFilter = () => {},
  removeFilterWithData = () => {},
  tableName,
  fetchedData,
  filterOptions = {},
  columnTypes = {}, // Default to an empty object
  // Pagination
  currentPage,
  itemsPerPage,
  totalItems,
  onPageChange,
  onItemsPerPageChange,
}) => {
  const [data, setData] = useState<any[]>(rows); // Define the data state
  const [selectedItemsPerPage, setSelectedItemsPerPage] =
    useState(itemsPerPage);
  const [sortedColumn, setSortedColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  useEffect(() => {
    if (typeof onItemsPerPageChange === "function") {
      onItemsPerPageChange(selectedItemsPerPage);
    }
  }, [selectedItemsPerPage]);

  const handleItemsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    setSelectedItemsPerPage(newItemsPerPage);
  };

  useEffect(() => {
    setData(sortData(rows)); // Reset and sort data when rows prop changes
  }, [rows, sortedColumn, sortDirection]);

  const handleSort = (column: string, direction: "asc" | "desc") => {
    setSortedColumn(column);
    setSortDirection(direction);
  };

  const sortData = (data: any[]) => {
    if (!sortedColumn) return data;
    return [...data].sort((a, b) => {
      if (a[sortedColumn] < b[sortedColumn])
        return sortDirection === "asc" ? -1 : 1;
      if (a[sortedColumn] > b[sortedColumn])
        return sortDirection === "asc" ? 1 : -1;
      return 0;
    });
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow overflow-auto min-h-[285px]">
        <table className="min-w-full text-sm text-left text-gray-500">
          <thead className="sticky top-0 bg-white text-black font-normal">
            <tr>
              {headers.map((header, index) => (
                <th
                  key={index}
                  scope="col"
                  className={`py-3 px-6 ${index === 0 ? "text-left" : "text-center"} ${filters[header]?.length > 0 ? "text-blue-500" : ""}`}
                >
                  <div
                    className={`flex items-center ${index === 0 ? "justify-start" : "justify-center"}`}
                  >
                    {header}
                    {applyFilter && tableName && (
                      <ColumnFilter
                        column={header}
                        filters={filters}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                        table={tableName}
                        onSort={handleSort}
                        filterOptions={filterOptions[header] || []}
                        columnType={columnTypes[header]}
                        columnIndex={index}
                      />
                    )}
                    {applyFilterFinal && removeFilterWithData && fetchedData && (
                      <ColumnFilter
                      column={header}
                      filters={filters}
                      applyFilterFinal={applyFilterFinal}
                      removeFilter={removeFilter}
                      removeFilterWithData={removeFilterWithData}
                      fetchedData={fetchedData}
                      onSort={handleSort}
                      filterOptions={getCaseInsensitiveValue(filterOptions, header) || []}
                      columnType={columnTypes[header]}
                      columnIndex={index}
                    />
                    )}
                  </div>
                </th>
              ))}
              <th scope="col" className="py-3 px-6 text-center"></th>
            </tr>
          </thead>
          <tbody className="bg-white ">
            {data.map((row: any, rowIndex: number) => (
              <React.Fragment key={rowIndex}>
                <tr
                  className="text-base text-black font-medium cursor-pointer border border-gray-200"
                  onClick={() => {
                    if (onRowClick) {
                      onRowClick(row);
                    }
                  }}
                >
                  {headers.map((header, headerIndex) => (
                    <td
                      key={headerIndex}
                      className={`py-4 px-6 whitespace-nowrap ${headerIndex === 0 ? "text-left" : "text-center"}`}
                    >
                      {typeof row[header] === "object" &&
                      row[header] !== null ? (
                        React.isValidElement(row[header]) ? (
                          row[header]
                        ) : "isImage" in row[header] ? (
                          <img src={row[header].url} alt="" className="..." />
                        ) : (
                          <div>
                            <div>{row[header].main}</div>
                            {row[header].comparison && (
                              <div className="text-xs text-gray-500">
                                {row[header].comparison}
                              </div>
                            )}
                          </div>
                        )
                      ) : (
                        row[header]
                      )}
                    </td>
                  ))}
                  {renderCustomColumn && (
                    <td className="py-4 px-6">
                      {renderCustomColumn(row, rowIndex)}
                    </td>
                  )}
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex-none">
        <Pagination
          currentPage={currentPage}
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          onPageChange={onPageChange}
          handleItemsPerPageChange={handleItemsPerPageChange}
        />
      </div>
    </div>
  );
};

export default Table;

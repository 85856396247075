/** @format */

import React, { useCallback, useEffect, useMemo, useState } from "react";
import FilterComponent from "../../components/FilterComponent";
import { useFilter } from "../../context/filterContext";
import Table from "../../components/Table";
import { privateSupabase } from "../../api/SupabaseClient";
import SourceDetails from "./sourceDetail";
import LoadingSpinner from "../../components/LoadingSpinner";
import FilterButton from "../../components/Buttons/FilterButton";

export interface Source {
  connector: string;
  table: string;
  rows: number;
  prefix: string;
}

export const RawData: React.FC = () => {
  const {
    sqlQuery,
  } = useFilter();
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filters, setFilters] = useState<Record<string, any>>({});


  const handleFilterButtonClick = () => {
    setShowFilterOptions(!showFilterOptions);
  };


  const handleFilterChange = useCallback((filterName: string, value: any) => {
    setFilters((prev) => ({ ...prev, [filterName]: value }));
  }, []);

  const onSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchInput(e.target.value);
    },
    []
  );

  const [isLoading, setIsLoading] = useState(false);
  const [allSourcesData, setAllSourcesData] = useState<Source[]>([]);
  const [displayedSourcesData, setDisplayedSourcesData] = useState<Source[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const { setActiveRoute, activeRoute } = useFilter();
  const [selectedSource, setSelectedSource] = useState<Source | null>(null);

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (activeRoute !== "sources") {
      setActiveRoute("sources");
    }
  }, [activeRoute, setActiveRoute]);

  const getSources = async () => {
    setIsLoading(true);

    let query = privateSupabase
      .from('source_details')
      .select('*');
    if (searchInput) {
      query = query.ilike('stream_name', `%${searchInput}%`);
    }

    Object.entries(filters).forEach(([key, value]) => {
      if (!value) return;

      query = query.ilike(key, `%${value}%`);
    });
    const { data: sources, error } = await query;

    if (error) {
      setAllSourcesData([]);
      setIsLoading(false);
      return;
    }

    const formattedSources = sources.map((source: any) => ({
      connector: source.connector,
      table: source.stream_name,
      rows: source.row_count,
      prefix: source.prefix,
    }));

    setAllSourcesData(formattedSources);
    setTotalItems(formattedSources.length);
    setIsLoading(false);
  };

  useMemo(() => {
    getSources();
  }, [filters, sqlQuery, searchInput]);

  useEffect(() => {
    const fromIndex = (currentPage - 1) * itemsPerPage;
    const toIndex = fromIndex + itemsPerPage;
    setDisplayedSourcesData(allSourcesData.slice(fromIndex, toIndex));
  }, [allSourcesData, currentPage, itemsPerPage]);

  const generateRows = () =>
    displayedSourcesData.map((source) => ({
      "Connector": source.connector,
      "Table": source.table,
      "Records": source.rows,
      "prefix": source.prefix,
    }));

  const headers = ["Connector", "Table", "Records"];

  const handleRowClick = (rowData: { [key: string]: any }) => {
    const source: Source = {
      connector: rowData.Connector,
      table: rowData.Table,
      rows: rowData.Records,
      prefix: rowData.prefix,
    };
    setSelectedSource(source);
  };

  const handleBack = () => {
    setSelectedSource(null)
  };

  return (
    <>
      {!selectedSource && (
        <div
          className={`flex flex-col justify-between bg-white rounded-2xl mx-6 pb-12}`}
          style={{ overflowY: "auto", maxHeight: "calc(100vh - 120px)" }}
        >

          <div className="flex flex-col justify-between bg-white rounded-2xl mx-6">
            <div className="flex flex-row bg-white justify-between pb-12 pt-4 mt-6">

              <div className="flex justify-start space-x-4 items-center">
                <div className="flex flex-row justify-start items-center space-x-4">
                  <FilterComponent
                    availableFilters={["connector", "stream_name"]}
                    placeHolders={["Connector", "Table"]}
                    filterValues={filters}
                    onFilterChange={handleFilterChange}
                    searchInput={searchInput}
                    onSearchChange={onSearchChange}
                    showFilters={showFilterOptions}
                    showSearch={true}
                  />
                  <div className="flex flex-row space-x-4 ">
                    <FilterButton
                      onClick={handleFilterButtonClick}
                    />
                  </div>
                </div>
              </div>

            </div>
            <div>
              {isLoading && <LoadingSpinner message="LOADING" />}
              {!isLoading && totalItems === 0 && "No products found. Please, import your data"}
              {!isLoading && totalItems > 0 && !selectedSource && (

                <Table
                  headers={headers}
                  rows={generateRows()}
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  onItemsPerPageChange={handleItemsPerPageChange}
                  totalItems={totalItems}
                  onPageChange={handlePageChange}
                  onRowClick={handleRowClick}
                />
              )}
            </div>
          </div>

        </div>
      )
      }
      {selectedSource && (
        <SourceDetails
          handleBack={handleBack}
          source={selectedSource}
        />
      )}

    </>
  );
};

/** @format */

import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import omniloyLogo from "../../assets/icons/omniloy_icon.svg";
import {
  SidebarDropdownOption,
  mainSidebarOptions,
  settingsSidebarOptions,
} from "./SidebarOptions";
import { useFilter } from "../../context/filterContext";
import { useChatVisibility } from "../../context/chatVisibilityContext";
import { useSelection } from "../../context";
import { SidebarItem } from "./SidebarItem";
import { Button, Typography } from "@material-tailwind/react";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { ReactComponent as OmniIcon } from "../../assets/icons/omni_icon.svg";
import { t } from "i18next";

const Sidebar = () => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [openDropdowns, setOpenDropdowns] = useState<{
    [key: string]: boolean;
  }>({});
  const location = useLocation();
  const navigate = useNavigate();
  const { setOpenCreateCustom, setTypeCustom } = useFilter();
  const { setGlobalRender, setCustomRender } = useChatVisibility();
  const { setSelectedOption: setGlobalSelectedOption } = useSelection();

  const currentSidebarOptions = useMemo(() => {
    if (location.pathname.startsWith("/settings")) {
      return settingsSidebarOptions;
    } else {
      return mainSidebarOptions;
    }
  }, [location.pathname]);

  const handleAddCardClick = () => {
    setOpenCreateCustom(true);
  };

  const excludedPaths = [
    "/settings/rawdata",
    "/settings/library",
    "/settings/profile",
    "/settings/semantic-layer",
    "/settings/company",
    "/settings/members",
    "/settings/sources",
  ];
  const isPathExcluded = excludedPaths.some((path) =>
    location.pathname.startsWith(path)
  );

  useEffect(() => {
    const initialDropdowns = currentSidebarOptions.reduce(
      (acc, option) => {
        if (option.dropdownOptions) {
          acc[option.label] = true;
        }
        return acc;
      },
      {} as { [key: string]: boolean }
    );
    setOpenDropdowns(initialDropdowns);
  }, [currentSidebarOptions]);

  useEffect(() => {
    // Actualiza selectedOption basado en la ruta actual
    const pathToLabelMap: { [key: string]: string } = {};
    currentSidebarOptions.forEach((option) => {
      if (option.dropdownOptions) {
        option.dropdownOptions.forEach((dropdownOption) => {
          pathToLabelMap[dropdownOption.path] = dropdownOption.label;
        });
      }
    });

    setSelectedOption(pathToLabelMap[location.pathname] || null);
  }, [location.pathname, currentSidebarOptions]);

  const redirectToHome = useCallback(() => {
    navigate("/");
    setOpenCreateCustom(false);
    setSelectedOption(null);
  }, [navigate]);

  const toggleDropdown = useCallback((label: string) => {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [label]: !prevState[label],
    }));
  }, []);

  const handleOptionSelect = useCallback(
    (option: SidebarDropdownOption, event: React.MouseEvent<HTMLElement>) => {
      setOpenCreateCustom(false);
      setGlobalRender(true);
      setCustomRender(false);
      setTypeCustom("");
      setSelectedOption(option.label ?? null);
      setGlobalSelectedOption(option.label ?? ""); // Actualiza el contexto
      navigate(option.path ?? "/");
    },
    [
      navigate,
      setOpenCreateCustom,
      setGlobalRender,
      setCustomRender,
      setTypeCustom,
      setGlobalSelectedOption,
    ]
  );

  const renderedSidebarOptions = useMemo(
    () =>
      currentSidebarOptions.map((option) => (
        <SidebarItem
          key={option.label}
          label={t(option.label)}
          customIcon={undefined}
          onSelect={(event) => toggleDropdown(option.label)}
          isOpen={openDropdowns[option.label]}
          toggleDropdown={(event) => toggleDropdown(option.label)}
          dropdownOptions={option.dropdownOptions}
        >
          {option.dropdownOptions &&
            openDropdowns[option.label] &&
            option.dropdownOptions.map((dropdownOption) => (
              <div
                key={dropdownOption.path}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignContent: "center",
                  alignItems: "center",
                  gap: "1.5rem",
                  paddingLeft: "2.5rem",
                  borderRadius: "10px",
                  backgroundColor:
                    selectedOption === dropdownOption.label
                      ? "#F2F5FF"
                      : "transparent",
                  cursor: "pointer",
                  marginBottom: "1.5rem",
                  padding: "0.5rem",
                }}
                onClick={(event) => handleOptionSelect(dropdownOption, event)}
              >
                <dropdownOption.Icon
                  className={`w-6 h-6 ${dropdownOption.isHeroIcon ? (selectedOption === dropdownOption.label ? "text-[#0458DD]" : "text-[#79767D]") : selectedOption === dropdownOption.label ? "fill-[#0458DD]" : "fill-[#79767D]"}`}
                />
                <Typography
                  style={{
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "17px",
                    color:
                      selectedOption === dropdownOption.label
                        ? "#0458DD"
                        : "#46474C",
                  }}
                  placeholder={undefined}
                >
                  {" "}
                  {t(dropdownOption.label)}
                </Typography>
              </div>
            ))}
        </SidebarItem>
      )),
    [
      currentSidebarOptions,
      handleOptionSelect,
      openDropdowns,
      selectedOption,
      toggleDropdown,
    ]
  );

  return (
    <div className="flex flex-col w-auto h-screen bg-white rounded-r-[20px] p-6 flex-shrink-0 justify-between shadow-lg">
      <div>
        {location.pathname.startsWith("/settings") ? (
          <div
            style={{ width: "242px" }}
            className="flex items-center py-4 px-1 cursor-pointer border-b border-[#EEEEEEEE]"
            onClick={redirectToHome}
          >
            <ChevronLeftIcon className="w-5 h-5 text-[#46474C]" />
            <Typography
              style={{
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "20px",
                color: "#46474C",
                marginLeft: "0.5rem",
              }}
              placeholder={undefined}
            >
              {t("SETTINGS")}
            </Typography>
          </div>
        ) : (
          <div className="flex-shrink-0 mb-[2.5rem] mt-6">
            <img
              src={omniloyLogo}
              alt="Omniloy Logo"
              className="w-auto h-full cursor-pointer"
              onClick={redirectToHome}
            />
          </div>
        )}
        <div className="flex-grow py-6 ">{renderedSidebarOptions}</div>
        {!isPathExcluded && (
          <div className="mt-4 py-14 border-t border-[#EEEEEEEE] ">
            <Button
              className="w-full"
              style={{
                display: "flex",
                alignItems: "center",
                background: "white",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "17px",
                textTransform: "none",
                color: "#5B26D3",
                gap: "16px",
                borderRadius: "25px",
                border: "solid 1px #D3BFFF",
                padding: "8px 16px 8px 16px",
                transition: "background 0.3s, border 0.3s",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.background = "#F1EBFF";
                e.currentTarget.style.border = "1px solid #EEEEEE";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.background = "white";
                e.currentTarget.style.border = "solid 1px #D3BFFF";
              }}
              onClick={handleAddCardClick}
              placeholder={undefined}
            >
              <OmniIcon /> {t("OMNI_AI")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(Sidebar);

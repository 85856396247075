import React, { useEffect, useState } from "react";
import { ArrowUpTrayIcon } from "@heroicons/react/24/solid";
import { TabsHeader, Tab, TabsBody, TabPanel, Spinner, Typography, Tabs, Button, Input, Dialog } from "@material-tailwind/react";
import { t } from "i18next";
import { ApiConnectionTab } from "./airbyte/ApiConnectionTab";
import { ReactComponent as CsvIcon } from "./../../assets/icons/csv_icon.svg";
import { useFilter } from "../../context/filterContext";
import { privateSupabase } from "../../api/SupabaseClient";
import { readString } from "react-papaparse";
import toast from "react-hot-toast";
import { ApiConnectionPostgresDialog } from "../dataConnection/airbyte/ApiConnectionPostgresDialog";
import { ApiConnectionSnowflakeDialog } from "./airbyte/ApiConnectionSnowflakeDialog";
import { ApiConnectionWooComerceDialog } from "./airbyte/ApiConnectionWooComerceDialog";
import { ApiConnectionMetabaseDialog } from "./airbyte/ApiConnectionMetabaseDialog";
import { ApiConnectionGoogleSheetsDialog } from "./airbyte/ApiConnectionGoogleSheetsDialog";
import { ModalFooter } from "../Modal/ModalFooter";
import { DeleteHeader } from "../Modal/DeleteHeader";
import ChatAssistant from "../OmniChat/ChatAssistant";
import DeleteIconButton from "../Buttons/DeleteIconButton";

export const DataLoader = ({ setIsNext, handleClose }: any) => {
  const [session, setSession] = useState<any>(null);
  const [activeTab, setActiveTab] = useState("upload");
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const [isCreatingTable, setIsCreatingTable] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [fileName, setFileName] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [nextState, setNextState] = useState<boolean>(false);
  const [selectedService, setSelectedService] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [existingTableName, setExistingTableName] = useState<string | null>(null);
  const [csvManagerOpen, setCsvManagerOpen] = useState(false);
  const [dataSourceName, setDataSourceName] = useState<string>("");
  const [dataSourceDesc, setDataSourceDesc] = useState<string>("");

  const {
    updateMessageInput,
    setChartRequest,
    tableName,
    columnConfigurations,
    setColumnConfigurations,
    setTableName,
  } = useFilter();

  const dataTabs = [
    {
      label: t("OPTION_CSV"),
      value: "upload",
    }
  ];

  useEffect(() => {
    privateSupabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  useEffect(() => {
    setTableName("");
    setColumnConfigurations([]);
  }, []);

  useEffect(() => {
    if (session && tableName !== "" && columnConfigurations.length > 0) {
      fetchUserProfileAndInitiateOperations();
    }
  }, [session, tableName, columnConfigurations]);

  const processCSV = async (file: any) => {
    try {
      const text = await file.text();
      readString(text, {
        complete: (results) => {
          setTableData(results.data);
          updateMessageInput(results.data);
          setChartRequest(`Save a new CSV data in a new table. The table name will be 'csv_${fileName!.toLowerCase().replace(/\s+/g, '_')}'`);
        },
        header: true,
        skipEmptyLines: true,
      });
    } catch (error) {
      toast.error(t("ERROR") + "EO-1009");
    }
  };

  useEffect(() => {
    if (fileName && description) {
      setNextState(false); // Close CSV manager view
      processCSV(uploadedFiles[0]);
    }
  }, [fileName, description]);

  const generateTables = async (name: string, description: string) => {
    console.log({ name, uploadedFiles })
    if (uploadedFiles) {
      setIsCreatingTable(true)
      setFileName(name);
      setDescription(description);
    }
  };

  const handleServiceSelection = (service: string) => {
    setSelectedService(service);
    setIsNext(true);
  };

  const handleBack = () => {
    setSelectedService("");
    setIsNext(false);
  };

  const renameKeysDynamically = (obj: { [key: string]: any }, newKeys: string[]): { [key: string]: any } => {
    const oldKeys = Object.keys(obj);
    let newObj: { [key: string]: any } = {};

    oldKeys.forEach((oldKey, index) => {
      const newKey = newKeys[index].split(' ')[0];
      newObj[newKey] = obj[oldKey];
    });

    return newObj;
  };

  const fetchUserProfileAndInitiateOperations = async () => {
    try {
      const createTable = async () => {
        const { error } = await privateSupabase.rpc("new_table_from_csv", {
          name: `csv_${fileName!.toLowerCase().replace(/\s+/g, '_')}`,
          columns: columnConfigurations,
        });
        if (error) {
          toast.error(t("ERROR") + "EO-1010");
          return false;
        }
        return true;
      };

      const insertData = async () => {
        const tableData_: { [key: string]: any }[] = tableData.map((obj: any) => renameKeysDynamically(obj, columnConfigurations));
        const { error: errorInsert } = await privateSupabase.from(`csv_${fileName!.toLowerCase().replace(/\s+/g, '_')}`).insert(tableData_);
        if (errorInsert) {
          toast.error(t("ERROR") + "EO-1011");
          return false;
        }
        return true;
      };

      const updateCsvDataFile = async () => {
        const path = `${fileName!.toLowerCase().replace(/\s+/g, '_')}/${uploadedFiles[0].name}`
        const { error: storageError } = await privateSupabase.storage
          .from("data_files")
          .upload(path, uploadedFiles[0]);

        if (storageError) {
          toast.error(t("ERROR") + "EO-1014");
          return false;
        }

        const { data, error } = await privateSupabase
          .from("data_files")
          .insert([{ name: fileName, description, file: uploadedFiles[0].name, table_name: `csv_${fileName!.toLowerCase().replace(/\s+/g, '_')}` }]).select();

        if (error) {
          toast.error(t("ERROR") + "EO-1015");
          return false;
        }
        return true;
      }

      const tableCreated = await createTable();
      if (!tableCreated) return;
      const dataInserted = await insertData();
      if (!dataInserted) return;

      await updateCsvDataFile();

    } catch (error) {
      toast.error(t("ERROR") + "EO-1016");
    } finally {
      toast.success("Your CSV has been processed!");
      setUploadedFiles([]);
      setIsCreatingTable(false);
      handleClose();
    }
  };

  const handleCSVUpload = (event: any) => {
    const files = event.target.files;
    if (files) {
      const fileList = Array.from(files);
      setUploadedFiles([...fileList]);
    }
  };

  const handleRemoveFile = (indexToRemove: number) => {
    setUploadedFiles(
      uploadedFiles.filter((_: any, index: number) => index !== indexToRemove)
    );
  };

  const checkTableExists = async (tableName: string) => {
    const { data, error } = await privateSupabase.rpc('table_exists', { p_table_name: tableName.toLowerCase().replace(/\s+/g, '_') })

    if (error) {
      toast.error(t("ERROR") + "EO-1043");
      return;
    }

    if (data) {
      setExistingTableName(tableName.toLowerCase().replace(/\s+/g, '_'));
      setIsModalOpen(true);
      return;
    }

    await generateTables(dataSourceName, dataSourceDesc);
    setCsvManagerOpen(false);
  };

  const renderServiceDialog = () => {
    switch (selectedService) {
      case "postgresql":
        return <ApiConnectionPostgresDialog handleBack={handleBack} />;
      case "snowflake":
        return <ApiConnectionSnowflakeDialog handleBack={handleBack} />;
      case "woocommerce":
        return <ApiConnectionWooComerceDialog handleBack={handleBack} />;
      case "metabase":
        return <ApiConnectionMetabaseDialog handleBack={handleBack} />;
      case "google sheets":
        return <ApiConnectionGoogleSheetsDialog handleBack={handleBack} />;
      default:
        return null;
    }
  };

  return (
    <>
      <ChatAssistant custom={true} messageServer={""} isDataLoader={true} />
      {!selectedService ? (
        <div key={activeTab} className="w-auto mt-6 flex-1 rounded-2xl border border-solid border-[#EEEEEE] bg-white mx-14 shadow-md"
          style={{ overflowY: "auto", maxHeight: "calc(100vh - 120px)" }}
        >
          <Tabs value={activeTab}>
            <TabsHeader
              className="rounded-none border-b border-blue-gray-50 bg-transparent p-0 w-full"
              indicatorProps={{
                className: "bg-transparent border-b-4 border-[#0059AD] shadow-none rounded-none w-full",
              }} placeholder={undefined}>
              {dataTabs.map(({ label, value }) => (
                <Tab
                  key={value}
                  value={value}
                  onClick={() => setActiveTab(value)}
                  className={`text-lg font-semibold justify-start px-6 py-4 ${activeTab === value ? "text-[#0059AD]" : "text-[#E6E0E9]"}`} placeholder={undefined}>
                  {label}
                </Tab>
              ))}
            </TabsHeader>
            <TabsBody placeholder={undefined}>
              {dataTabs.map(({ value }) => (
                <TabPanel
                  key={value}
                  value={value}
                  className="p-0"
                >
                  {value === "upload" && (
                    <div className="tab-website">
                      <div className="!p-0">
                        <div className="relative min-h-full overflow-auto ">
                          {isCreatingTable && (
                            <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-30 flex items-center justify-center z-50 rounded">
                              <div className="text-center w-full justify-center">
                                <Spinner
                                  color="blue"
                                  className="h-12 w-12 flex mx-auto" />
                                <p className="text-white mt-4">
                                  {t("SETTING_TABLES")}
                                </p>
                              </div>
                            </div>
                          )}
                          <div className="flex-1 px-6 pt-4 pb-8 min-w-full min-h-full">
                            <div className="csv-upload-zone space-y-6">
                              <Typography placeholder={undefined}>
                                {t("CSV_COLUMNS")}
                              </Typography>
                              <div className="flex !w-full justify-center">
                                <div className="flex flex-col space-y-4 w-full">
                                  <div
                                    className={`w-full border-2 border-dotted border-gray-300 rounded-lg 
                                      ${uploadedFiles.length > 0 ? "py-24" : "py-44"} flex flex-col items-center`}
                                  >
                                    <Typography className="text-gray-500 text-sm mb-6" placeholder={undefined}>
                                      {t("SELECT_CSV")}
                                    </Typography>
                                    <label
                                      htmlFor="csvFileInput"
                                      className="cursor-pointer bg-white text-gray-700 py-3 px-8 rounded-lg shadow-sm hover:bg-gray-50 border border-[#E6E0E9] flex justify-center items-center space-x-2 mx-auto"
                                    >
                                      <ArrowUpTrayIcon className="w-6 h-6" />
                                      <Typography className="text-base leading-normal" placeholder={undefined}>
                                        {t("UPLOAD")}
                                      </Typography>
                                      <input
                                        type="file"
                                        accept=".csv"
                                        onChange={handleCSVUpload}
                                        style={{ display: "none" }}
                                        id="csvFileInput"
                                      />
                                    </label>
                                  </div>
                                  {uploadedFiles.length > 0 && (
                                    <div className="mt-6 w-full h-auto">
                                      <Typography className="mb-3" placeholder={undefined}>
                                        {t("File")}
                                      </Typography>
                                      <div
                                        className="overflow-y-auto"
                                        style={{ maxHeight: "9.75rem" }}
                                      >
                                        {uploadedFiles.map(
                                          (file: any, index: number) => (
                                            <div
                                              key={index}
                                              className={`flex bg-white border-2 border-[#E6E0E9] px-4 py-2 items-center justify-between overflow-y-auto ${index !== 0
                                                ? "border-t-0"
                                                : ""
                                                }`}
                                            >
                                              <div className="flex items-center space-x-4">
                                                <CsvIcon />
                                                <Typography className="font-medium text-[#AEA9B1]" placeholder={undefined}>
                                                  {file.name.replace(/\.csv$/, "").toUpperCase()}
                                                </Typography>
                                              </div>
                                              <DeleteIconButton showText={false} onDelete={() => handleRemoveFile(index)} />
                                            </div>
                                          )
                                        )}
                                        <div className="flex justify-end mt-2">
                                          <Button
                                            className="border border-blue-500 text-white bg-blue-500 hover:bg-blue-700 rounded-lg"
                                            onClick={() => setCsvManagerOpen(true)}
                                            disabled={isCreatingTable} placeholder={undefined}>
                                            {t("NEXT")}
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Dialog
                        className="p-4 bg-gray-100 max-h-[1000px] overflow-y-auto"
                        open={csvManagerOpen}
                        handler={() => setCsvManagerOpen(false)} placeholder={undefined}
                      >
                        <div className="p-4 bg-gray-100 max-h-[1000px] overflow-y-auto">
                          <div className="flex flex-col border p-4 rounded-xl bg-white shadow mb-4">
                            <div className="mb-4">
                              <Typography className="text-xs text-black my-1" placeholder={undefined}>
                                {t("NAME")}
                              </Typography>
                              <Input placeholder="Type here" name="dataSourceName"
                                value={dataSourceName}
                                onChange={(e) => setDataSourceName(e.target.value)} crossOrigin={undefined} />
                            </div>
                            <div className="mb-4">
                              <Typography className="text-xs text-black my-1" placeholder={undefined}>
                                {t("DATA_DESCRIPTION")}
                              </Typography>
                              <Input placeholder="Type here" name="dataSourceDesc"
                                value={dataSourceDesc}
                                onChange={(e) => setDataSourceDesc(e.target.value)} crossOrigin={undefined} />
                            </div>
                          </div>
                          <div className="flex justify-end space-x-4">
                            <Button className="text-blue-500 border border-blue-500 bg-white hover:bg-blue-50 rounded-lg" onClick={() => setCsvManagerOpen(false)} variant="outlined" placeholder={undefined}>
                              {t("BACK")}
                            </Button>
                            <Button className="border border-blue-500 text-white bg-blue-500 hover:bg-blue-700 rounded-lg" color="blue" onClick={() => checkTableExists(dataSourceName)} placeholder={undefined}>
                              {t("SAVE")}
                            </Button>
                          </div>
                        </div>
                      </Dialog>
                      <Dialog
                        style={{ width: "970px", minWidth: "970px" }}
                        open={isModalOpen}
                        handler={() => setIsModalOpen(false)} placeholder={undefined}
                      >
                        <DeleteHeader title={t("WARNING_DUPLICATE")} onClose={() => setIsModalOpen(false)} />
                        <div className="flex justify-start gap-8 mt-6 pb-8">
                          <Typography style={{ padding: "0px 80px" }} placeholder={undefined}>
                            {t("TABLE_EXISTS")} {existingTableName}. {t("CHANGE_NAME")}
                          </Typography>
                        </div>
                        <ModalFooter
                          isRed={false}
                          buttonText={t("BACK")}
                          onClick={() => setIsModalOpen(false)}
                        />
                      </Dialog>
                    </div>
                  )}
                  {value === "api" && (
                    <ApiConnectionTab handleServiceSelection={handleServiceSelection} />
                  )}
                </TabPanel>
              ))}
            </TabsBody>
          </Tabs>
        </div>
      ) : (
        renderServiceDialog()
      )}
    </>
  );
};


import React from "react";
import {
    ArrowsPointingOutIcon,
} from "@heroicons/react/24/outline";
import { t } from "i18next";

const ExpandIconButton = ({
    onClick
}: {
    onClick: () => void;
}) => {
    return (
        <button
            className="flex px-4 py-2 text-gray-800 hover:bg-gray-100 w-full text-left items-center gap-2"
            onClick={onClick}
        >
            <ArrowsPointingOutIcon className="w-5 h-5" />
            {t("EXPAND")}
        </button>
    )
}

export default ExpandIconButton;
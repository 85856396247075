import React from "react";
import toast, { ToastBar, Toaster, resolveValue } from "react-hot-toast";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";

const CustomToast = () => {
  return (
    <Toaster
      position="top-right"
      toastOptions={{
        className: '',
        duration: 5000,
        style: {
          color: '#fff',
          background: "unset",
          boxShadow: "unset",
          minWidth: "fit-content"
        },
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                background: t.type === 'success' ? '#51B767' : t.type === 'error' ? '#B3261E' : '#333', // Adjust background based on toast type
                borderRadius: '8px',
                padding: '8px 16px',
                color: '#fff',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <div style={{ marginRight: '12px' }}>
                {t.type === 'success' ? (
                  <CheckCircleIcon className="h-6 w-6 text-white" style={{ color: '#fff' }} />
                ) : t.type === 'error' ? (
                  <XCircleIcon className="h-6 w-6 text-white" style={{ color: '#fff' }} />
                ) : (
                  icon
                )}
              </div>
              <div style={{ flexGrow: 1 }}>
                {resolveValue(message, t)}
              </div>
              <button
                onClick={() => toast.dismiss(t.id)}
                style={{
                  marginLeft: '12px',
                  background: 'transparent',
                  border: 'none',
                  color: '#fff',
                  cursor: 'pointer',
                  position: "relative",
                  top: "-8px"
                }}
              >
                &times;
              </button>
            </div>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};

export default CustomToast;

import React, { useState, useEffect } from 'react';
import { Button, DialogFooter, Input, Switch, Typography } from '@material-tailwind/react';
import { createConnection, getSourceStreams, launchSync } from '../../../api/airbyte/apiFunctions';
import { UUID } from 'crypto';

interface CheckedState {
  [key: string]: boolean;
}

interface StreamListProps {
  sourceId: string;
  connectionName: string;
  handleClose: () => {};
}

const ApiConnectionStreamsDialog = ({ sourceId, connectionName, handleClose }: StreamListProps) => {
  const [streams, setStreams] = useState<any[]>([]);
  const [checkedState, setCheckedState] = useState<CheckedState>({});
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(true);
  const [prefix, setPrefix] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getSourceStreams(sourceId);
        setStreams(data);
        setLoading(false);
        const initialState = data.reduce((acc: any, stream: { streamName: any; }) => ({ ...acc, [stream.streamName]: false }), {});
        setCheckedState(initialState);
      } catch (err) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSwitchChange = (streamName: string) => {
    const newState = {
      ...checkedState,
      [streamName]: !(checkedState[streamName] ?? false)
    };
    setCheckedState(newState);
    setSelectAll(Object.values(newState).every(Boolean));
  };

  const handleSelectAllChange = () => {
    const newState = Object.keys(checkedState).reduce((acc, key) => ({
      ...acc,
      [key]: !selectAll
    }), {});
    setCheckedState(newState);
    setSelectAll(!selectAll);
  };

  const handleSubmit = async () => {

    const selectedStreams = Object.entries(checkedState)
      .filter(([streamName, isChecked]) => isChecked)
      .map(([streamName]) => ({
        name: streamName,
        syncMode: 'full_refresh_overwrite'
      }));

    const connectionParams = {
      connectionName,
      sourceId: sourceId as UUID,
      destinationId: `19421644-ee0c-4b6c-b77b-b8bc8bc1cdf4` as UUID,
      configurations: {
        streams: selectedStreams
      },
      prefix
    }

    const connection = await createConnection(connectionParams);

    launchSync(connection.connectionId)
    handleClose()
  };

  if (loading) return <div className="text-center">Loading...</div>;

  return (
    <div>
      <div className="flex flex-col border p-4 rounded-xl bg-white shadow mb-4"
        style={{ maxHeight: '500px', overflowY: 'auto', overflowX: 'hidden' }}>
        <Typography className="text-sm my-1" placeholder={undefined}  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          Select tables
        </Typography>
        <div className="flex justify-between items-center py-2 border-b">
          <Typography className="text-gray-800 text-xs font-bold" placeholder={undefined}  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Select All</Typography>
          <Switch
            color="blue"
            checked={selectAll}
            onChange={handleSelectAllChange}
            crossOrigin={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}          />
        </div>
        {streams.map((item, index) => (
          <div key={index} className="flex justify-between items-center py-2 border-b border-gray-300">
            <div className="text-gray-800 text-xs">{item.streamName}</div>
            <Switch
              color="blue"
              checked={checkedState[item.streamName] || false}
              onChange={() => handleSwitchChange(item.streamName)}
              crossOrigin={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            />
          </div>
        ))}
      </div>
      <div className="flex flex-col border p-4 rounded-xl bg-white shadow mb-4">
        <Typography className="text-xs my-1" placeholder={undefined}  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          Tables prefix
        </Typography>
        <Input
          type="text"
          placeholder="Enter table prefix"
          value={prefix}
          onChange={e => setPrefix(e.target.value)}
          crossOrigin={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}        />
      </div>
      <DialogFooter placeholder={undefined} className="space-x-4 mt-4"  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <Button
          color="red"
          onClick={handleClose}
          variant="outlined"
          placeholder={undefined}  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}        >
          Cancel
        </Button>
        <Button color="blue" onClick={handleSubmit} placeholder={undefined}  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          Save
        </Button>
      </DialogFooter>
    </div>
  );

};

export default ApiConnectionStreamsDialog;


import React from "react";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { t } from "i18next";

const DownloadIconButton = ({
    isDisabled = false,
    showText,
    onClick
}: {
    isDisabled?: boolean,
    showText: boolean;
    onClick: () => void;
}) => {
    return (
        <button disabled={isDisabled}
            className={`flex px-4 py-2 text-gray-800 ${showText ? "hover:bg-gray-100" : (isDisabled ? "" : "hover:text-gray-500")} w-full text-left items-center gap-2`}
            onClick={onClick}
        >
            <ArrowDownTrayIcon className="w-5 h-5" />
            {showText && (
                t("DOWNLOAD")
            )}
        </button>
    )
}

export default DownloadIconButton;
import React from 'react';
import { Button } from "@material-tailwind/react";
import { FunnelIcon } from "@heroicons/react/24/outline";
import { t } from 'i18next';
import { ButtonProperties } from './MainButton';

const FilterButton = ({ onClick }: ButtonProperties) => {
    return (
        <Button
            style={{
                minWidth: "140px",
                textTransform: "none",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#48464C",
                background: "white"
            }}
            className="h-10 border border-[#AEA9B1]"
            variant="outlined"
            onClick={onClick}
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
        >
            <div className="flex bg-[white] justify-center items-center gap-2">
                <FunnelIcon className="h-4 w-4 cursor-pointer " />
                {t("FILTERS")}
            </div>
        </Button>
    );
};

export default FilterButton;

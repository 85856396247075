/** @format */

import {
  Bar,
  Line,
  Doughnut,
  Pie,
  Radar,
  PolarArea,
  Bubble,
  Scatter,
} from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { applyDatasetsColors, applyMultipleColors } from "../../utils/chartColorUtils";
Chart.register(...registerables);

export const ChartCard = ({
  data,
  type,
  colors,
  labels
}: any) => {
  const cardStyle = "w-full h-auto p-8";
  let multiColoredData: any = { ...data };
  if (!data || !data.datasets || !Array.isArray(data.datasets)) {
    return (
      <div
        className={`${cardStyle} flex items-center justify-center bg-gray-100`}
      >
        <p className="text-gray-500">No data available</p>
      </div>
    );
  }
  if (colors) {
    if (data.datasets && labels !== undefined) {
      if (data.datasets.length !== 1 && (type === "bar" || type === "line")) {
        multiColoredData = {
          ...data,
          datasets: applyDatasetsColors(data, colors),
          labels: labels
        };
      } else {
        multiColoredData = {
          ...data,
          datasets: applyMultipleColors(data, colors),
          labels: labels
        };
      }
    } else {
      if (data.datasets.length !== 1 && (type === "bar" || type === "line")) {
        multiColoredData = {
          ...data,
          datasets: applyDatasetsColors(data, colors),
        };
      } else {
        multiColoredData = {
          ...data,
          datasets: applyMultipleColors(data, colors),
        };
      }
    }
  }

  const options: any = {
    plugins: {
      legend: {
        position: type === "line" || type === "bar" ? "left" : "bottom",
        display: true,
      },
    },
    elements: {
      bar: {
        barPercentage: 0.5,
        barThickness: 6,
        maxBarThickness: 8,
        minBarLength: 2,
        borderRadius: 10,
      },
    },
    indexAxis: "x",
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: type === "line" || type === "bar" ? true : false,
          text: data?.legend ? data.legend[0] : "",
          font: {
            size: 10,
            weight: 'bold',
            lineHeight: 1.2,
          },
        },
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  switch (type) {
    case "line":
      return (
        <Line
          data={multiColoredData}
          options={options}
          className={cardStyle}
        />
      );
    case "doughnut":
      return (
        <Doughnut
          data={multiColoredData}
          options={options}
          className={cardStyle}
        />
      );
    case "bar":
      return (
        <Bar
          data={multiColoredData}
          options={options}
          className={cardStyle}
        />
      );
    case "pie":
      return (
        <Pie
          data={multiColoredData}
          options={options}
          className={cardStyle}
        />
      );
    case "radar":
      return (
        <Radar
          data={multiColoredData}
          options={options}
          className={cardStyle}
        />
      );
    case "polarArea":
      return (
        <PolarArea
          data={multiColoredData}
          options={options}
          className={cardStyle}
        />
      );
    case "bubble":
      return (
        <Bubble
          data={multiColoredData}
          options={options}
          className={cardStyle}
        />
      );
    case "scatter":
      return (
        <Scatter
          data={multiColoredData}
          options={options}
          className={cardStyle}
        />
      );
    default:
      return (
        <Bar
          data={multiColoredData}
          options={options}
          className={cardStyle}
        />
      );
  }
};

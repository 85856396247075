

/** @format */
import React from "react";
import MainButton from "../../components/Buttons/MainButton";
import Table from "../../components/Table";
import LoadingSpinner from "../../components/LoadingSpinner";

interface SourceDetailsProps {
    columnHeaders: any;
    rows: any;
    currentPage: any;
    itemsPerPage: any;
    handleItemsPerPageChange: any;
    totalItems: any;
    handlePageChange: any;
    handleOpen: any;
    role: number;
    loading: boolean;
}
const SourceDetails = ({
    columnHeaders,
    rows,
    currentPage,
    itemsPerPage,
    handleItemsPerPageChange,
    totalItems,
    handlePageChange,
    handleOpen,
    role,
    loading
}: SourceDetailsProps) => {
    return (
        <>
            <div className="flex flex-row justify-between items-center px-8 mt-8 mb-4">
                <div className="flex flex-row justify-start items-center space-x-4">
                </div>
                {role !== 3 &&
                    <div className="flex justify-end space-x-4">
                        <MainButton buttonText={"CREATE_SYNC"} onClick={handleOpen} />
                    </div>
                }
            </div>
            {loading ? (
                <LoadingSpinner message="LOADING" />

            ) : (
                <div className="flex flex-col h-full px-8 py-8">
                    <Table
                        headers={columnHeaders}
                        rows={rows}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        onItemsPerPageChange={handleItemsPerPageChange}
                        totalItems={totalItems}
                        onPageChange={handlePageChange}
                    />
                </div>
            )}
        </>
    );
};

export default SourceDetails;

/** @format */

import React, { useState } from "react";
import DownloadOptions from "./DownloadOptions";
import DeleteIconButton from "../Buttons/DeleteIconButton";
import ExpandIconButton from "../Buttons/ExpandIconButton";
import DuplicateIconButton from "../Buttons/DuplicateIconButton";
import DownloadIconButton from "../Buttons/DownloadIconButton";
import EditIconButton from "../Buttons/EditIconButton";

const CardOptionsMenu = ({
  isEditMode,
  handleDuplicate,
  handleDelete,
  setIsDialogOpen,
  deleteElement,
  openEdit,
  handleDownloadOptionClick,
  type,
  handleEditOptionClick,
  isLibrary = false
}: any) => {
  const [isDownloadOptionsOpen, setIsDownloadOptionsOpen] = useState(false);
  return (
    <div className="absolute right-8 -top-2 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-10">
      <div className="py-1">
        {isEditMode ? (
          <>
            <ExpandIconButton onClick={() => {
              setIsDialogOpen(true);
            }} />
            <DuplicateIconButton showText={true} onClick={handleDuplicate} />
            <DeleteIconButton showText={true} onDelete={handleDelete} />

          </>
        ) : (
          <>
            <ExpandIconButton onClick={() => {
              setIsDialogOpen(true);
            }} />
            <DownloadIconButton showText={true} onClick={() => setIsDownloadOptionsOpen(!isDownloadOptionsOpen)} />
            {isDownloadOptionsOpen && (
              <DownloadOptions
                onOptionClick={handleDownloadOptionClick}
                type={type}
              />
            )}
            {isLibrary && (
              <>
                <EditIconButton showText={true} onClick={openEdit} />
                <DeleteIconButton showText={true} onDelete={deleteElement} />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CardOptionsMenu;

/** @format */

import React from "react";
import { Card } from "./types";
import { t } from "i18next";
import { Dialog, DialogHeader } from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Library from "../Library";

interface CardSelectionModalProps {
  open: boolean;
  onClose: () => void;
  onSelect: (card: Card) => void;
}

const CardSelectionModal: React.FC<CardSelectionModalProps> = ({
  open,
  onClose,
  onSelect,
}) => {

  return (
    <Dialog open={open} handler={onClose} size="lg" className="rounded-3xl " placeholder={undefined}>
      <DialogHeader
        className="flex justify-between p-8 items-center"
        placeholder={undefined}
      >
        <span>{t("SELECT_ELEMENT")}</span>
        <XMarkIcon
          className="w-8 h-8 text-[#76787D] cursor-pointer"
          onClick={onClose}
        />
      </DialogHeader>
      <div className="overflow-y-auto h-[80vh] m-y-auto">
        <Library onClick={onSelect} isSelect={true} role={3} />
      </div>
    </Dialog>
  );
};

export default CardSelectionModal;

import React from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";

interface CloseButtonProps {
  onClick: () => void;
  className?: string;
  altText?: string;
}

const CloseButton: React.FC<CloseButtonProps> = ({ onClick, className, altText = "Close" }) => {
  return (
    <button
      className={`flex px-4 py-2 text-gray-800 hover:text-gray-500 text-left items-center gap-2`}
      onClick={onClick}
    >
      <XMarkIcon className={`${className ? className : "w-5 h-5"}`} onClick={onClick} />
    </button>
  )
};

export default CloseButton;

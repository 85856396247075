/** @format */

export const COLORS = [
  { name: "Cyan", value: "rgb(75,192,192)" },
  { name: "Yellow", value: "rgb(255,205,86)" },
  { name: "Orange", value: "rgb(255,159,64)" },
  { name: "Indigo", value: "rgb(99, 102, 241)" },
  { name: "Pink", value: "rgb(255,99,132)" },
  { name: "Green", value: "rgb(20, 184, 166)" },
  { name: "Purple", value: "rgb(168, 85, 247)" },
  { name: "Red", value: "rgb(239, 68, 68)" },
  { name: "Blue", value: "rgb(54,162,235)" },
  { name: "Gray", value: "rgb(107, 114, 128)" },
];

export const BORDER_COLORS = [
  { name: "Cyan", value: "rgb(75,192,192)" },
  { name: "Yellow", value: "rgb(255,205,86)" },
  { name: "Orange", value: "rgb(255,159,64)" },
  { name: "Indigo", value: "rgb(99, 102, 241)" },
  { name: "Pink", value: "rgb(255,99,132)" },
  { name: "Green", value: "rgb(20, 184, 166)" },
  { name: "Purple", value: "rgb(168, 85, 247)" },
  { name: "Red", value: "rgb(239, 68, 68)" },
  { name: "Blue", value: "rgb(54,162,235)" },
  { name: "Gray", value: "rgb(107, 114, 128)" },
];

export const applyMultipleColors = (data: any, colorNames: string[]) => {
  if (!data.datasets) {
    return data;
  }

  const getColorByName = (colorArray: any[], name: string) => {
    const color = colorArray.find((c) => {
      let colorName = name;
      if (name.includes("-")) {
        colorName = name.split("-")[1];
      }
      return c.name.toLowerCase() === colorName.toLowerCase();
    });
    return color ? color.value : colorArray[0].value;
  };

  return data.datasets.map((dataset: any) => ({
    ...dataset,
    backgroundColor: dataset.data.map((_: any, index: number) => {
      const colorName = colorNames[index] || colorNames[colorNames.length - 1];
      return getColorByName(COLORS, colorName);
    }),
    borderColor: dataset.data.map((_: any, index: number) => {
      const colorName = colorNames[index] || colorNames[colorNames.length - 1];
      return getColorByName(BORDER_COLORS, colorName);
    }),
  }));
};

export const applyDatasetsColors = (data: any, colorNames: string[]) => {
  if (!data.datasets) {
    return data;
  }

  const getColorByName = (colorArray: any[], name: string) => {
    const color = colorArray.find((c) => {
      let colorName = name;
      if (name.includes("-")) {
        colorName = name.split("-")[1];
      }
      return c.name.toLowerCase() === colorName.toLowerCase();
    });
    return color ? color.value : colorArray[0].value;
  };

  return data.datasets.map((dataset: any, index: number) => {
    const colorName = colorNames[index] || colorNames[colorNames.length - 1];
    return {
      ...dataset,
      backgroundColor: getColorByName(COLORS, colorName),
      borderColor: getColorByName(BORDER_COLORS, colorName)
    };
  });

};

export const colorByName = (colorArray: any[], name: string) => {
  const color = colorArray.find((c) => {
    let colorName = name;
    if (name.includes("-")) {
      colorName = name.split("-")[1];
    }
    return c.name.toLowerCase() === colorName.toLowerCase();
  });
  return color ? color.value : colorArray[0].value;
};

export const getRGBColors = (colorNames: string[]) => {
  return colorNames.map((name) => colorByName(COLORS, name));
};

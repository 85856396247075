/** @format */
import React from "react";
import MainMetrics from "../../components/MainMetrics";

export function Dashboard() {
  return (
    <>
      <div
        key={"mainMetrics"}
        className="w-full pb-12 px-6 pt-4 bg-[#F2F5FF]"
        style={{ overflowY: "auto", height: "calc(100vh - 120px)" }}
      >
        <div className="tab-mainMetrics">
          <div className="!p-0">
            <MainMetrics />
          </div>
        </div>
      </div>
    </>
  );
}

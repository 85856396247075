/** @format */

import React, { useState, useEffect, useRef } from "react";
import { Spinner } from "@material-tailwind/react";
import { ReactComponent as ChatSpark } from "../../assets/icons/chat_spark_icon.svg";
import {
  HandThumbDownIcon,
  HandThumbUpIcon,
  ArrowPathIcon,
  ShareIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { useFilter } from "../../context/filterContext";
import useDashboardData from "../../pages/DashboardCreator/useDashboardData";
import { useChatVisibility } from "../../context/chatVisibilityContext";
import { t } from "i18next";
import { getConfig } from "../../config";
import defaultPlaceholder from "../../assets/images/blank-profile-picture.png";
import {
  getCubeCompanyName,
  privateSupabase,
  userSession,
} from "../../api/SupabaseClient";
import GlobalFilterBar from "../../components/GlobalFilterBar";
import VerifyButton from "./verifyButton";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import toast from "react-hot-toast";
import {
  calculateResult,
  generateRandomId,
  createIncrementalCounter,
  capitalizeFirstLetter,
  checkForKeyPhrases,
  listToArray,
  renameKeysToMatchHeadersSave,
} from "../../utils/chatAIUtils";
import VerifyDialog from "./VerifyDialog";
import PromptInput from "./PromptInput";
import SaveButton from "./SaveButton";
import DataPointRender from "./DataPointRender";
import TableRender from "./TableRender";
import ChartRender from "./ChartRender";
import useCubeData from "../../hooks/useCubeData";
import { milliseconds } from "date-fns";

const ChatAssistant = ({ custom, messageServer, isDataLoader = false }) => {
  const [ws, setWs] = useState(null);
  const { websocketServer } = getConfig();
  const [currentTask, setCurrentTask] = useState("");
  const [isConnected, setIsConnected] = useState(false);
  const [hasUserStartedTyping, setHasUserStartedTyping] = useState(false);
  const { globalRender } = useChatVisibility();
  const [isDownloadOptionsOpen, setIsDownloadOptionsOpen] = useState(false);
  const cardRef = useRef(null);
  const projectUrl = localStorage.getItem("supabaseProjectUrl");
  const successMessageAdded = useRef(false);
  const [isSaved, setIsSaved] = useState(false);
  const [savedItems, setSavedItems] = useState({});
  //Verify Dialog
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogOpen = () => setDialogOpen(!dialogOpen);
  const [clearedFilters, setClearedFilters] = useState(false);
  //Filters on Tables
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const fromIndex = (currentPage - 1) * itemsPerPage;
  const toIndex = fromIndex + itemsPerPage;
  const [paginatedData, setPaginatedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isConfigured, setIsConfigured] = useState(false);

  //Profile data
  const [profileDetails, setProfileDetails] = useState();
  const [session, setSession] = useState(null);

  const [, setRenderTrigger] = useState(0);

  const [selectedMeasures, setSelectedMeasures] = useState([]);
  const [selectedDimensions, setSelectedDimensions] = useState([]);
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedTimeDimensions, setSelectedtimeDimensions] = useState([]);
  const { cubeMetadata, filterData, handleExecuteQueryCube } = useCubeData();

  const [initialQuery, setInitialQuery] = useState("");

  const getProfileDetails = async () => {
    try {
      const { data, error } = await privateSupabase
        .from("profiles")
        .select(
          `
            *,
            role:roles(role_description),
            company:company_details(company)
          `
        )
        .eq("id", session.user.id)
        .single();
      if (error) throw error;

      setProfileDetails(data);
    } catch (error) {
      console.error("ERROR: ", error);
    }
  };

  const getImageSrc = () => {
    if (profileDetails?.profile_picture && session?.user?.id) {
      return `${projectUrl}/storage/v1/object/public/profile_pictures/${session?.user?.id}/${profileDetails?.profile_picture}`;
    }
    return defaultPlaceholder;
  };

  useEffect(() => {
    if (session) {
      const fetchCompanyDetails = async () => {
        await getProfileDetails();
      };
      fetchCompanyDetails();
    }
  }, [session]);

  const messagesEndRef = useRef(null);
  const containerRef = useRef(null);
  const downloadButtonRef = useRef(null);
  const {
    csvInput,
    chartRequest,
    setTableName,
    tableDefinition,
    allTableNames,
    setTablesExplanation,
    openCreateCustom,
    setOpenCreateCustom,
    setTypeCustom,
    setHasMessages,
    setTableHeaders,
    setTableData,
    setTableTitle,
    setTableDescription,
    initialMessage,
    setInitialMessage,
    setNoDataQuery,
    setColumnConfigurations,
    allSelectedTablesInfo,
    setCleanedQuery,
    chartChatData,
    setRenderTypes,
    currentView,
    tableTitle,
    tableData,
    tableHeaders,
    noDataQuery,
    chartType,
    tablesExplanation,
    cleanedQuery,
    datapointInfo,
    setTableStep,
    setTypeUsage,
    setSegmentStep,
    setChartStep,
    renderType,
    setQuerySteps,
    setQueryError,
    setQueryErrorCode,
    setQueryErrorHint,
    setQueryErrorExplanation,
    querySteps,
    queryError,
    queryErrorCode,
    queryErrorHint,
    queryErrorExplanation,
    newThreadId,
    setLastSqlQuery,
    lastSqlQuery,
    threadId,
    setThreadId,
    setNewThreadId,
    setChatHistory,
    tables,
    setTables,
    typeCustoms,
    setTypeCustoms,
    renderTypes,
    currentTable,
    setCurrentTable,
    selectedRep,
    setSelectedRep,
    setShouldScroll,
    isCreateAi,
    setIsCreateAi,
    setRenderType,
    setChartChatData,
    setTask,
    message,
    setMessage,
    sqlDescription,
    setSqlDescription,
    messages,
    setMessages,
  } = useFilter();

  useEffect(() => {
    localStorage.removeItem("actualMessageUser");
    localStorage.removeItem("currentTask");
    setMessage("");
    setMessages([]);
    setTypeCustoms([]);
    setRenderTypes([]);
    setTables([]);
    setMessages([
      {
        id: Date.now(),
        text: t("ASSISTANT"),
        sender: "server",
        type: "text",
      },
    ]);
  }, []);

  const {
    draggableItems,
    setDraggableItems,
    isLoading,
    dashboardTitle,
    tempTitle,
    setDashboardTitle,
  } = useDashboardData(selectedRep);

  const handleEditTitle = (tableId) => {
    setTables((prevTables) => {
      const newTables = prevTables.map((table) =>
        table.id === tableId ? { ...table, isEditingTitle: true } : table
      );
      return newTables;
    });
  };

  const handleTitleChange = (tableId, newTitle) => {
    setTables((prevTables) =>
      prevTables.map((table) =>
        table.id === tableId
          ? { ...table, editableTitle: newTitle || "" }
          : table
      )
    );
  };

  const handleSaveTitle = (tableId) => {
    setTables((prevTables) => {
      const newTables = prevTables.map((table) =>
        table.id === tableId
          ? {
              ...table,
              tableTitle: table.editableTitle,
              isEditingTitle: false,
            }
          : table
      );
      return newTables;
    });
  };

  const handleCancelEdit = (tableId) => {
    setTables((prevTables) =>
      prevTables.map((table) =>
        table.id === tableId
          ? {
              ...table,
              editableTitle: table.tableTitle,
              isEditingTitle: false,
            }
          : table
      )
    );
  };

  const generateIndex = createIncrementalCounter();

  // Ensure the threadId is generated when a new chat is initiated
  useEffect(() => {
    if (!threadId) {
      const initialThreadId = generateRandomId();
      setThreadId(initialThreadId);
      if (newThreadId) {
        setThreadId(initialThreadId);
      }
    }
    if (newThreadId) {
      const initialThreadId = generateRandomId();
      setThreadId(initialThreadId);
      setNewThreadId(false); // Reset the flag after generating the new thread ID
    }
    if (messages.length === 0) {
      setHasMessages(false);
    } else {
      setHasMessages(true);
    }
    if (messageServer) {
      setMessages([
        {
          text: messageServer,
          sender: "server",
          type: "text",
        },
      ]);
    }
  }, [newThreadId, threadId, messages, messageServer]);

  useEffect(() => {
    if (renderTypes.length > 0) {
      setMessages((prevMessages) => {
        // Filter out duplicates and messages with "Here is your final result" without an ID
        const filteredMessages = prevMessages.filter((message) => {
          return !(message.text === "Here is your final result" && !message.id);
        });

        // Check if the current task ID is already in the filtered messages
        if (!filteredMessages.some((message) => message.id === currentTask)) {
          return [
            ...filteredMessages,
            {
              id: currentTask,
              text: "Here is your final result",
              sender: "server",
              type: "text",
            },
          ];
        }

        return filteredMessages;
      });
      clearProcessingMessage();
    }
  }, [renderTypes, currentTask]);

  useEffect(() => {
    const handleAuthStateChange = (_event, newSession) => {
      setSession(newSession);
    };

    userSession().then((session) => {
      setSession(session.data.session);
    });

    privateSupabase.auth.onAuthStateChange(handleAuthStateChange);
  }, []);

  const chatClasses = `flex flex-col ${
    custom || openCreateCustom
      ? "w-full h-screen"
      : "fixed bottom-0 right-10 z-50 w-5/10 w-[430px] h-[500px] "
  } transform transition-transform 
    ${
      custom || openCreateCustom
        ? "!min-h-[calc(100vh-400px)] overflow-y-auto scroll-smooth"
        : ""
    }
  }`;

  const contentClasses = `flex flex-col flex-grow bg-[#F7F7F7] ${
    custom || openCreateCustom
      ? "rounded-2xl pt-32 pl-32 pr-32 pb-10 overflow-y-auto"
      : "p-6 rounded-none overflow-y-auto"
  }  w-full`;

  function organizeData(data) {
    const dataOrganized = { filter: [] };

    if (!Array.isArray(data)) {
      throw new Error("Expected an array of operations");
    }

    data.forEach((operation) => {
      switch (operation.function_name) {
        case "createSQLquery": {
          dataOrganized.sqlQuery = operation.arguments.sql;
          break;
        }
        case "createTableStructure": {
          setTableName(operation.arguments.tableName);
          setColumnConfigurations(operation.arguments.columns);
          break;
        }
        default:
          console.warn(`Unhandled function name: ${operation.function_name}`);
          break;
      }
    });

    return dataOrganized;
  }

  useEffect(() => {
    if (isConnected && ws) {
      if (initialMessage !== "" && !globalRender) {
        setMessages(() => [
          {
            id: Date.now(),
            text: initialMessage,
            sender: "user",
            type: "text",
          },
        ]);
        const appendedMessage = `${initialMessage} based on this table names: ${allTableNames}`;
        const response = {
          type: "query",
          task: appendedMessage,
          thread_id: threadId,
        };
        ws && ws.send(JSON.stringify(response));
      }
    }
  }, [isConnected, ws, globalRender]);

  const handleInputChange = (e) => {
    setMessage(e.target.value);
    setInitialMessage(e.target.value);

    if (!hasUserStartedTyping) {
      setHasUserStartedTyping(true);
    }
  };
  useEffect(() => {
    if (message.toLowerCase().includes("campaign")) {
      setTableName("");
    }
  }, [message, setTableName]);

  const sendResponse = (currentMessage = message) => {
    if (!message.trim() && !currentMessage.trim()) return;
    let phraseContext;
    if (message) {
      phraseContext = checkForKeyPhrases(message);
    }
    if (currentMessage) {
      phraseContext = checkForKeyPhrases(currentMessage);
    }
    setIsSaved(false);
    setTablesExplanation([]);
    setTableData([]);
    setTableDescription("");
    setTableHeaders([]);
    setTableTitle("");
    setCleanedQuery("");
    setQuerySteps(null);
    setQueryError(null);
    setQueryErrorCode(null);
    setQueryErrorHint(null);
    setQueryErrorExplanation(null);
    localStorage.setItem(
      "selectTablesInfo",
      JSON.stringify(allSelectedTablesInfo)
    );
    if (message) {
      localStorage.setItem("actualMessageUser", message);
    }
    if (currentMessage) {
      localStorage.setItem("actualMessageUser", currentMessage);
    }

    if (phraseContext) {
      setMessages((prevMessages) => {
        const updatedMessages = prevMessages.filter(
          (msg) =>
            msg.text !== "Processing your request..." &&
            msg.text !==
              "Please wait. We are trying to regenerate your result..."
        );

        return [
          ...updatedMessages,
          {
            text: currentMessage,
            sender: "user",
            type: "text",
          },
          {
            id: "processing",
            text: "Processing your request...",
            sender: "server",
            type: "text",
          },
        ];
      });
    } else {
      setMessages((prevMessages) => {
        const updatedMessages = prevMessages.filter(
          (msg) =>
            msg.text !== "Processing your request..." &&
            msg.text !==
              "Please wait. We are trying to regenerate your result..."
        );

        return [
          ...updatedMessages,
          {
            text: currentMessage,
            sender: "user",
            type: "text",
          },
        ];
      });
    }

    setMessage("");

    if (phraseContext) {
      let responseMessage;
      let appendedMessage;
      if (message) {
        appendedMessage = `${message}`;
      }
      if (currentMessage) {
        appendedMessage = `${currentMessage}`;
      }
      if (globalRender) {
        responseMessage = `Sure! Let's move over to the Create ${capitalizeFirstLetter(
          phraseContext
        )} Section to get started.`;
      } else {
        responseMessage = "Processing your request...";
        const response = {
          type: "query",
          task: appendedMessage,
          thread_id: threadId,
        };
        ws && ws.send(JSON.stringify(response));
      }

      setMessages((prevMessages) => [
        ...prevMessages.filter(
          (msg) => msg.text !== "Processing your request..."
        ),
        {
          id: Date.now(),
          text: responseMessage,
          sender: "server",
          type: "text",
        },
      ]);
    } else {
      let appendedMessage;
      if (message) {
        appendedMessage = `${message}`;
      }
      if (currentMessage) {
        appendedMessage = `${currentMessage}`;
      }
      const response = {
        type: "query",
        task: appendedMessage,
        thread_id: threadId,
      };
      ws && ws.send(JSON.stringify(response));
      setMessages((prevMessages) => [...prevMessages]);
    }

    if (!hasUserStartedTyping) {
      setHasUserStartedTyping(true);
      setMessages([]);
    }
  };

  const handleKeyPress = (e) => {
    if (e.charCode === 13 && message.trim()) {
      // setChartChatData(null);
      setTablesExplanation([]);
      setTableData([]);
      setTableDescription("");
      setTableHeaders([]);
      setTableTitle("");
      setCleanedQuery("");
      setTypeCustom("");
      e.preventDefault();
      sendResponse();
    }
  };

  const clearMessages = () => {
    setMessages([]);
  };

  const clearProcessingMessage = () => {
    setMessages((prevMessages) => {
      const updatedMessages = prevMessages.filter(
        (message) =>
          message.sender !== "server" ||
          (typeof message.text === "string" &&
            !message.text.startsWith("Processing your request,"))
      );
      return updatedMessages;
    });
  };

  const connectWebSocket = async () => {
    const websocket = new WebSocket(websocketServer);
    setWs(websocket);

    websocket.onopen = () => handleWebsocketOpen(websocket);
    websocket.onclose = handleWebsocketClose;
    websocket.onmessage = (e) => handleWebsocketMessage(websocket, e);
    websocket.onerror = handleWebsocketError;
  };

  const handleWebsocketOpen = () => {
    setIsConnected(true);
    cleanConnectionMessages();
  };

  function extractSubdomain(url) {
    const match = url.match(/https:\/\/([^.]+)\./);
    if (match) {
      return match[1];
    }
    return null;
  }

  useEffect(() => {
    const configureWebSocket = async () => {
      const company_name = await getCubeCompanyName();
      let tablesInfoStore = localStorage.getItem("selectTablesInfo");
      let tableDataPase = JSON.parse(tablesInfoStore);
      if (company_name !== "") {
        console.log("🚀 ~ configureWebSocket ~ company_name:", company_name);
        if (ws && isConnected && (allSelectedTablesInfo || tableDataPase)) {
          ws.send(
            JSON.stringify({
              type: "configure",
              configData: [company_name],
            })
          );
        }
      }
      setIsConfigured(true); // Set to true after configuration is done
    };

    configureWebSocket();
  }, [ws, isConnected]);

  useEffect(() => {
    if (ws && chartRequest !== "") {
      sendInitialChartRequest(ws);
    }
  }, [chartRequest]);

  const addServerMessage = (message, type) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        id: Date.now(),
        text: message,
        sender: "server",
        type: type,
      },
    ]);
  };

  const handleWebsocketClose = () => {
    setIsConnected(false);
    setInitialMessage("");
    addServerMessage("Connection lost. Please reconnect.", "text");
  };

  const handleWebsocketError = (error) => {
    console.error("WebSocket Error: ", error);
    setIsConnected(false);
  };
  const handleWebsocketMessage = async (websocket, event) => {
    const data = JSON.parse(event.data);
    setMessages((prevMessages) =>
      prevMessages.filter((msg) => msg.text !== "Processing your request...")
    );

    switch (data.type) {
      case "tool":
        await handleToolTypeMessage(websocket, data);
        break;
      case "result":
        handleResultTypeMessage(websocket, data.message);
        break;
      case "plan":
        handlePlanTypeMessage(data);
        break;
      default:
        handleDefaultMessage(data);
        break;
    }
  };

  const handlePlanTypeMessage = (data) => {
    const steps = data.message
      .split(/#E\d+\s*/)
      .filter((step) => step.trim() !== "");
    if (steps.length > 0) {
      setMessages((prevMessages) =>
        prevMessages.filter(
          (msg) =>
            typeof msg.text === "string" &&
            !msg.text.startsWith(
              "Processing your request, please be patient while we work on these steps:"
            )
        )
      );

      const customMessage = `Processing your request, please be patient while we work on these steps:\n`;

      const combinedSteps = steps
        .map((step, index) => `\nStep ${index + 1}: ${step}`)
        .join("");

      addServerMessage(`${customMessage}${combinedSteps}`, "text");

      setLoading(true); // Enable the spinner
    }
  };

  const handleToolTypeMessage = async (websocket, data) => {
    const organizedData = organizeData(data.functions);
    if (organizedData.length === 0) {
      addErrorMessage();
      sendToolResponse(websocket, "error", false);
      return;
    }
    handleFunctionalityMessages(websocket, data.functions);
  };

  const handleFunctionalityMessages = (websocket, functions) => {
    functions.forEach(async (func) => {
      switch (func.function_name) {
        case "getCubeQuery":
          await handleGetCubeQuery(websocket, func);
          break;
        case "getInsights":
          await handleGetInsights(websocket, func);
          break;
        case "getExplorationResults":
          await handleGetExplorationResults(func);
          break;
        case "getSqlDescription":
          await handleGetSQLDescription(websocket, func);
          break;
        default:
          console.log(func);
          break;
      }
    });
  };

  const cleanConnectionMessages = () => {
    setMessages((prevMessages) =>
      prevMessages.filter(
        (message) => message.text !== "Connection lost. Please reconnect."
      )
    );
  };

  const addErrorMessage = () => {
    addServerMessage(
      "There was a problem processing your request. Please try again later.",
      "text"
    );
  };

  const sendToolResponse = (websocket, functionName, responseFunction) => {
    websocket.send(
      JSON.stringify({
        type: "toolResponse",
        response: {
          function_name: functionName,
          response: JSON.stringify(responseFunction),
        },
      })
    );
  };

  const sendInitialChartRequest = (websocket) => {
    const firstTwoCsvInput =
      csvInput.length > 1 ? csvInput.slice(0, 2) : csvInput;
    websocket &&
      websocket.send(
        JSON.stringify({
          type: "csvLoader",
          task:
            chartRequest +
            " " +
            JSON.stringify(firstTwoCsvInput) +
            " " +
            tableDefinition,
          thread_id: threadId,
        })
      );
  };

  const getTableHeaders = (data) => {
    return Object.keys(data);
  };

  const handleGetSQLDescription = async (websocket, func, task) => {
    const taskid = localStorage.getItem("currentTask");
    const { sqlQuery, description } = func.arguments;
    setTables((prevTables) =>
      prevTables.map((table) => {
        return table.id === taskid
          ? {
              ...table,
              sqlQuery: sqlQuery[0],
              sqlDescription: description,
            }
          : table;
      })
    );
    sendToolResponse(websocket, func.function_name, true);
  };

  const mergeFilters = (initialFilters, newFilters) => {
    const mergedFilters = [...initialFilters];

    newFilters.forEach((newFilter) => {
      const existingIndex = mergedFilters.findIndex(
        (filter) => filter.member === newFilter.member
      );
      if (existingIndex !== -1) {
        // Update existing filter
        mergedFilters[existingIndex] = newFilter;
      } else {
        // Add new filter
        mergedFilters.push(newFilter);
      }
    });

    return mergedFilters;
  };

  const mergeArrays = (initialArray, newArray) => {
    const mergedArray = [...initialArray];

    newArray.forEach((newItem) => {
      if (!mergedArray.includes(newItem)) {
        mergedArray.push(newItem);
      }
    });

    return mergedArray;
  };

  const handleExecuteQueryCubeInternal = async (queryObject) => {
    const newSqlQuery = JSON.stringify(queryObject);

    try {
      const data = await handleExecuteQueryCube(newSqlQuery);
      const headers = getTableHeaders(data.data[0]);
      setTableData(data.data);
      setCleanedQuery(newSqlQuery);
      setTables((prevTables) =>
        prevTables.map((table) =>
          table.id === currentTask
            ? {
                ...table,
                tableData: data.data,
                tableHeaders: headers,
                cleanedQuery: newSqlQuery,
                lastSqlQuery: newSqlQuery,
              }
            : table
        )
      );
      setRenderTrigger((prev) => prev + 1);
    } catch (error) {
      console.error("Error executing query:", error);
    }
  };

  const handleFilterClick = (
    selectedMeasures,
    selectedDimensions,
    selectedFilters,
    selectedSegments,
    selectedTimeDimensions,
    tableQuery
  ) => {
    const parsedQuery = JSON.parse(tableQuery);
    setSelectedMeasures(selectedMeasures);
    setSelectedDimensions(selectedDimensions);
    setSelectedSegments(selectedSegments);
    setSelectedFilters(selectedFilters);
    setSelectedtimeDimensions(selectedTimeDimensions);

    const updatedQuery = {
      measures: selectedMeasures,
      dimensions: selectedDimensions,
      filters: selectedFilters,
      timeDimensions: selectedTimeDimensions,
      segments: selectedSegments,
      limit: parsedQuery?.limit || initialQuery.limit || 10,
      offset: parsedQuery?.offset || initialQuery.offset || 0,
    };

    handleExecuteQueryCubeInternal(updatedQuery);
  };

  const clearFilters = async () => {
    // Reset selected filters to initial query values
    setSelectedMeasures(initialQuery.measures || []);
    setSelectedDimensions(initialQuery.dimensions || []);
    setSelectedtimeDimensions(initialQuery.timeDimensions || []);
    setSelectedFilters(initialQuery.filters || []);
    setSelectedSegments(initialQuery.segments || []);

    // Use the cleanedQuery to reset the table data
    const data = await handleExecuteQueryCube(initialQuery); // Replace this with the actual query execution function
    const headers = getTableHeaders(data.data[0]);
    setTableData(data.data); // Ensure tableData is updated here
    setCleanedQuery(initialQuery);
    setTables((prevTables) =>
      prevTables.map((table) =>
        table.id === currentTask
          ? {
              ...table,
              tableData: data.data,
              tableHeaders: headers,
              cleanedQuery: initialQuery,
              lastSqlQuery: initialQuery,
            }
          : table
      )
    );
    setRenderTrigger((prev) => prev + 1);
    setClearedFilters(true);
  };

  const [initialFunc, setInitialFunc] = useState(null);
  const [totalItems, setTotalItems] = useState(0);

  const handlePageChange = (newPage) => {
    console.log("🚀 ~ handlePageChange ~ newPage:", newPage);
    setCurrentPage(newPage);
    setLoading(true);

    const offset = (newPage - 1) * itemsPerPage;
    console.log("🚀 ~ handlePageChange ~ offset:", offset);
    let limit = itemsPerPage;

    if (newPage * itemsPerPage > totalItems) {
      limit = totalItems - offset;
    }

    const updatedQuery = { ...JSON.parse(cleanedQuery), offset, limit };
    handleExecuteQueryCubeInternal(updatedQuery);
  };

  const handleGetCubeQuery = async (websocket, func) => {
    const { sqlQuery } = initialFunc ? initialFunc.arguments : func.arguments;
    console.log("🚀 ~ handleGetCubeQuery ~ sqlQuery:", sqlQuery);
    let parsedQuery = JSON.parse(initialQuery ? initialQuery : sqlQuery);

    // Maintain existing limit and offset if they exist
    const existingLimit =
      parsedQuery.limit !== undefined ? parsedQuery.limit : itemsPerPage;
    const existingOffset =
      parsedQuery.offset !== undefined
        ? parsedQuery.offset
        : (currentPage - 1) * itemsPerPage;

    parsedQuery = {
      ...parsedQuery,
      offset: existingOffset,
      limit: existingLimit,
    };

    const updatedQueryString = JSON.stringify(parsedQuery);
    const newMessageId = generateRandomId();
    setCurrentTask(newMessageId);
    localStorage.setItem("currentTask", newMessageId);
    const dataQuery = await handleExecuteQueryCube(updatedQueryString);
    const dataForRender = dataQuery.data;

    if (!dataForRender || dataForRender.length === 0) {
      addServerMessage(
        "Process failed, please try with a different message",
        "text"
      );
      setLoading(false);
      return null;
    }

    const headers = getTableHeaders(dataForRender[0]);

    if (func || initialFunc) {
      if (func.arguments) {
        setInitialFunc(func);
      }
      const { title, description, data } = initialFunc
        ? initialFunc.arguments
        : func.arguments;
      let { displayType } = initialFunc
        ? initialFunc.arguments
        : func.arguments;
      const updatedTypeCustoms = [...typeCustoms, displayType];
      setTypeCustoms(updatedTypeCustoms);
      setTypeCustoms((prev) => [...prev, displayType]);

      if (displayType === "dataPoint") {
        displayType = "datapoint";
      }
      const dataQuery = JSON.parse(data);
      const dataForTotal = dataQuery.data;
      setTotalItems(dataForTotal.length);
      setInitialQuery(updatedQueryString);

      let newIndex = generateIndex();
      const newTable = {
        id: newMessageId,
        index: newIndex,
        tableData: dataForRender,
        tableHeaders: headers,
        tableTitle: title,
        tableDescription: description,
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        lastSqlQuery: updatedQueryString,
        explanation: [{ description: description }],
        editableTitle: title,
        isEditingTitle: false,
        task: "Here is your result",
        type: "",
        cleanedQuery: updatedQueryString,
        function: updatedQueryString,
        totalItems: dataForTotal.length || 0,
        displayType: displayType || "table",
        initialQuery: sqlQuery,
      };

      setTables((prevTables) => {
        const existingTableIndex = prevTables.findIndex(
          (table) => table.id === newMessageId
        );

        if (existingTableIndex !== -1) {
          const updatedTables = [...prevTables];
          updatedTables[existingTableIndex] = newTable;
          return updatedTables;
        } else {
          return [...prevTables, newTable];
        }
      });
      setCleanedQuery(updatedQueryString);
      setTablesExplanation((prevTables) => [
        ...prevTables,
        {
          title: title,
          description: description,
        },
      ]);
    } else {
      setTables((prevTables) =>
        prevTables.map((table) =>
          table.id === currentTask
            ? {
                ...table,
                tableData: dataForRender,
                currentPage: currentPage,
                itemsPerPage: itemsPerPage,
                lastSqlQuery: updatedQueryString,
                cleanedQuery: updatedQueryString,
                totalItems: dataQuery.totalItems || 0,
              }
            : table
        )
      );
    }
    setLoading(false);
  };

  const handleGetInsights = async (websocket, func) => {
    const { title } = func.arguments;
    let { displayType } = func.arguments;
    const { query } = func.arguments;
    const { data } = func.arguments;
    const { insight } = func.arguments;
    const dataJson = JSON.parse(data);
    const dataForRender = dataJson.data;
    setTableData(dataForRender);
    const headers = getTableHeaders(dataForRender[0]);
    // setLastSqlQuery(query);

    const updatedTypeCustoms = [...typeCustoms, displayType];
    setTypeCustoms(updatedTypeCustoms);
    setTypeCustoms((prev) => [...prev, displayType]);
    addServerMessage(`Insight: ` + insight, "text");
    try {
      if (data && data.length > 0) {
        const newMessageId = generateRandomId();
        let newIndex = generateIndex();
        const newTable = {
          id: newMessageId,
          index: newIndex,
          tableData: dataForRender,
          tableHeaders: headers,
          tableTitle: title,
          tableDescription: "",
          currentPage: 1,
          itemsPerPage: 10,
          lastSqlQuery: query,
          explanation: [
            {
              description: insight[0],
            },
          ],
          editableTitle: title,
          isEditingTitle: false,
          task: "Here is your result",
          type: "",
          cleanedQuery: query,
          function: query,
          displayType,
          initialQuery: JSON.stringify(query),
        };
        setTables((prevTables) => [...prevTables, newTable]);
        setLastSqlQuery(query);
        setCleanedQuery(query);
        setTablesExplanation((prevTables) => [
          ...prevTables,
          {
            title: title,
            description: insight[0],
          },
        ]);
        setCurrentTask(newMessageId);
        sendToolResponse(websocket, func.function_name, data);
      }
    } catch (error) {
      console.error("Error retrieving segment details:", error);
      setQueryError(error.message);
    }
  };

  const handleGetExplorationResults = async (func) => {
    const { insights, queries } = func.arguments;

    try {
      const combinedSteps = insights
        .map(
          (insight, index) =>
            `Insight #${index + 1}: ${insight}\nQuery: ${queries[index]}\n\n`
        )
        .join("\n");

      addServerMessage(`${combinedSteps}`, "text");
    } catch (error) {
      console.error("Error retrieving segment details:", error);
      setQueryError(error.message);
    }
  };

  const clearAllServerMessagesExceptRetry = () => {
    setMessages((prevMessages) =>
      prevMessages.filter(
        (msg) =>
          !msg.text.startsWith(
            "Please wait. We are trying to regenerate your result..."
          ) &&
          !msg.text.startsWith(
            "Processing your request, please be patient while we work on these steps:"
          )
      )
    );
  };

  const reloadChat = () => {
    clearAllServerMessagesExceptRetry();
    addServerMessage(
      "Please wait. We are trying to regenerate your result...",
      "text"
    );
    let InfoMessage = localStorage.getItem("actualMessageUser");
    if (InfoMessage !== "") {
      setMessage(InfoMessage);
      setMessages([
        {
          id: Date.now(),
          text: t("ASSISTANT"),
          sender: "server",
          type: "text",
        },
        {
          text: InfoMessage,
          sender: "user",
          type: "text",
        },
      ]);
      sendResponse(InfoMessage);
    }
  };

  const handleResultTypeMessage = (websocket, data) => {
    if (
      typeof data === "string" &&
      data.includes("Here is a concise version")
    ) {
      return null;
    }
    if (typeof data === "string" && !data.includes("Created function")) {
      addServerMessage(
        data
          ? data.replace(/^"(.*)"$/, "$1")
          : "Your request has failed, in the panel aside you will find more details about it.",
        "text"
      );
    } else {
      setQueryError(null);
      if (!successMessageAdded.current) {
        addServerMessage(
          "Your request has been processed successfully. Feel free to continue sending messages if you need further assistance.",
          "text"
        );
        successMessageAdded.current = true;
      }
    }
  };

  const handleDefaultMessage = (data) => {
    addServerMessage(
      data.message || "Received a message from the server.",
      "text"
    );
  };

  useEffect(() => {
    connectWebSocket();
    return () => ws && ws.close();
  }, []);

  const handleReconnectClick = () => {
    ws && ws.close();
    connectWebSocket();
    clearMessages();
  };

  const handleQuestionClick = (question) => {};

  const handleThumbUpClick = (event) => {
    event.stopPropagation();

    setOpenCreateCustom(true);

    setMessage("");
  };

  const handleThumbDownClick = (event) => {
    event.stopPropagation();
    setMessages([
      {
        id: Date.now(),
        text: "Ok, I see those tables doesn't fit your requirements, let's try again, please wait a few moments...",
        sender: "server",
        type: "text",
      },
    ]);
    if (initialMessage !== "") {
      const appendedMessage = `${initialMessage} based on this table names: ${allTableNames}`;
      const response = {
        type: "query",
        task: appendedMessage,
        thread_id: threadId,
      };
      ws && ws.send(JSON.stringify(response));
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      const { scrollHeight, clientHeight } = containerRef.current;
      containerRef.current.scrollTo({
        top: scrollHeight - clientHeight,
        behavior: "smooth",
      });
    }
  }, [messages]);

  useEffect(() => {
    const scrollToBottom = () => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
      }
    };

    const timer = setTimeout(() => {
      scrollToBottom();
    }, 100);

    return () => clearTimeout(timer);
  }, [messages]);

  useEffect(() => {
    if (threadId) {
      getChatHistory(threadId);
    }
  }, [threadId, messages]);

  const getChatHistory = async () => {
    try {
      // First, get all thread_ids
      const { data: allThreadIds, error: threadError } = await privateSupabase
        .from("newcheckpoints")
        .select("thread_id");

      if (threadError) throw threadError;

      if (!allThreadIds || allThreadIds.length === 0) {
        console.log("No thread IDs found");
        return;
      }

      // Get unique thread_ids
      const uniqueThreadIds = [
        ...new Set(allThreadIds.map((item) => item.thread_id)),
      ];

      // For each unique thread_id, get the latest entry
      const latestEntries = await Promise.all(
        uniqueThreadIds.map(async (thread_id) => {
          const { data, error } = await privateSupabase
            .from("newcheckpoints")
            .select("*")
            .eq("thread_id", thread_id)
            .order("step", { ascending: false })
            .limit(1)
            .single();

          if (error) {
            console.error(
              `Error fetching data for thread_id ${thread_id}:`,
              error
            );
            return null; // Return null or handle the error as needed
          }
          return data;
        })
      );

      // Filter out any null values from failed fetches
      const validEntries = latestEntries.filter((entry) => entry !== null);

      // Sort valid entries by created_at in descending order (most recent to oldest)
      const sortedEntries = validEntries.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );

      setChatHistory(sortedEntries);
    } catch (error) {
      console.error("Error fetching chat history:", error);
    }
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    const firstItemIndex = (currentPage - 1) * itemsPerPage;
    const newCurrentPage = Math.floor(firstItemIndex / newItemsPerPage) + 1;
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newCurrentPage);
  };

  const renameKeysToMatchHeaders = (dataArray, headersArray) => {
    if (
      !Array.isArray(dataArray) ||
      !Array.isArray(headersArray) ||
      dataArray.length === 0 ||
      headersArray.length === 0
    ) {
      return dataArray;
    }

    const sampleData = dataArray[0];
    const headerKeyMapping = {};

    headersArray.forEach((header, index) => {
      const headerKey = header.replace(/ /g, "_").toLowerCase();
      const dataKey = Object.keys(sampleData)[index];
      if (dataKey) {
        headerKeyMapping[dataKey] = headerKey;
      }
    });

    const updatedDataArray = dataArray.map((item) => {
      const updatedItem = {};
      Object.keys(item).forEach((key) => {
        const newKey = headerKeyMapping[key] || key;
        updatedItem[newKey] = item[key];
      });
      return updatedItem;
    });

    return updatedDataArray;
  };

  const generateRows = (table) => {
    const headers =
      typeof table.tableHeaders === "string"
        ? table.tableHeaders.split(",").map((header) => header.trim())
        : Array.isArray(table.tableHeaders)
          ? table.tableHeaders
          : [];

    const data = renameKeysToMatchHeaders(table.tableData, headers);
    return data.map((item) => {
      let row = {};
      headers.forEach((header) => {
        const key = header.replace(/ /g, "_").toLowerCase();
        row[header] = item[key];
      });
      return row;
    });
  };

  const handleDownloadOptionClick = async (option, table) => {
    if (downloadButtonRef.current) {
      downloadButtonRef.current.style.visibility = "hidden";
    }
    switch (option) {
      case "CSV":
        downloadCSV(table);
        break;
      case "PDF":
        await downloadPDF(table);
        break;
      case "PNG":
        await downloadPNG(table);
        break;
      default:
        console.log(`Unhandled option: ${option}`);
    }
    if (downloadButtonRef.current) {
      downloadButtonRef.current.style.visibility = "visible";
    }
    setIsDownloadOptionsOpen(false);
  };

  const downloadCSV = (table) => {
    const headers = table.tableHeaders.join(",");
    const rows = table.tableData
      .map((row) =>
        table.tableHeaders
          .map((header) => JSON.stringify(row[header] || ""))
          .join(",")
      )
      .join("\n");

    const csvContent = `${headers}\n${rows}`;
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `${table.tableTitle || "data"}.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadPDF = async (table) => {
    const element = document.getElementById(`chart-${table.id}`);
    if (!element) {
      console.error("Element not found");
      return;
    }
    const canvas = await html2canvas(element, {
      scale: 2,
    });
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF();
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save(`${table.tableTitle || "data"}.pdf`);
  };

  const downloadPNG = async (table) => {
    const element = document.getElementById(`chart-${table.id}`);
    if (!element) {
      console.error("Element not found");
      return;
    }
    const canvas = await html2canvas(element, {
      scale: 2,
    });
    const imgData = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.setAttribute("href", imgData);
    link.setAttribute("download", `${table.tableTitle || "data"}.png`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getNextPosition = () => {
    let maxY = 0;
    let maxH = 0;
    draggableItems.forEach((item) => {
      const itemY = item.position?.reduce((acc, pos) => {
        if (pos.report === selectedRep) {
          return pos.size.gs_y;
        }
        return acc;
      }, 0);
      const itemH = item.position?.reduce((acc, pos) => {
        if (pos.report === selectedRep) {
          return pos.size.gs_h;
        }
        return acc;
      }, 0);
      if (itemY + itemH > maxY + maxH) {
        maxY = itemY;
        maxH = itemH;
      }
    });
    return { x: 0, y: maxY + maxH }; // Position new items in the next available row
  };

  const addSectionOrCardFromLibrary = (card) => {
    const newCard = {
      ...card,
      label: card.name,
      position: [],
    };

    const { x, y } = getNextPosition();
    const size = getItemSize(newCard.type);

    newCard.position.push({
      size: { gs_x: x, gs_y: y, gs_w: size.w, gs_h: size.h },
      report: selectedRep,
    });
    newCard.gsX = x;
    newCard.gsY = y;
    newCard.gsW = size.w;
    newCard.gsH = size.h;

    const newDraggableItems = [...draggableItems, newCard];
    setDraggableItems(newDraggableItems);
    forceUpdate(); // Force update after adding a new card
    return newDraggableItems;
  };

  const getItemSize = (type) => {
    if (type === "title") {
      return { w: 12, h: 2 };
    } else if (type === "datapoint") {
      return { w: 6, h: 4 };
    } else {
      return { w: 6, h: 6 };
    }
  };

  const forceUpdate = () => setRenderTrigger((prev) => prev + 1);

  const saveData = async (table, typeToRender) => {
    const newDraggableItems = addSectionOrCardFromLibrary(table);
    await handleSave(newDraggableItems, typeToRender);
  };

  const handleSave = async (currentDraggableItems, typeToRender) => {
    let itemType = "";
    let visualizationType = "";
    if (
      typeToRender === "barChart" ||
      typeToRender === "lineChart" ||
      typeToRender === "doghnutChart"
    ) {
      itemType = "chart";
      if (typeToRender === "barChart") {
        visualizationType = "bar";
      } else if (typeToRender === "lineChart") {
        visualizationType = "line";
      } else if (typeToRender === "doghnutChart") {
        visualizationType = "doughnut";
      }
    } else if (typeToRender === "table") {
      itemType = "table";
    } else {
      itemType = "datapoint";
    }

    let currentReport = selectedRep || null;
    currentDraggableItems.forEach((item) => {
      if (!item.id || isNaN(Number(item.id))) {
        item.id = null;
      }
    });
    const newItems = currentDraggableItems.filter((item) => !item.id);
    let updatedDraggableItems = [];
    let newId;

    if (newItems.length > 0) {
      // Insert new items into the Supabase library table and get their IDs
      const newItemsInserts = newItems.map(async (item) => {
        try {
          const { data, error } = await privateSupabase
            .from("library")
            .insert([
              {
                type: itemType,
                title: item.tableTitle,
                position: [
                  {
                    size: {
                      gs_x: item.gsX,
                      gs_y: item.gsY,
                      gs_w: item.gsW,
                      gs_h: item.gsH,
                    },
                    report: currentReport || "temp", // Use temporary report id
                  },
                ],
                thread_id: 1,
                query: item.cleanedQuery,
                created_by: session.user.email,
                size: "normal",
                function: item.function,
                visualization: visualizationType,
                sqlQuery: item.sqlQuery,
                sqlDescription: item.sqlDescription,
              },
            ])
            .select();

          if (error) {
            console.error("Error inserting new library item: ", error);
            return null;
          } else {
            newId = data[0].id;
            return data[0];
          }
        } catch (err) {
          console.error("Unexpected error inserting new library item: ", err);
          return null;
        }
      });

      const insertedItems = await Promise.all(newItemsInserts);

      const filteredDraggableItems = currentDraggableItems.filter(
        (item) => item.id
      );
      const newDraggableItems = insertedItems.map((insertedItem) => {
        const position = insertedItem.position.find(
          (pos) => pos.report === selectedRep
        );

        return {
          ...insertedItem,
          gsX: position?.size.gs_x,
          gsY: position?.size.gs_y,
          gsW: position?.size.gs_w,
          gsH: position?.size.gs_h,
        };
      });

      updatedDraggableItems = [...filteredDraggableItems, ...newDraggableItems];
      setDraggableItems(updatedDraggableItems);
    }

    if (isCreateAi === true) {
      // If there is no selectedReport, insert a new report into the reports_dashboard table
      if (!currentReport) {
        try {
          const { data, error } = await privateSupabase
            .from("reports_dashboard")
            .insert([
              {
                title: tempTitle || dashboardTitle,
                created_by: session.user.email,
                last_update: new Date().toISOString(),
                items: [], // Will update items after getting the report ID
              },
            ])
            .select();

          if (error) {
            console.error("Error inserting new report: ", error);
            return;
          } else {
            currentReport = data[0].id;
          }
        } catch (err) {
          console.error("Unexpected error inserting new report: ", err);
          return;
        }
      }

      // Proceed with saving the positions of all items
      const updates = newItems.map(async (item) => {
        if (item.id) {
          const node = document.querySelector(
            `.grid-stack-item[gs-id='${item.id}']`
          );
          if (node) {
            const gsX = parseInt(node.getAttribute("gs-x") || "0");
            const gsY = parseInt(node.getAttribute("gs-y") || "0");
            const gsW = parseInt(node.getAttribute("gs-w") || "0");
            const gsH = parseInt(node.getAttribute("gs-h") || "0");

            const newPosition = {
              size: {
                gs_x: gsX,
                gs_y: gsY,
                gs_w: gsW,
                gs_h: gsH,
              },
              report: currentReport,
            };

            try {
              const { data, error } = await privateSupabase
                .from("library")
                .select("position")
                .eq("id", item.id)
                .single();

              if (error) {
                console.error("Error fetching library item: ", error);
              } else {
                let existingPositions = Array.isArray(data.position)
                  ? data.position
                  : [];

                const positionIndex = existingPositions.findIndex(
                  (pos) => pos.report === currentReport
                );

                if (positionIndex !== -1) {
                  // Update the existing position for the current report
                  existingPositions[positionIndex] = newPosition;
                } else {
                  // Add the new position for the current report
                  existingPositions.push(newPosition);
                }

                const { error: updateError } = await privateSupabase
                  .from("library")
                  .update({ position: existingPositions })
                  .eq("id", item.id);

                if (updateError) {
                  console.error("Error updating library item: ", updateError);
                } else {
                  console.log(`Successfully updated item with id: ${item.id}`);
                }
              }
            } catch (err) {
              console.error("Unexpected error updating library item: ", err);
            }
          } else {
            console.error(`Node not found for item with id: ${item.id}`);
          }
        } else {
          console.error("Item does not have an id: ", item);
        }
      });

      await Promise.all(updates)
        .then(() => {
          console.log("All items updated successfully.");
        })
        .catch((error) => {
          console.error("Error updating items: ", error);
        });

      let itemIds = [];
      if (updatedDraggableItems.length > 0) {
        itemIds = updatedDraggableItems.map((item) => item.id);
      }

      const updateReportsDashboard = async () => {
        const { error: updateError } = await privateSupabase
          .from("reports_dashboard")
          .update({
            title: tempTitle || dashboardTitle,
            last_update: new Date().toISOString(),
            items: itemIds,
          })
          .eq("id", currentReport);

        if (updateError) {
          console.error("Error updating reports_dashboard: ", updateError);
        } else {
          initializeMainDashboard();
        }
      };

      await updateReportsDashboard();
      setDashboardTitle(tempTitle || dashboardTitle);
      setShouldScroll(true);
      toast.success("Data saved successfully to Dashboard & Library!");
    } else {
      toast.success("Data saved successfully to Library!");
    }
  };

  const initializeMainDashboard = async () => {
    try {
      const { data } = await fetchMainDashboard();

      if (!data) {
        console.log("no data", data);
      } else {
        setSelectedRep(data?.id);
      }

      if (data) {
        setSelectedRep(data?.id);
      }
    } catch (error) {
      console.error("ERROR: ", error);
    }
  };

  const fetchMainDashboard = async () => {
    const { data, error } = await privateSupabase
      .from("reports_dashboard")
      .select("id")
      .eq("title", "Main Dashboard")
      .single();

    if (error) {
      console.error("Error fetching main dashboard: ", error);
    }

    return { data, error };
  };

  const handleCopyClick = (e, sqlQuery) => {
    e.preventDefault();
    navigator.clipboard
      .writeText(sqlQuery)
      .then(() => {
        toast.success("SQL query copied to clipboard!");
      })
      .catch((err) => {
        toast.error("Failed to copy the SQL query.");
        console.error("Failed to copy text: ", err);
      });
  };

  return (
    <div className={isDataLoader ? "hidden" : ""}>
      <div
        className={chatClasses}
        style={{ marginLeft: custom ? 0 : "1%" }}
        ref={messagesEndRef}
      >
        <div className={contentClasses}>
          <div
            ref={containerRef}
            className="overflow-y-auto rounded-xl flex-grow w-full"
          >
            {messages &&
              messages.map((message, index) => (
                <>
                  <div
                    key={message.id || index}
                    className="flex flex-row items-start justify-start p-2"
                  >
                    {message.sender === "user" ? (
                      <img
                        src={getImageSrc()}
                        alt="userImage"
                        className="w-10 h-10 mr-2 mt-3 rounded-full"
                        style={{
                          width: "32px",
                          height: "32px",
                          background: "white",
                        }}
                      />
                    ) : (
                      <ChatSpark className="mr-2 mt-3" />
                    )}
                    <div
                      className="flex items-center my-2 p-2 rounded-lg text-[#0b0b0c] bg-[#F7F7F7] text-left w-full justify-between h-full"
                      onClick={() => {
                        if (message.clickable) {
                          handleQuestionClick(message.text);
                        }
                      }}
                      style={{
                        cursor: message.clickable ? "pointer" : "default",
                      }}
                    >
                      <span
                        id={`message-${index}`}
                        key={index}
                        style={{ whiteSpace: "pre-wrap" }}
                        className={`flex w-full h-full ${
                          custom && "break-normal"
                        } overflow-y-auto`}
                      >
                        {message.text}
                      </span>
                      {typeof message.text === "string" &&
                        message.text.startsWith(
                          "Processing your request, please be patient while we work on these steps:"
                        ) && (
                          <div className="flex items-center justify-center ml-4">
                            {loading && <Spinner className="h-6 w-6" />}
                          </div>
                        )}

                      {typeof message.text === "string" &&
                        message.text.includes("Attempt failed") && (
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-red-500 cursor-pointer mr-4 flex justify-end"
                            onClick={() => toast.error(queryError)}
                          />
                        )}
                      {message.sender === "server" && message.buttons && (
                        <div className="flex justify-end my-2 mr-2 w-full">
                          {message.buttons.map((button, btnIndex) => (
                            <button
                              key={btnIndex}
                              onClick={() => button.onClick()}
                              className="text-[#3E54AC] bg-[#F1EBFF] border border-[#B493FF] font-bold py-2 px-4 rounded inline-flex items-center mx-2"
                            >
                              {button.text}
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                    {typeof message.text === "string" &&
                      (message.text.includes(
                        "Sure! Let's move over to the Create"
                      ) ||
                        message.text === messageServer ||
                        message.promptTableSelection) && (
                        <div className="flex justify-start ml-2 mr-auto">
                          <HandThumbUpIcon
                            className="h-5 w-5 text-[#9667FF] cursor-pointer"
                            onClick={handleThumbUpClick}
                          />
                          <HandThumbDownIcon
                            className="h-5 w-5 text-[#9667FF] cursor-pointer"
                            onClick={handleThumbDownClick}
                          />
                        </div>
                      )}
                  </div>
                  {message.sender === "user" && custom === true ? (
                    <div>
                      {custom ? (
                        <div className="flex flex-col justify-start ml-14 mt-2 items-center bg-[#1C1C1C0D] h-0.5 max-w-screen"></div>
                      ) : (
                        <div className="flex flex-col justify-start ml-14 mt-2 items-center bg-[#1C1C1C0D] h-0.5 w-[300px]"></div>
                      )}
                    </div>
                  ) : (
                    <>
                      {typeof message.text === "string" &&
                        !message.text.includes("Here is your final result") && (
                          <div className="flex flex-col justify-start ml-14 mt-2 items-center bg-[#1C1C1C0D] h-0.5 max-w-screen"></div>
                        )}
                      {typeof message.text === "string" &&
                        (message.text.includes("Here is your final result") ||
                          message.text.includes(
                            "Your request has been processed successfully"
                          )) &&
                        tables
                          .filter((table) => table.id === message.id)
                          .map((table, idx) => {
                            const typeToRender = table.displayType;
                            return (
                              <div
                                key={table.id}
                                className="flex flex-col justify-start items-center max-w-screen mx-auto bg-transparent p-4 overflow-x-hidden"
                              >
                                <GlobalFilterBar
                                  onFilterClick={(
                                    selectedMeasures,
                                    selectedDimensions,
                                    selectedFilters,
                                    selectedSegments,
                                    selectedTimeDimensions
                                  ) =>
                                    handleFilterClick(
                                      selectedMeasures,
                                      selectedDimensions,
                                      selectedFilters,
                                      selectedSegments,
                                      selectedTimeDimensions,
                                      table.cleanedQuery
                                    )
                                  }
                                  filterData={filterData}
                                  cubeMetadata={cubeMetadata}
                                  clearFilters={clearFilters}
                                  initialQuery={JSON.parse(table.initialQuery)}
                                  clearedFilters={clearedFilters}
                                  setClearedFilters={setClearedFilters}
                                  isChatAI={true}
                                />
                                {renderTypes.length > 0 &&
                                  currentView === "table" &&
                                  (typeToRender === "barChart" ||
                                    typeToRender === "doghnutChart" ||
                                    typeToRender === "lineChart") && (
                                    <ChartRender
                                      table={table}
                                      typeToRender={typeToRender}
                                      noDataQuery={noDataQuery}
                                      datapointInfo={datapointInfo}
                                      handleTitleChange={handleTitleChange}
                                      handleSaveTitle={handleSaveTitle}
                                      handleCancelEdit={handleCancelEdit}
                                      handleEditTitle={handleEditTitle}
                                      setCurrentTable={setCurrentTable}
                                      setIsDownloadOptionsOpen={
                                        setIsDownloadOptionsOpen
                                      }
                                      isDownloadOptionsOpen={
                                        isDownloadOptionsOpen
                                      }
                                      handleDownloadOptionClick={
                                        handleDownloadOptionClick
                                      }
                                    />
                                  )}
                                <>
                                  {typeToRender === "segment" ||
                                  typeToRender === "table" ? (
                                    <TableRender
                                      table={table}
                                      typeToRender={typeToRender}
                                      noDataQuery={noDataQuery}
                                      tableData={tableData}
                                      currentPage={currentPage}
                                      itemsPerPage={itemsPerPage}
                                      totalItems={table.totalItems}
                                      handleTitleChange={handleTitleChange}
                                      handleSaveTitle={handleSaveTitle}
                                      handleCancelEdit={handleCancelEdit}
                                      handleEditTitle={handleEditTitle}
                                      setCurrentTable={setCurrentTable}
                                      setIsDownloadOptionsOpen={
                                        setIsDownloadOptionsOpen
                                      }
                                      isDownloadOptionsOpen={
                                        isDownloadOptionsOpen
                                      }
                                      handleDownloadOptionClick={
                                        handleDownloadOptionClick
                                      }
                                      handleItemsPerPageChange={
                                        handleItemsPerPageChange
                                      }
                                      setCurrentPage={handlePageChange} // Update currentPage change handler
                                      generateRows={generateRows}
                                    />
                                  ) : (
                                    typeToRender === "datapoint" && (
                                      <DataPointRender
                                        table={table}
                                        typeToRender={typeToRender}
                                        noDataQuery={noDataQuery}
                                        datapointInfo={datapointInfo}
                                        handleTitleChange={handleTitleChange}
                                        handleSaveTitle={handleSaveTitle}
                                        handleCancelEdit={handleCancelEdit}
                                        handleEditTitle={handleEditTitle}
                                        setCurrentTable={setCurrentTable}
                                        setIsDownloadOptionsOpen={
                                          setIsDownloadOptionsOpen
                                        }
                                        isDownloadOptionsOpen={
                                          isDownloadOptionsOpen
                                        }
                                        handleDownloadOptionClick={
                                          handleDownloadOptionClick
                                        }
                                      />
                                    )
                                  )}
                                </>
                                {(table.explanation.length > 0 || queryError) &&
                                typeToRender !== "" ? (
                                  <div className="flex w-full justify-between items-center p-2">
                                    <div className="flex flex-row items-start space-x-4 ml-14 mt-2">
                                      <ArrowPathIcon
                                        onClick={reloadChat}
                                        className="w-6 h-6 text-[#76787D] cursor-pointer"
                                      />
                                      <HandThumbDownIcon className="w-6 h-6 text-[#76787D] cursor-pointer" />
                                      <ShareIcon className="w-6 h-6 text-[#E0E4E9]" />
                                    </div>
                                    <div className="flex flex-row mr-8 ml-auto p-4">
                                      <VerifyButton
                                        currentView={typeToRender}
                                        setCurrentView={handleDialogOpen}
                                        typeCustom={typeToRender}
                                        table={table}
                                        setTable={setCurrentTable}
                                        isLoading={
                                          !table.sqlDescription ||
                                          !table.sqlQuery
                                        }
                                      />

                                      {!savedItems[table.id] && (
                                        <SaveButton
                                          isCreateAi={isCreateAi}
                                          onClick={() => {
                                            saveData(table, typeToRender);
                                          }}
                                        />
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                                {table.sqlDescription !== undefined &&
                                  table.sqlQuery !== undefined && (
                                    <VerifyDialog
                                      description={table.sqlDescription}
                                      lastSqlQuery={table.sqlQuery}
                                      currentTable={currentTable}
                                      queryError={queryError}
                                      queryErrorExplanation={
                                        queryErrorExplanation
                                      }
                                      queryErrorCode={queryErrorCode}
                                      queryErrorHint={queryErrorHint}
                                      querySteps={querySteps}
                                      handleCopyClick={handleCopyClick}
                                      dialogOpen={dialogOpen}
                                      handleDialogOpen={handleDialogOpen}
                                    />
                                  )}
                              </div>
                            );
                          })}
                    </>
                  )}
                </>
              ))}
          </div>
          <PromptInput
            message={message}
            handleInputChange={handleInputChange}
            handleKeyPress={handleKeyPress}
            sendResponse={sendResponse}
            isConnected={isConnected}
            isConfigured={isConfigured}
          />
          {!isConnected && (
            <div className="mt-4 text-center">
              <button
                onClick={handleReconnectClick}
                className="bg-[#F1EBFF] border border-[#B493FF] rounded-2xl px-4 py-2 text-[#3E54AC]"
              >
                Reconnect
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatAssistant;

/** @format */

import React, { useEffect, useState, useMemo, useRef } from "react";
import { t } from "i18next";
import toast from "react-hot-toast";
import { privateSupabase, publicSupabase } from "../../api/SupabaseClient";
import defaultPlaceholder from "../../assets/images/blank-profile-picture.png";
import { useNavigate, useLocation } from "react-router-dom";
import { Menu, MenuItem } from "@material-tailwind/react";
import {
  ArrowRightStartOnRectangleIcon,
  UserCircleIcon,
  Cog6ToothIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { ReactComponent as FeedbackIcon } from "./../../assets/icons/feedback_icon.svg";

const Navbar = () => {
  const [session, setSession] = useState<any>(null);
  const [profile, setProfile] = useState<any>();
  const [company, setCompany] = useState<any>();
  const [companyLogo, setCompanyLogo] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState(companyLogo || defaultPlaceholder);
  const navigate = useNavigate();
  const location = useLocation();
  const menuRef = useRef<HTMLDivElement>(null);
  const profileIconRef = useRef<HTMLDivElement>(null);
  const redirectToHome = () => {
    navigate("/");
  };

  const handleError = () => {
    setImgSrc(defaultPlaceholder);
  };

  useEffect(() => {
    privateSupabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  const getCompany = async () => {
    try {
      const { data, error } = await privateSupabase
        .from("company_details")
        .select("*")
        .eq("id", 1)
        .single();
      if (error) throw error;
      setCompany(data.company);
      updateCompanyLogo(data);
    } catch (error) {
      toast.error(t("ERROR") + "EO-1007");
    }
  };

  const getProfile = async () => {
    try {
      const { data, error } = await privateSupabase
        .from("profiles")
        .select("*")
        .eq("id", session.user.id)
        .single();
      if (error) throw error;
      setProfile(data);
    } catch (error) {
      console.error("ERROR: ", error);
    }
  };

  const updateCompanyLogo = (data: any) => {
    const projectUrl = localStorage.getItem("supabaseProjectUrl");
    const logoSrc = data.company_logo
      ? `${projectUrl}/storage/v1/object/public/company_logos/company_logo/${data.company_logo}`
      : "";
    if (logoSrc) {
      setCompanyLogo(logoSrc);
    } else {
      setCompanyLogo(defaultPlaceholder);
    }
  };

  useEffect(() => {
    setImgSrc(companyLogo);
  }, [companyLogo]);

  useEffect(() => {
    if (session && session.user) {
      getProfile();
    }
    getCompany();
  }, [session]);

  const handleLogout = () => {
    Promise.all([publicSupabase.auth.signOut(), privateSupabase.auth.signOut()])
      .then(() => {
        localStorage.clear();
        redirectToHome();
      })
      .catch((error) => {
        console.error("Error during sign out:", error);
      });
  };

  const breadcrumb = useMemo(() => {
    switch (location.pathname) {
      case "/leads":
        return `${t("CONTACTS")} > ${t("LEADS")}`;
      case "/customers":
        return `${t("CONTACTS")} > ${t("CUSTOMERS")}`;
      case "/segments":
        return `${t("CONTACTS")} > ${t("SEGMENTS")}`;
      case "/analytics":
        return `${t("PERFORMANCE")} > ${t("REPORTS")}`;
      case "/settings/sync":
        return `${t("INTEGRATIONS")} > ${t("SYNC")}`;
      case "/settings/rawdata":
        return `${t("DATA_MANAGER")} > ${t("RAW_DATA")}`;
      case "/settings/library":
        return `${t("DATA_MANAGER")} > ${t("LIBRARY")}`;
      case "/settings/profile":
        return `${t("ACCOUNT")} > ${t("MY_PROFILE")}`;
      case "/settings/company":
        return `${t("ACCOUNT")} > ${t("COMPANY")}`;
      case "/settings/members":
        return `${t("ACCOUNT")} > ${t("MEMBERS")}`;
      case "/settings/sources":
        return `${t("CONNECTORS")} > ${t("SOURCES")}`;
      default:
        return `${t("HOME")} > ${t("MAIN_METRICS")}`;
    }
  }, [location.pathname]);

  const handleMenuClick = (path: string) => {
    setMenuOpen(false);
    navigate(path);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target as Node) &&
      profileIconRef.current &&
      !profileIconRef.current.contains(event.target as Node)
    ) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    if (menuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuOpen]);

  return (
    <div className="bg-[#F2F5FF] px-4 py-4 flex justify-between items-center z-10 h-24">
      <div className="flex items-center ml-8 gap-3 text-base font-medium leading-5">
        {breadcrumb.split(" > ").map((crumb, index) => (
          <React.Fragment key={index}>
            <p
              className={`text-${
                index === breadcrumb.split(" > ").length - 1
                  ? "[#2F3235]"
                  : "[#46474C]"
              }`}
            >
              {crumb}
            </p>
            {index < breadcrumb.split(" > ").length - 1 && (
              <p className="text-[#46474C]">{">"}</p>
            )}
          </React.Fragment>
        ))}
      </div>

      <div className="relative" ref={menuRef}>
        <div
          className="flex justify-center items-center rounded-[8px] px-4 cursor-pointer"
          onClick={() => setMenuOpen(!menuOpen)}
          ref={profileIconRef}
        >
          <img
            src={imgSrc}
            alt="Company Logo"
            className="w-10 h-10 rounded-full"
            style={{
              width: "50px",
              height: "50px",
              background: "white",
            }}
            onError={handleError}
          />
        </div>
        {menuOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-white border rounded-lg shadow-lg z-20">
            <div className="p-4 border-b border-[#EEEEEEEE]">
              <p
                style={{
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "20px",
                  color: "#5D6064",
                }}
              >
                {profile?.full_name ? profile.full_name : profile.email}
              </p>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "15px",
                  color: "#5D6064",
                }}
              >
                {company}
              </p>
            </div>
            <Menu>
              <MenuItem
                className="hover:bg-[#F3F5F9]"
                style={{
                  display: "flex",
                  borderRadius: "unset",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#2F3235",
                  padding: "0.5rem 1rem",
                  alignItems: "center",
                  marginTop: "16px",
                }}
                onClick={() => handleMenuClick("/settings/rawdata")}
                placeholder={undefined}
              >
                <Cog6ToothIcon className="w-5 h-5 mr-2" />
                {t("SETTINGS")}
              </MenuItem>
              <MenuItem
                className="hover:bg-[#F3F5F9]"
                style={{
                  display: "flex",
                  borderRadius: "unset",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#2F3235",
                  padding: "0.5rem 1rem",
                  alignItems: "center",
                }}
                onClick={() => handleMenuClick("/settings/profile")}
                placeholder={undefined}
              >
                <UserCircleIcon className="w-5 h-5 mr-2" />
                {t("ACCOUNT")}
              </MenuItem>
              <MenuItem
                className="hover:bg-[#F3F5F9]"
                style={{
                  display: "flex",
                  borderRadius: "unset",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#2F3235",
                  padding: "0.5rem 1rem",
                  alignItems: "center",
                }}
                onClick={() => handleMenuClick("/settings/feedback")}
                placeholder={undefined}
              >
                <FeedbackIcon className="w-5 h-5 mr-2" />
                {t("FEEDBACK")}
              </MenuItem>
              <MenuItem
                className="hover:bg-[#F3F5F9]"
                style={{
                  display: "flex",
                  borderRadius: "unset",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#2F3235",
                  padding: "0.5rem 1rem",
                  alignItems: "center",
                  marginBottom: "16px",
                }}
                onClick={() => handleMenuClick("/support")}
                placeholder={undefined}
              >
                <QuestionMarkCircleIcon className="w-5 h-5 mr-2" />
                {t("CONTACT_SUPPORT")}
              </MenuItem>
              <MenuItem
                className="hover:bg-[#F3F5F9]"
                style={{
                  display: "flex",
                  borderRadius: "unset",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#2F3235",
                  padding: "0.5rem 1rem",
                  alignItems: "center",
                }}
                onClick={() => handleLogout()}
                placeholder={undefined}
              >
                <ArrowRightStartOnRectangleIcon className="w-5 h-5 mr-2" />
                {t("LOGOUT")}
              </MenuItem>
            </Menu>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;

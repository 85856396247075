/** @format */

import React, { ChangeEvent, KeyboardEvent } from "react";
import { ReactComponent as NewSend } from "../../assets/icons/new_send_icon1.svg";
import { t } from "i18next";

interface PromptInputProps {
  message: string;
  isConnected: boolean;
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleKeyPress: (e: KeyboardEvent<HTMLInputElement>) => void;
  sendResponse: (message: string) => void;
  isConfigured?: boolean;
}

const PromptInput: React.FC<PromptInputProps> = ({
  message,
  isConnected,
  isConfigured, // Add this line
  handleInputChange,
  handleKeyPress,
  sendResponse,
}) => {
  return (
    <div className="mt-auto flex relative max-w-screen items-center">
      <input
        type="text"
        className="flex-grow p-4 pl-4 pr-16 border bg-[#FFFFFF] rounded-[30px] text-[#46474C] focus:outline-none focus:border-[#8B98CD] max-w-screen shadow-lg"
        placeholder={t("ENTER_PROMPT")}
        value={message}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
      />
      <button
        disabled={!isConnected || !isConfigured} // Update this line
        className={`absolute rounded-[38px] pl-4 right-2 w-[48px] h-[48px] bg-[#5B26D3] flex items-center pr-3 ${!isConnected || !isConfigured ? "opacity-50" : ""}`} // Update this line
        onClick={() => sendResponse(message)}
      >
        <NewSend className="h-5 w-5 text-[#FFFFFF]" />
      </button>
    </div>
  );
};

export default PromptInput;

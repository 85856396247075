/** @format */

import React from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import SearchButton from "./SearchButton";

interface FilterComponentProps {
  availableFilters: string[];
  placeHolders?: string[];
  filterValues: Record<string, string>;
  onFilterChange: (filterName: string, value: string) => void;

  searchInput: string;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  showFilters: boolean; // Controlled by parent for dynamic show/hide
  showSearch?: boolean; // Optional prop to control visibility of the search input
  onDownload?: () => void;
}

const FilterComponent: React.FC<FilterComponentProps> = React.memo(
  ({
    availableFilters,
    placeHolders,
    filterValues,
    onFilterChange,
    searchInput,
    onSearchChange,
    showFilters,
    showSearch = true,
    onDownload,
  }) => {
    return (
      <div className="flex items-center justify-between">
        <div className="flex flex-wrap items-center gap-4">
          {showSearch && (
            <SearchButton
              searchInput={searchInput}
              onSearchChange={onSearchChange}
            />
          )}
          {showFilters &&
            availableFilters.map((filter, index) => (
              <div key={filter} className="relative">
                <input
                  type="text"
                  placeholder={
                    placeHolders !== undefined ? placeHolders[index] : filter
                  }
                  value={filterValues[filter] || ""}
                  onChange={(e) => onFilterChange(filter, e.target.value)}
                  className="pl-4 pr-10 py-2 border border-[#79767D] rounded-lg"
                />
                {filterValues[filter] && (
                  <button
                    onClick={() => onFilterChange(filter, "")}
                    className="absolute inset-y-0 right-0 px-3 flex items-center"
                  >
                    <XMarkIcon className="h-4 w-4 text-red-500" />
                  </button>
                )}
              </div>
            ))}
        </div>
      </div>
    );
  }
);

export default FilterComponent;

/** @format */

import React, { useCallback, useEffect, useState } from "react";
import FilterComponent from "../../components/FilterComponent";
import { privateSupabase } from "../../api/SupabaseClient";
import { InfoCardMini } from "../../components/Cards";
import Table from "../../components/Table";
import {
  fetchData,
  fetchFilterOptions,
  applyFilter,
  removeFilter,
} from "../../utils/ColumnFilterHandler";

export function Leads() {
  const [searchInput, setSearchInput] = useState("");
  const [filterValues, setFilterValues] = useState({});

  const [data, setData] = useState<any[]>([]);
  const [columns, setColumns] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  const [totalLeads, setTotalLeads] = useState<number>(0);
  const [convertedLeads, setConvertedLeads] = useState<number>(0);

  const [filters, setFilters] = useState<Record<string, string[]>>({});
  const [filterOptions, setFilterOptions] = useState<Record<string, string[]>>(
    {}
  );

  const tableName = "view_leads";

  const columnTypes: Record<string, string> = {
    "Last modification": "timestamp",
  };

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    const firstItemIndex = (currentPage - 1) * itemsPerPage;
    const newCurrentPage = Math.floor(firstItemIndex / newItemsPerPage) + 1;
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newCurrentPage);
  };

  useEffect(() => {
    const fetchDataAsync = async () => {
      setIsLoading(true);
      const fromIndex = (currentPage - 1) * itemsPerPage;
      const toIndex = fromIndex + itemsPerPage;
      const { data, totalItems } = await fetchData(
        privateSupabase,
        tableName,
        filters,
        searchInput,
        columnTypes,
        fromIndex,
        toIndex
      );
      setData(data);
      setTotalItems(totalItems);
      setColumns(data.length > 0 ? Object.keys(data[0]) : []);
      setIsLoading(false);
    };

    fetchDataAsync();
  }, [itemsPerPage, currentPage, filters, searchInput]);

  useEffect(() => {
    const fetchFilterOptionsAsync = async () => {
      const options = await fetchFilterOptions(
        privateSupabase,
        tableName,
        columnTypes
      );
      setFilterOptions(options);
    };

    fetchFilterOptionsAsync();
  }, []);

  const handleFilterChange = useCallback((filterName: any, value: any) => {
    setFilterValues((prev) => ({ ...prev, [filterName]: value }));
  }, []);

  const getFilters = () => {
    return ["name", "created_by"];
  };

  const onSearchChange = useCallback((e: any) => {
    setSearchInput(e.target.value);
  }, []);

  const fetchInfoCardData = async () => {
    try {
      const { count: totalCount, error: totalError } = await privateSupabase
        .from(tableName)
        .select("*", { count: "exact" });
      if (totalError) {
        throw totalError;
      }
      setTotalLeads(totalCount || 0);

      const { count: convertedCount, error: convertedError } =
        await privateSupabase
          .from(tableName)
          .select("*", { count: "exact" })
          .eq("Status", "completed");
      if (convertedError) {
        throw convertedError;
      }
      setConvertedLeads(convertedCount || 0);
    } catch (error) {
      console.error(`Error fetching info card data: ${error}`);
    }
  };

  useEffect(() => {
    fetchInfoCardData();
  }, []);

  const infoCardData = [
    {
      title: "Total Leads",
      mainValue: totalLeads,
    },
    {
      title: "Converted Leads",
      mainValue: convertedLeads,
    },
  ];

  return (
    <div className="tab-products flex flex-col overflow-hidden h-full">
      <div className="flex justify-between items-center flex-wrap gap-1 mb-4 p-6">
        {infoCardData.map((card, index) => (
          <div key={index} className="flex-grow">
            <InfoCardMini title={card.title} data={card.mainValue} />
          </div>
        ))}
      </div>
      <div className="flex flex-col justify-between bg-white rounded-2xl mx-6 flex-grow overflow-hidden">
        <div className="flex flex-row justify-between items-center w-full px-8 mt-8 mb-4">
          <div className="flex flex-row justify-start items-center space-x-4">
            <FilterComponent
              availableFilters={getFilters()}
              placeHolders={["Name", "Created By"]}
              filterValues={filterValues}
              onFilterChange={handleFilterChange}
              searchInput={searchInput}
              onSearchChange={onSearchChange}
              showFilters={false}
              showSearch={true}
            />
          </div>
        </div>
        <div className="px-8 pb-8 flex-grow overflow-hidden">
          <Table
            headers={columns.map((col) =>
              col
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")
            )} // Convert column names to display names
            rows={data.map((row: any) => {
              return columns.reduce((acc: Record<string, any>, column) => {
                acc[
                  column
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")
                ] = row[column];
                return acc;
              }, {});
            })}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            onItemsPerPageChange={handleItemsPerPageChange}
            totalItems={totalItems}
            onPageChange={setCurrentPage}
            filters={filters}
            applyFilter={async (column, value) => {
              const newFilterOptions = await applyFilter(
                privateSupabase,
                tableName,
                filters,
                column,
                value,
                columnTypes
              );
              setFilterOptions((prevOptions) => ({
                ...prevOptions,
                ...newFilterOptions,
                [column]: prevOptions[column], // Preserve current column options
              }));
              setFilters((prevFilters) => ({
                ...prevFilters,
                [column]: value,
              }));
              setCurrentPage(1);
            }}
            removeFilter={async (column) => {
              const newFilterOptions = await removeFilter(
                privateSupabase,
                tableName,
                filters,
                column,
                columnTypes
              );
              setFilterOptions((prevOptions) => ({
                ...prevOptions,
                ...newFilterOptions,
                [column]: prevOptions[column], // Preserve current column options
              }));
              setFilters((prevFilters) => {
                const { [column]: removed, ...rest } = prevFilters;
                return rest;
              });
              setCurrentPage(1);
            }}
            tableName={tableName}
            filterOptions={filterOptions} // Pass filter options to Table component
            columnTypes={columnTypes} // Pass column types to Table component
          />
        </div>
      </div>
      <div className="h-10"></div>
    </div>
  );
}

/** @format */

export const transformData = (items: any, title: any) => {
  if (!Array.isArray(items) || items.length === 0) {
    console.error("Invalid items array");
    return { labels: [], datasets: [] };
  }

  // Obtener las claves del primer objeto, asumimos que todos los objetos tienen las mismas claves
  const keys = Object.keys(items[0]);

  // Suponiendo que la primera clave es para las etiquetas y el resto son para los datos
  const labelKey = keys[0];

  // Extraer etiquetas dinámicamente
  const labels = items.map((item) => item[labelKey]);

  // Crear datasets dinámicamente
  const datasets = keys.slice(1).map((dataKey) => ({
    label: dataKey,
    data: items.map((item) => parseFloat(item[dataKey])),
    borderWidth: 1,
  }));

  return {
    labels: labels,
    datasets: datasets,
    legend: keys
  };
};

export const transformKeyToTitle = (key: string) => {
  return key
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const generateDataPoint = (queryResult: { [x: string]: any }[]) => {
  const key = Object.keys(queryResult[0])[0];
  const value = queryResult[0][key];
  const title = transformKeyToTitle(key);

  return {
    title: title,
    data: value,
  };
};
export function calculateResult(args: any) {
  let a = typeof args.a === "string" ? parseFloat(args.a) : args.a;
  let b = typeof args.b === "string" ? parseFloat(args.b) : args.b;

  switch (args.operator) {
    case "add":
    case "+":
      return a + b;
    case "subtract":
    case "-":
      return a - b;
    case "multiply":
    case "*":
      return a * b;
    case "divide":
    case "/":
      return a / b;
    case "power":
    case "^":
      return Math.pow(a, b);
    case "root":
      return Math.pow(a, 1 / b);
    default:
      throw new Error(`Unknown operator: ${args.operator}`);
  }
}

export const generateRandomId = () => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomPart = "";
  for (let i = 0; i < 4; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomPart += characters[randomIndex];
  }
  const timestamp = Date.now().toString();
  return `t_id_${randomPart}${timestamp}`;
};

export function createIncrementalCounter() {
  let count = 0;

  return function () {
    return count++;
  };
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const checkForKeyPhrases = (input: any) => {
  const phrases: any = {
    chart: ["graph", "chart", "diagram"],
    table: ["table"],
    segment: ["segment"],
    view: ["create a view", "generate a view", "view"],
    datapoint: ["data point", "datapoint"],
  };

  let context = null;
  Object.keys(phrases).forEach((type) => {
    if (
      phrases[type].some((phrase: string) =>
        input.toLowerCase().includes(phrase.toLowerCase())
      )
    ) {
      context = type;
    }
  });
  return context;
};

export const isObjectEmpty = (obj: any) => {
  return Object.keys(obj).length === 0;
};

export const listToArray = (inputString: any) => {
  try {
    // Try to parse the inputString as JSON
    const parsedArray = JSON.parse(inputString);
    if (Array.isArray(parsedArray)) {
      return parsedArray.map((str) => str.trim().replace(/\"|\[|\]/g, ""));
    }
  } catch (error) {
    // If parsing fails, handle it as a regular string
  }

  // Fallback for handling regular comma-separated string
  return inputString
    .split(",")
    .map((str: any) => str.trim().replace(/\"|\[|\]/g, ""));
};

export const renameKeysToMatchHeadersSave = (
  dataArray: any,
  headersArray: any
) => {
  if (
    !Array.isArray(dataArray) ||
    !Array.isArray(headersArray) ||
    dataArray.length === 0 ||
    headersArray.length === 0
  ) {
    return dataArray;
  }

  const normalizeString = (str: any) =>
    str.toLowerCase().replace(/[\s_()]/g, "");

  const getBestMatchKey = (key: any, headers: any) => {
    const normalizedKey = normalizeString(key);
    let bestMatchHeader = headers[0];
    let bestMatchScore = 0;

    headers.forEach((header: any) => {
      const normalizedHeader = normalizeString(header);
      let matchScore = 0;

      const keyWords = normalizedKey.split(/[^a-z0-9]/).filter(Boolean);
      const headerWords = normalizedHeader.split(/[^a-z0-9]/).filter(Boolean);

      keyWords.forEach((word: any) => {
        if (headerWords.includes(word)) {
          matchScore++;
        }
      });

      if (matchScore > bestMatchScore) {
        bestMatchScore = matchScore;
        bestMatchHeader = header;
      }
    });

    return bestMatchHeader;
  };

  return dataArray.map((item) => {
    const updatedItem: any = {};
    headersArray.forEach((header) => {
      const bestMatchKey = getBestMatchKey(header, Object.keys(item));
      updatedItem[header] = item[bestMatchKey];
    });
    return updatedItem;
  });
};

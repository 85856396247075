import React from "react";
import { Typography } from "@material-tailwind/react";
import { ReactComponent as PostgresIcon } from "./../../../assets/icons/postgresql_icon.svg";
import { ReactComponent as WooCommerceIcon } from "./../../../assets/icons/woocomerce_icon.svg";
import { ReactComponent as MetabaseIcon } from "./../../../assets/icons/metabase_icon.svg";
import { ReactComponent as SheetsIcon } from "./../../../assets/icons/sheets_icon.svg";
import { ReactComponent as SnowflakeIcon } from "./../../../assets/icons/snowflake_icon.svg";
import { ReactComponent as ActiveCampaignIcon } from "./../../../assets/icons/activecampaign_icon.svg";
import { ReactComponent as AirtableIcon } from "./../../../assets/icons/airtable_icon.svg";
import { t } from "i18next";
import MainButton from "../../Buttons/MainButton";

const services = [
  { name: "PostgreSQL", disabled: false, icon: PostgresIcon },
  { name: "WooCommerce", disabled: false, icon: WooCommerceIcon },
  { name: "Metabase", disabled: false, icon: MetabaseIcon },
  { name: "Google Sheets", disabled: false, icon: SheetsIcon },
  { name: "Snowflake", disabled: false, icon: SnowflakeIcon },
  { name: "ActiveCampaign", disabled: false, icon: ActiveCampaignIcon },
  { name: "Airtable", disabled: false, icon: AirtableIcon }
];

export const ApiConnectionTab = ({ handleServiceSelection }: { handleServiceSelection: (service: string) => void }) => {
  return (
    <div className="flex-1 px-6 pt-4 pb-8 min-w-full min-h-full">
      <div className="csv-upload-zone space-y-6">
        <Typography placeholder={undefined}>{t("DATABASE_WAREHOUSE")}</Typography>
        <div className="flex w-full justify-center">
          <ul className="list-none w-full">
            {services.map((service) => (
              <li
                key={service.name}
                className={`flex justify-between items-center py-2 border-b-2 ${service.disabled ? "text-gray-400 cursor-not-allowed" : "text-gray-800"
                  }`}
              >
                <div className="flex items-center">
                  <service.icon style={{ width: '24px', height: '24px' }} className="text-gray-500 mr-2" />
                  <span className={`${service.disabled ? "text-gray-400" : "text-gray-800"}`}>
                    {service.name}
                  </span>
                </div>
                <MainButton buttonText={"CONNECT"} onClick={() => handleServiceSelection(service.name.toLowerCase())} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

/** @format */

import React, { useState, useEffect, useCallback } from "react";
import Table from "../../components/Table";
import { privateSupabase } from "../../api/SupabaseClient";
import { Tabs, TabsHeader, Tab } from "@material-tailwind/react";
import FilterComponent from "../../components/FilterComponent";
import LoadingSpinner from "../../components/LoadingSpinner";
import MainButton from "../../components/Buttons/MainButton";
import { useFilter } from "../../context/filterContext";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { ExpandedCard } from "../../components/Cards";
import { normalizeItem } from "../../utils/cardUtils";
import { t } from "i18next";
import { Card } from "../DashboardCreator/types";
import { processItems } from "../DashboardCreator/useDashboardData";
import GridStackLibraryContainer from "./GridStackLibraryContainer";
import useCubeData from "../../hooks/useCubeData";

interface FilterOptions {
  [key: string]: string;
}

interface TabData {
  label: string;
  value: string;
  desc: string;
}

interface LibraryProps {
  onClick: (card: Card) => void;
  isSelect?: boolean;
  role: number;
}

const tabData: TabData[] = [
  {
    label: "CARDS",
    value: "cards",
    desc: ``,
  },
  {
    label: "LIST",
    value: "list",
    desc: ``,
  },
];

export const Library = ({ onClick, isSelect, role }: LibraryProps) => {
  const { handleExecuteQueryCube } = useCubeData();
  const [libraryData, setLibraryData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filterValues, setFilterValues] = useState<FilterOptions>({});
  const [activeTab, setActiveTab] = useState("cards");
  const [isLoading, setIsLoading] = useState(false);
  const { setOpenCreateCustom, setIsLarge } = useFilter();
  const [selectedCard, setSelectedCard] = useState<any>(null);
  const [selectedTimeFilters, setSelectedTimeFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isEditElement, setEditElement] = useState(false);
  const [selectedMeasures, setSelectedMeasures] = useState<any[]>([]);
  const [selectedDimensions, setSelectedDimensions] = useState<any[]>([]);
  const [selectedSegments, setSelectedSegments] = useState<any[]>([]);
  const [selectedTimeDimensions, setSelectedTimeDimensions] = useState<any>([]);

  const handleSizeChange = (isLarge: boolean) => {
    setIsLarge(isLarge);
  };

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const getItems = async (filters: FilterOptions = {}) => {
    console.log("get items: ", isEditElement);
    setIsLoading(true);
    try {
      let query = privateSupabase
        .from("library")
        .select(`
          *,
          profiles:created_by (
            email,
            full_name
          )
        `, { count: "exact" })
        .order("created_at", { ascending: false })
        .neq("type", "title");

      Object.entries(filters).forEach(([key, value]) => {
        if (!value) return;
        if (key === "search") {
          query = query.ilike("title", `%${value}%`);
        } else {
          query = query.ilike(key, `%${value}%`);
        }
      });

      const { data: items, error: errorProfiles } = await query;
      if (!items) {
        setLibraryData([]);
        setTotalItems(0);
        return;
      }

      if (filters.search) {
        const itemList = await processItems(
          items,
          handleExecuteQueryCube,
          selectedFilters,
          selectedMeasures,
          selectedTimeDimensions,
          selectedDimensions,
          selectedSegments
        );

        if (errorProfiles) {
          setIsLoading(false);
          throw errorProfiles;
        }
        setLibraryData(itemList || []);
        setTotalItems(itemList.length);
      } else {
        const from = (currentPage - 1) * itemsPerPage;
        const to = from + itemsPerPage - 1;

        const paginatedQuery = query.range(from, to);
        const {
          data: paginatedItems,
          error: paginatedError,
          count: paginatedCount,
        } = await paginatedQuery;

        if (paginatedError) {
          setIsLoading(false);
          throw paginatedError;
        }

        const paginatedItemLists = await processItems(
          paginatedItems,
          handleExecuteQueryCube,
          selectedFilters,
          selectedMeasures,
          selectedTimeDimensions,
          selectedDimensions,
          selectedSegments
        );
        setLibraryData(paginatedItemLists || []);
        setTotalItems(paginatedCount || 0);
      }
      setIsLoading(false);
    } catch (error: any) {
      setLibraryData([]);
    }
  };

  useEffect(() => {
    getItems({ ...filterValues, search: searchInput });
  }, [filterValues, searchInput, currentPage, itemsPerPage, isEditElement]);

  const handleRowClick = (row: any) => {
    const selectedItem = libraryData.find((item) => item.id === row.id);
    if (isSelect === false || activeTab === "list") {
      setSelectedCard(normalizeItem(selectedItem));
    } else {
      onClick(normalizeItem(selectedItem));
    }
  };

  const handleItemClick = (row: any) => {
    const selectedItem = libraryData.find((item) => item.id === row.id);
    onClick(normalizeItem(selectedItem));
  };

  const generateRows = () =>
    libraryData.map((item) => ({
      id: item.id,
      Name: item.title,
      Type: item.type.toUpperCase(),
      "Created by": item.profiles.full_name !== "" && item.profiles.full_name !== null ? item.profiles.full_name : item.profiles.email,
    }));

  const headers = ["Name", "Type", "Created by"];

  const handleFilterChange = useCallback(
    (filterName: string, value: string) => {
      setFilterValues((prev) => ({ ...prev, [filterName]: value }));
    },
    []
  );

  const onSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchInput(e.target.value);
    },
    []
  );

  const handleAddCardClick = () => {
    setOpenCreateCustom(true);
  };

  const generatePageNumbers = (currentPage: any, totalPages: any) => {
    const pageNeighbours = 1;

    const startPage = Math.max(2, currentPage - pageNeighbours);
    const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

    let pages: any = [1];
    if (startPage > 2) {
      pages.push("...");
    }

    for (let page = startPage; page <= endPage; page++) {
      pages.push(page);
    }

    if (endPage < totalPages - 1) {
      pages.push("...");
    }

    if (totalPages > 1) {
      pages.push(totalPages);
    }

    return pages;
  };

  const handleDelete = (id: number) => {
    setLibraryData((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  const renderPagination = () => (
    <div className="flex justify-center items-center mt-4 pb-4">
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="px-4 py-2 mx-2 bg-gray-200 rounded disabled:opacity-50"
      >
        <ChevronLeftIcon className="h-6 w-6" />
      </button>

      {generatePageNumbers(
        currentPage,
        Math.ceil(totalItems / itemsPerPage)
      ).map((page: any, idx: any) =>
        page === "..." ? (
          <span key={idx} className="mx-1">
            {page}
          </span>
        ) : (
          <button
            key={idx}
            onClick={() => handlePageChange(page)}
            className={
              currentPage === page
                ? "mx-1 px-2 py-1 rounded bg-[#F4F6FA] text-[#0059AD]"
                : "mx-1 px-2 py-1 rounded text-gray-700 bg-white hover:bg-blue-100"
            }
          >
            {page}
          </button>
        )
      )}

      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
        className="px-4 py-2 mx-2 bg-gray-200 rounded disabled:opacity-50"
      >
        <ChevronRightIcon className="h-6 w-6" />
      </button>
    </div>
  );

  return (
    <>
      <div className="flex flex-col justify-between bg-white rounded-2xl mx-6 h-[calc(100%-50px)] pb-6">
        <div className="flex flex-row bg-white justify-between mx-6 pb-12 pt-4 mt-6">
          <div className="flex justify-start space-x-4 items-center">
            <FilterComponent
              availableFilters={["name", "created_by"]}
              placeHolders={["Name", "Created By"]}
              filterValues={filterValues}
              onFilterChange={handleFilterChange}
              searchInput={searchInput}
              onSearchChange={onSearchChange}
              showFilters={showFilterOptions}
              showSearch={true}
            />
          </div>
          <div className="flex justify-start items-center my-auto gap-4">
            <Tabs
              value={activeTab}
              className="flex justify-start items-center my-auto"
            >
              <TabsHeader
                className="bg-transparent border p-2 rounded-lg"
                indicatorProps={{ className: "bg-gray-900/10 shadow-none" }}
                placeholder={undefined}
              >
                {tabData.map(({ label, value }) => (
                  <Tab
                    key={value}
                    value={value}
                    onClick={() => setActiveTab(value)}
                    className={`px-4 rounded-md cursor-pointer text-sm ${
                      activeTab === value
                        ? "text-white !bg-[#0458DD]"
                        : "text-black bg-white"
                    }`}
                    placeholder={undefined}
                  >
                    {t(label)}
                  </Tab>
                ))}
              </TabsHeader>
            </Tabs>
            {role !== 3 && (
              <MainButton
                buttonText="ADD_ELEMENT"
                onClick={handleAddCardClick}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col flex-1 justify-between bg-white rounded-2xl mx-6 overflow-y-auto">
          {isLoading && <LoadingSpinner message="LOADING" />}
          {!isLoading && activeTab === "list" && (
            <Table
              headers={headers}
              rows={generateRows()}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              onItemsPerPageChange={handleItemsPerPageChange}
              totalItems={totalItems}
              onPageChange={handlePageChange}
              onRowClick={isSelect ? handleItemClick : handleRowClick}
            />
          )}
          {!isLoading && activeTab === "cards" && (
            <div className="flex-1 flex flex-col justify-between">
              <GridStackLibraryContainer
                draggableItems={libraryData}
                onItemClick={handleItemClick}
                isSelect={isSelect}
                setEditElement={setEditElement}
              />
              {renderPagination()}
            </div>
          )}
        </div>
      </div>
      {selectedCard && (
        <ExpandedCard
          isOpen={!!selectedCard}
          onClose={() => setSelectedCard(null)}
          source={selectedCard}
        />
      )}
    </>
  );
};

export default Library;

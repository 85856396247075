/** @format */

import React from "react";
import { Button } from "@material-tailwind/react";
import { ArrowPathIcon } from "@heroicons/react/24/solid";

export interface ButtonProperties {
  buttonText?: string;
  onClick: () => void;
  plusIcon?: boolean;
}

const RefreshButton = ({
  onClick,
}: ButtonProperties) => {
  return (
    <Button
      style={{
        minWidth: "40px",
        minHeight: "40px",
        width: "40px",
        height: "40px",
        textTransform: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      className="bg-[#0059AD]"
      color="white"
      variant="outlined"
      onClick={onClick}
      placeholder={undefined}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
    >
      <div className="flex justify-center items-center gap-2">
         <ArrowPathIcon style={{width: "1.5rem", height: "1.5rem"}}/>
      </div>
    </Button>
  );
};

export default RefreshButton;

/** @format */

import {
  UserIcon,
  BookOpenIcon,
  ChartPieIcon,
  UserGroupIcon,
  ListBulletIcon,
  CursorArrowRippleIcon,
} from "@heroicons/react/24/outline";
import { ReactComponent as RawDataIcon } from "./../../assets/icons/raw_data_icon.svg";
import { ReactComponent as SourcesIcon } from "./../../assets/icons/sources_icon.svg";
import { ReactComponent as CompanyIcon } from "./../../assets/icons/company_icon.svg";

export interface SidebarDropdownOption {
  label: string;
  path: string;
  Icon: React.ElementType;
  isHeroIcon?: boolean; // Nueva propiedad para indicar si el icono es de Heroicons
  selected?: boolean;
}

export interface SidebarOptionProps {
  label: string;
  arrowDown?: boolean;
  dropdownOptions?: SidebarDropdownOption[];
  path?: string;
}

export const mainSidebarOptions: SidebarOptionProps[] = [
  // HIDDEN UNTIL NEW APPROACH ON THOSE TABLES
  // {
  //   label: "CONTACTS",
  //   arrowDown: true,
  //   dropdownOptions: [
  //     { label: "LEADS", path: "/leads", Icon: ChartPieIcon, isHeroIcon: true },
  //     { label: "CUSTOMERS", path: "/customers", Icon: UserGroupIcon, isHeroIcon: true },
  //     { label: "SEGMENTS", path: "/segments", Icon: ListBulletIcon, isHeroIcon: true },
  //   ],
  // },
  {
    label: "PERFORMANCE",
    arrowDown: true,
    dropdownOptions: [
      {
        label: "REPORTS",
        path: "/analytics",
        Icon: CursorArrowRippleIcon,
        isHeroIcon: true,
      },
    ],
  },
];

export const settingsSidebarOptions: SidebarOptionProps[] = [
  {
    label: "DATA_MANAGER",
    arrowDown: true,
    dropdownOptions: [
      {
        label: "RAW_DATA",
        path: "/settings/rawdata",
        Icon: RawDataIcon,
        isHeroIcon: false,
      },
      {
        label: "LIBRARY",
        path: "/settings/library",
        Icon: BookOpenIcon,
        isHeroIcon: true,
      },
      {
        label: "Semantic Layer",
        path: "/settings/semantic-layer",
        Icon: RawDataIcon,
        isHeroIcon: false,
      }
    ],
  },
  {
    label: "ACCOUNT",
    arrowDown: true,
    dropdownOptions: [
      {
        label: "MY_PROFILE",
        path: "/settings/profile",
        Icon: UserIcon,
        isHeroIcon: true,
      },
      {
        label: "COMPANY",
        path: "/settings/company",
        Icon: CompanyIcon,
        isHeroIcon: false,
      },
      {
        label: "MEMBERS",
        path: "/settings/members",
        Icon: UserGroupIcon,
        isHeroIcon: true,
      },
    ],
  },
  {
    label: "CONNECTORS",
    arrowDown: true,
    dropdownOptions: [
      {
        label: "SOURCES",
        path: "/settings/sources",
        Icon: SourcesIcon,
        isHeroIcon: false,
      },
    ],
  },
];

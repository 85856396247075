/** @format */

import React from "react";
import {
  CheckIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Table from "../Table";
import { Typography } from "@material-tailwind/react";
import DownloadOptions from "../Cards/DownloadOptions";
import DownloadIconButton from "../Buttons/DownloadIconButton";
import EditIconButton from "../Buttons/EditIconButton";

interface TableCardProps {
  table: any;
  typeToRender: string;
  noDataQuery: boolean;
  tableData: any[];
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  handleTitleChange: (id: string, value: string) => void;
  handleSaveTitle: (id: string) => void;
  handleCancelEdit: (id: string) => void;
  handleEditTitle: (id: string) => void;
  setCurrentTable: (table: any) => void;
  setIsDownloadOptionsOpen: (isOpen: boolean) => void;
  isDownloadOptionsOpen: boolean;
  handleDownloadOptionClick: (option: string, table: any) => void;
  handleItemsPerPageChange: (itemsPerPage: number) => void;
  setCurrentPage: (page: number) => void;
  generateRows: (table: any) => any[];
}

const TableRender = ({
  table,
  typeToRender,
  noDataQuery,
  tableData,
  currentPage,
  totalItems,
  itemsPerPage,
  handleTitleChange,
  handleSaveTitle,
  handleCancelEdit,
  handleEditTitle,
  setCurrentTable,
  setIsDownloadOptionsOpen,
  isDownloadOptionsOpen,
  handleDownloadOptionClick,
  handleItemsPerPageChange,
  setCurrentPage,
  generateRows,
}: any) => {
  const headersArray =
    typeof table.tableHeaders === "string"
      ? table.tableHeaders.split(",").map((header: any) => header.trim())
      : Array.isArray(table.tableHeaders)
        ? table.tableHeaders
        : [];

  const handlePageChange = (newPage: any) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="w-full rounded-lg shadow-lg bg-white p-4 relative">
      <div className="flex justify-between items-center text-base text-[#48464C] font-medium mb-4 w-full">
        <div className="flex items-center w-full">
          {table.tableTitle && (
            <div className="flex items-center w-full">
              {table.isEditingTitle ? (
                <div className="flex items-center w-full">
                  <input
                    type="text"
                    value={table.editableTitle}
                    onChange={(e) =>
                      handleTitleChange(table.id, e.target.value)
                    }
                    className="border-b border-[#48464C] outline-none flex-grow"
                    autoFocus
                  />
                  <CheckIcon
                    className="w-5 h-5 ml-2 text-[#48464C] cursor-pointer"
                    onClick={() => handleSaveTitle(table.id)}
                  />
                  <XMarkIcon
                    className="w-5 h-5 ml-2 text-[#48464C] cursor-pointer"
                    onClick={() => handleCancelEdit(table.id)}
                  />
                </div>
              ) : (
                <div className="flex items-center w-full">
                  <span className="flex-grow">{table.editableTitle}</span>
                  
                </div>
              )}
            </div>
          )}
        </div>
        <div className="flex flex-row">
        <EditIconButton showText={false} onClick={() => handleEditTitle(table.id)} />
        <div className="relative">
          <DownloadIconButton showText={false} onClick={() => {
            setCurrentTable(table);
            setIsDownloadOptionsOpen(!isDownloadOptionsOpen);
          }} />
          {isDownloadOptionsOpen && (
            <DownloadOptions
              onOptionClick={(option) =>
                handleDownloadOptionClick(option, table)
              }
              type={typeToRender}
            />
          )}
        </div>
        </div>
      </div>

      <div className="text-sm font-normal text-[#48464C] mb-10">
        {table.tableDescription && table.tableDescription}
      </div>
      <div
        id={`datapoint-${table.id}`}
        className="max-h-[700px] overflow-y-auto"
      >
        {Array.isArray(table.tableData) && table.tableData.length > 0 && (
          <Table
            headers={
              Array.isArray(headersArray)
                ? headersArray.filter((header) =>
                  table.tableData.some(
                    (data: any) =>
                      data[header] !== null && data[header] !== ""
                  )
                )
                : []
            }
            rows={generateRows(table)}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            onItemsPerPageChange={handleItemsPerPageChange}
            totalItems={totalItems}
            onPageChange={handlePageChange}
          />
        )}
        {noDataQuery && tableData.length === 0 && (
          <Typography
            className="flex w-full items-center justify-center text-lg font-semibold"
            placeholder={undefined}
          >
            No data fetched, please try again
          </Typography>
        )}
      </div>
    </div>
  );
};

export default TableRender;

/** @format */
import React from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
  Typography,
} from "@material-tailwind/react";

const FeedbackDialog = ({ isOpen, onClose }) => {
  const handleSendEmail = () => {
    window.location.href = "mailto:info@omniloy.com";
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      handler={onClose}
      className="!max-w-[90vw] !min-w-[70vw] overflow-auto"
    >
      <div className="flex flex-col w-full justify-between text-[#46474C]">
        <DialogHeader className="px-6 py-6 text-xl">
          Provide Feedback
        </DialogHeader>
        <DialogBody className="px-6 py-4">
          <Typography>
            Please provide screenshots of the problem encountered or any other
            issues or detail the problem.
          </Typography>
        </DialogBody>
        <DialogFooter className="flex-shrink-0">
          <Button
            variant="outlined"
            onClick={onClose}
            className="mr-1 rounded-full !text-[#76787D] border-[#76787D]"
          >
            Cancel
          </Button>
          <Button
            variant="filled"
            onClick={handleSendEmail}
            className="rounded-full px-16 text-white bg-[#0458DD] hover:shadow-none"
          >
            Send Email
          </Button>
        </DialogFooter>
      </div>
    </Dialog>
  );
};

export default FeedbackDialog;

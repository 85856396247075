/** @format */

import React from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
} from "@material-tailwind/react";

interface DuplicateReportModalProps {
  open: boolean;
  onClose: () => void;
  onDuplicate: () => void;
}

const DuplicateReportModal: React.FC<DuplicateReportModalProps> = ({
  open,
  onClose,
  onDuplicate,
}) => {
  return (
    <Dialog open={open} handler={onClose} placeholder={undefined}>
      <DialogHeader placeholder={undefined}>Duplicate Report</DialogHeader>
      <DialogBody placeholder={undefined}>
        Are you sure you want to duplicate this report?
      </DialogBody>
      <DialogFooter placeholder={undefined}>
        <Button
          variant="text"
          color="gray"
          onClick={onClose}
          placeholder={undefined}
        >
          Cancel
        </Button>
        <Button
          variant="gradient"
          color="blue"
          onClick={onDuplicate}
          placeholder={undefined}
        >
          Duplicate
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default DuplicateReportModal;

import React, { useState } from "react";
import { Input, Button, Typography } from "@material-tailwind/react";
import { publicSupabase } from "../../../api/SupabaseClient";
import login_screen from "../../../assets/images/login_screen.svg";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { t } from "i18next";

export function PasswordReset() {
  const [email, setEmail] = useState("");
  const currentURL = window.location.href.substring(0, window.location.href.lastIndexOf('/'));

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const changePasswordUrl = `${currentURL}/change-password`;
    const { error } = await publicSupabase.auth.resetPasswordForEmail(email, {
      redirectTo: changePasswordUrl
    });

    if (!error) {
      toast.success('Check your email!');
    } else {
      // Do something different here
      toast.error(t("ERROR") + "EO-1051");
    }
  };

  return (
    <div className="flex w-full h-screen bg-white overflow-hidden">
      <div className="flex-shrink-0 w-2/5 h-full">
        <img src={login_screen} alt="login" className="w-auto h-full" />
      </div>
      <div className="w-3/5 m-auto">
        <div className="max-w-md m-auto bg-white rounded-lg p-10">

          <div className="mb-8 items-start">
            <Typography
              className="text-3xl font-medium text-[#0B1962] text-left"
              placeholder={""}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              {t("RESET_PASSWORD")}
            </Typography>
            <hr className="my-8" style={{ color: "#C5CCE6", height: "1px" }} />
          </div>
          <div>
            <form onSubmit={handleSubmit} className="flex flex-col gap-6">
              <Input
                type="email"
                size="lg"
                label={t("EMAIL")}
                placeholder=""
                onChange={handleEmailChange}
                crossOrigin={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              />

              <div style={{
                gap: "25px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}>
                <Button
                  style={{
                    minWidth: "150px",
                    textTransform: "none",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="w-full mt-4 bg-[#0458DD]"
                  placeholder={undefined}
                  type="submit" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}              >
                  {t("SEND_PASS_RECOVERY_LINK")}
                </Button>
                <Link
                  style={{ fontWeight: 400, fontSize: "14px", lineHeight: "16px" }}
                  to="/sign-in"
                  className="text-xs text-[#3E54AC] hover:underline"
                >
                  {t("RETURN_TO_LOGIN")}
                </Link>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from "react";
import { Dialog, Typography } from "@material-tailwind/react";
import { DeleteHeader, ModalFooter } from "../../../../components/Modal";
import { t } from "i18next";
import { privateSupabase } from "../../../../api/SupabaseClient";
import toast from "react-hot-toast";

interface DeleteUserModalProps {
    open: boolean;
    handler: (open: boolean) => void;
    email: string;
    fetchUsers: () => Promise<void>;
}

const DeleteUserModal: React.FC<DeleteUserModalProps> = ({
    open,
    handler,
    email,
    fetchUsers
}) => {

    const deleteUser = async () => {
        try {
            const { data, error } = await privateSupabase
                .from("profiles")
                .delete()
                .eq("email", email);

            if (error) {
                throw error;
            }
            await fetchUsers();
            handler(false);
            toast.success("Profile deleted successfully");
        } catch (error) {
            console.error("Failed to delete profile:", error);
            toast.error(t("ERROR") + "EO-1090");
        }
    };
    return (
        <Dialog
            style={{ width: "970px", minWidth: "970px" }}
            open={open}
            handler={handler}
            placeholder={undefined}
        >
            <DeleteHeader title={t("DELETE_CONFIRMATION")} onClose={() => handler(false)} />
            <div className="flex justify-start gap-8 mt-6 pb-8">
                <Typography style={{ padding: "0px 80px" }} placeholder={undefined}>
                    {t("DELETE_TEXT")} {email}? {t("DELETE_TEXT_2")}
                </Typography>
            </div>
            <ModalFooter
                isRed={true}
                buttonText={t("DELETE")}
                onClick={() => { if (email) deleteUser(); }}
            />
        </Dialog>
    );
};

export default DeleteUserModal;

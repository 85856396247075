/** @format */

import React, { useState, useEffect, createContext } from "react";
import { useLocation } from "react-router-dom";

interface SelectionContextData {
  currentPath: string;
  setCurrentPath: React.Dispatch<React.SetStateAction<string>>;
}

export const CurrentPathContext = createContext<SelectionContextData>({
  currentPath: "",
  setCurrentPath: () => {},
});

export const CurrentPathProvider = ({ children }: any) => {
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const location = useLocation();

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  return (
    <CurrentPathContext.Provider value={{ currentPath, setCurrentPath }}>
      {children}
    </CurrentPathContext.Provider>
  );
};

/** @format */

import React, { useState, useEffect, useRef } from "react";
import { privateSupabase } from "../../api/SupabaseClient";
import CardSelectionModal from "./CardSelectionModal";
import DashboardTitle from "./DashboardTitle";
import DashboardControls from "./DashboardControls";
import SideMenu from "./SideMenu";
import useDashboardData from "./useDashboardData";
import GridStackContainer from "./GridStackContainer";
import LoadingSpinner from "../../components/LoadingSpinner";
import { GridStack } from "gridstack";
import { t } from "i18next";
import { Card } from "./types";
import { useFilter } from "../../context/filterContext";

export const DashboardCreator = ({
  selectedReport,
  isMainDashboard = false,
  isNewDashboard = false,
  selectedFilters = [],
  selectedMeasures = [],
  selectedSegments = [],
  selectedTimeDimensions = [],
  selectedDimensions = [],
  filtersUpdated = false,
  onClose = () => {},
  setFiltersUpdated = (val: boolean) => {},
}: any) => {
  const gridInstance = useRef<GridStack | null>(null);
  const { draggableItems, setDraggableItems, isLoading, fetchData } =
    useDashboardData(selectedReport);
  const {
    setOpenEditDashboard,
    openEditDashboard,
    setOpenCreateCustom,
    shouldScroll,
  } = useFilter();
  const nodesRef = useRef(new Map());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tempTitle, setTempTitle] = useState("");
  const [dashboardTitle, setDashboardTitle] = useState("");
  const [floatMode, setFloatMode] = useState(true);
  const [initialDraggableItems, setInitialDraggableItems] = useState<any[]>([]);
  const [, setRenderTrigger] = useState(0);
  const mainContainerRef = useRef<HTMLDivElement | null>(null);
  const [session, setSession] = useState<any>(null);

  useEffect(() => {
    privateSupabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  useEffect(() => {
    const fetchItems = async () => {
      const ids = draggableItems
        .filter((item) => item.type === "card")
        .map((item) => item.data.id);
      if (ids.length > 0) {
        const { data, error } = await privateSupabase
          .from("library")
          .select("*")
          .in("id", ids);

        if (error) {
          console.error("Error fetching items:", error);
        } else {
          data.forEach((item) => {
            const positions = item.position;
            if (positions && gridInstance.current) {
              const currentPosition = positions.find(
                (pos: any) => pos.report === selectedReport
              );
              if (currentPosition) {
                const node = document.querySelector(
                  `.grid-stack-item[gs-id='${item.id}']`
                );
                if (node) {
                  const options = {
                    x: currentPosition.size.gs_x,
                    y: currentPosition.size.gs_y,
                    w: currentPosition.size.gs_w,
                    h: currentPosition.size.gs_h,
                  };
                  gridInstance.current.update(node as HTMLElement, options);
                }
              }
            }
          });
        }
      }
    };

    fetchItems();
  }, [draggableItems]);

  useEffect(() => {
    if (selectedReport) {
      const fetchTitle = async () => {
        const { data, error } = await privateSupabase
          .from("reports_dashboard")
          .select("title")
          .eq("id", selectedReport)
          .single();

        if (error) {
          console.error("Error fetching report title: ", error);
        } else {
          setDashboardTitle(data.title);
          setTempTitle(data.title);
        }
      };

      fetchTitle();
    }
  }, [selectedReport]);

  useEffect(() => {
    if (filtersUpdated) {
      fetchData(
        selectedReport,
        selectedFilters,
        selectedMeasures,
        selectedTimeDimensions,
        selectedDimensions,
        selectedSegments
      );
      setFiltersUpdated(false); // Reset after fetching data
    }
  }, [
    filtersUpdated,
    fetchData,
    selectedReport,
    selectedFilters,
    selectedMeasures,
    selectedTimeDimensions,
    selectedDimensions,
    selectedSegments,
  ]);

  useEffect(() => {
    if (!isLoading && shouldScroll) {
      scrollDown();
    }
  }, [isLoading, shouldScroll]);

  const handleSave = async () => {
    let currentReport = selectedReport || null;

    const newItems = draggableItems.filter((item) => !item.id);

    let insertedItems: any = [];
    if (newItems.length > 0) {
      const newItemsInserts = newItems.map(async (item) => {
        try {
          const { data, error } = await privateSupabase
            .from("library")
            .insert([
              {
                type: item.type,
                title: item.title,
                position: [
                  {
                    size: {
                      gs_x: item.gsX,
                      gs_y: item.gsY,
                      gs_w: item.gsW,
                      gs_h: item.gsH,
                    },
                    report: currentReport || "temp",
                  },
                ],
                thread_id: 1,
                query: "",
                created_by: session.user.email,
                size: "normal",
              },
            ])
            .select();

          if (error) {
            console.error("Error inserting new library item: ", error);
            return null;
          } else {
            return { ...item, id: data[0].id };
          }
        } catch (err) {
          console.error("Unexpected error inserting new library item: ", err);
          return null;
        }
      });

      insertedItems = await Promise.all(newItemsInserts);
    }

    const updatedDraggableItems = draggableItems.map((item) => {
      const insertedItem = insertedItems.find(
        (newItem: any) => newItem && newItem.title === item.title && !item.id
      );
      return insertedItem ? { ...item, id: insertedItem.id } : item;
    });

    if (!currentReport) {
      try {
        const { data, error } = await privateSupabase
          .from("reports_dashboard")
          .insert([
            {
              title: tempTitle || dashboardTitle,
              created_by: session.user.email,
              last_update: new Date().toISOString(),
              items: [],
            },
          ])
          .select();

        if (error) {
          console.error("Error inserting new report: ", error);
          return;
        } else {
          currentReport = data[0].id;
        }
      } catch (err) {
        console.error("Unexpected error inserting new report: ", err);
        return;
      }
    }

    const updates = updatedDraggableItems.map(async (item) => {
      if (item.id) {
        const node = document.querySelector(
          `.grid-stack-item[gs-id='${item.id}']`
        );

        if (node) {
          const gsX = parseInt(node.getAttribute("gs-x") || "0");
          const gsY = parseInt(node.getAttribute("gs-y") || "0");
          const gsW = parseInt(node.getAttribute("gs-w") || "0");
          const gsH = parseInt(node.getAttribute("gs-h") || "0");

          const newPosition = {
            size: {
              gs_x: gsX,
              gs_y: gsY,
              gs_w: gsW,
              gs_h: gsH,
            },
            report: currentReport,
          };

          try {
            const { data, error } = await privateSupabase
              .from("library")
              .select("position")
              .eq("id", item.id)
              .single();

            if (error) {
              console.error("Error fetching library item: ", error);
            } else {
              let existingPositions = Array.isArray(data.position)
                ? data.position
                : [];

              const positionIndex = existingPositions.findIndex(
                (pos: any) => pos.report === currentReport
              );

              if (positionIndex !== -1) {
                existingPositions[positionIndex] = newPosition;
              } else {
                existingPositions.push(newPosition);
              }

              const { error: updateError } = await privateSupabase
                .from("library")
                .update({ position: existingPositions })
                .eq("id", item.id);

              if (updateError) {
                console.error("Error updating library item: ", updateError);
              } else {
                console.log(`Successfully updated item with id: ${item.id}`);
              }
            }
          } catch (err) {
            console.error("Unexpected error updating library item: ", err);
          }
        } else {
          console.error(`Node not found for item with id: ${item.id}`);
        }
      } else {
        console.error("Item does not have an id: ", item);
      }
    });

    await Promise.all(updates)
      .then(() => {
        console.log("All items updated successfully.");
      })
      .catch((error) => {
        console.error("Error updating items: ", error);
      });

    const itemIds = updatedDraggableItems.map((item) => item.id);

    const updateReportsDashboard = async () => {
      const { error: updateError } = await privateSupabase
        .from("reports_dashboard")
        .update({
          title: tempTitle || dashboardTitle,
          last_update: new Date().toISOString(),
          items: itemIds,
        })
        .eq("id", currentReport);

      if (updateError) {
        console.error("Error updating reports_dashboard: ", updateError);
      }
    };

    await updateReportsDashboard();
    setOpenEditDashboard(false);
    fetchData(currentReport);
    setInitialDraggableItems([]);
    setDashboardTitle(tempTitle || dashboardTitle);
  };

  const handleCancel = () => {
    setDraggableItems(initialDraggableItems);
    setOpenEditDashboard(false);
    if (isNewDashboard) {
      onClose();
    }
  };

  const handleTitleChange = (updatedTitle: string, itemId: string) => {
    const updatedItems = draggableItems.map((item) => {
      if (item.id === itemId && item.type === "title") {
        return { ...item, title: updatedTitle };
      }
      return item;
    });
    setDraggableItems(updatedItems);
  };

  const handleIconChange = (iconKey: string, sectionIndex: any) => {
    const updatedItems = draggableItems.map((item) => {
      if (item.sectionIndex === sectionIndex && item.type === "title") {
        return { ...item, icon: iconKey };
      }
      return item;
    });
    setDraggableItems(updatedItems);
  };

  const deleteItem = (itemIndex: number) => {
    const updatedItems = [...draggableItems];
    updatedItems.splice(itemIndex, 1);
    setDraggableItems(updatedItems);
  };

  const duplicateItem = (itemIndex: any) => {
    const itemToDuplicate: any = draggableItems[itemIndex];
    const newItems: any = [...draggableItems];
    const duplicatedItem = {
      ...itemToDuplicate,
      key: `${itemToDuplicate.key}-copy`,
    };
    newItems.splice(itemIndex + 1, 0, duplicatedItem);
    setDraggableItems(newItems);
  };

  const handleSizeChange = (itemId: any, x: any, y: any, w: any, h: any) => {
    if (gridInstance.current) {
      const node: any = document.querySelector(
        `.grid-stack-item[gs-id='${itemId}']`
      );
      if (node) {
        gridInstance.current.update(node, { x, y, w, h });
      }

      setDraggableItems((prevItems) =>
        prevItems.map((item) =>
          item.id === itemId
            ? { ...item, gsX: x, gsY: y, gsW: w, gsH: h }
            : item
        )
      );
    }
  };

  const handleCardSelect = (selectedCard: Card) => {
    setIsModalOpen(false);
    addSectionOrCardFromLibrary(selectedCard);
  };

  const addSectionOrCardFromLibrary = (card: Card) => {
    const newCard: any = {
      ...card,
      label: card.name,
      position: [],
    };

    const { x, y } = getNextPosition();
    const size = getItemSize(newCard.type);

    newCard.position.push({
      size: { gs_x: x, gs_y: y, gs_w: size.w, gs_h: size.h },
      report: selectedReport,
    });
    newCard.gsX = x;
    newCard.gsY = y;
    newCard.gsW = size.w;
    newCard.gsH = size.h;

    const newDraggableItems = [...draggableItems, newCard];

    setDraggableItems(newDraggableItems);
    forceUpdate();
  };

  const addSectionOrCard = (
    type: "Title" | "Normal Card" | "Small Card" | "Table"
  ) => {
    const newTitle: any = {
      type: "title",
      title: "Test",
      position: [],
    };

    const { x, y } = getNextPosition();
    const size = getItemSize(type);

    newTitle.position.push({
      size: { gs_x: x, gs_y: y, gs_w: size.w, gs_h: size.h },
      report: selectedReport,
    });
    newTitle.gsX = x;
    newTitle.gsY = y;
    newTitle.gsW = size.w;
    newTitle.gsH = size.h;

    setDraggableItems([...draggableItems, newTitle]);
    forceUpdate();
  };

  const toggleFloatMode = () => {
    setFloatMode(!floatMode);
  };

  const handleEditMode = () => {
    setInitialDraggableItems(JSON.parse(JSON.stringify(draggableItems)));
  };

  const getNextPosition = () => {
    let maxY = 0;
    let maxH = 0;
    draggableItems.forEach((item) => {
      const itemY = item.position?.reduce((acc: any, pos: any) => {
        if (pos.report === selectedReport) {
          return pos.size.gs_y;
        }
        return acc;
      }, 0);
      const itemH = item.position?.reduce((acc: any, pos: any) => {
        if (pos.report === selectedReport) {
          return pos.size.gs_h;
        }
        return acc;
      }, 0);
      if (itemY + itemH > maxY + maxH) {
        maxY = itemY;
        maxH = itemH;
      }
    });
    return { x: 0, y: maxY + maxH };
  };

  const getItemSize = (type: string) => {
    if (type === "title") {
      return { w: 12, h: 2 };
    } else if (type === "datapoint") {
      return { w: 6, h: 4 };
    } else {
      return { w: 6, h: 6 };
    }
  };

  const scrollDown = () => {
    if (mainContainerRef.current) {
      const findAndScrollToGridStack = () => {
        if (mainContainerRef.current) {
          const gridStackContainer =
            mainContainerRef.current.querySelector(".grid-stack");
          if (gridStackContainer) {
            const rect = gridStackContainer.getBoundingClientRect();
            const yOffset =
              gridStackContainer.getBoundingClientRect().top +
              window.pageYOffset;
            window.scrollTo({ top: yOffset, behavior: "smooth" });
          } else {
            console.log("Did not found gridStack");
          }
        }
      };
      findAndScrollToGridStack();
    } else {
      console.log("mainContainerRef.current is null");
    }
  };

  const forceUpdate = () => setRenderTrigger((prev) => prev + 1);

  return (
    <div ref={mainContainerRef} className="flex flex-col w-full h-full ">
      {isLoading ? (
        <div className="flex-1 justify-center items-center">
          <LoadingSpinner message={t("LOADING_DASHBOARD")} />
        </div>
      ) : (
        <>
          <div className="flex items-center justify-between mb-4">
            <DashboardTitle
              isEditMode={openEditDashboard}
              isMainDashboard={isMainDashboard}
              tempTitle={tempTitle}
              dashboardTitle={dashboardTitle}
              setTempTitle={setTempTitle}
              handleBackToReports={onClose}
            />
            <DashboardControls
              isEditMode={openEditDashboard}
              setIsEditMode={setOpenEditDashboard}
              handleCancel={handleCancel}
              handleSave={handleSave}
              handleEditMode={handleEditMode}
              toggleFloatMode={toggleFloatMode}
            />
          </div>

          <div className="flex w-full h-full">
            {openEditDashboard && (
              <SideMenu
                iconStyle="h-6 w-6 text-black"
                hoverStyle="hover:text-gray-700"
                handleAddCardFromLibrary={() => setIsModalOpen(true)}
                handleAddCardFromAI={() => {
                  setOpenCreateCustom(true);
                }}
                addSectionOrCard={addSectionOrCard}
              />
            )}
            <div
              className={`flex-1 overflow-y-auto ${openEditDashboard ? "ml-20" : ""}`}
            >
              <GridStackContainer
                draggableItems={draggableItems}
                isEditMode={openEditDashboard}
                nodesRef={nodesRef}
                deleteItem={deleteItem}
                duplicateItem={duplicateItem}
                handleTitleChange={handleTitleChange}
                handleIconChange={handleIconChange}
                handleSizeChange={handleSizeChange}
                isMainDashboard={isMainDashboard}
                selectedReport={selectedReport}
                floatMode={floatMode}
                forceUpdate={forceUpdate}
              />
            </div>
          </div>
          <CardSelectionModal
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onSelect={handleCardSelect}
          />
        </>
      )}
    </div>
  );
};

export default DashboardCreator;

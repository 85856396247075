/** @format */

import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { t } from "i18next";

interface PaginationProps {
  currentPage: number;
  totalItems: number;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
  handleItemsPerPageChange: (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalItems,
  itemsPerPage,
  onPageChange,
  handleItemsPerPageChange,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const generatePageNumbers = (currentPage: number, totalPages: number) => {
    const pageNeighbours = 1;
    const startPage = Math.max(2, currentPage - pageNeighbours);
    const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

    let pages: any = [1];

    if (startPage > 2) {
      pages.push("...");
    }

    for (let page = startPage; page <= endPage; page++) {
      pages.push(page);
    }

    if (endPage < totalPages - 1) {
      pages.push("...");
    }

    if (totalPages > 1) {
      pages.push(totalPages);
    }

    return pages;
  };

  const pageNumbers = generatePageNumbers(currentPage, totalPages);
  const itemsPerPageOptions = [5, 10, 20].filter(
    (option) => option === 5 || 
                (option === 10 && totalItems > 5 && totalItems < 20) || 
                option <= totalItems || 
                (option === 20 && totalItems >= 20)
  );
  


  return (
    <div className="flex justify-between items-center py-2 px-5 bg-white border-t">
      <div className="flex flex-row items-center">
        <span className="text-sm font-medium text-gray-700 mr-2 whitespace-nowrap">
          {t("RESULTS_PER_PAGE")}:
        </span>
        <div className="flex-1">
          <select
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
            className="form-select block w-full text-sm border-gray-300 rounded-md border px-2 py-1"
          >
            {itemsPerPageOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="flex items-center">
        <button
          onClick={() => onPageChange(Math.max(1, currentPage - 1))}
          disabled={currentPage === 1}
          className={`text-sm p-1 rounded-full ${currentPage <= 1
              ? "text-gray-300"
              : "text-gray-600 hover:bg-gray-100"
            }`}
        >
          <ChevronLeftIcon className="h-6 w-6" />
        </button>

        {pageNumbers.map((page: any, idx: any) =>
          page === "..." ? (
            <span key={idx} className="mx-1">
              {page}
            </span>
          ) : (
            <button
              key={idx}
              onClick={() => onPageChange(page)}
              className={
                currentPage === page
                  ? "mx-1 px-2 py-1 rounded bg-[#F4F6FA] text-[#0059AD]"
                  : "mx-1 px-2 py-1 rounded text-gray-700 bg-white hover:bg-blue-100"
              }
            >
              {page}
            </button>
          )
        )}

        <button
          onClick={() => onPageChange(Math.min(totalPages, currentPage + 1))}
          disabled={currentPage === totalPages}
          className={`text-sm p-1 rounded-full ${currentPage >= totalPages
              ? "text-gray-300"
              : "text-gray-600 hover:bg-gray-100"
            }`}
        >
          <ChevronRightIcon className="h-6 w-6" />
        </button>
      </div>
    </div>
  );
};

export default Pagination;

/** @format */
import React, { useState } from "react";
import {
  Button,
  Typography,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Dialog,
} from "@material-tailwind/react";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { format } from "sql-formatter";
import QuerySteps from "./QuerySteps";
import { t } from "i18next";
import FeedbackDialog from "./FeedbackDialog";

interface VerifyDialogProps {
  currentTable: {
    lastSqlQuery: string;
    explanation?: { description: string }[];
  };
  queryError?: string;
  queryErrorExplanation?: string;
  queryErrorCode?: string;
  queryErrorHint?: string;
  querySteps?: { title: string; description: string }[];
  handleCopyClick: (e: React.MouseEvent, query: string) => void;
  dialogOpen: boolean;
  handleDialogOpen: () => void;
  lastSqlQuery: string;
  description: string;
}

const VerifyDialog: React.FC<VerifyDialogProps> = ({
  description,
  lastSqlQuery,
  currentTable,
  queryError,
  queryErrorExplanation,
  queryErrorCode,
  queryErrorHint,
  querySteps,
  handleCopyClick,
  dialogOpen,
  handleDialogOpen,
}) => {
  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);
  let formatSQL;

  formatSQL = format(lastSqlQuery, {
    language: "postgresql",
    tabWidth: 2,
    keywordCase: "upper",
    linesBetweenQueries: 2,
  });

  const handleFeedbackDialogOpen = () => {
    setIsFeedbackDialogOpen(!isFeedbackDialogOpen);
  };

  return (
    <>
      <Dialog
        open={dialogOpen}
        handler={handleDialogOpen}
        className="!max-h-[90vh] !h-auto !max-w-[90vw] !min-w-[70vw] overflow-auto"
        placeholder={undefined}
      >
        <div className="flex flex-col w-full !h-full justify-between !text-[#46474C]">
          <DialogHeader className="px-6 py-6 text-xl" placeholder={undefined}>
            {t("VERIFY_RESULTS")}
          </DialogHeader>

          <Typography
            className="px-6 py-1 mb-4 font-medium"
            placeholder={undefined}
          >
            How Omni Generated This
          </Typography>

          <DialogBody className="flex w-full h-full" placeholder={undefined}>
            <div className="w-full space-y-4 px-2">
              {lastSqlQuery ? (
                <div className="flex flex-col gap-4 px-2">
                  <div className="!text-[#46474C]">
                    {description.split("\n").map((line, lineIndex) => (
                      <Typography placeholder={undefined}>{line}</Typography>
                    ))}
                  </div>

                  {querySteps && <QuerySteps querySteps={querySteps} />}
                </div>
              ) : (
                <div className="text-red-500">
                  {queryError && (
                    <Typography
                      className="flex w-full items-center justify-center text-lg font-semibold"
                      placeholder={undefined}
                    >
                      Error: {queryError}
                    </Typography>
                  )}
                  {queryErrorCode && (
                    <Typography
                      className="flex w-full items-center justify-center text-lg font-semibold"
                      placeholder={undefined}
                    >
                      Code: {queryErrorCode}
                    </Typography>
                  )}
                  {queryErrorHint && (
                    <Typography
                      className="flex w-full items-center justify-center text-lg font-semibold"
                      placeholder={undefined}
                    >
                      Hint: {queryErrorHint}
                    </Typography>
                  )}
                  {queryErrorExplanation && (
                    <Typography
                      className="flex w-full items-center justify-center text-lg font-semibold"
                      placeholder={undefined}
                    >
                      Explanation: {queryErrorExplanation}
                    </Typography>
                  )}
                </div>
              )}
            </div>
          </DialogBody>
          <Typography className="px-6 py-1 font-medium" placeholder={undefined}>
            Code to Verify
          </Typography>
          <DialogBody
            className="flex flex-col w-full h-full px-6"
            placeholder={undefined}
          >
            <div className="p-4 rounded-xl border">
              {lastSqlQuery !== "" && <pre>{formatSQL}</pre>}
              <DocumentDuplicateIcon
                className="absolute top-8 right-8 h-6 w-6 text-gray-500 cursor-pointer"
                onClick={(e) => handleCopyClick(e, currentTable.lastSqlQuery)}
              />
            </div>
          </DialogBody>
          <DialogFooter className="flex-shrink-0" placeholder={undefined}>
            <Button
              variant="outlined"
              onClick={handleFeedbackDialogOpen}
              className="mr-1 rounded-full !text-[#76787D] border-[#76787D]"
              placeholder={undefined}
            >
              Provide Feedback
            </Button>
            <Button
              variant="filled"
              onClick={handleDialogOpen}
              className="rounded-full px-16 text-white bg-[#0458DD] hover:shadow-none"
              placeholder={undefined}
            >
              Close
            </Button>
          </DialogFooter>
        </div>
      </Dialog>
      <FeedbackDialog
        isOpen={isFeedbackDialogOpen}
        onClose={handleFeedbackDialogOpen}
      />
    </>
  );
};

export default VerifyDialog;

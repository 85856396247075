/** @format */

import React, { useEffect, useState } from "react";
import { Input, Button, Typography } from "@material-tailwind/react";
import eyeIcon from "./../../assets/icons/eye_icon.svg";
import login_screen from "./../../assets/images/login_screen.svg";
import { useNavigate, Link } from "react-router-dom";
import { t } from "i18next";
import { useSupabase } from "../../context/supabaseContext";
import {
  fetchPrivateSetup,
  privateSignIn,
  privateSupabase,
  publicSignIn,
} from "../../api/SupabaseClient";
import i18n from "../../locales/i18n";

export const SignIn = () => {
  const { publicSupabase, initializePrivateSupabase } = useSupabase();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { setUserIdPrivate } = useSupabase();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value);
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(e.target.value);

  useEffect(() => {
    if (publicSupabase && email && password && loading) {
      const signIn = async () => {
        localStorage.clear();
        const { data, error } = await publicSignIn();
        if (data && data.user) {
          setShowError(false);
          let projectUrl = localStorage.getItem("supabaseProjectUrl");
          let anonKey = localStorage.getItem("supabaseAnonKey");
          if (anonKey === null && projectUrl === null) {
            const { data: fetchSetupData, error: fetchSetupError } =
              await fetchPrivateSetup(email);
            if (fetchSetupError) {
              console.error(
                "[LOGIN ERROR] - Fetching Private Setup Error: ",
                fetchSetupError.message
              );
              setShowError(true);
              navigate("/sign-in");
            }
            if (fetchSetupData) {
              projectUrl = fetchSetupData.project_url;
              anonKey = fetchSetupData.anon_key;
              localStorage.setItem(
                "supabaseProjectUrl",
                fetchSetupData.project_url
              );
              localStorage.setItem("supabaseAnonKey", fetchSetupData.anon_key);
            }
          }
          if (!projectUrl || !anonKey) {
            console.error(
              "Error: Supabase project URL or anonymous key is missing"
            );
            return {
              success: false,
              error: "Missing project URL or anonymous key",
            };
          }

          initializePrivateSupabase(projectUrl!, anonKey!);

          const { data, error } = await privateSignIn(email, password);
          if (data && data.user) {
            setUserIdPrivate(data.user.id as any);
            const { data: dataUpdate, error: errorUpdate } =
              await privateSupabase.rpc("update_last_login", {
                user_email: data.user.email,
              });

            if (errorUpdate) throw errorUpdate;
            const { data: profileData, error: profileError } =
              await privateSupabase
                .from("profiles")
                .select("language")
                .eq("id", data.user.id)
                .single();

            if (profileError) {
              console.error(
                "[LOGIN ERROR] - Fetching Profile Error: ",
                profileError.message
              );
            } else {
              const userLanguage = profileData.language || "en";
              localStorage.setItem("i18nextLng", userLanguage);
              i18n.changeLanguage(userLanguage);
            }

            navigate("/");
          }
          if (error) {
            console.error(
              "[LOGIN ERROR] - Private Login Error: ",
              error.message
            );
            setShowError(true);
            navigate("/sign-in");
          }
        } else if (error) {
          console.error("[LOGIN ERROR] - Public Login Error: ", error.message);
          setShowError(true);
          localStorage.clear();
          navigate("/sign-in");
        }
        setLoading(false);
      };

      signIn();
    }
  }, [
    publicSupabase,
    email,
    password,
    loading,
    navigate,
    initializePrivateSupabase,
  ]);

  const initiateLogin = () => {
    setLoading(true);
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    initiateLogin();
  };

  return (
    <div className="flex w-full h-screen bg-white overflow-hidden">
      <div className="flex-shrink-0 w-2/5 h-full">
        <img src={login_screen} alt="login" className="w-auto h-full" />
      </div>
      <div className="w-3/5 m-auto">
        <div className="max-w-md m-auto bg-white rounded-lg p-10">
          <div className="mb-8 items-start">
            <Typography
              className="text-3xl font-medium text-[#0B1962] text-left"
              placeholder={""}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              {t("LOG_IN_TO_YOUR_ACCOUNT")}
            </Typography>
          </div>
          <hr className="my-8" style={{ color: "#C5CCE6", height: "1px" }} />
          <div>
            <form onSubmit={handleFormSubmit} className="flex flex-col gap-2">
              <Input
                type="email"
                size="lg"
                label={t("EMAIL")}
                placeholder=""
                onChange={handleEmailChange}
                error={showError}
                style={showError ? { backgroundColor: "#FBD7D5" } : {}}
                crossOrigin={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              />

              <Input
                type={passwordVisible ? "text" : "password"}
                size="lg"
                label={t("PASSWORD")}
                placeholder=""
                onChange={handlePasswordChange}
                icon={
                  <img
                    src={eyeIcon}
                    alt="Toggle visibility"
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    className="cursor-pointer"
                  />
                }
                error={showError}
                style={showError ? { backgroundColor: "#FBD7D5" } : {}}
                crossOrigin={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              />

              <Link
                to="/reset-password"
                className="text-xs text-[#0458DD] hover:underline self-end"
              >
                {t("FORGOT_PASSWORD")}
              </Link>

              <Button
                style={{
                  minWidth: "150px",
                  textTransform: "none",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                type="submit"
                className="w-full mt-4 bg-[#0458DD]"
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                {t("LOG_IN")}
              </Button>

              {showError && (
                <div>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      lineHeight: "17.92px",
                      textAlign: "left",
                      color: "#D50000",
                    }}
                    className="text-start mb-2"
                    placeholder={undefined}
                  >
                    {t("LOGIN_ERROR")}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: "300",
                      lineHeight: "13.44px",
                      textAlign: "left",
                      color: "#D50000",
                    }}
                    className="text-start mb-2"
                    placeholder={undefined}
                  >
                    {t("LOGIN_ERROR_MESSAGE")}
                  </Typography>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

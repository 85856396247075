/** @format */

import React, { createContext, useState, useContext, useEffect } from "react";
import { privateSupabase } from "../api/SupabaseClient";
import { t } from "i18next";

const FilterContext = createContext();

export const useFilter = () => useContext(FilterContext);

export const FilterProvider = ({ children }) => {
  const [currentView, setCurrentView] = useState("table");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [displayedSourcesData, setDisplayedSourcesData] = useState([]);
  const [companyLogo, setCompanyLogo] = useState("");
  const [editedTitle, setEditedTitle] = useState("");
  const [open, setOpen] = useState(false);
  const [typeUsage, setTypeUsage] = useState("");
  const [renderType, setRenderType] = useState("");
  const [newThreadId, setNewThreadId] = useState(false);
  const [filters, setFilters] = useState({});
  const [sqlQuery, setSqlQuery] = useState(""); // Added state for SQL query
  const [usersList, setUsersList] = useState([]);
  const [emailPng, setEmailPng] = useState(null);
  const [csvInput, setCsvInput] = useState([]);
  const [chartRequest, setChartRequest] = useState("");
  const [jsxContent, setJsxContent] = useState("");
  const [tableName, setTableName] = useState("");
  const [columnConfigurations, setColumnConfigurations] = useState([]);
  const [insertItems, setInsertItems] = useState([]);
  const [removedCharts, setRemovedCharts] = useState(false);
  const [tableDefinition, setTableDefinition] = useState(null);
  const [pageCode, setPageCode] = useState(null);
  const [updatedPageCode, setUpdatedPageCode] = useState(null);
  const [listReadyToSend, setListReadyToSend] = useState(false);
  const [organizeCards, setOrganizeCards] = useState(null);
  const [selectedList, setSelectedList] = useState(null);
  const [updatedOrganizedCards, setUpdatedOrganizedCards] = useState(null);
  const [currentlyOrganizing, setCurrentlyOrganizing] = useState(null);
  const [allSelectedTablesInfo, setAllSelectedTablesInfo] = useState([]);
  const [allTableNames, setAllTableNames] = useState(() => {
    const savedNames = localStorage.getItem("allTableNames");
    return savedNames ? JSON.parse(savedNames) : [];
  });
  const [tableToGetColumns, setTableToGetColumns] = useState(null);
  const [tableForSegments, setTablesForSegment] = useState(null);
  const [tablesExplanation, setTablesExplanation] = useState([]);
  const [activeRoute, setActiveRoute] = useState("");
  const [openCreateCustom, setOpenCreateCustom] = useState(false);
  const [openEditDashboard, setOpenEditDashboard] = useState(false);
  const [infoCards, setInfoCards] = useState([]);
  const [typeCustom, setTypeCustom] = useState("");
  const [renderChart, setRenderChart] = useState(1);
  const [tableStep, setTableStep] = useState(0);
  const [segmentStep, setSegmentStep] = useState(0);
  const [chartStep, setChartStep] = useState(0);
  const [hasMessages, setHasMessages] = useState(false);
  const [phraseText, setPhraseText] = useState("");
  const [tableData, setTableData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableTitle, setTableTitle] = useState("");
  const [tableDescription, setTableDescription] = useState("");
  const [chartTableData, setChartTableData] = useState([]);
  const [chartChatData, setChartChatData] = useState(null);
  const [initialMessage, setInitialMessage] = useState("");
  const [chartType, setChartType] = useState("");
  const [noDataQuery, setNoDataQuery] = useState(false);
  const [dashboardChart, setDashboardChart] = useState(null);
  const [dashboardTable, setDashboardTable] = useState(null);
  const [dashboardCreatorInit, setDashboardCreatorInit] = useState(false);
  const [datapointInfo, setDatapointInfo] = useState(null);
  const [cleanedQuery, setCleanedQuery] = useState("");
  const [creatingFromReport, setCreatingFromReport] = useState(false);
  const [createdElement, setCreatedElement] = useState({});
  const [currentCardPage, setCurrentCardPage] = useState(1);
  const [currentPageInfo, setCurrentPageInfo] = useState({
    currentPage: 1,
    itemId: null,
  });
  const [pageNumbers, setPageNumbers] = useState({});
  const [itemsCardPerPage, setItemsCardPerPage] = useState(3);
  const [totalCardItems, setTotalCardItems] = useState(0);
  const [querySteps, setQuerySteps] = useState([]);
  const [queryError, setQueryError] = useState(null);
  const [queryErrorCode, setQueryErrorCode] = useState(null);
  const [queryErrorHint, setQueryErrorHint] = useState(null);
  const [queryErrorExplanation, setQueryErrorExplanation] = useState(null);
  const [lastSqlQuery, setLastSqlQuery] = useState("");
  const [threadId, setThreadId] = useState(null);
  const [isLarge, setIsLarge] = useState(false);
  const [size, setSize] = useState({});
  const [chatHistory, setChatHistory] = useState([]);
  const [tables, setTables] = useState([]);
  const [prevThreadId, setPrevThreadId] = useState(null);
  const [matchingIndex, setMatchingIndex] = useState(null);
  const [task, setTask] = useState(null);

  const [typeCustoms, setTypeCustoms] = useState([]);
  const [renderTypes, setRenderTypes] = useState([]);
  const [currentTable, setCurrentTable] = useState([]);
  const [chartsChatData, setChartsChatData] = useState([]);
  const [chartsType, setChartsType] = useState([]);
  const [selectedRep, setSelectedRep] = useState(0);
  const [shouldScroll, setShouldScroll] = useState(false);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [sqlDescription, setSqlDescription] = useState("");
  const updateFilters = (newFilters) => {
    setFilters(newFilters);
  };

  useEffect(() => {
    localStorage.setItem("allTableNames", JSON.stringify(allTableNames));
  }, [allTableNames]);

  const updateInfoCards = (newInfoCards) => {
    const filteredInfoCards = newInfoCards.filter((card) => {
      return card.data.length > 0 && card.data[0].count !== null;
    });

    setInfoCards(filteredInfoCards);
  };

  const updateSqlQuery = (newSqlQuery) => {
    setSqlQuery(newSqlQuery);
  };

  const updateUsersList = (newUsersList) => {
    setUsersList(newUsersList);
  };

  const updateMessageInput = (newMessageInput) => {
    setCsvInput(newMessageInput);
  };

  const updateJsxContent = (newContent) => {
    setJsxContent(newContent);
  };

  const updateEmailPng = (newImagePng) => {
    setEmailPng(newImagePng);
  };

  const getTableInfo = async () => {
    if (!tableName) return; // Ensure there's a tableName set before fetching its configuration

    const { data, error } = await privateSupabase.rpc(
      "get_columns_definition",
      {
        target_table_name: tableName,
      }
    );
    if (data) {
      setTableDefinition(data);
    }
  };

  useEffect(() => {
    getTableInfo();
  }, [tableName]);

  return (
    <FilterContext.Provider
      value={{
        filters,
        updateFilters,
        sqlQuery,
        updateSqlQuery,
        usersList,
        updateUsersList,
        emailPng,
        updateEmailPng,
        csvInput,
        updateMessageInput,
        jsxContent,
        updateJsxContent,
        chartRequest,
        setChartRequest,
        tableName,
        setTableName,
        insertItems,
        setInsertItems,
        columnConfigurations,
        setColumnConfigurations,
        removedCharts,
        setRemovedCharts,
        tableDefinition,
        setTableDefinition,
        infoCards,
        updateInfoCards,
        pageCode,
        setPageCode,
        updatedPageCode,
        setUpdatedPageCode,
        organizeCards,
        setOrganizeCards,
        updatedOrganizedCards,
        setUpdatedOrganizedCards,
        selectedList,
        setSelectedList,
        listReadyToSend,
        setListReadyToSend,
        currentlyOrganizing,
        setCurrentlyOrganizing,
        allTableNames,
        setAllTableNames,
        tableToGetColumns,
        setTableToGetColumns,
        tableForSegments,
        setTablesForSegment,
        tablesExplanation,
        setTablesExplanation,
        activeRoute,
        setActiveRoute,
        openCreateCustom,
        setOpenCreateCustom,
        openEditDashboard,
        setOpenEditDashboard,
        typeCustom,
        setTypeCustom,
        renderChart,
        setRenderChart,
        tableStep,
        setTableStep,
        segmentStep,
        setSegmentStep,
        chartStep,
        setChartStep,
        hasMessages,
        setHasMessages,
        phraseText,
        setPhraseText,
        tableData,
        setTableData,
        tableHeaders,
        setTableHeaders,
        tableTitle,
        setTableTitle,
        tableDescription,
        setTableDescription,
        chartTableData,
        setChartTableData,
        chartChatData,
        setChartChatData,
        initialMessage,
        setInitialMessage,
        chartType,
        setChartType,
        noDataQuery,
        setNoDataQuery,
        dashboardChart,
        setDashboardChart,
        dashboardCreatorInit,
        setDashboardCreatorInit,
        dashboardTable,
        setDashboardTable,
        allSelectedTablesInfo,
        setAllSelectedTablesInfo,
        datapointInfo,
        setDatapointInfo,
        cleanedQuery,
        setCleanedQuery,
        messages,
        setMessages,
        creatingFromReport,
        setCreatingFromReport,
        createdElement,
        setCreatedElement,
        currentCardPage,
        setCurrentCardPage,
        itemsCardPerPage,
        setItemsCardPerPage,
        totalCardItems,
        setTotalCardItems,
        currentPageInfo,
        setCurrentPageInfo,
        pageNumbers,
        setPageNumbers,
        currentView,
        setCurrentView,
        currentPage,
        setCurrentPage,
        itemsPerPage,
        setItemsPerPage,
        displayedSourcesData,
        setDisplayedSourcesData,
        companyLogo,
        setCompanyLogo,
        editedTitle,
        setEditedTitle,
        open,
        setOpen,
        typeUsage,
        setTypeUsage,
        renderType,
        setRenderType,
        querySteps,
        setQuerySteps,
        queryError,
        setQueryError,
        queryErrorCode,
        setQueryErrorCode,
        queryErrorHint,
        setQueryErrorHint,
        queryErrorExplanation,
        setQueryErrorExplanation,
        newThreadId,
        setNewThreadId,
        lastSqlQuery,
        setLastSqlQuery,
        threadId,
        setThreadId,
        isLarge,
        setIsLarge,
        size,
        setSize,
        chatHistory,
        setChatHistory,
        tables,
        setTables,
        prevThreadId,
        setPrevThreadId,
        matchingIndex,
        setMatchingIndex,
        task,
        setTask,
        typeCustoms,
        setTypeCustoms,
        renderTypes,
        setRenderTypes,
        currentTable,
        setCurrentTable,
        chartsChatData,
        setChartsChatData,
        chartsType,
        setChartsType,
        selectedRep,
        setSelectedRep,
        shouldScroll,
        setShouldScroll,
        message,
        setMessage,
        sqlDescription,
        setSqlDescription,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

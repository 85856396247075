/** @format */

import { SupabaseClient } from "@supabase/supabase-js"; // Ensure you have the right import for your Supabase client

export interface ColumnTypes {
  [key: string]: string;
}

export interface FilterOptions {
  [key: string]: string[];
}

interface Filters {
  [key: string]: string[];
}

const transformTimestampToDate = (data: any[], columnTypes: ColumnTypes) => {
  return data.map((row) => {
    const transformedRow = { ...row };
    Object.keys(columnTypes).forEach((column) => {
      if (
        columnTypes[column] === "timestamp" ||
        columnTypes[column] === "timestampz"
      ) {
        const dateValue = new Date(transformedRow[column]);
        if (isNaN(dateValue.getTime())) {
          console.warn(
            `Invalid date value for column ${column}: ${transformedRow[column]}`
          );
          transformedRow[column] = null; // Or any default value you want to set for invalid dates
        } else {
          transformedRow[column] = dateValue.toISOString().split("T")[0];
        }
      }
    });
    return transformedRow;
  });
};

const getDateRange = (range: string): [string, string] | null => {
  const now = new Date();
  let startDate: Date;
  let endDate: Date = now;

  switch (range) {
    case "last_year":
      startDate = new Date(
        now.getFullYear() - 1,
        now.getMonth(),
        now.getDate()
      );
      break;
    case "last_month":
      startDate = new Date(
        now.getFullYear(),
        now.getMonth() - 1,
        now.getDate()
      );
      break;
    case "last_week":
      startDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 7
      );
      break;
    case "today":
      startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1); // Include the entire day
      break;
    case "all_time":
      startDate = new Date(1970, 0, 1);
      endDate = now;
      break;
    default:
      return null;
  }
  return [startDate.toISOString(), endDate.toISOString()];
};

const fetchData = async (
  supabase: SupabaseClient,
  tableName: string,
  filters: Filters,
  searchInput: string,
  columnTypes: ColumnTypes,
  fromIndex: number,
  toIndex: number
) => {
  let query = supabase
    .from(tableName)
    .select("*", { count: "exact" })
    .range(fromIndex, toIndex - 1);

  // Apply filters
  Object.keys(filters).forEach((column) => {
    if (filters[column].length > 0) {
      filters[column].forEach((filterValue) => {
        if (filterValue.includes(",")) {
          const [start, end] = filterValue.split(",");
          query = query
            .gte(column, new Date(start).toISOString())
            .lte(column, new Date(end).toISOString());
        } else {
          const dateRange = getDateRange(filterValue);
          if (dateRange) {
            const [startDate, endDate] = dateRange;
            query = query.gte(column, startDate).lte(column, endDate);
          } else {
            query = query.eq(column, filterValue);
          }
        }
      });
    }
  });

  // Apply search filter
  if (searchInput) {
    query = query.ilike("name", `%${searchInput}%`);
  }

  const { data, error, count } = await query;

  if (error) {
    console.error(`Error getting info from table ${tableName}`, error);
    return { data: [], totalItems: 0 };
  }

  if (!data || !count) {
    return { data: [], totalItems: 0 };
  }

  const transformedData = transformTimestampToDate(data, columnTypes);

  return { data: transformedData, totalItems: count };
};

const fetchFilterOptions = async (
  supabase: SupabaseClient,
  tableName: string,
  columnTypes: ColumnTypes
) => {
  const { data, error } = await supabase.from(tableName).select("*");
  if (error) {
    console.error(
      `Error getting filter options from table ${tableName}`,
      error
    );
    return {};
  }

  const transformedData = transformTimestampToDate(data, columnTypes);

  const newFilterOptions: FilterOptions = {};

  transformedData.forEach((item: any) => {
    Object.keys(item).forEach((column) => {
      if (!newFilterOptions[column]) {
        newFilterOptions[column] = [];
      }
      if (item[column] !== null && item[column] !== undefined) {
        newFilterOptions[column].push(item[column]);
      }
    });
  });

  // Remove duplicates
  Object.keys(newFilterOptions).forEach((column) => {
    newFilterOptions[column] = Array.from(new Set(newFilterOptions[column]));
  });

  const mappedFilterOptions: FilterOptions = {};
  Object.keys(newFilterOptions).forEach((column) => {
    const displayName = mapColumnNameToDisplayName(column);
    mappedFilterOptions[displayName] = newFilterOptions[column];
  });

  return mappedFilterOptions;
};

// Mapping functions
const mapColumnNameToDisplayName = (columnName: string) => {
  const mapping: Record<string, string> = {
    name: "Name",
    created_by: "Created by",
    last_edited: "Last Edited",
  };
  return mapping[columnName] || columnName;
};

const mapDisplayNameToColumnName = (displayName: string) => {
  const mapping: Record<string, string> = {
    Name: "name",
    "Created by": "created_by",
    "Last Edited": "last_edited",
  };
  return mapping[displayName] || displayName;
};

const applyFilter = async (
  supabase: SupabaseClient,
  tableName: string,
  filters: Filters,
  displayColumn: string,
  value: string[],
  columnTypes: ColumnTypes
) => {
  const column = mapDisplayNameToColumnName(displayColumn); // Map display name to column name
  const updatedFilters = { ...filters, [column]: value };

  let query = supabase.from(tableName).select("*");

  Object.keys(updatedFilters).forEach((col) => {
    if (updatedFilters[col].length > 0) {
      updatedFilters[col].forEach((filterValue) => {
        if (filterValue.includes(",")) {
          const [start, end] = filterValue.split(",");
          query = query
            .gte(col, new Date(start).toISOString())
            .lte(col, new Date(end).toISOString());
        } else {
          const dateRange = getDateRange(filterValue);
          if (dateRange) {
            const [startDate, endDate] = dateRange;
            query = query.gte(col, startDate).lte(col, endDate);
          } else {
            query = query.eq(col, filterValue);
          }
        }
      });
    }
  });

  const { data, error } = await query;
  if (error) {
    console.error(
      `Error getting filter options from table ${tableName}`,
      error
    );
    return {};
  }

  const transformedData = transformTimestampToDate(data, columnTypes);

  const newFilterOptions: FilterOptions = {};

  transformedData.forEach((item: any) => {
    Object.keys(item).forEach((col) => {
      if (!newFilterOptions[col]) {
        newFilterOptions[col] = [];
      }
      if (item[col] !== null && item[col] !== undefined) {
        newFilterOptions[col].push(item[col]);
      }
    });
  });

  // Remove duplicates and ensure selected options are preserved
  Object.keys(newFilterOptions).forEach((col) => {
    newFilterOptions[col] = Array.from(new Set(newFilterOptions[col]));
  });

  const mappedFilterOptions: FilterOptions = {};
  Object.keys(newFilterOptions).forEach((col) => {
    const displayName = mapColumnNameToDisplayName(col);
    mappedFilterOptions[displayName] = newFilterOptions[col];
  });

  return mappedFilterOptions;
};

const removeFilter = async (
  supabase: SupabaseClient,
  tableName: string,
  filters: Filters,
  displayColumn: string,
  columnTypes: ColumnTypes
) => {
  const column = mapDisplayNameToColumnName(displayColumn); // Map display name to column name
  const updatedFilters = { ...filters };
  delete updatedFilters[column];

  let query = supabase.from(tableName).select("*");

  Object.keys(updatedFilters).forEach((col) => {
    if (updatedFilters[col].length > 0) {
      updatedFilters[col].forEach((filterValue) => {
        if (filterValue.includes(",")) {
          const [start, end] = filterValue.split(",");
          query = query
            .gte(col, new Date(start).toISOString())
            .lte(col, new Date(end).toISOString());
        } else {
          const dateRange = getDateRange(filterValue);
          if (dateRange) {
            const [startDate, endDate] = dateRange;
            query = query.gte(col, startDate).lte(col, endDate);
          } else {
            query = query.eq(col, filterValue);
          }
        }
      });
    }
  });

  const { data, error } = await query;
  if (error) {
    console.error(
      `Error getting filter options from table ${tableName}`,
      error
    );
    return {};
  }

  const transformedData = transformTimestampToDate(data, columnTypes);

  const newFilterOptions: FilterOptions = {};

  transformedData.forEach((item: any) => {
    Object.keys(item).forEach((col) => {
      if (!newFilterOptions[col]) {
        newFilterOptions[col] = [];
      }
      if (item[col] !== null && item[col] !== undefined) {
        newFilterOptions[col].push(item[col]);
      }
    });
  });

  // Remove duplicates and ensure selected options are preserved
  Object.keys(newFilterOptions).forEach((col) => {
    newFilterOptions[col] = Array.from(new Set(newFilterOptions[col]));
  });

  const mappedFilterOptions: FilterOptions = {};
  Object.keys(newFilterOptions).forEach((col) => {
    const displayName = mapColumnNameToDisplayName(col);
    mappedFilterOptions[displayName] = newFilterOptions[col];
  });

  return mappedFilterOptions;
};

const removeFilterWithData = (
  data: any[],
  filters: Filters,
  displayColumn: string,
  columnTypes: ColumnTypes
) => {
  const column = mapDisplayNameToColumnName(displayColumn);
  const updatedFilters = { ...filters };
  delete updatedFilters[column];

  // Filter the data based on the updated filters
  const filteredData = data.filter((item) => {
    return Object.entries(updatedFilters).every(([col, filterValues]) => {
      if (filterValues.length === 0) return true;

      return filterValues.some((filterValue) => {
        if (filterValue.includes(",")) {
          const [start, end] = filterValue.split(",");
          const itemDate = new Date(item[col]);
          return itemDate >= new Date(start) && itemDate <= new Date(end);
        } else {
          const dateRange = getDateRange(filterValue);
          if (dateRange) {
            const [startDate, endDate] = dateRange;
            const itemDate = new Date(item[col]);
            return (
              itemDate >= new Date(startDate) && itemDate <= new Date(endDate)
            );
          } else {
            return item[col] === filterValue;
          }
        }
      });
    });
  });

  const transformedData = transformTimestampToDate(filteredData, columnTypes);

  const newFilterOptions: FilterOptions = {};

  transformedData.forEach((item: any) => {
    Object.keys(item).forEach((col) => {
      if (!newFilterOptions[col]) {
        newFilterOptions[col] = [];
      }
      if (item[col] !== null && item[col] !== undefined) {
        newFilterOptions[col].push(item[col]);
      }
    });
  });

  // Remove duplicates and ensure selected options are preserved
  Object.keys(newFilterOptions).forEach((col) => {
    newFilterOptions[col] = Array.from(new Set(newFilterOptions[col]));
  });

  const mappedFilterOptions: FilterOptions = {};
  Object.keys(newFilterOptions).forEach((col) => {
    const displayName = mapColumnNameToDisplayName(col);
    mappedFilterOptions[displayName] = newFilterOptions[col];
  });

  return mappedFilterOptions;
};

const createFilterOptions = (data: any[], columnTypes: ColumnTypes) => {
  const transformedData = transformTimestampToDate(data, columnTypes);

  const newFilterOptions: FilterOptions = {};

  transformedData.forEach((item: any) => {
    Object.keys(item).forEach((column) => {
      if (!newFilterOptions[column]) {
        newFilterOptions[column] = [];
      }
      if (item[column] !== null && item[column] !== undefined) {
        newFilterOptions[column].push(item[column]);
      }
    });
  });

  // Remove duplicates
  Object.keys(newFilterOptions).forEach((column) => {
    newFilterOptions[column] = Array.from(new Set(newFilterOptions[column]));
  });

  const mappedFilterOptions: FilterOptions = {};
  Object.keys(newFilterOptions).forEach((column) => {
    const displayName = mapColumnNameToDisplayName(column);
    mappedFilterOptions[displayName] = newFilterOptions[column];
  });
  return mappedFilterOptions;
};

const applyFilterWithSql = async (
  supabase: SupabaseClient,
  sql: string,
  filters: Filters,
  displayColumn: string,
  value: string[],
  columnTypes: ColumnTypes
) => {
  const column = mapDisplayNameToColumnName(displayColumn);
  const updatedFilters = { ...filters, [column]: value };

  // Start with the base SQL query
  let query = sql.replace(/;$/, "");
  const params: any[] = [];
  let paramIndex = 1;

  // Build WHERE clauses for filters
  const whereClauses: string[] = [];
  Object.entries(updatedFilters).forEach(([col, filterValues]) => {
    if (filterValues.length > 0) {
      filterValues.forEach((filterValue) => {
        if (filterValue.includes(",")) {
          const [start, end] = filterValue.split(",");
          whereClauses.push(
            `${col} >= $${paramIndex} AND ${col} <= $${paramIndex + 1}`
          );
          params.push(
            new Date(start).toISOString(),
            new Date(end).toISOString()
          );
          paramIndex += 2;
        } else {
          const dateRange = getDateRange(filterValue);
          if (dateRange) {
            const [startDate, endDate] = dateRange;
            whereClauses.push(
              `${col} >= $${paramIndex} AND ${col} <= $${paramIndex + 1}`
            );
            params.push(startDate, endDate);
            paramIndex += 2;
          } else {
            whereClauses.push(`${col} = $${paramIndex}`);
            params.push(filterValue);
            paramIndex++;
          }
        }
      });
    }
  });

  // Append WHERE clauses to the query if there are any
  if (whereClauses.length > 0) {
    // Check if the query already has a WHERE clause
    if (query.toLowerCase().includes("where")) {
      query += ` AND (${whereClauses.join(" AND ")})`;
    } else {
      // Find the position of GROUP BY, ORDER BY, or LIMIT, whichever comes first
      const groupByIndex = query.toLowerCase().indexOf("group by");
      const orderByIndex = query.toLowerCase().indexOf("order by");
      const limitIndex = query.toLowerCase().indexOf("limit");

      let insertIndex = query.length;
      if (groupByIndex !== -1)
        insertIndex = Math.min(insertIndex, groupByIndex);
      if (orderByIndex !== -1)
        insertIndex = Math.min(insertIndex, orderByIndex);
      if (limitIndex !== -1) insertIndex = Math.min(insertIndex, limitIndex);

      // Insert the WHERE clause at the appropriate position
      query =
        query.slice(0, insertIndex) +
        ` WHERE ${whereClauses.join(" AND ")} ` +
        query.slice(insertIndex);
    }
  }

  const newClauses = replacePlaceholders(query, params);
  // Execute the query
  // const { data, error } = await supabase.rpc('custom_sql_function', { sql_query: query, params });

  const { data, error } = await supabase.rpc("execute_dynamic_query_json_new", {
    query_text: newClauses,
  });

  if (error) {
    console.error(`Error executing custom SQL query`, error);
    return {};
  }

  const transformedData = transformTimestampToDate(data, columnTypes);

  const newFilterOptions: FilterOptions = {};

  transformedData.forEach((item: any) => {
    Object.keys(item).forEach((col) => {
      if (!newFilterOptions[col]) {
        newFilterOptions[col] = [];
      }
      if (item[col] !== null && item[col] !== undefined) {
        newFilterOptions[col].push(item[col]);
      }
    });
  });

  // Remove duplicates and ensure selected options are preserved
  Object.keys(newFilterOptions).forEach((col) => {
    newFilterOptions[col] = Array.from(new Set(newFilterOptions[col]));
  });

  const mappedFilterOptions: FilterOptions = {};
  Object.keys(newFilterOptions).forEach((col) => {
    const displayName = mapColumnNameToDisplayName(col);
    mappedFilterOptions[displayName] = newFilterOptions[col];
  });

  return mappedFilterOptions;
};

const replacePlaceholders = (query: string, params: string[]) => {
  let modifiedQuery = query;

  if (params.length > 1) {
    // Handle multiple parameters (IN clause)
    const whereClauseIndex = modifiedQuery.toUpperCase().indexOf("WHERE");
    if (whereClauseIndex === -1) {
      throw new Error("WHERE clause not found in the original query.");
    }

    const beforeWhere = modifiedQuery.substring(0, whereClauseIndex + 5); // +5 to include "WHERE"
    const afterWhere = modifiedQuery.substring(whereClauseIndex + 5);

    const inClause = `Campaign IN (${params.map((param) => `'${param}'`).join(", ")})`;
    modifiedQuery = `${beforeWhere} ${inClause} ${afterWhere.replace(/(\w+)\s*=\s*\$\d+(\s+AND\s+)?/g, "")}`;
  } else {
    // Handle single parameter
    modifiedQuery = modifiedQuery.replace(
      /(\w+)\s*=\s*\$1(\s+AND\s+)?/,
      `$1 = '${params[0]}'`
    );
  }

  // Remove any trailing AND if present
  modifiedQuery = modifiedQuery.replace(
    /\s+AND\s*($|\s+GROUP BY|\s+ORDER BY|\s+LIMIT)/i,
    "$1"
  );

  return modifiedQuery;
};

const filterData = (data: any[], filters: Filters) => {
  return data.filter((item) => {
    return Object.entries(filters).every(([column, filterValues]) => {
      if (filterValues.length === 0) return true;

      return filterValues.some((filterValue) => {
        const strFilterValue = String(filterValue);
        if (strFilterValue.includes(",")) {
          const [start, end] = strFilterValue.split(",");
          const itemDate = new Date(item[column]);
          return itemDate >= new Date(start) && itemDate <= new Date(end);
        } else {
          const dateRange = getDateRange(strFilterValue);
          if (dateRange) {
            const [startDate, endDate] = dateRange;
            const itemDate = new Date(item[column]);
            return (
              itemDate >= new Date(startDate) && itemDate <= new Date(endDate)
            );
          } else {
            const caseInsensitve = getCaseInsensitiveValue(item, column);
            return caseInsensitve == strFilterValue;
          }
        }
      });
    });
  });
};

const areArraysEqual = (arr1: any, arr2: any) => {
  if (arr1.length !== arr2.length) return false;
  return arr1.every((item: any, index: number) => {
    return JSON.stringify(item) === JSON.stringify(arr2[index]);
  });
};

const getCaseInsensitiveValue = (obj: any, key: string) => {
  // Convert the key to lowercase, remove spaces and underscores
  const keyNormalized = key.toLowerCase().replace(/[\s_]/g, "");

  // Find a matching key, ignoring case, spaces, and underscores
  const matchingKey = Object.keys(obj).find((k) => {
    const kNormalized = k.toLowerCase().replace(/[\s_]/g, "");
    return kNormalized === keyNormalized;
  });

  return matchingKey ? obj[matchingKey] : undefined;
};

export {
  fetchData,
  fetchFilterOptions,
  applyFilter,
  removeFilter,
  transformTimestampToDate,
  getDateRange,
  createFilterOptions,
  removeFilterWithData,
  applyFilterWithSql,
  filterData,
  areArraysEqual,
  getCaseInsensitiveValue,
};

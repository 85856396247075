
import React from "react";
import { ShareIcon } from "@heroicons/react/24/outline";

const ShareIconButton = ({
    isDisabled,
    onClick
}: {
    isDisabled: boolean
    onClick: () => void;
}) => {
    return (
        <button disabled={isDisabled}
            className={`flex px-4 py-2 text-gray-800 w-full ${isDisabled ? "" : "hover:text-gray-500"} text-left items-center gap-2`}
            onClick={onClick}
        >
            <ShareIcon className="w-5 h-5" />
        </button>
    )
}

export default ShareIconButton;
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Dialog, Input } from "@material-tailwind/react";
import { fetchPrivateSetup, getCurrentUser, privateSupabase, publicSupabase } from '../../../../api/SupabaseClient';
import arrowIcon from "../../../../assets/icons/arrow_icon.svg";
import toast from 'react-hot-toast';
import { t } from 'i18next';
import { ModalFooter, ModalHeader } from '../../../../components/Modal';

interface Role {
    id: string;
    role_description: string;
}

interface InviteMembersProps {
    inviteDialogOpen: boolean;
    setInviteDialogOpen: (open: boolean) => void;
    roles: Role[];
}

const InviteMembers = ({ inviteDialogOpen, setInviteDialogOpen, roles }: InviteMembersProps) => {
    const [member, setMember] = useState({ email: "", role: 1 });
    const [anonKey, setAnonKey] = useState("");
    const [projectUrl, setProjectUrl] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(false);

    useEffect(() => {
        const fetchUserAndSetup = async () => {
            const user = await getCurrentUser(privateSupabase);
            if (user) {
                const result = await fetchPrivateSetup(user?.email!);
                setAnonKey(result.data?.anon_key || "");
                setProjectUrl(result.data?.project_url || "");
            } else {
                console.error("User is not authenticated");
            }
        };
        fetchUserAndSetup();
    }, []);

    const validateEmail = (email: string) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const handleInviteMembers = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setMember((prevMember) => ({ ...prevMember, [name]: value }));

        if (name === 'email') {
            const isValid = validateEmail(value);
            setIsEmailValid(isValid);
        }
    };

    const inviteUser = async () => {
        const { email, role } = member;

        // Comprobar si el email ya existe en la tabla private_projects
        const { data: existingProjectEmails, error: projectEmailsError } = await publicSupabase
            .from("private_projects")
            .select("project_emails")
            .eq("anon_key", anonKey)
            .eq("project_url", projectUrl)
            .single();

        if (projectEmailsError) {
            setInviteDialogOpen(false);
            console.error("Error fetching project emails:", projectEmailsError);
            toast.error(t("ERROR") + "EO-1061");
            return;
        }

        if (existingProjectEmails && existingProjectEmails.project_emails.includes(email)) {
            setInviteDialogOpen(false);
            toast.error("User already invited");
            return;
        }

        // Comprobar si el email ya existe en la tabla invited_members
        const { data: existingInvitedMembers, error: invitedMembersError } = await publicSupabase
            .from("invited_members")
            .select("email")
            .eq("email", email)
            .single();

        if (invitedMembersError && invitedMembersError.code !== 'PGRST116') {
            setInviteDialogOpen(false);
            console.error("Error fetching invited members:", invitedMembersError);
            toast.error(t("ERROR") + "EO-1061");
            return;
        }

        if (existingInvitedMembers) {
            setInviteDialogOpen(false);
            toast.error("User already invited");
            return;
        }

        // Proceder con la invitación si el email no existe en ninguna de las tablas
        const { data: projectData, error: projectError } = await publicSupabase
            .from("private_projects")
            .select("*")
            .eq("anon_key", anonKey)
            .eq("project_url", projectUrl)
            .single();

        if (projectData) {
            const updatedEmails = [...projectData.project_emails, email];
            await publicSupabase
                .from("private_projects")
                .update({ project_emails: updatedEmails })
                .eq("id", projectData.id);

            await publicSupabase
                .from("invited_members")
                .insert({ email, role });

            const { data: invited_members, error: projectError } = await publicSupabase
                .from("invited_members")
                .select("id")
                .eq("email", email)
                .eq("role", role)
                .single();
            const id = invited_members?.id;

            const { error } = await privateSupabase.functions.invoke(
                "inviteUsers",
                {
                    body: JSON.stringify({ id, email }),
                }
            );

            if (error) {
                // Delete email from private_projects
                await publicSupabase
                    .from("private_projects")
                    .update({ project_emails: projectData.project_emails })
                    .eq("id", projectData.id);

                // Delete email from invited_members
                await publicSupabase
                    .from("invited_members")
                    .delete()
                    .eq("email", email)
                    .eq("role", role);

                setInviteDialogOpen(false);
                toast.error(t("ERROR") + "EO-1061");
            } else {
                setInviteDialogOpen(false);
                toast.success("New member successfully invited!");
            }
        }
    };

    return (
        <Dialog
            open={inviteDialogOpen}
            handler={setInviteDialogOpen} placeholder={undefined}>
            <ModalHeader title={t("INVITE_NEW_MEMBER")} onClose={() => setInviteDialogOpen(false)} />
            <div>
                <div className="flex flex-col" style={{ padding: "20px 80px" }}>
                    <label className="block" style={{ marginBottom: "20px" }}>
                        <span style={{ display: "flex", color: "#46474C", fontWeight: "400", fontSize: "16px", lineHeight: "24px", marginBottom: "20px" }}>{t("INVITE_EMAIL")}</span>
                        <Input
                            type="email"
                            name="email"
                            value={member.email}
                            onChange={handleInviteMembers}
                            placeholder={t("Email")} crossOrigin={undefined} />
                    </label>
                    <label className="block" style={{ marginBottom: "20px" }}>
                        <span style={{ display: "flex", color: "#46474C", fontWeight: "400", fontSize: "16px", lineHeight: "24px", marginBottom: "20px" }}>{t("INVITE_PERMISSION")}</span>
                        <div style={{ position: 'relative', width: '220px' }}>
                            <select
                                name="role"
                                style={{
                                    width: '100%',
                                    height: '40px',
                                    color: 'black',
                                    lineHeight: '24px',
                                    border: '1px solid #8A8D94',
                                    borderRadius: '8px',
                                    padding: '0 40px 0 10px',
                                    appearance: 'none',
                                    WebkitAppearance: 'none',
                                    MozAppearance: 'none',
                                }}
                                value={member.role}
                                onChange={handleInviteMembers}
                            >
                                {roles.map((role: any) => (
                                    <option key={role.id} value={role.id}>
                                        {t(role.role_description)}
                                    </option>
                                ))}
                            </select>
                            <div style={{
                                position: 'absolute',
                                right: '10px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                pointerEvents: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                            }}>
                                <img src={arrowIcon} alt="arrow icon" />
                            </div>
                        </div>
                    </label>
                </div>
            </div>
            <ModalFooter buttonText={t("INVITE")} onClick={inviteUser} disabled={!isEmailValid} />
        </Dialog>
    );
};

export default InviteMembers;

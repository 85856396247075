/** @format */

import React from "react";

interface InfoCardProps {
  title: string;
  data: number | string;
}

export const InfoCardMini: React.FC<InfoCardProps> = ({ title, data }) => {
  const formattedData =
    typeof data === "number" ? data.toLocaleString("de-DE") : data;
  return (
    <div className="bg-white flex flex-col justify-between shadow-md rounded-lg p-4">
      <div className="flex justify-center text-xs text-gray-500">
        {title}
      </div>
      <div className="flex justify-center text-lg font-bold">
        {formattedData}
      </div>
    </div>
  );
};

/** @format */

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationES from "./es.json";
import translationEN from "./en.json";

const currentLanguage = localStorage.getItem("i18nextLng") || "en";

i18n.use(initReactI18next).init({
  resources: {
    es: {
      translation: translationES,
    },
    en: {
      translation: translationEN,
    },
  },
  lng: currentLanguage, // Default language
  fallbackLng: "en", // Fallback language if translation is missing
  interpolation: {
    escapeValue: false, // React already does escaping
  },
});

export default i18n;

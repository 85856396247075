import React from "react";
import { Typography } from "@material-tailwind/react";
import { t } from "i18next";

interface DetailTitleProps {
    titleText: string;
    marginBottom: string;
}

const DetailsTitle = ({ titleText, marginBottom }: DetailTitleProps) => {
    return (
        <div>
            <Typography
                style={{
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "24px",
                    color: "#46474C",
                    marginBottom: marginBottom,
                }}
                className="text-lg flex-1 font-normal"
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
            >
                {t(titleText)}
            </Typography>
        </div>
    );
};

export default DetailsTitle;

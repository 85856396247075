// TableDataContext.tsx
import React, { createContext, useContext, useState, ReactNode } from "react";

interface TableData {
  headers: string[];
  rows: any[];
}

interface TableDataContextProps {
  tableData: TableData;
  setTableData: React.Dispatch<React.SetStateAction<TableData>>;
}

const TableDataContext = createContext<TableDataContextProps | undefined>(undefined);

export const useTableData = (): TableDataContextProps => {
  const context = useContext(TableDataContext);
  if (!context) {
    throw new Error("useTableData must be used within a TableDataProvider");
  }
  return context;
};

interface TableDataProviderProps {
  children: ReactNode;
}

export const TableDataProvider: React.FC<TableDataProviderProps> = ({ children }:any) => {
  const [tableData, setTableData] = useState<TableData>({ headers: [], rows: [] });

  return (
    <TableDataContext.Provider value={{ tableData, setTableData }}>
      {children}
    </TableDataContext.Provider>
  );
};

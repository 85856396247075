/** @format */

import { useState, useEffect } from "react";
import { getConfig } from "../config";
import { getCubeCompanyName } from "../api/SupabaseClient";

const config = getConfig();
const useCubeData = () => {
  const [cubeMetadata, setCubeMetadata] = useState<any>({});
  const [filterData, setFilterData] = useState<any>({});
  const [cubeData, setCubeData] = useState<any[]>([]);

  useEffect(() => {
    fetchCubeMetadata();
    fetchCubedata();
  }, []);

  const fetchCubeMetadata = async () => {
    const company_name = await getCubeCompanyName();
    const url =
      config.semanticLayer + `/api/getMeta?projectName=${company_name}`;

    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization:
          "CUBEJS-TOKEN eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjA2MDE4MDQsImV4cCI6MTcyMDY4ODIwNH0.BgbsrxCm_zJ6BWGhaL5i5vuz-bLVtICBoirUDJzVwUQ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCubeMetadata(data);
      })
      .catch((error) => console.error("Error:", error));
  };

  const fetchCubedata = async () => {
    const projectName = await getCubeCompanyName();
    const url =
      config.semanticLayer + `/company/company-cube-files/${projectName}`;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization:
          "CUBEJS-TOKEN eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjA2MDE4MDQsImV4cCI6MTcyMDY4ODIwNH0.BgbsrxCm_zJ6BWGhaL5i5vuz-bLVtICBoirUDJzVwUQ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const cubeDataArray = Object.entries(data).map(
          ([fileName, content]) => ({
            fileName,
            content,
          })
        );
        setCubeData(cubeDataArray);
      })
      .catch((error) => console.error("Error:", error));
  };

  const handleExecuteQueryCube = async (queryString: any): Promise<any> => {
    const url = config.semanticLayer + "/api/executeQuery";
    const company_name = await getCubeCompanyName();
    const query = {
      query: queryString,
      projectName: company_name,
    };

    // console.log("Query Object: ", query);

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization:
            "CUBEJS-TOKEN eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjA2MDE4MDQsImV4cCI6MTcyMDY4ODIwNH0.BgbsrxCm_zJ6BWGhaL5i5vuz-bLVtICBoirUDJzVwUQ",
        },
        body: JSON.stringify(query),
      });
      const data = await response.json();
      // console.log(data);
      setFilterData(data);
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  return {
    cubeMetadata,
    filterData,
    handleExecuteQueryCube,
    cubeData,
  };
};

export default useCubeData;

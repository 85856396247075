/** @format */
import React, { useEffect, useState } from "react";
import { privateSupabase, userSession } from "../../../api/SupabaseClient";
import { useSupabase } from "../../../context/supabaseContext";
import MemberDetails from "./Member/MemberDetails";

export const defaultPlaceholder = "https://via.placeholder.com/150";
export function Members() {
  const { getUserRole } = useSupabase();
  const [role, setRole] = useState<number>(3);
  const [session, setSession] = useState<any>(null);

  useEffect(() => {
    const handleAuthStateChange = (_event: any, newSession: any) => {
      setSession(newSession);
    };

    userSession().then((session: any) => {
      setSession(session.data.session);
    });

    privateSupabase.auth.onAuthStateChange(handleAuthStateChange);
  }, [])

  useEffect(() => {
    const fetchUserRole = async () => {
      const userRole = await getUserRole();
      setRole(userRole);
    }
    fetchUserRole();
  }, [session]);

  return (
    <MemberDetails role={role} />
  );
}

// utils/formatDate.ts

/**
 * Transforms a date from YYYY-MM-DD format to DD/MM/YYYY format.
 * @param dateStr - The date string in YYYY-MM-DD format.
 * @returns The date string in DD/MM/YYYY format.
 */
const formatDate = (dateStr: string): string => {
    const [year, month, day] = dateStr.split("-");
    return `${day}/${month}/${year}`;
  };
  
  export default formatDate;
  
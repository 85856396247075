/** @format */

import { privateSupabase } from "../api/SupabaseClient";

export const transformSqlToSupabaseQuery = (sql: any, table: any) => {
  const hasNewlineOrSemicolon = /\n|;\s*$/.test(sql);
  const sanitizedSql = hasNewlineOrSemicolon
    ? sql.replace(/\n/g, " ").replace(/;\s*$/, "")
    : sql;

  let query = privateSupabase.from(table).select("*");

  // Check for WHERE clause
  const whereClauseMatch = sanitizedSql.match(/WHERE (.+?)( ORDER| LIMIT|$)/);
  if (whereClauseMatch) {
    const conditions = whereClauseMatch[1].split(" AND ");
    conditions.forEach((condition: any) => {
      const [key, operator, value] = parseCondition(condition);
      if (key && operator && value !== undefined) {
        query = applyConditionToQuery(query, key, operator, value);
      }
    });
  }

  // Check for ORDER BY clause
  const orderClauseMatch = sanitizedSql.match(/ORDER BY (\w+) (ASC|DESC)/);
  if (orderClauseMatch) {
    query = query.order(orderClauseMatch[1], {
      ascending: orderClauseMatch[2].toUpperCase() === "ASC",
    });
  }

  // Check for LIMIT clause
  const limitClauseMatch = sanitizedSql.match(/LIMIT (\d+)/);
  if (limitClauseMatch) {
    query = query.limit(parseInt(limitClauseMatch[1]));
  }

  return query;
};

export const executeSQLQuery = async (
  query: string,
  limit: number = 5,
  offset: number = 0,
  type?: string
) => {
  try {
    const cleanQuery = query.replace(/;$/, "");
    let paginatedQuery = cleanQuery;
    if (type === "table") {
    // Check if the original query contains a LIMIT clause
    if (/LIMIT\s+\d+/i.test(cleanQuery)) {
      // If LIMIT exists, only add OFFSET
      paginatedQuery = `${cleanQuery} OFFSET ${offset}`;
    } else {
      // If LIMIT does not exist, add both LIMIT and OFFSET
      paginatedQuery = `${cleanQuery} LIMIT ${limit} OFFSET ${offset}`;
    }
  }
    const { data, error } = await privateSupabase.rpc(
      "execute_dynamic_query_json_new",
      {
        query_text: paginatedQuery,
      }
    );
    if (error) throw error;
    if (data.length === 0) {
      console.log("No data fetched from that query");
    }
    return data;
  } catch (error) {
    console.error("Error executing SQL query:", error);
    throw error;
  }
};

export const getTotalCount = async (query: string) => {
  try {
    const cleanQuery = query.replace(/;$/, ""); // Remove the trailing semicolon if it exists
    const countQuery = `SELECT COUNT(*) FROM (${cleanQuery}) AS total`;
    const { data, error } = await privateSupabase.rpc(
      "execute_dynamic_query_json_new",
      {
        query_text: countQuery,
      }
    );
    if (error) throw error;
    const total = data[0]?.count || 0;
    return total;
  } catch (error) {
    console.error("Error getting total count:", error);
    throw error;
  }
};

const parseCondition = (conditionString: any) => {
  const match = conditionString.match(
    /(\w+)\s*(=|<|>|<=|>=|<>|BETWEEN)\s*(.+)/
  );
  if (match) {
    const key = match[1];
    const operator = match[2];
    let value = match[3].replace(/'/g, "");
    return [key, operator, value];
  }
  return [null, null, null];
};

const applyConditionToQuery = (
  query: any,
  key: any,
  operator: any,
  value: any
) => {
  switch (operator) {
    case "=":
      return query.eq(key, value);
    case "<":
      return query.lt(key, value);
    case ">":
      return query.gt(key, value);
    case "<=":
      return query.lte(key, value);
    case ">=":
      return query.gte(key, value);
    case "<>":
      return query.neq(key, value);
    case "BETWEEN":
      const rangeValues = value.split(" AND ").map(Number);
      if (
        rangeValues.length === 2 &&
        rangeValues.every((v: any) => !isNaN(v))
      ) {
        const [start, end] = rangeValues;
        return query.gte(key, start).lte(key, end);
      }
      break;
    default:
      return query;
  }
};

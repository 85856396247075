/** @format */

import React, { useState } from "react";
import {
  EllipsisVerticalIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import DeleteIconButton from "./Buttons/DeleteIconButton";
import DuplicateIconButton from "./Buttons/DuplicateIconButton";

const ReportsOptions = ({
  onDelete,
  onDuplicate,
  onRename,
}: {
  onDelete: () => void;
  onDuplicate: () => void;
  onRename: () => void;
}) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  const handleDuplicateClick = () => {
    setIsOptionsOpen(false);
    onDuplicate();
  };

  const handleDeleteClick = () => {
    setIsOptionsOpen(false);
    onDelete();
  };

  const handleRenameClick = () => {
    setIsOptionsOpen(false);
    onRename();
  };

  return (
    <div className="relative p-4" onClick={(e) => e.stopPropagation()}>
      <EllipsisVerticalIcon
        className="w-5 h-5 cursor-pointer"
        onClick={() => setIsOptionsOpen(!isOptionsOpen)}
      />
      {isOptionsOpen && (
        <div className="absolute right-0 top-8 w-36 bg-white border border-gray-200 rounded-lg shadow-lg z-10">
          <button
            className="flex px-4 py-2 text-gray-800 hover:bg-gray-100 w-full text-left items-center gap-2"
            onClick={handleRenameClick}
          >
            <PencilSquareIcon className="w-5 h-5" />
            Rename
          </button>
          <DuplicateIconButton showText={true} onClick={handleDuplicateClick} />
          <DeleteIconButton showText={true} onDelete={handleDeleteClick} />
        </div>
      )}
    </div>
  );
};

export default ReportsOptions;
